const phoenix = {
  gradients: [
    "linear-gradient(to top,#37b3ff 0,#84d0ff 100%)", // tm-blue
    // "linear-gradient(to right,#5b1991 0,#fe0070 100%)", // tm-magenta
    // "linear-gradient(to right,#00877c 0,#c6e200 100%)", // tm-green
    // "linear-gradient(to right,#eb006f 0,#ffa400 100%)" // tm-orange
  ],
  logoImage: {
    src: "/images/telstra.svg",
    alt: "Telstra",
    styles: `
  height: 39px;
  margin-top: 0px;
  margin-right: 10px;
`,
  },
};

const koala = {
  gradients: [
    "linear-gradient(135deg, rgba(0,128,128,1) 0%,rgba(255,255,255,1) 100%)",
  ],
  logoImage: {
    src: "/images/kmart.svg",
    alt: "Kmart",
    styles: `
  height: 28px;
  margin-top: 0px;
  margin-right: 10px;
`,
  },
};

const falcon = {
  gradients: [
    // "linear-gradient(to right,#015786 0,#0c88c8 100%)" // dark-blue
    // "linear-gradient(to right,#2fbad4 0,#71c8d9 100%)" // teal-blue
    "linear-gradient(to right,#0dc9ec 0,#71c8d9 100%)", // teal-blue
  ],
  logoImage: {
    src: "/images/anz.svg",
    alt: "ANZ",
    styles: `
  height: 33px;
  margin-top: -5px;
  margin-right: 10px;
`,
  },
};

const auntie = {
  gradients: [
    // "linear-gradient(to right,#015786 0,#0c88c8 100%)" // dark-blue
    // "linear-gradient(to right,#2fbad4 0,#71c8d9 100%)" // teal-blue
    "linear-gradient(to right,#ECB800 0,#FFCE20 100%)", // teal-blue
  ],
  logoImage: {
    src: "/images/abc.svg",
    alt: "ABC",
    styles: `
  height: 34px;
  margin-top: 0;
  margin-right: 10px;
`,
  },
};

const osprey = {
  gradients: [
    "linear-gradient(to right,#de2619 0,#000000 100%)", // red-black
  ],
  logoImage: {
    src: "/images/nab.svg",
    alt: "NAB",
    styles: `
  height: 31px;
  margin-top: 0px;
  margin-right: 10px;
`,
  },
};

const bee = {
  gradients: [
    "linear-gradient(to right,#F5CF33 0,#FFDE5C 100%)", // yellow-yellow
  ],
  logoImage: {
    src: "/images/CBA_logo.svg",
    alt: "CBA",
    styles: `
  height: 36px;
  margin-right: 10px;
`,
  },
};

const wolf = {
  gradients: [
    "linear-gradient(to right,#ffdb00 0,#ffdb00 100%)", // yellow-yellow
  ],
  logoImage: {
    src: "/images/IKEA_logo.svg",
    alt: "IKEA",
    styles: `
  height: 34px;
  margin-right: 10px;
`,
  },
};

const lion = {
  gradients: [
    "linear-gradient(to right,#FF6200 0,#FF6200 100%)", // orange-orange
  ],
  logoImage: {
    src: "/images/ing.svg",
    alt: "ING",
    styles: `
  height: 28px;
  margin-top: 0px;
  margin-right: 10px;
`,
  },
};

const fallback = {
  gradients: ["linear-gradient(180deg, #46AFEF 0%, #0B6FAD 100%)"],
};

const customerConfigLookup = {
  falcon,
  phoenix,
  osprey,
  bee,
  wolf,
  lion,
  koala,
  auntie,
  default: fallback,
  "phoenix-te": phoenix,
};

const getCustomerConfig = (customer) => {
  if (!customer || !customerConfigLookup[customer]) {
    // return fallback config if we can't find the customer
    return fallback;
  }
  return customerConfigLookup[customer];
};

export default getCustomerConfig;
