import { reduce } from "lodash";
import { Avatar } from "orcs-design-system";
import styled from "styled-components";
import { getGradients } from "src/styles/gradient";

export const placeholderGradient = `linear-gradient(
    to right,
    rgb(246, 247, 248) 0%,
    rgb(237, 238, 241) 20%,
    rgb(246, 247, 248) 40%,
    rgb(246, 247, 248) 100%
  )`;

export const idAsNum = (id) => {
  return reduce(id, (num, _, index) => num + id.charCodeAt(index), 0);
};

export const getTeamGradient = (group, globalConfig) => {
  const gradients = getGradients(globalConfig);
  return group ? gradients[idAsNum(group.id) % gradients.length] : gradients[0];
};

export const AvatarWithGradient = styled(Avatar)`
  > div:first-child {
    background-image: ${({ bgGradient, loading = false }) =>
      bgGradient && !loading
        ? bgGradient
        : `linear-gradient(
        to right,
        rgb(246, 247, 248) 0%,
        rgb(237, 238, 241) 20%,
        rgb(246, 247, 248) 40%,
        rgb(246, 247, 248) 100%
      )`};
    background-size: cover;
    background-position: center center;

    > span {
      color: #fff;
    }
  }
`;
