const Defaults = {
  continuous: true,
  scrollToFirstStep: true,
  showProgress: true,
  showSkipButton: true,
  styles: {
    options: {
      zIndex: 10000,
      primaryColor: "#7211ED",
      width: 420,
    },
  },
};

export default Defaults;
