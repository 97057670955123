import {
  requestorForecastMainQuery,
  requestorForecastMainQueryForGroup,
  approverForecastMainQuery,
  approverConstraintsMainQuery,
  approverPlannerMainQuery,
  requestorPlannerMainQuery,
  lineOfBusinessPlannerMainQuery,
} from "src/allocation/allocation.graphql";

import { FORECAST } from "../pageModes";
import { CURRENT_GROUP_ONLY_VIEW, GROUPED_BY_CONSTRAINTS } from "../viewModes";

const selectQuery = (
  pageMode,
  viewMode,
  isRequestorGroup,
  isLineOfBusinessGroup
) => {
  if (pageMode === FORECAST) {
    if (isRequestorGroup) {
      if (viewMode === CURRENT_GROUP_ONLY_VIEW) {
        return requestorForecastMainQueryForGroup;
      }

      return requestorForecastMainQuery;
    }

    if (viewMode === GROUPED_BY_CONSTRAINTS) {
      return approverConstraintsMainQuery;
    }

    return approverForecastMainQuery;
  }

  if (isRequestorGroup) {
    return requestorPlannerMainQuery;
  }

  if (isLineOfBusinessGroup) {
    return lineOfBusinessPlannerMainQuery;
  }

  return approverPlannerMainQuery;
};

export default selectQuery;
