import { useQuery } from "@apollo/client";
import { getFilterItems } from "./sidebar.graphql";
import { useSidebarContext, ACTIONS } from "./context";

const useGetSidebarData = (filters, shouldGetFilters) => {
  const [
    {
      query: { variables },
    },
    dispatch,
  ] = useSidebarContext();

  useQuery(getFilterItems, {
    variables: {
      ...variables,
      filter: filters,
    },
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      dispatch({
        type: ACTIONS.QUERY_COMPLETE,
        data,
      });
    },
    onError: (error) => {
      dispatch({ type: ACTIONS.QUERY_ERROR, error });
    },
    skip: !shouldGetFilters,
  });

  return null;
};

export default useGetSidebarData;
