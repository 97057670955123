import React, { useEffect } from "react";
import PropTypes from "prop-types";
import debug from "debug";

import { useVersion } from "src/contexts/global/GlobalContext";
import { useTenantId } from "src/contexts/global/ApolloSettingsContext";

import { reportError } from "../../services/errorReporting";
import ErrorMessage from "./ErrorMessage";

const debugLog = debug("orchestrated:error");

const useClientDiagnositicsInfo = () => {
  const tenantId = useTenantId();
  const version = useVersion();

  const UI_VERSION = process.env.REACT_APP_GIT_SHA
    ? process.env.REACT_APP_GIT_SHA.slice(0, 7)
    : "version_not_set";
  const UI_BUILD = process.env.REACT_APP_BUILD_NUMBER;
  return `Tenant: ${tenantId} UI_Version: ${UI_VERSION} [${UI_BUILD}] Backend_Version: ${version}`;
};
/**
 * @deprecated Please replace with appropriate level ../ErrorBoundary control
 * */
const ErrorPage = ({
  error,
  withDiagnostics = true,
  reportErrors = true,
  showHeader = true,
  showContainer = true,
}) => {
  useEffect(() => {
    if (!reportErrors) {
      return;
    }
    if (error) {
      reportError(error);
    }

    if (error) {
      if (error.networkError && error.networkError.response) {
        debugLog(error.networkError.response);
      } else {
        debugLog(error);
      }
    }
  }, [error, reportErrors]);

  const diagnostics = useClientDiagnositicsInfo();

  return (
    <ErrorMessage
      error={error}
      clientInfo={withDiagnostics ? diagnostics : undefined}
      showHeader={showHeader}
      showContainer={showContainer}
    />
  );
};

ErrorPage.propTypes = {
  error: PropTypes.object.isRequired,
  withDiagnostics: PropTypes.bool,
  reportErrors: PropTypes.bool,
  showHeader: PropTypes.bool,
  showContainer: PropTypes.bool,
};

export default ErrorPage;
