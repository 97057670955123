import memoizeOne from "memoize-one";
import { get, includes, reduce, first, size, filter, chain } from "lodash";
import { TAG_ATTRIBUTE_TYPES } from "src/consts/tags";
import urlPaths, { url, PERSON_TAB } from "src/consts/urlPaths";
import { isPowerOrAdmin } from "src/util/auth0";

export const SPECIALIST = "SPECIALIST";

export const getTagsOfType = (tags, type) => {
  return filter(tags, (tag) => tag.type === type);
};

export const getTagTypeName = (tagType, tagConfigTypes) => {
  return get(tagConfigTypes, [tagType, "name"], tagType);
};

export const getVisibleTagTypes = memoizeOne((tagConfig, component) => {
  const tagTypeIds = reduce(
    tagConfig,
    (prev, config, id) => {
      const { visibleIn } = config;

      if (visibleIn && includes(visibleIn, component)) {
        prev.push(id);
      }

      return prev;
    },
    []
  );

  return tagTypeIds;
});

export const isTagVisible = (tag, tagConfig, component) => {
  if (!tagConfig[tag.type]) {
    return false;
  }

  if (component) {
    const { visibleIn } = tagConfig[tag.type];

    if (visibleIn && includes(visibleIn, component)) {
      return true;
    }
  }

  return false;
};

export const isPersonEntityTag = (tagTypeConfig) => {
  const values = get(tagTypeConfig, "attributes.values");
  const attribute = first(values);
  return size(values) === 1 && attribute?.type === TAG_ATTRIBUTE_TYPES.PERSON;
};

export const onTagClick = (tag, history) => {
  if (tag && history) {
    const values = get(tag, "tagTypeConfig.attributes.values");
    const attribute = first(values);
    const hasOnlyPersonAttribute =
      size(values) === 1 && attribute?.type === TAG_ATTRIBUTE_TYPES.PERSON;
    const personUrl = get(tag, `attributes.[${attribute?.id}]`);
    const nextUrl =
      hasOnlyPersonAttribute && personUrl
        ? url(urlPaths.PERSON_DETAILS, {
            id: personUrl,
            tabType: PERSON_TAB.DETAILS,
          })
        : url(urlPaths.TAG_DETAILS, {
            id: tag.tagId || tag.id,
          });
    history.push(nextUrl);
  }
};

export const isTagTypeReadOnly = ({ tagType, tagConfig, user }) => {
  const readOnlyFromConfig = get(tagConfig, [tagType, "readOnly"], false);
  return readOnlyFromConfig || !isPowerOrAdmin(user);
};

export const getTagTypesWithShowingHistory = (tagTypesConfig) => {
  return chain(tagTypesConfig)
    .filter(({ showPreviousAppliedTags }) => showPreviousAppliedTags)
    .map("id")
    .value();
};
