/* eslint-disable no-param-reassign */
import { forEach, isFinite } from "lodash";
import setLineItemTotalCost from "./setLineItemTotalCost";

export const getBudgetStatus = (
  percentage,
  overBudgetLimit,
  underBudgetLimit
) => {
  const remaining = 100 - percentage;

  if (remaining >= 0) {
    return remaining <= underBudgetLimit ? null : "warning";
  }

  return Math.abs(remaining) > overBudgetLimit ? "danger" : null;
};

const setGroupingTotalCost = (
  grouping,
  { recalcChildren = false, overBudgetLimit, underBudgetLimit } = {}
) => {
  const { childGroupings, lineItems, budget } = grouping;

  let groupingTotalCost = 0;

  forEach(childGroupings, (child) => {
    if (recalcChildren) {
      setGroupingTotalCost(child, {
        recalcChildren: true,
        overBudgetLimit,
        underBudgetLimit,
      });
    }

    if (isFinite(child.totalCost)) {
      groupingTotalCost += child.totalCost;
    }
  });

  forEach(lineItems, (lineItem) => {
    if (recalcChildren) {
      setLineItemTotalCost(lineItem);
    }

    if (isFinite(lineItem.totalCost)) {
      groupingTotalCost += lineItem.totalCost;
    }
  });

  grouping.totalCost = groupingTotalCost;

  if (isFinite(budget) && budget !== 0) {
    const percentage = (groupingTotalCost * 100) / budget;
    grouping.budgetPercentage = percentage;
    grouping.budgetStatus = getBudgetStatus(
      percentage,
      overBudgetLimit,
      underBudgetLimit
    );
  }
};

export default setGroupingTotalCost;
