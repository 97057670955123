import { values, keyBy, forEach } from "lodash";

import {
  getConstraintColumns,
  getForecastMacroAllocations,
  setGroupingsWithActualConstraints,
  setGroupingsWithDefaultConstraints,
  setGroupingsWithForecastCells,
  setGroupingsWithForecastDefaultCells,
  setCalculatedSumTotals,
  setNotifications,
  pruneEmptyConstraintGroupings,
  buildGroupingLookup,
  buildLineItemLookup,
  buildCellLookup,
  buildConstraintCellLookup,
  hashGrouping,
} from "../util";

import buildConstraintGroupings from "./buildGroupings/constraintGroupings";
import setGroupingsWithLineItems from "./setLineItems/demandLineItems";

const buildGroupedByConstraints = ({
  rootGroup,
  data,
  hideHiddenTeams,
  groupTypes,
  shouldHash,
}) => {
  const allocationProjectLookup = keyBy(data.allocationProjects, "id");

  const columns = getConstraintColumns(data.allocationProjects);
  const macroAllocations = getForecastMacroAllocations(
    data.macroAllocations,
    data.allocationProjects
  );

  const groupings = buildConstraintGroupings({
    rootGroup: {
      ...rootGroup,
      totalCapacityFte: data.rootGroupTotalCapacityFte,
      directMembersCapacityFte: data.rootGroupDirectMembersCapacityFte,
    },
    macroAllocations,
    constraints: data.constraints,
    extraSupplyGroups: data.descendantSupplyGroups,
    columnCount: columns.length,
    hideHiddenTeams,
    groupTypes,
  });
  setGroupingsWithActualConstraints(
    groupings,
    data.constraints,
    allocationProjectLookup,
    columns
  );
  setGroupingsWithDefaultConstraints(
    groupings,
    allocationProjectLookup,
    columns
  );

  // to work out forecast totals we need line items & forecast cells (they are not shown in the UI)
  setGroupingsWithLineItems({
    groupings,
    macroAllocations,
    columnCount: columns.length,
  });
  setGroupingsWithForecastCells({
    groupings,
    macroAllocations,
    columns,
    groupedByDemand: false,
    rootGroup,
  });
  setGroupingsWithForecastDefaultCells(groupings, columns, false, rootGroup);
  setCalculatedSumTotals(groupings, columns);
  setNotifications(groupings, columns);

  pruneEmptyConstraintGroupings(groupings);

  const columnLookup = keyBy(columns, "id");
  const groupingLookup = buildGroupingLookup(groupings);
  const lineItemLookup = buildLineItemLookup(values(groupingLookup));
  const cellLookup = buildCellLookup(values(lineItemLookup));
  const constraintCellLookup = buildConstraintCellLookup(
    values(groupingLookup)
  );

  // Hash groupings in case anything changed
  if (shouldHash) {
    forEach(groupings, hashGrouping);
  }

  return {
    columns,
    groupings,
    // lookup objects: for when we need fast access to objects in the tree
    lookups: {
      allocationProjectLookup,
      columnLookup,
      groupingLookup,
      lineItemLookup,
      cellLookup,
      constraintCellLookup,
    },
  };
};

export default buildGroupedByConstraints;
