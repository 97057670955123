export default {
  EOT: 253392484149,
  MAX_OPTIONS_DISPLAY: 15,
  IMPORT_DATE_POLLING: 300000,
};

export const ILLEGAL_TEAM_NAME_CHARACTERS = /[^A-Za-z0-9-_&()+=\s:>,/"']/g;

export const MAX_TEAM_NAME_LENGTH = 100;

export const MIN_TEAM_NAME_LENGTH = 3;

export const LEGAL_TEAM_ID = /^[0-9A-NP-Z]{8}$/;

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

export const DEFAULT_DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm:ss";

export const NOOP = () => {};
