import { isEmpty, forEach } from "lodash";

const findFirstLineItem = (groupings) => {
  let childGroupings = groupings;
  const path = [];
  let found = false;

  while (!found && !isEmpty(childGroupings)) {
    const firstGrouping = childGroupings[0];
    path.push(firstGrouping);

    if (!isEmpty(firstGrouping.lineItems)) {
      found = true;
      path.push(firstGrouping.lineItems[0]);
    } else {
      childGroupings = firstGrouping.childGroupings;
    }
  }

  if (!found) {
    return null;
  }

  return path;
};

const getFirstLineItemExpandedIds = (groupings) => {
  const path = findFirstLineItem(groupings);

  if (isEmpty(path)) {
    return null;
  }

  const firstLineItem = path.pop();
  const expandedGroupIds = {};
  const expandedLineItemsGroupIds = {};

  forEach(path, (item) => {
    expandedGroupIds[item.id] = true;
    if (item.canToggleLineItems) {
      expandedLineItemsGroupIds[item.id] = true;
    }
  });

  return [expandedGroupIds, expandedLineItemsGroupIds, firstLineItem];
};

export default getFirstLineItemExpandedIds;
