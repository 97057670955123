import React, { useEffect } from "react";
import PropTypes from "prop-types";
import debug from "debug";
import { Notification, StyledLink } from "orcs-design-system";
import { reportError } from "src/services/errorReporting";

const debugLog = debug("orchestrated:error");

const DefaultMessage = () => {
  return (
    <>
      Sorry, an error occurred during this operation. Please try again. Should
      the issue persist, please contact{" "}
      <StyledLink
        bold
        white
        href={process.env.REACT_APP_HELP_URL}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        support
      </StyledLink>
      .
    </>
  );
};

const ErrorNotification = ({
  error,
  info,
  message,
  report = true,
  closable = true,
  floating = true,
  onDismiss = () => {},
}) => {
  useEffect(() => {
    if (error && report) {
      reportError(error, info);
    }

    if (error) {
      if (error.networkError && error.networkError.response) {
        debugLog(error.networkError.response);
      } else {
        debugLog(error.message, error);
      }
    }
  }, [error, report, info]);

  return (
    <Notification
      colour="danger"
      icon={["fas", "exclamation-circle"]}
      closable={closable}
      floating={floating}
      centered={floating}
      onDismiss={onDismiss}
      bottom={floating && "20px"}
    >
      {!message ? <DefaultMessage /> : message}
    </Notification>
  );
};

ErrorNotification.propTypes = {
  error: PropTypes.object,
  info: PropTypes.object,
  message: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  report: PropTypes.bool,
  closable: PropTypes.bool,
  floating: PropTypes.bool,
  onDismiss: PropTypes.func,
};

export default ErrorNotification;
