import { get } from "lodash";

// Feature flags from flagsmith
export const FLAG_NAMES = {
  unsplash: "unsplash",
  disableMap: "disable-maps",
};

export const shouldUseHomePage = (userPerson) => {
  return typeof get(userPerson, "aggregateId") !== "undefined";
};

export const isSuggestChangeEnabled = (workspace) => {
  return get(workspace, "config.featureFlags.enableSuggestTeamChange", false);
};
