import { values, forEach } from "lodash";
import { hashGrouping, hashLineItem } from "./util";

const updateModelWithHiddenTeam = (dynamicModel, groupId) => {
  const {
    lookups: { groupingLookup, lineItemLookup },
  } = dynamicModel;

  const grouping = groupingLookup[groupId];

  if (grouping) {
    grouping.group.isHidden = true;
    grouping.canHide = false;
    hashGrouping(grouping, {
      rehashChildren: false,
      rehashParents: true,
    });
  }

  const allLineItems = values(lineItemLookup);
  forEach(allLineItems, (lineItem) => {
    if (lineItem.groupId === groupId) {
      lineItem.group.isHidden = true; // eslint-disable-line
      lineItem.canHide = false; // eslint-disable-line
      hashLineItem(lineItem, {
        rehashChildren: false,
        rehashParents: true,
      });
    }
  });

  return dynamicModel;
};

export default updateModelWithHiddenTeam;
