import React, { useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";

import { getAuth0UserMetadata, getApiUrlFromAuth0 } from "src/services/auth";
import {
  getActiveTenant,
  getDateSettingsFromLocalStorage,
  saveDateSettingsToLocalStorage,
  deleteDateSettingsFromLocalStorage,
} from "src/services/localStorage";
import Auth0UserProps from "src/custom-prop-types/auth0user";

import { workspaceIdFromUrl } from "src/consts/urlPaths";
import { Provider } from "./ApolloSettingsContext";

const ApolloSettingsContextProvider = ({
  children,
  user,
  getTokenSilently,
}) => {
  const urlWorkspaceId = workspaceIdFromUrl();
  const history = useHistory();
  const location = useLocation();
  const userEmail = get(user, "email");
  const username = get(user, "name");
  const overrideTenant = useMemo(() => getActiveTenant(userEmail), [userEmail]);
  const [tenantId, setTenantId] = useState(
    get(overrideTenant, "tenantId", get(getAuth0UserMetadata(user), "tenantId"))
  );

  const urlFromAuth = get(getAuth0UserMetadata(user), "apiUrl");
  const apiUrl = getApiUrlFromAuth0(
    get(overrideTenant, "tenantId", urlFromAuth),
    tenantId,
    overrideTenant
  );

  const [workspaceId, _setWorkspaceId] = useState(urlWorkspaceId);
  const [workspaceChanged, _setWorkspaceChanged] = useState(false);

  const savedDateSettings = useMemo(
    () => getDateSettingsFromLocalStorage(tenantId, workspaceId, username),
    [tenantId, workspaceId, username]
  );
  const [dateSettings, _setDateSettings] = useState(savedDateSettings);

  const setWorkspaceId = useCallback(
    (newWorkspaceId, isActive) => {
      if (!isActive && !urlWorkspaceId) {
        history.push(
          newWorkspaceId
            ? `/${newWorkspaceId}${location?.pathname}`
            : location?.pathname
        );
      } else if (
        !isActive &&
        urlWorkspaceId &&
        urlWorkspaceId !== newWorkspaceId
      ) {
        history.push(
          newWorkspaceId
            ? location?.pathname.replace(urlWorkspaceId, newWorkspaceId)
            : location?.pathname.replace(`/${urlWorkspaceId}`, "")
        );
      } else if (isActive) {
        history.push(location?.pathname.replace(`/${urlWorkspaceId}`, ""));
      }

      _setWorkspaceId(newWorkspaceId);
      _setWorkspaceChanged(true);
    },
    [location, urlWorkspaceId, history]
  );

  const setWorkspaceChanged = useCallback((value) => {
    _setWorkspaceChanged(value);
  }, []);

  const setDateSettings = useCallback(
    (changes) => {
      const updatedDateSettings = { ...dateSettings, ...changes };
      saveDateSettingsToLocalStorage(
        tenantId,
        workspaceId,
        username,
        updatedDateSettings
      );
      _setDateSettings(updatedDateSettings);
    },
    [dateSettings, tenantId, workspaceId, username]
  );

  const clearDateSettings = useCallback(() => {
    deleteDateSettingsFromLocalStorage(tenantId, workspaceId, username);
    _setDateSettings({});
  }, [tenantId, workspaceId, username]);

  const values = {
    overrideTenant,
    tenantId,
    setTenantId,
    workspaceId: urlWorkspaceId || workspaceId,
    setWorkspaceId,
    dateSettings,
    setDateSettings,
    clearDateSettings,
    getTokenSilently,
    workspaceChanged,
    setWorkspaceChanged,
    apiUrl,
  };

  return <Provider {...values}>{children}</Provider>;
};

ApolloSettingsContextProvider.propTypes = {
  user: Auth0UserProps,
  getTokenSilently: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default ApolloSettingsContextProvider;
