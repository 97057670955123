import { useEffect } from "react";

import eventEmitter, { EVENTS } from "src/services/eventEmitter";

import { resetTreeActiveNode } from "./index.logic";

const useUpdateCurrentTag = (setTreeData) => {
  useEffect(() => {
    const handler = (tag) => {
      Promise.resolve().then(() => {
        setTreeData((tree) => {
          return resetTreeActiveNode(tree, tag);
        });
      });
    };

    eventEmitter.on(EVENTS.CURRENT_TAG_UPDATED, handler);

    return () => {
      eventEmitter.removeListener(EVENTS.CURRENT_TAG_UPDATED, handler);
    };
  }, [setTreeData]);
};

export default useUpdateCurrentTag;
