import { has, map } from "lodash";
import { Spacer, TextInput } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { TAG_ATTRIBUTE_ACTION } from "src/consts/tags";

import TagAttributeInput from "./TagAttributeInput";
import { getLabel } from "./TagAttributeModal.logic";

export const AdjustedTextInput = styled(TextInput)`
  height: 38px;
`;

const TagAttributesFields = ({
  attrTypeValues,
  updatedAttributes,
  onAttrValueChange,
  onSelectAttrValue,
  onDateChange,
  fieldToBeFocused = {},
  fieldsToShowInvalidity = {},
  featureFlags,
  tagTypesConfig,
  editMode = false,
}) => {
  return (
    <Spacer mb="r">
      {map(attrTypeValues, (attrType) => {
        const {
          id,
          associatedValue,
          defaultValue,
          action,
          type,
          label = "",
          isEntityTagAttr,
        } = attrType;

        let value = defaultValue;
        if (has(updatedAttributes, id)) {
          value = updatedAttributes[id];
        }
        const isReadOnly =
          action === TAG_ATTRIBUTE_ACTION.READONLY || !editMode;
        const isMandatory =
          action === TAG_ATTRIBUTE_ACTION.EDITABLE ||
          action === TAG_ATTRIBUTE_ACTION.MANDATORY;

        return (
          <TagAttributeInput
            key={id}
            type={type}
            id={id}
            data-testid={associatedValue}
            label={getLabel(label, isReadOnly)}
            placeholder="Not set"
            fullWidth
            value={value}
            InputComponent={AdjustedTextInput}
            associatedValue={associatedValue}
            disabled={isReadOnly}
            mandatory={isMandatory}
            onChange={onAttrValueChange(id, isEntityTagAttr)}
            onSelectAttrValue={onSelectAttrValue(id, isEntityTagAttr)}
            onDateChange={onDateChange(id, isEntityTagAttr)}
            invalid={fieldsToShowInvalidity[id]}
            focus={fieldToBeFocused?.id === id}
            featureFlags={featureFlags}
            tagTypesConfig={tagTypesConfig}
          />
        );
      })}
    </Spacer>
  );
};

TagAttributesFields.propTypes = {
  attrTypeValues: PropTypes.array,
  updatedAttributes: PropTypes.object,
  onAttrValueChange: PropTypes.func,
  onSelectAttrValue: PropTypes.func,
  onDateChange: PropTypes.func,
  fieldToBeFocused: PropTypes.object,
  fieldsToShowInvalidity: PropTypes.object,
  featureFlags: PropTypes.object,
  tagTypesConfig: PropTypes.array,
  editMode: PropTypes.bool,
};

export default TagAttributesFields;
