import React from "react";
import PropTypes from "prop-types";
import { Tag } from "orcs-design-system";

const HashTag = ({ element, selected, focused, children }) => {
  const { tag } = element;
  const { label, type } = tag;
  return (
    <Tag m="xxs" disabled selected={selected} focused={focused} tagType={type}>
      #{label}
      {children}
    </Tag>
  );
};

HashTag.propTypes = {
  element: PropTypes.object,
  selected: PropTypes.bool,
  focused: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default HashTag;
