/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import themeGet from "@styled-system/theme-get";
import { get, isUndefined } from "lodash";
import { Icon, P } from "orcs-design-system";
import PropTypes from "prop-types";
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Treebeard, decorators } from "react-treebeard";
import styled from "styled-components";
import { getActiveTheme } from "src/services/localStorage";

import * as placeholder from "src/components/Placeholder/PlaceholderStyles";
import { NOOP } from "src/util/consts";
import GroupTypeBadge from "src/components/GroupTypeBadge";

import TreeViewStyle from "./TreeView.json";
import { updateTreeObject } from "./TreeView.util";

const isCompactTheme = getActiveTheme() === "compact";

const TreeGroupExpandIconFlexWrapper = styled.button`
  border: none;
  background: none;
  appearance: none;
  z-index: 2;
  cursor: pointer;
  padding: ${themeGet("space.xs")};
`;

export const TreeHeaderTitle = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  background: none;
  appearance: none;
  justify-content: space-between;
  font-family: inherit;
  z-index: 2;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  text-align: left;
  text-decoration: ${({ isHidden }) => (isHidden ? "line-through" : "none")};
  margin-left: ${({ hasChildren }) =>
    hasChildren ? "0px" : isCompactTheme ? "11px" : "15px"};
  line-height: 1.8rem;
  padding: 0 ${themeGet("space.s")};
`;

export const TreeHeaderStatus = styled.div`
  display: none;
`;

export const TreeHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    background: transparent;
    height: 100%;
    width: calc(100% + 1016px);
    top: 0;
    left: -1000px;
    transition: background 200ms ease-in-out;
  }

  &:hover {
    &:before {
      background: rgba(156, 224, 248, 0.4);
    }
    .treeHeaderStatus {
      display: block;
    }
  }
`;

const TreeText = styled(P)`
  word-break: break-word;
  z-index: 2;
`;

const ActiveTreeNodeProps = {
  id: "active-node",
  "data-testid": "active-node-test",
};

class TreeContainer extends decorators.Container {
  renderToggleDecorator() {
    const { style, node } = this.props;
    return <Toggle style={style.toggle} node={node} />;
  }
}

const Toggle = ({ node }) => (
  <TreeGroupExpandIconFlexWrapper
    aria-expanded={node.toggled ? "true" : "false"}
    aria-label={node.toggled ? "Collapse tree node" : "Expand tree node"}
    flexDirection="column"
    alignItems="center"
    className="treeExpandIcon"
    display={node.children ? "flex" : "none"}
  >
    <Icon icon={["fal", "chevron-right"]} size="1x" />
  </TreeGroupExpandIconFlexWrapper>
);

const Loading = () => {
  return <placeholder.Line width={250} height={24} />;
};

export const DefaultTreeHeader = ({
  nodeStatus,
  nodeIcon,
  onActivate,
  onToggle,
  cursor,
  withGroupTypeBadge,
  flipOnClickHeader,
  groupTypes,
  node,
}) => {
  useEffect(() => {
    if (node.active) {
      cursor.current = node;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { group: treeNodeGroup } = node;
  const hasChildren = Boolean(node.children);

  const activeNodeProps = node.active ? ActiveTreeNodeProps : {};

  const onClickHeader = (e) => {
    e.stopPropagation();
    if (flipOnClickHeader && hasChildren) {
      onToggle(node, !node.toggled);
    } else {
      onActivate(node);
    }
  };

  return (
    <TreeHeader hasChildren={hasChildren} onClick={onClickHeader}>
      <TreeHeaderTitle
        role="link"
        className="treeHeaderTitle"
        data-testid={node.active ? "treeheader-node-bold" : "treeheader-node"}
        isHidden={get(node, "group.isHidden")}
        hasChildren={hasChildren}
      >
        <TreeText
          fontWeight={node.active ? "bold" : "normal"}
          data-testid="treeheader-node-name"
          color={node.isPlaceholder ? "greyDark" : "black"}
          fontSize="1"
          {...activeNodeProps}
        >
          {node.name}
        </TreeText>
        <TreeHeaderStatus className="treeHeaderStatus">
          {nodeStatus(node)}
        </TreeHeaderStatus>
      </TreeHeaderTitle>
      {withGroupTypeBadge && treeNodeGroup && (
        <GroupTypeBadge group={treeNodeGroup} groupTypes={groupTypes} />
      )}
      {nodeIcon(node)}
    </TreeHeader>
  );
};

const buildHeader = (props) => {
  if (props.customizeTreeHeader) {
    const headerComponent = props.customizeTreeHeader(props);
    if (!isUndefined(headerComponent)) {
      return headerComponent;
    }
  }

  return function WrappedTreeHeader({ node }) {
    return <DefaultTreeHeader {...props} node={node} />;
  };
};

// eslint-disable-next-line react/display-name
const TreeView = ({
  data,
  "data-testid": testId,
  className,
  onNodeSelected = NOOP,
  onNodeToggled = NOOP,
  nodeStatus = NOOP,
  nodeIcon = NOOP,
  customizeTreeHeader,
  withGroupTypeBadge = false,
  flipOnClickHeader = false,
  groupTypes,
  shouldIgnoreOnToggle = null,
}) => {
  const [treeData, setTreeData] = useState(data);
  const cursor = useRef();

  useEffect(() => {
    setTreeData(data);
  }, [data]);

  useEffect(() => {
    TreeView.scrollActiveNodeIntoView();
  }, []);

  const refreshTree = useCallback(() => {
    setTreeData((td) => updateTreeObject(td));
  }, []);

  // onToggle is used for expanding nodes
  const onToggle = useCallback(
    (node, toggled) => {
      if (shouldIgnoreOnToggle && shouldIgnoreOnToggle(node)) {
        return;
      }
      // If not doesn't want to be mutated
      if (node.noActivation) {
        return;
      }

      if (node.children) {
        // toggle it if it has children
        node.toggled = toggled;
      }

      // refresh the render
      refreshTree();

      // Callback for node toggled
      onNodeToggled(node, refreshTree);
    },
    [shouldIgnoreOnToggle, refreshTree, onNodeToggled]
  );

  // onActivate is used on leaf node clicks
  const onActivate = useCallback(
    (node) => {
      if (cursor.current) {
        // reset the old node to inactive
        cursor.current.active = false;
      }

      if (node.noActivation) {
        return;
      }

      // set the new node to active
      node.active = true;

      // track the cursor/active node
      cursor.current = node;

      // refresh the render
      refreshTree();

      // Callback for node active
      onNodeSelected(node, refreshTree);
    },
    [onNodeSelected, refreshTree]
  );

  const Header = useMemo(
    () =>
      buildHeader({
        nodeStatus,
        nodeIcon,
        onActivate,
        onToggle,
        refreshTree,
        cursor,
        customizeTreeHeader,
        withGroupTypeBadge,
        flipOnClickHeader,
        groupTypes,
      }),
    [
      nodeStatus,
      nodeIcon,
      onActivate,
      onToggle,
      refreshTree,
      customizeTreeHeader,
      withGroupTypeBadge,
      flipOnClickHeader,
      groupTypes,
    ]
  );

  return (
    <div data-testid={testId}>
      <Treebeard
        animations={false}
        className={className}
        data={treeData}
        style={TreeViewStyle}
        onToggle={onToggle}
        decorators={{
          ...decorators,
          Header,
          Toggle,
          Loading,
          Container: TreeContainer,
        }}
      />
    </div>
  );
};

TreeView.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  "data-testid": PropTypes.string,
  className: PropTypes.string,
  onNodeSelected: PropTypes.func,
  onNodeToggled: PropTypes.func,
  nodeStatus: PropTypes.func,
  nodeIcon: PropTypes.func,
  customizeTreeHeader: PropTypes.func,
  flipOnClickHeader: PropTypes.bool,
  groupTypes: PropTypes.object,
};

TreeView.scrollActiveNodeIntoView = (block = "end") => {
  const elem = document.querySelector("#active-node");
  if (elem && elem.scrollIntoView) {
    elem.scrollIntoView({ behavior: "smooth", block });
  }
};

export default TreeView;
