import { StyledLink } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { useGlobalConfig } from "src/contexts/global/GlobalContext";
import useDefaultRoutePath from "src/app/Restricted/useDefaultRoutePath";

const LogoLink = styled(StyledLink)`
  line-height: 0;
`;

const CustomLogo = styled.img`
  ${(props) => props.styles}
`;

const Logo = ({ workspace }) => {
  const defaultRoutePath = useDefaultRoutePath();
  const { config } = useGlobalConfig() || {};
  if (workspace && !workspace.loading && config && config.logoImage) {
    return (
      <LogoLink
        bold
        white
        to={defaultRoutePath}
        data-testid="directory-logo-link"
      >
        <CustomLogo {...config.logoImage} alt="logo" />
      </LogoLink>
    );
  }

  return null;
};

Logo.propTypes = {
  workspace: PropTypes.object,
};

export default Logo;
