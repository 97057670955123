import { SideNav, Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import { useTheme } from "styled-components";

import { useAuth0 } from "src/contexts/auth0Provider";
import useWindowResizeListener from "src/hooks/useWindowResizeListener";

import resetAppHeight from "./libs/resetAppHeight";

const SideNavWithOnlyLogout = ({ sideNavHeight = `calc(100vh - 82px)` }) => {
  const { logout } = useAuth0();
  const theme = useTheme();

  const isDemo = !!window?.location?.search?.includes("demo=true");
  useWindowResizeListener(resetAppHeight, isDemo, theme);

  const logoutItem = {
    iconName: "lock",
    name: "Logout",
    bottomAligned: true,
    onClick: logout,
    actionType: "button",
  };

  return (
    <Box py={["0", "0", "0", "r"]} pl={["0", "0", "0", "r"]} bg="greyLightest">
      <SideNav items={[logoutItem]} sideNavHeight={sideNavHeight} />{" "}
    </Box>
  );
};

SideNavWithOnlyLogout.propTypes = {
  sideNavHeight: PropTypes.string,
};

export default SideNavWithOnlyLogout;
