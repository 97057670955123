const fullDisplayName = ({ firstName, surname, preferredName }) => {
  if (preferredName || firstName || surname) {
    const first = (preferredName || firstName || "").trim();
    const last = (surname || "").trim();
    return `${first} ${last}`.trim();
  }
  return "Unknown Name";
};

const fullLegalName = ({ firstName, surname }) => {
  const first = firstName || "<Unknown>";
  const last = surname || "<Unknown>";
  return `${first} ${last}`.trim();
};

const displayInitials = ({ firstName, surname, preferredName }) => {
  const first = (preferredName || firstName || "").trim();
  const last = (surname || "").trim();
  return first && last ? first.charAt(0) + last.charAt(0) : null;
};

const preferredFirstName = (person) => {
  const { preferredName, firstName } = person;
  return preferredName || firstName || fullDisplayName(person);
};

export { fullDisplayName, fullLegalName, displayInitials, preferredFirstName };
