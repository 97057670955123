import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Spacer,
  Card,
  Button,
  TextInput,
  TextArea,
} from "orcs-design-system";

import ConfigTypeSelect from "./ConfigTypeSelect";

const WorkspaceForm = ({ onSubmit, isLoading, preferredUsername }) => {
  const nameInputRef = useRef();
  const [formValues, setFormValues] = useState({
    name: `New Workspace`,
    description: `Created by ${preferredUsername} on ${new Date().toLocaleString(
      "en-AU"
    )}`,
  });

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const onFieldChange = (name) => (e) => {
    setFormValues({
      ...formValues,
      [name]: e.target.value,
    });
  };

  const onConfigTypeSelect = (option) => {
    setFormValues({
      ...formValues,
      configType: option,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    onSubmit(formValues);
  };

  return (
    <Card title="New workspace">
      <Box maxWidth="500px" width="100%">
        <Spacer mb="r">
          <TextInput
            name="name"
            type="text"
            id="name"
            label="Name"
            mandatory
            fullWidth
            value={formValues.name}
            onChange={onFieldChange("name")}
            ref={nameInputRef}
            onFocus={(e) => {
              e.target.select();
            }}
          />
          <ConfigTypeSelect
            selectedConfigType={formValues.configType}
            onConfigTypeSelect={onConfigTypeSelect}
          />
          <TextArea
            fullWidth
            id="description"
            name="description"
            label="Description"
            onChange={onFieldChange("description")}
            rows="3"
            defaultValue={formValues.description}
            disabled={isLoading}
          />
          <Button
            onClick={onFormSubmit}
            isLoading={isLoading}
            disabled={isLoading}
          >
            Create workspace
          </Button>
        </Spacer>
      </Box>
    </Card>
  );
};

WorkspaceForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  preferredUsername: PropTypes.string.isRequired,
};

export default WorkspaceForm;
