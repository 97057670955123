import { useQuery } from "@apollo/client";
import { first, isEmpty } from "lodash";
import { useState } from "react";

import { pagedSearchTags } from "src/queries/tags.graphql";

const useSelectedTagValue = (value) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const { loading } = useQuery(pagedSearchTags, {
    variables: {
      input: {
        filter: {
          tagIds: [value],
        },
      },
    },
    onCompleted: (response) => {
      const tag = first(response?.results?.tags);
      if (!isEmpty(tag)) {
        setSelectedValue({
          label: tag.displayValue,
          value: tag.id,
        });
      }
    },
    skip: !value || selectedValue,
  });

  return {
    selectedValue,
    setSelectedValue,
    selectedValueLoading: loading,
  };
};

export default useSelectedTagValue;
