// @ts-check
import {
  orderBy,
  split,
  last,
  includes,
  filter,
  map,
  isEmpty,
  pullAll,
  join,
  get,
} from "lodash";

// @ts-ignore
import { HIERARCHY_SEPARATOR } from "src/allocation/consts";

/**
 * Group object
 * @typedef {Object} Group
 * @property {string} id
 * @property {string} name
 * @property {string} hierarchy
 * @property {[string]} hierarchyIds
 * @property {string} type
 * @property {boolean} isHidden
 */

/**
 * Group object
 * @typedef {Object} GroupFteStats
 * @property {string} groupId
 * @property {Group} group
 * @property {number} memberCount
 * @property {number} memberFte
 * @property {number} totalFte
 */

/**
 * get group name array from a hierarchy string
 * @param {string} hierarchy
 * @returns [string] group name arrays
 */
export const getGroupNamesArrayFromHierarchy = (hierarchy) =>
  split(hierarchy, HIERARCHY_SEPARATOR);

/**
 * get the group name from a hierarchy
 * @param {string} hierarchy
 */
export const getGroupName = (hierarchy) => {
  return hierarchy ? last(getGroupNamesArrayFromHierarchy(hierarchy)) : "";
};

/**
 * sort GroupFteStats by group hierarchy
 * @param {GroupFteStats[]} groups
 * @returns {GroupFteStats[]} ordered group fte stats
 */
export const getSortedGroupsByHierarchy = (groups) => {
  // @ts-ignore
  return orderBy(groups, "group.hierarchy");
};

/**
 * is given hierarchy within another hierarchy
 * @param {string[]} parentGroupHierarchyIds
 * @param {string[]} childGroupHierarchyIds
 */
export const isWithinHierarchy = (
  parentGroupHierarchyIds,
  childGroupHierarchyIds
) => {
  const parentGroupHierarchy = join(
    parentGroupHierarchyIds,
    HIERARCHY_SEPARATOR
  );
  const childGroupHierarchy = join(childGroupHierarchyIds, HIERARCHY_SEPARATOR);

  return (
    childGroupHierarchy === parentGroupHierarchy ||
    childGroupHierarchy.indexOf(parentGroupHierarchy + HIERARCHY_SEPARATOR) !==
      -1
  );
};

/**
 * Deprecated, use methods in customerConfig instead
 * @param group
 * @param allocationConfig
 * @returns {boolean}
 */
export const isDemandGroup = (group, allocationConfig) => {
  return includes(
    [...allocationConfig.requestorTypes, ...allocationConfig.targetTypes],
    group.type
  );
};

/**
 * Deprecated, use methods in customerConfig instead
 * @param group
 * @param allocationConfig
 * @returns {boolean}
 */
export const isApproverType = (group, allocationConfig) =>
  allocationConfig.approverTypes.includes(group.type);

/**
 * Deprecated, use methods in customerConfig instead
 * @param group
 * @param allocationConfig
 * @returns {boolean}
 */
export const isRequestorType = (group, allocationConfig) =>
  allocationConfig.requestorTypes.includes(group.type);

/**
 * check whether the group type can be added as new role or not
 * @param {Group} group
 * @param {object} groupTypes
 */

export const canBeAddedAsNewRole = (group, groupTypes) => {
  if (!group) {
    return false;
  }

  const targetType = get(groupTypes, group.type, {});

  return !targetType.planning?.isExcludedFromNewRole;
};

/**
 * get sub group (can have child) from current parent group
 * @param {object} groupTypes
 * @param {Group} group
 */
export const getSubGroupTypes = (groupTypes, group) => {
  const { type } = group;

  if (!type || !groupTypes || !groupTypes[type]) {
    return [];
  }

  const { childTypes } = groupTypes[type];

  if (!childTypes) {
    return [];
  }

  const subGroupTypes = [].concat(childTypes);
  const targetTypes = [];

  while (subGroupTypes.length > 0) {
    const t = subGroupTypes.shift();
    const g = groupTypes[t];

    if (g) {
      const { childTypes: c, canCreateChild } = g;
      if (canCreateChild && !isEmpty(c)) {
        if (!includes(targetTypes, t)) {
          targetTypes.push(t);
        }
        const newTypes = pullAll([...c], targetTypes);
        subGroupTypes.push(...newTypes);
      }
    }
  }

  return targetTypes;
};

export const getSupplyGroupTypesHierarchy = (groupTypes) => {
  const rootTypes = filter(groupTypes, "isRoot");
  const supplyRootTypes = filter(groupTypes, "isSupplyRoot");
  const supplySourceTypes = filter(groupTypes, "isSupplySource");
  if (
    !isEmpty(rootTypes) &&
    !isEmpty(supplyRootTypes) &&
    !isEmpty(supplySourceTypes)
  ) {
    return {
      rootTypes: map(rootTypes, "id"),
      rootTypesLabel: join(map(rootTypes, "name"), ", "),
      supplyRootTypes: map(supplyRootTypes, "id"),
      supplyRootTypesLabel: join(map(supplyRootTypes, "name"), ", "),
      supplySourceTypes: map(supplySourceTypes, "id"),
      supplySourceTypesLabel: join(map(supplySourceTypes, "name"), ", "),
    };
  }
  // not sure if all workspaces have config set up correctly. Going to
  // leave these defaults here in case not
  const defaultGroupTypes = {
    rootTypes: ["Function"],
    rootTypesLabel: "Function",
    supplyRootTypes: ["Chapter Area"],
    supplyRootTypesLabel: "Chapter Area",
    supplySourceTypes: ["Chapter", "COE"],
    supplySourceTypesLabel: "Chapter, COE",
  };
  return defaultGroupTypes;
};

export const buildLineItemsId = (groupingId, type) => {
  return type ? `${groupingId}-${type}` : groupingId;
};
