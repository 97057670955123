import PropTypes from "prop-types";
import React from "react";

import { CustomMenuList } from "./MenuList.styled";

const MenuList = ({ children, innerRef, innerProps }) => (
  <CustomMenuList ref={innerRef} {...innerProps} id="react-select-menu">
    {children}
  </CustomMenuList>
);

MenuList.propTypes = {
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.node,
};

export default MenuList;
