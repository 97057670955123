import newCell from "../../newCell";

import { PLANNER_CURRENT } from "../../../../../columnTypes";
import getFte from "./getFte";

export default ({ lineItem, macroAllocation }) => {
  const {
    id,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    allocatedMemberFte, // current fte
    note, // skills
  } = macroAllocation;
  const { grouping } = lineItem;
  const value = grouping.isMovedIn ? null : getFte(allocatedMemberFte) || 0;
  return newCell({
    lineItem,
    id: `${id}-${PLANNER_CURRENT}`,
    columnType: PLANNER_CURRENT,
    macroAllocationId: id,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    value,
    skills: note,
    isEditable: false,
  });
};
