import { map, trim } from "lodash";
import { Select } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import SelectComponents from "src/components/SelectComponents";
import usePagedTagSearch from "src/hooks/usePagedTagSearch";

import { getMenuList } from "./components/getMenuList";
import useSelectedTagValue from "./hooks/useSelectedTagValue";

const TagSearchSelect = ({
  tagTypes,
  value,
  onSelectTag,
  label,
  disabled,
  invalid,
  mandatory,
  focus,
}) => {
  const { selectedValue, setSelectedValue, selectedValueLoading } =
    useSelectedTagValue(value);
  const { data, loading, loadOptions, totalTagCount, resetTotalTagCount } =
    usePagedTagSearch({
      tagTypes,
    });

  const handleSelect = (selected) => {
    setSelectedValue(selected);
    onSelectTag(selected);
  };

  const options = map(data?.results?.tags, (tag) => ({
    label: tag.displayValue,
    value: tag.id,
  }));

  const handleInputChange = (val) => {
    if (!trim(val)) {
      resetTotalTagCount();
    }
  };

  return (
    <Select
      inputId="tagSearchSelector"
      label={label}
      isDisabled={disabled}
      invalid={invalid}
      placeholder="Type to search tag name or type"
      noOptionsMessage={() => "No search results"}
      cacheOptions={false}
      backspaceRemovesValue={true}
      isClearable
      isSearchable
      isLoading={loading || selectedValueLoading}
      defaultOptions={options}
      loadOptions={loadOptions}
      onChange={handleSelect}
      onInputChange={handleInputChange}
      value={selectedValue}
      menuPlacement="auto"
      components={{
        ...SelectComponents,
        MenuList: getMenuList(totalTagCount),
      }}
      selectType="async"
      focus={focus}
      mandatory={mandatory}
    />
  );
};

TagSearchSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onSelectTag: PropTypes.func,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  mandatory: PropTypes.bool,
  focus: PropTypes.bool,
  tagTypes: PropTypes.array,
};

export default TagSearchSelect;
