import { isLineOfBusiness } from "src/util/customerConfig";
import { FORECAST, PLANNER } from "../../pageModes";

import {
  GROUPED_BY_DEMAND,
  GROUPED_BY_SUPPLY,
  GROUPED_BY_CONSTRAINTS,
  QUICK_ACTIVITY_VIEW,
  CURRENT_GROUP_ONLY_VIEW,
} from "../../viewModes";

import buildGroupedByDemand from "./buildGroupedByDemand";
import buildGroupedBySupply from "./buildGroupedBySupply";
import buildGroupedByConstraints from "./buildGroupedByConstraints";
import buildPlannerGroupedBySupply from "./buildPlannerGroupedBySupply";
import buildPlannerGroupedByDemand from "./buildPlannerGroupedByDemand";
import buildPlannerLineOfBusiness from "./buildPlannerLineOfBusiness";

export default ({
  mainQueryData,
  viewMode,
  pageMode,
  rootGroup,
  groupTypes = {},
  approvalReasons = {},
  hideHiddenTeams = false,
  showObjectives = false,
  shouldHash = false,
  isBudgetEnabled,
  useDeltaForBudget,
  overBudgetLimit,
  underBudgetLimit,
  isRealtimeFteMode,
  useRealtimeChangeOnly,
  useRealtimeFteDelta,
}) => {
  if (pageMode === FORECAST) {
    switch (viewMode) {
      case GROUPED_BY_DEMAND:
      case CURRENT_GROUP_ONLY_VIEW: {
        return buildGroupedByDemand({
          rootGroup,
          data: mainQueryData,
          hideHiddenTeams,
          showObjectives,
          groupTypes,
          shouldHash,
          isBudgetEnabled,
          overBudgetLimit,
          underBudgetLimit,
        });
      }
      case GROUPED_BY_SUPPLY: {
        return buildGroupedBySupply({
          rootGroup,
          data: mainQueryData,
          insertExtraDemandGroupings: rootGroup.isSource,
          hideHiddenTeams,
          showObjectives,
          groupTypes,
          shouldHash,
          isBudgetEnabled,
          overBudgetLimit,
          underBudgetLimit,
        });
      }
      case GROUPED_BY_CONSTRAINTS: {
        return buildGroupedByConstraints({
          rootGroup,
          data: mainQueryData,
          hideHiddenTeams,
          groupTypes,
          shouldHash,
        });
      }
      default: {
        return {};
      }
    }
  }

  if (pageMode === PLANNER) {
    if (isLineOfBusiness(groupTypes, rootGroup)) {
      return buildPlannerLineOfBusiness({
        rootGroup,
        data: mainQueryData,
        hideHiddenTeams,
        showObjectives,
        groupTypes,
        shouldHash,
      });
    }
    switch (viewMode) {
      case GROUPED_BY_DEMAND: {
        return buildPlannerGroupedByDemand({
          rootGroup,
          data: mainQueryData,
          hideHiddenTeams,
          showObjectives,
          groupTypes,
          shouldHash,
          isBudgetEnabled,
          useDeltaForBudget,
          overBudgetLimit,
          underBudgetLimit,
          isRealtimeFteMode,
          useRealtimeChangeOnly,
          useRealtimeFteDelta,
        });
      }
      case GROUPED_BY_SUPPLY: {
        return buildPlannerGroupedBySupply({
          rootGroup,
          data: mainQueryData,
          insertExtraDemandGroupings: rootGroup.isSource,
          hideHiddenTeams,
          showObjectives,
          groupTypes,
          approvalReasons,
          shouldHash,
          isBudgetEnabled,
          useDeltaForBudget,
          overBudgetLimit,
          underBudgetLimit,
        });
      }
      case QUICK_ACTIVITY_VIEW: {
        return buildPlannerGroupedBySupply({
          rootGroup,
          data: mainQueryData,
          insertExtraDemandGroupings: false,
          hideHiddenTeams,
          showObjectives,
          groupTypes,
          approvalReasons,
          shouldHash,
        });
      }
      default: {
        return {};
      }
    }
  }

  return {};
};
