import React from "react";

import PropTypes from "prop-types";
import { H1, P } from "orcs-design-system";
import { copywriting } from "./ErrorPage.config";
import ErrorPageWrapper from "./ErrorPageWrapper";

const ForbiddenError = ({ clientInfo }) => {
  return (
    <ErrorPageWrapper clientInfo={clientInfo}>
      <H1 mb="between">{copywriting.forbiddenErrorHeading}</H1>
      <P>{copywriting.forbiddenErrorDetail}</P>
    </ErrorPageWrapper>
  );
};

ForbiddenError.propTypes = {
  clientInfo: PropTypes.string,
};

export default ForbiddenError;
