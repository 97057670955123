const ls = window.sessionStorage;

export const getItem = (storageKey) => {
  if (ls) {
    const value = ls.getItem(storageKey);
    try {
      return JSON.parse(value);
    } catch (e) {
      return {};
    }
  }
  return {};
};

export const saveItem = (storageKey, value) => {
  try {
    ls.setItem(storageKey, JSON.stringify(value));
  } catch {
    // If there is any error, do nothing
  }
};
