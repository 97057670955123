import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { reportMessage } from "src/services/errorReporting";
import ErrorPage from "src/components/ErrorPage";

// @deprecated : Fold into error pages stuff when the feature flag is permanently enabled
export const UnauthenticatedApp = ({ errorContext }) => {
  const errorDescription = errorContext.errorDescription
    ? decodeURIComponent(errorContext.errorDescription)
    : JSON.stringify(errorContext);
  useEffect(() => {
    reportMessage("Auth0 error encountered", {
      tags: { error: errorContext.error, source: errorContext.source },
    });
  }, [errorContext.error, errorContext.source]);
  return (
    <ErrorPage
      reportErrors={false}
      withDiagnostics={false}
      error={{ errorDescription, ...errorContext }}
    />
  );
};

UnauthenticatedApp.propTypes = {
  errorContext: PropTypes.oneOfType([
    PropTypes.shape({
      errorDescription: PropTypes.string,
      error: PropTypes.string,
      source: PropTypes.string,
    }),
  ]),
};

export default UnauthenticatedApp;
