import { map } from "lodash";
import { Node } from "slate";

export const serializeToPlainText = (msg) => {
  return map(msg, (n) => Node.string(n)).join("\n");
};

export const isContentEmpty = (msg) => {
  // Replace all separators (space, \n, \r, \t)
  const plainInput = serializeToPlainText(msg).replace(/\s/g, "");
  return !plainInput;
};
