/* eslint-disable react/display-name */
import React, { useReducer, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import constate from "constate";
import { useLocation } from "react-router-dom";
import { get, isNil } from "lodash";

import GroupPropType from "src/custom-prop-types/group";
import { isDemandGroup } from "src/allocation/util/group";

import forecastReducer from "./reducer";
import initialState from "./reducer/initialState";
import { getPersistedExpandedIds } from "./expandedIds.util";
import * as ACTIONS from "./reducer/actionTypes";
import * as VIEW_MODES from "./reducer/viewModes";
import * as PAGE_MODES from "./reducer/pageModes";
import * as SAVE_STATUS from "./reducer/saveStatus";
import * as COLUMN_TYPES from "./reducer/columnTypes";
import * as NOTIFICATION_STATUS from "./reducer/notificationStatus";
import * as MACRO_ALLOCATION_TYPES from "./reducer/macroAllocationTypes";
import * as FILTERS from "./reducer/filterTypes";

export {
  ACTIONS,
  VIEW_MODES,
  PAGE_MODES,
  COLUMN_TYPES,
  SAVE_STATUS,
  NOTIFICATION_STATUS,
  MACRO_ALLOCATION_TYPES,
  FILTERS,
};

const useForecastReducer = ({
  group,
  pageMode,
  initialState: initialStateOverride,
  workspace,
  allocationProject,
}) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  let viewMode = searchParams.get("view");
  const {
    enableMacroAllocationPerspective: withPerspective,
    showObjectives,
    hideHiddenTeams,
  } = workspace.config.allocation;

  const targetGroupSearchParamId = searchParams.get("target_id");
  const sourceGroupSearchParamId = searchParams.get("source_id");

  if (
    !viewMode ||
    (viewMode === VIEW_MODES.GROUPED_BY_CONSTRAINTS &&
      isDemandGroup(group, workspace.config.allocation))
  ) {
    viewMode = isDemandGroup(group, workspace.config.allocation)
      ? VIEW_MODES.GROUPED_BY_DEMAND
      : VIEW_MODES.GROUPED_BY_SUPPLY;
  }

  const { previouslyExpandedGroupIds, previouslyExpandedLineItemsGroupIds } =
    getPersistedExpandedIds(group, pageMode, viewMode);

  const initForcastState = useMemo(
    () => ({
      ...initialState({
        group,
        workspace,
        viewMode,
        pageMode,
        targetGroupSearchParamId,
        sourceGroupSearchParamId,
        previouslyExpandedGroupIds,
        previouslyExpandedLineItemsGroupIds,
        hideHiddenTeams: isNil(hideHiddenTeams) ? true : hideHiddenTeams,
        showObjectives: isNil(showObjectives) ? true : showObjectives,
        withPerspective,
        activeAllocationProject: allocationProject,
      }),
      ...initialStateOverride,
    }),
    [
      allocationProject,
      group,
      hideHiddenTeams,
      initialStateOverride,
      pageMode,
      previouslyExpandedGroupIds,
      previouslyExpandedLineItemsGroupIds,
      showObjectives,
      sourceGroupSearchParamId,
      targetGroupSearchParamId,
      viewMode,
      withPerspective,
      workspace,
    ]
  );

  const [contextState, dispatch] = useReducer(
    forecastReducer,
    initForcastState
  );

  const currentGroupId = contextState.lookupData.parentGroupId;
  useEffect(() => {
    if (group.id !== currentGroupId) {
      dispatch({
        type: ACTIONS.REPLACE_ALL_STATE,
        newState: initForcastState,
      });
    }
  }, [group, initForcastState, currentGroupId]);

  return {
    contextState,
    dispatch,
  };
};

export const isFinancialForecastingEnabled = (contextState) => {
  const flag = get(contextState, "model.isBudgetEnabled");

  if (!flag) {
    return false;
  }

  const viewMode = get(contextState, "userInteraction.viewMode");
  return (
    flag &&
    viewMode !== VIEW_MODES.QUICK_ACTIVITY_VIEW &&
    viewMode !== VIEW_MODES.GROUPED_BY_CONSTRAINTS
  );
};

// The `useXXX` functions should match the order of the selector functions in constate call.
const [
  ForecastContextProvider,

  // Full context
  useForecastContext,

  // main reducer selectors
  useDispatch,
  usePageMode,
  useActiveAllocationProject,
  useMainQuery,
  useUserInteraction,
  useForecastModel,
  useLookupData,

  // deep selectors
  useRootGroup,
  useColumns,
  useModelLookups,

  // flags
  useFinancialForecasting,
  useIsGroupExtraInfoLoaded,

  // Lookup data
  useApprovalReasons,
  useAllocationProjects,
  useGroupTypesLookup,

  // User interactions
  useViewMode,
  useSearchMode,
  useGroupSearchMode,
  useSearchResult,
  useMembersFilter,

  // User interactions - expanded ids
  useExpandedGroupIds,
  useExpandedLineItemsGroupIds,
  useExpandedMemberListIds,
  useExpandedObjectivesIds,
  useExpandedTagsIds,
  useExpandAllGroups,
] = constate(
  useForecastReducer,
  // Full context
  (value) => [value.contextState, value.dispatch],

  // main reducer selectors
  (value) => value.dispatch,
  ({ contextState }) => contextState.pageMode,
  ({ contextState }) => contextState.activeAllocationProject,
  ({ contextState }) => contextState.mainQuery,
  ({ contextState }) => contextState.userInteraction,
  ({ contextState }) => contextState.model,
  ({ contextState }) => contextState.lookupData,

  // deep selectors
  ({ contextState }) => get(contextState, "model.rootGroup"),
  ({ contextState }) => get(contextState, "model.dynamicModel.columns"),
  ({ contextState }) => get(contextState, "model.dynamicModel.lookups"),

  // flags
  ({ contextState }) => isFinancialForecastingEnabled(contextState),
  ({ contextState }) => get(contextState, "model.isGroupExtraInfoLoaded"),

  // Lookup data
  ({ contextState }) => get(contextState, "lookupData.approvalReasons"),
  ({ contextState }) => get(contextState, "lookupData.allocationProjectLookup"),
  ({ contextState }) => get(contextState, "lookupData.groupTypesLookup"),

  // User interactions
  ({ contextState }) => get(contextState, "userInteraction.viewMode"),
  ({ contextState }) => get(contextState, "userInteraction.searchMode"),
  ({ contextState }) => get(contextState, "userInteraction.groupSearchMode"),
  ({ contextState }) => get(contextState, "userInteraction.searchResult"),
  ({ contextState }) => get(contextState, "userInteraction.membersFilter"),

  // User interactions - expanded ids
  ({ contextState }) => get(contextState, "userInteraction.expandedGroupIds"),
  ({ contextState }) =>
    get(contextState, "userInteraction.expandedLineItemsGroupIds"),
  ({ contextState }) =>
    get(contextState, "userInteraction.expandedMemberListIds"),
  ({ contextState }) =>
    get(contextState, "userInteraction.expandedObjectivesIds"),
  ({ contextState }) => get(contextState, "userInteraction.expandedTagsIds"),
  ({ contextState }) => get(contextState, "userInteraction.expandAllGroups")
);

// a helpful simple HOC to easily provide the context
const withForecastContextProvider = (WrappedComponent) => {
  // eslint-disable-next-line react/prefer-stateless-function
  return class extends React.Component {
    render() {
      return (
        <ForecastContextProvider {...this.props}>
          <WrappedComponent />
        </ForecastContextProvider>
      );
    }
  };
};

export {
  withForecastContextProvider,
  ForecastContextProvider,
  useForecastContext,
  useDispatch,
  usePageMode,
  useMainQuery,
  useUserInteraction,
  useLookupData,
  useIsGroupExtraInfoLoaded,
  useForecastModel,
  useActiveAllocationProject,
  useRootGroup,
  useColumns,
  useFinancialForecasting,
  useModelLookups,
  useApprovalReasons,
  useAllocationProjects,
  useGroupTypesLookup,
  useViewMode,
  useSearchMode,
  useGroupSearchMode,
  useSearchResult,
  useMembersFilter,
  useExpandedGroupIds,
  useExpandedLineItemsGroupIds,
  useExpandedMemberListIds,
  useExpandedObjectivesIds,
  useExpandedTagsIds,
  useExpandAllGroups,
};

ForecastContextProvider.propTypes = {
  group: GroupPropType.isRequired,
  pageMode: PropTypes.string.isRequired,
  children: PropTypes.node,
  initialState: PropTypes.object,
  workspace: PropTypes.object,
};
