import { useQuery } from "@apollo/client";
import { get, isEmpty } from "lodash";
import { useMemo, useRef } from "react";

import { getGroupsByTypeQuery } from "src/queries/group.graphql";
import { throwIfRequired } from "src/util/error";
import { getRootGroupTypeIds } from "src/util/groupType";

const useRootGroupsQuery = ({
  workspace,
  featureFlags,
  isSelected,
  rootGroupVariables = {},
}) => {
  const savedDataRef = useRef(null);

  const rootGroupTypeIds = useMemo(
    () => getRootGroupTypeIds(get(workspace, "config.groupTypes")),
    [workspace]
  );

  const variables = {
    groupTypes: rootGroupTypeIds,
    includeEmptyGroups: true,
  };

  const { data, error, loading } = useQuery(getGroupsByTypeQuery, {
    variables: {
      ...variables,
      ...rootGroupVariables,
    },
    nextFetchPolicy: "standby", // Stop triggering this query from cache update
    skip: isEmpty(rootGroupTypeIds) || !isSelected || featureFlags.loading,
    errorPolicy: "all",
  });

  throwIfRequired(error);

  // This is needed as useQuery has been observed returning new objects even though
  // the cached data is unchanged, and that can trigger the GroupsNavigation component
  // to collapse and rebuild the org hierarchy tree, which can result in displaying
  // groups in pre-mutation locations, possibly as duplicates, etc.
  if (!savedDataRef.current && data) {
    savedDataRef.current = data;
  }

  return {
    data: savedDataRef.current,
    error,
    loading,
    rootGroupTypeIds,
  };
};

export default useRootGroupsQuery;
