import { useQuery } from "@apollo/client";
import { get, uniqBy, isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { TagTypeConfigPropType } from "../propTypes";
import { availableTags as availableTagsQuery } from "../queries/tags.graphql";
import { filterTagTypes } from "../utils/filterTagTypes";

import TagSelector from "./TagSelector";

const TagSelectorContainer = ({
  existingTags,
  setNewTag,
  tagTypesConfig,
  tagTypes,
  removeTag,
  onTagsLoaded,
  onBlur,
  onError,
  onEntityTagClick,
  showTagTypeHelp,
  helpIsOpen,
  isAssociationTag,
  onEditTagAttributes,
  selectorRef,
  onCreateNewTag,
  label,
  bulkTagMode,
}) => {
  const {
    data: tagsData,
    loading,
    error,
  } = useQuery(availableTagsQuery, {
    variables: {
      tagTypes: filterTagTypes({ tagTypesConfig, tagTypes }),
    },
  });

  const allTags = uniqBy(
    get(tagsData, "tags", []),
    (tag) => `${tag.type}.${tag.displayValue}`
  );

  useEffect(() => {
    // todo: Revisit error handling here
    if (error && onError) {
      onError(error);
    }
  }, [onError, error]);

  useEffect(() => {
    if (!loading && !error && !isEmpty(allTags) && onTagsLoaded) {
      onTagsLoaded(allTags);
    }
  }, [loading, error, allTags, onTagsLoaded]);

  return (
    <TagSelector
      selectorRef={selectorRef}
      tagTypes={tagTypes}
      loading={loading}
      existingTags={existingTags}
      setNewTag={setNewTag}
      tagTypesConfig={tagTypesConfig}
      removeTag={removeTag}
      setError={onError}
      onBlur={onBlur}
      onEntityTagClick={onEntityTagClick}
      allTags={allTags}
      showTagTypeHelp={showTagTypeHelp}
      helpIsOpen={helpIsOpen}
      isAssociationTag={isAssociationTag}
      onEditTagAttributes={onEditTagAttributes}
      onCreateNewTag={onCreateNewTag}
      label={label}
      bulkTagMode={bulkTagMode}
    />
  );
};

TagSelectorContainer.propTypes = {
  existingTags: PropTypes.array,
  setNewTag: PropTypes.func,
  tagTypesConfig: PropTypes.arrayOf(TagTypeConfigPropType).isRequired,
  tagTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  removeTag: PropTypes.func,
  onBlur: PropTypes.func,
  onError: PropTypes.func,
  onTagsLoaded: PropTypes.func,
  onEntityTagClick: PropTypes.func,
  label: PropTypes.string,
  showTagTypeHelp: PropTypes.func,
  helpIsOpen: PropTypes.bool,
  isAssociationTag: PropTypes.bool,
  onEditTagAttributes: PropTypes.func,
  selectorRef: PropTypes.object,
  onCreateNewTag: PropTypes.func,
  bulkTagMode: PropTypes.func,
};

export default TagSelectorContainer;
