import { mapValues, filter, keyBy, forEach, find, get } from "lodash";

import { newLineItem, getDailyRateByFlag } from "../../util";

const setSupplyGroupingsWithDemandLineItems = ({
  groupings,
  macroAllocations,
  columnCount,
  groupTypes = {},
  canRename = false,
  canHide = false,
  isBudgetEnabled,
} = {}) => {
  forEach(groupings, (grouping) => {
    const macroAllocationsForGrouping = filter(macroAllocations, {
      sourceGroupId: grouping.groupId,
    });
    const lineItemGroupLookup = mapValues(
      keyBy(macroAllocationsForGrouping, "targetGroupId"),
      "targetGroup"
    );

    forEach(macroAllocationsForGrouping, (macroAllocation) => {
      if (
        !find(grouping.lineItems, {
          groupId: macroAllocation.targetGroupId,
          type: macroAllocation.type,
        })
      ) {
        const group = lineItemGroupLookup[macroAllocation.targetGroupId];
        const groupType = groupTypes[group.type];
        const lineItem = newLineItem({
          group,
          grouping,
          groupId: macroAllocation.targetGroupId,
          columnCount,
          memberCount: group.memberCount,
          canViewInDetails: true,
          canViewInTeamBuilder: true,
          canRename: canRename && get(groupType, "canRename", false),
          canHide: canHide && !group.isHidden,
          isDemand: true,
          isSkillsMandatory: get(
            groupType,
            "planning.isSkillsMandatory",
            false
          ),
          type: macroAllocation.type,
          dailyRate: getDailyRateByFlag(isBudgetEnabled, null, grouping),
        });
        grouping.lineItems.push(lineItem);
        grouping.lineItems.sort((a, b) =>
          a.group.name.localeCompare(b.group.name)
        );
      }
    });

    setSupplyGroupingsWithDemandLineItems({
      groupings: grouping.childGroupings,
      macroAllocations,
      columnCount,
      groupTypes,
      canRename,
      canHide,
      isBudgetEnabled,
    });
  });
};

export default setSupplyGroupingsWithDemandLineItems;
