import PropTypes from "prop-types";
import React, { useContext, useReducer, useEffect } from "react";
import { useHistory } from "react-router-dom";

// This context assumes the component injected is already wrapped around Modal
// gives better control around the styling/sizes of modal depending on context
// uses a simplistic approach to hide, ie: hide/unmount component instead of
// persisting in background which can get heavy

export const ModalContext = React.createContext();
export const useModal = () => useContext(ModalContext);

export const reducer = (state, action) => {
  const { isModalVisible, modalComponent, type, target, component } = action;
  switch (type) {
    case "setModalComponent": {
      return {
        ...state,
        isModalVisible: true,
        modalComponent,
        target,
        component,
      };
    }
    case "setModalVisible": {
      return {
        ...state,
        isModalVisible,
      };
    }
    default:
      return state;
  }
};

const initialState = {
  modalComponent: null,
  isModalVisible: false,
};

const ModalComponent = () => {
  const [state] = useModal();

  if (!state.modalComponent || !state.isModalVisible) {
    return null;
  }

  return state.modalComponent;
};

export const ModalProvider = ({ children }) => {
  const value = useReducer(reducer, initialState);
  const history = useHistory();

  useEffect(() => {
    const [state, dispatch] = value;

    if (!state.isModalVisible) {
      return () => {};
    }

    const unregisterCallback = history.listen(() => {
      if (state.isModalVisible) {
        dispatch({ type: "setModalVisible", isModalVisible: false });
      }
    });

    return unregisterCallback;
  }, [history, value]);

  return (
    <ModalContext.Provider value={value}>
      {children}
      <ModalComponent />
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node,
};
