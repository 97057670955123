import { Flex, FlexItem, Small } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const DropdownMenuList = ({ totalTagCount }) => {
  return (
    <Flex
      style={{ padding: "10px" }}
      justifyContent="space-between"
      alignItems="center"
    >
      <FlexItem flex="0 0 auto" ml="s">
        <Small>{`Showing first ${totalTagCount.currentSize} results out of ${totalTagCount.total}. Please refine your search.`}</Small>
      </FlexItem>
    </Flex>
  );
};

DropdownMenuList.propTypes = {
  totalTagCount: PropTypes.elementType,
};

export default DropdownMenuList;
