import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSidebarContext, ACTIONS } from "./context";

const perfromSidebarAction = (dispatch, locationState) => {
  // sidebar command sent via router state
  const sidebar = locationState && locationState.sidebar;
  if (sidebar) {
    if (sidebar.action === "openSidebar") {
      dispatch({
        type: ACTIONS.SELECT_SIDEBAR_TAB,
        selectedTab: sidebar.selectedTab,
      });
      return true;
    }
    if (sidebar.action === "preventCollapse") {
      // do nothing
      return true;
    }
    if (sidebar.action === "collapseSidebar") {
      dispatch({
        type: ACTIONS.SELECT_SIDEBAR_TAB,
        selectedTab: null,
      });
      return true;
    }
  }

  // collapse by default on route change.
  dispatch({
    type: ACTIONS.SELECT_SIDEBAR_TAB,
    selectedTab: null,
  });
  return false;
};

const useLocationSidebarEffects = () => {
  const location = useLocation();
  const [, dispatch] = useSidebarContext();

  useEffect(() => {
    perfromSidebarAction(dispatch, location.state);
  }, [dispatch, location.path, location.state]);

  return null;
};

export default useLocationSidebarEffects;
