import { isNil } from "lodash";

export const getDailyRate = (group, parentGrouping, sourceGroupLookup) => {
  if (group && !isNil(group.dailyRate)) {
    return group.dailyRate;
  }

  // Find dailyRate from nearest parent group
  if (sourceGroupLookup) {
    const { hierarchyIds } = group;
    if (hierarchyIds && hierarchyIds.length > 0) {
      for (let i = hierarchyIds.length - 1; i >= 0; i -= 1) {
        const parentGroup = sourceGroupLookup[hierarchyIds[i]];
        if (parentGroup && !isNil(parentGroup.dailyRate)) {
          return parentGroup.dailyRate;
        }
      }
    }
  }

  if (parentGrouping && !isNil(parentGrouping.dailyRate)) {
    return parentGrouping.dailyRate;
  }

  return null;
};

export const getDailyRateByFlag = (
  isBudgetEnabled,
  group,
  parentGrouping,
  sourceGroupLookup
) => {
  if (isBudgetEnabled) {
    return getDailyRate(group, parentGrouping, sourceGroupLookup);
  }

  return null;
};
