/* eslint-disable import/prefer-default-export */
import { isNumber } from "lodash";

const numberToLocaleString = (
  value,
  { decimalplaces = 2, defaultValue = null } = {}
) => {
  const lang = navigator.language || "en-AU";

  return isNumber(value)
    ? value.toLocaleString(lang, { maximumFractionDigits: decimalplaces })
    : defaultValue;
};

export { numberToLocaleString };
