/* eslint-disable no-param-reassign */
import { get, find, forEach } from "lodash";
import { getIndirectForecastFlg } from "src/allocation/util/allocationProject";
import { PLANNER } from "../pageModes";
import { GROUPED_BY_SUPPLY } from "../viewModes";
import {
  newGrouping,
  newGroupingOptions,
  newLineItem,
  setLineItemWithForecastDefaultCells,
  setLineItemWithPlannerDefaultCells,
  setGroupingWithCalculatedSumTotals,
  hashLineItem,
  getDailyRateByFlag,
} from "./util";

const updateModelWithNewPlaceholderRole = ({
  forecastModel,
  role,
  supplyRoot,
  sourceGroupLookup,
  rootGroup,
  macroAllocationType,
  groupTypes,
  callback,
  isBudgetEnabled,
}) => {
  const indirectPlaceholderFlg = getIndirectForecastFlg(groupTypes, rootGroup);

  if (!indirectPlaceholderFlg) {
    return forecastModel;
  }

  const { columns, lookups } = forecastModel;
  const { groupingLookup, lineItemLookup, cellLookup } = lookups;
  let supplyRootGrouping = groupingLookup[supplyRoot.id];
  let roleGrouping = groupingLookup[role.id];
  const isNewSupplyRootGrouping = !supplyRootGrouping;
  const isNewRoleGrouping = !roleGrouping;

  let lineItem = find(get(roleGrouping, "lineItems"), {
    groupId: rootGroup.id,
    type: macroAllocationType,
  });

  if (supplyRootGrouping && roleGrouping && lineItem) {
    // Grouping already exists
    if (callback) {
      setTimeout(callback, 100, supplyRootGrouping, roleGrouping);
    }
    return forecastModel;
  }

  const rootGrouping = groupingLookup[rootGroup.id];

  // If supplyRoot does not exist, add it to rootGrouping
  if (!supplyRootGrouping) {
    supplyRootGrouping = newGrouping({
      ...newGroupingOptions({
        group: supplyRoot,
        groupType: get(groupTypes, supplyRoot.type, {}),
        isBudgetEnabled,
        sourceGroupLookup,
      }),
      parentGrouping: rootGrouping,
      rootGrouping,
      nestedLevel: rootGrouping.nestedLevel + 1,
      memberCount: supplyRoot.memberCount,
      isSupply: true,
      isDemand: false,
      canAddLineItem: false,
      canViewInTeamBuilder: rootGroup.isSource,
      canViewInDetails: true,
      showObjectives: false,
      columnCount: columns.length,
    });

    rootGrouping.childGroupings.push(supplyRootGrouping);
    rootGrouping.childGroupings.sort((a, b) =>
      a.group.name.localeCompare(b.group.name)
    );

    groupingLookup[supplyRootGrouping.id] = supplyRootGrouping;
  }

  // If roleGrouping does not exist, add it to supplyRootGrouping
  if (!roleGrouping) {
    roleGrouping = newGrouping({
      ...newGroupingOptions({
        group: role,
        groupType: get(groupTypes, role.type, {}),
        isBudgetEnabled,
        sourceGroupLookup,
      }),
      parentGrouping: supplyRootGrouping,
      rootGrouping,
      nestedLevel: supplyRootGrouping.nestedLevel + 1,
      memberCount: role.memberCount,
      isSupply: true,
      isDemand: false,
      canAddLineItem: false,
      canViewInTeamBuilder: rootGroup.isSource,
      canViewInDetails: true,
      showObjectives: false,
      columnCount: columns.length,
    });

    supplyRootGrouping.childGroupings.push(roleGrouping);
    supplyRootGrouping.childGroupings.sort((a, b) =>
      a.group.name.localeCompare(b.group.name)
    );

    groupingLookup[roleGrouping.id] = roleGrouping;
  }

  if (!lineItem) {
    const rootGroupType = get(groupTypes, rootGroup.type, {});
    lineItem = newLineItem({
      group: rootGroup,
      grouping: roleGrouping,
      groupId: rootGroup.id,
      columnCount: columns.length,
      canViewInDetails: true,
      canViewInTeamBuilder: true,
      isDemand: true,
      canRename: false,
      canHide: false,
      isSkillsMandatory: rootGroupType.isSkillsMandatory,
      type: macroAllocationType,
      dailyRate: getDailyRateByFlag(
        isBudgetEnabled,
        role,
        roleGrouping,
        sourceGroupLookup
      ),
    });

    setLineItemWithForecastDefaultCells({
      lineItem,
      targetGroupId: rootGroup.id,
      sourceGroupId: role.id,
      columns: forecastModel.columns,
      rootGroup,
    });

    roleGrouping.lineItems.push(lineItem);
    hashLineItem(lineItem, { rehashParents: true });

    // add objects to lookups
    lineItemLookup[lineItem.id] = lineItem;
    forEach(lineItem.cells, (cell) => {
      cellLookup[cell.id] = cell;
    });
  }

  if (isNewRoleGrouping) {
    setGroupingWithCalculatedSumTotals(roleGrouping, forecastModel.columns, {
      recalcParents: isNewSupplyRootGrouping,
    });
  }

  // If callback is passed, then call it after everything is ready
  if (callback) {
    setTimeout(callback, 100, supplyRootGrouping, roleGrouping);
  }

  return forecastModel;
};

const updateModelWithNewDirectRole = ({
  forecastModel,
  role,
  sourceGroupLookup,
  targetGroupId,
  pageMode,
  isRequestor,
  rootGroup,
  macroAllocationType,
  callback,
  isBudgetEnabled,
}) => {
  const grouping = forecastModel.lookups.groupingLookup[targetGroupId];

  if (!grouping) {
    return forecastModel;
  }

  if (
    find(grouping.lineItems, { groupId: role.id, type: macroAllocationType })
  ) {
    return forecastModel;
  }

  const lineItem = newLineItem({
    group: role,
    grouping,
    groupId: role.id,
    columnCount: forecastModel.columns.length,
    canViewInDetails: true,
    canViewInTeamBuilder: true,
    isSupply: true,
    canRename: false,
    canHide: false,
    isSkillsMandatory: grouping.isSkillsMandatory,
    type: macroAllocationType,
    dailyRate: getDailyRateByFlag(
      isBudgetEnabled,
      role,
      grouping,
      sourceGroupLookup
    ),
  });

  if (pageMode === PLANNER) {
    setLineItemWithPlannerDefaultCells({
      lineItem,
      targetGroupId,
      sourceGroupId: role.id,
      allocationProject: forecastModel.columns[0].allocationProject,
      isRequestor,
    });
  } else {
    setLineItemWithForecastDefaultCells({
      lineItem,
      targetGroupId,
      sourceGroupId: role.id,
      columns: forecastModel.columns,
      rootGroup,
    });
  }
  grouping.lineItems.push(lineItem);
  hashLineItem(lineItem, { rehashParents: true });

  // add objects to lookups
  // eslint-disable-next-line no-param-reassign
  forecastModel.lookups.lineItemLookup[lineItem.id] = lineItem;
  forEach(lineItem.cells, (cell) => {
    // eslint-disable-next-line no-param-reassign
    forecastModel.lookups.cellLookup[cell.id] = cell;
  });

  // If callback is passed, then call it after everything is ready
  if (callback) {
    setTimeout(callback, 100, lineItem, grouping);
  }

  return forecastModel;
};

const updateModelWithNewRole = (params) => {
  const { targetView } = params;

  if (targetView === GROUPED_BY_SUPPLY) {
    return updateModelWithNewPlaceholderRole(params);
  }

  return updateModelWithNewDirectRole(params);
};

export default updateModelWithNewRole;
