export const TAGS = {
  USER_COMMENT: "USER_COMMENT",
  SYSTEM_COMMENT: "SYSTEM_COMMENT",
  MOVING_GROUP: "MOVING_GROUP",
  SET_GROUP_MANAGER: "SET_GROUP_MANAGER",
  TAGGING: {
    COMMENT: "TAGGING_COMMENT",
    TAG_TYPE: "TAGGING_TAG_TYPE",
    TAG: "TAGGING_TAG",
  },
  ALLOCATION: {
    COMMENT: "ALLOCATION_COMMENT",
    FORECASTING: "ALLOCATION_FORECASTING",
    PLANNING: "ALLOCATION_PLANNING",
    INDIVIDUAL_ALLOCATION: "ALLOCATION_INDIVIDUAL_ALLOCATION",

    DEMAND: "ALLOCATION_DEMAND",
    SUPPLY: "ALLOCATION_SUPPLY",

    // system generated comments
    ACTION: {
      REQUESTED: "ALLOCATION_ACTION:REQUESTED",
      REQUEST_SUBMITTED: "ALLOCATION_ACTION:REQUEST_SUBMITTED",
      APPROVED: "ALLOCATION_ACTION:APPROVED",
      APPROVAL_SUBMITTED: "ALLOCATION_ACTION:APPROVAL_SUBMITTED",
      FORECASTED_FTE: "ALLOCATION_ACTION:FORECASTED_FTE",
      CONFIDENCE: "ALLOCATION_ACTION:FORECASTED_CONFIDENCE",
      FORECASTED_SKILLS: "ALLOCATION_ACTION:FORECASTED_SKILLS",
    },

    // system generated comments
    INDIVIDUAL_ALLOCATION_ACTION: {
      ALLOCATED: "INDIVIDUAL_ALLOCATION_ACTION:ALLOCATED",
      FTE_UPDATED: "INDIVIDUAL_ALLOCATION_ACTION:FTE_UPDATED",
      UNALLOCATED: "INDIVIDUAL_ALLOCATION_ACTION:UNALLOCATED",
    },
  },
  OBJECTIVE: {
    COMMENT: "OBJECTIVE_COMMENT",
  },

  PERSON_PLACEHOLDER: "PERSON_PLACEHOLDER",
  SUPPLY_GROUP_UPDATE: "SUPPLY_GROUP_UPDATE",
  PERSON_UPDATE: "PERSON_UPDATE",
  DOWNLOAD: "DOWNLOAD",
  GROUP_UPDATE: "GROUP_UPDATE",
  MEMBERSHIP_UPDATE: "MEMBERSHIP_UPDATE",

  GROUP_ID: (groupId) => `GROUP_ID:${groupId}`,
  PERSON_ID: (personId) => `PERSON_ID:${personId}`,
  ASSOCIATED_ENTITY_ID: (id) => `ASSOCIATED_ENTITY_ID:${id}`,
  ALLOCATION_PROJECT_ID: (allocationProjectId) =>
    `ALLOCATION_PROJECT_ID:${allocationProjectId}`,
  MACRO_ALLOCATION_ID: (macroAllocationId) =>
    `MACRO_ALLOCATION_ID:${macroAllocationId}`,
  INDIVIDUAL_ALLOCATION_ID: (individualAllocationId) =>
    `INDIVIDUAL_ALLOCATION_ID:${individualAllocationId}`,
  ENTITY_TYPE: (type) => `ENTITY_TYPE:${type}`,
  TAGGING_TYPE_ID: (tagType) => `TAGGING_TYPE_ID:${tagType}`,
  TAGGING_VALUE_ID: (id) => `TAGGING_VALUE_ID:${id}`,
  TAG_ORIGINAL_DISPLAY_VALUE: (value) => `TAG_ORIGINAL_DISPLAY_VALUE:${value}`,
};

export const CONTENT_MARKUP = {
  SLATE: "slate",
};

export const ALLOCATION_THREAD_ID = (
  allocationProjectId,
  sourceGroupId,
  targetGroupId
) => `allocation-${allocationProjectId}-${sourceGroupId}-${targetGroupId}`;

export const MOVE_GROUP_THREAD_ID = (groupId, newParentGroupId) =>
  `planning-move-group-${groupId}-${newParentGroupId}`;

export const SET_MANAGER_THREAD_ID = (groupId, aggregateid) =>
  `set-manager-${groupId}-${aggregateid}`;

export const ENTITY_TYPES = {
  GROUP: "group",
  PERSON: "person",
};
