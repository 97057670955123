import moment from "moment";

export default () => {
  moment.locale("en-short", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "<1m",
      ss: "%ds",
      m: "1m",
      mm: "%dm",
      h: "1h",
      hh: "%dh",
      d: "1d",
      dd: "%dd",
      M: "1M",
      MM: "%dM",
      y: "1Y",
      yy: "%dY",
    },
  });
};
