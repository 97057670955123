import { get, filter } from "lodash";

export const getForecastAllocationProjects = (allocationProjects) => {
  return filter(allocationProjects, (ap) => ap.isActive || ap.forecast);
};

export const getForecastReadonlyFlag = (allocationProject, rootGroup) => {
  const isDemand = get(rootGroup, "isDemand", false);
  if (isDemand) {
    return get(allocationProject, "isRequestorForecastReadonly", false);
  }

  const isSource = get(rootGroup, "isSource", false);
  if (isSource) {
    return get(allocationProject, "isApproverForecastReadonly", false);
  }

  return false;
};

export const getForecastReadonlyMsg = (allocationProject, rootGroup) => {
  const isDemand = get(rootGroup, "isDemand", false);
  if (isDemand) {
    return get(allocationProject, "requestorForecastReadonlyHover", "");
  }

  const isSource = get(rootGroup, "isSource", false);
  if (isSource) {
    return get(allocationProject, "approverForecasteadonlyHover", "");
  }

  return "";
};

export const getIndirectForecastFlg = (groupTypes, group) => {
  const groupType = get(groupTypes, group.type, {});

  return get(groupType, "planning.canHaveIndirectPlaceholderForecasts", false);
};

export const getIndirectForecastTargetFlg = (groupTypes, group) => {
  const groupType = get(groupTypes, group.type, {});

  return get(groupType, "planning.isIndirectPlaceholderForecastTarget", false);
};
