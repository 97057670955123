import { get, includes, isEmpty } from "lodash";

const INTERNAL_SUPPORT_EMAIL_DOMAINS = [
  "support.orchestrated.io",
  "support.teamform.co",
];
const auth0Namespace = "http://orchestrated.io/2020/03/identity/claims";

export const POWER_ROLE = "POWER";
export const ADMIN_ROLE = "ADMIN";
export const DIRECTORY_ROLE = "DIRECTORY";
export const REPORTING_ROLE = "REPORTING";

// TODO: Move relevant metadata from user to app metadata object
// export const auth0AppMetadataKey = `${auth0Namespace}/app_metadata`;
export const auth0UserMetadataKey = `${auth0Namespace}/user_metadata`;

// this is compiled, no risk of timing attack
export const isProdAuth0Tenant =
  process.env.REACT_APP_AUTH0_DOMAIN === "id.orchestrated.io";

const getFromCustomClaims = (user, key) =>
  get(user, `${auth0Namespace}/${key}`);

export const getAuth0UserMetadata = (user) =>
  getFromCustomClaims(user, "user_metadata");

const getFromUserMetadata = (user, key) => get(getAuth0UserMetadata(user), key);

export const isPowerOrAdmin = (user) => {
  const roles = get(getAuth0UserMetadata(user), "roles");
  return includes(roles, POWER_ROLE) || includes(roles, ADMIN_ROLE);
};

export const canAccessPeopleMapper = (user) => {
  return isPowerOrAdmin(user);
};

export const isAdmin = (user) => {
  const roles = get(getAuth0UserMetadata(user), "roles");
  return includes(roles, ADMIN_ROLE);
};

export const getAuth0ConnectionFromHostname = (connection) => {
  return connection
    ? {
        connection,
      }
    : null;
};

export const getGraphqlUrlFromAuth0Tenant = (tenant) =>
  `${tenant.tenantUrl}/${tenant.tenantId}/graphql`;

export const isInternalSupportUser = (user) => {
  const isInternalSupportEmail = INTERNAL_SUPPORT_EMAIL_DOMAINS.includes(
    user.email.split("@")[1]
  );

  return (
    isInternalSupportEmail && Boolean(getFromUserMetadata(user, "tenantList"))
  );
};

export const isCypressTestUser = (user) => {
  return /^cypress-.*test\.orchestrated.io$/.test(user.email);
};

export const isUserWithTenantPrompt = (user) => {
  const isCypressTesting = isCypressTestUser(user);

  const isSupportUser = isInternalSupportUser(user);
  const isRunCypressLocally = process.env.REACT_APP_CYPRESS_LOCAL === "true";
  return (!isRunCypressLocally && isCypressTesting) || isSupportUser;
};

export const getPreferredUsername = (user) =>
  getFromCustomClaims(user, "preferred_name");

export const tenantAvailable = (user) => {
  const tenantId = getFromUserMetadata(user, "tenantId");
  return tenantId !== "TBC" && !isEmpty(tenantId);
};

export const getTenantId = (user) => getFromUserMetadata(user, "tenantId");

export const getCreatedAtDate = (user) =>
  getFromCustomClaims(user, "created_at");
