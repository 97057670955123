import { createMacroAllocationId } from "src/allocation/util/macroAllocation";
import newCell from "../newCell";

export default ({
  lineItem,
  targetGroupId,
  sourceGroupId,
  allocationProjectId,
  columnType,
  isEditable,
  dailyRate,
  workingDays,
}) => {
  const id = createMacroAllocationId(
    allocationProjectId,
    sourceGroupId,
    targetGroupId
  );

  return newCell({
    lineItem,
    id: `${id}-${columnType}`,
    macroAllocationId: id,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    isStatic: false,
    isEditable,
    value: 0,
    defaultedValue: 0,
    columnType,
    dailyRate,
    workingDays,
  });
};
