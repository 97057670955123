import PropTypes from "prop-types";
import { get, chain } from "lodash";
import React, { useState } from "react";
import { Box, Button, Icon, Select, Spacer, H4 } from "orcs-design-system";

import AuthLayout from "src/components/AuthLayout";
import Auth0User from "src/custom-prop-types/auth0user";
import { auth0UserMetadataKey } from "src/services/auth";
import { saveActiveTenant } from "src/services/localStorage";

const SelectTenantPrompt = ({ children, user, isFullScreen, isSelectOnly }) => {
  const [selectedTenant, setSelectedTenant] = useState({
    name: "",
    tenantId: "",
    label: "Select a tenant...",
  });
  const [isTenantSelected, setIsTenantSelected] = useState(false);
  const tenantList =
    get(user[auth0UserMetadataKey], "tenantList.tenants") || [];
  const preferredUsername = user.given_name
    ? `${user.given_name} ${user.family_name}`
    : user.nickname;

  const tenantOptions = chain(tenantList)
    .sortBy("name")
    .map((t) => ({
      label: t.name ? `${t.name} (${t.tenantId})` : `${t.tenantId}`,
      tenantUrl: t.tenantUrl,
      tenantId: t.tenantId,
    }))
    .tap((list) => {
      if (process.env.REACT_APP_IS_OFFLINE) {
        list.push({
          label: "Local Host",
          tenantUrl: "https://localhost:4000",
          tenantId: "localhost",
        });
      }
    })
    .value();

  const onTenantSelected = () => {
    if (!selectedTenant.tenantId) {
      return;
    }
    saveActiveTenant(user.email, selectedTenant);
    setIsTenantSelected(true);
  };

  const onTenantChange = (selectedOption) => {
    setSelectedTenant(selectedOption);

    if (isSelectOnly) {
      saveActiveTenant(user.email, selectedOption);
      window.location.reload();
    }
  };

  if (isTenantSelected) {
    saveActiveTenant(user.email, selectedTenant);
    if (!children) {
      window.location.reload();
      return "Proceeding...";
    }
    return children;
  }

  const tenantSelect = (
    <Select
      inputId="TenantSelect"
      ariaLabel="Select tenant"
      data-testid="cp-select-tenant-dropdown"
      placeholder="Select a tenant"
      options={tenantOptions}
      value={selectedTenant}
      onChange={onTenantChange}
      height="30px"
      padding="0 8px"
    />
  );

  if (isSelectOnly) {
    return <Box width="200px">{tenantSelect}</Box>;
  }

  const title = isFullScreen
    ? `Welcome back ${preferredUsername}. Select a tenant to proceed.`
    : `Switch to another tenant`;

  const Form = (
    <Spacer my={4}>
      <H4 data-testid="cp-select-tenant-title">{title}</H4>
      {tenantSelect}
      <Button
        iconLeft
        fullWidth
        data-testid="cp-select-tenant-proceed"
        onClick={onTenantSelected}
      >
        <Icon icon={["fas", "lock"]} />
        Proceed
      </Button>
    </Spacer>
  );

  if (isFullScreen) {
    return <AuthLayout customWidth="480px">{Form}</AuthLayout>;
  }
  return (
    <Box bg="white" boxBorder="default" shadow="default" borderRadius={2} p={4}>
      {Form}
    </Box>
  );
};

SelectTenantPrompt.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  isFullScreen: PropTypes.bool,
  isSelectOnly: PropTypes.bool,
  user: Auth0User,
};

export default SelectTenantPrompt;
