import {
  PLANNER_CURRENT,
  PLANNER_REQUESTED,
  PLANNER_APPROVED,
} from "../../../../../columnTypes";
import newCurrentBaselineCell from "./newCurrentBaselineCell";
import newRequestorCell from "./newRequestorCell";
import newApproverCell from "./newApproverCell";

export default ({
  lineItem,
  macroAllocation,
  allocationProject,
  columnType,
  isRequestor,
  isApprover,
  isEditable,
  approvalReasons,
  useDeltaForBudget,
  isRealtimeFteMode,
  useRealtimeChangeOnly,
  useRealtimeFteDelta,
}) => {
  if (columnType === PLANNER_CURRENT) {
    return newCurrentBaselineCell({ lineItem, macroAllocation });
  }
  if (columnType === PLANNER_REQUESTED) {
    return newRequestorCell({
      lineItem,
      macroAllocation,
      allocationProject,
      isRequestor,
      isEditable,
      useDeltaForBudget,
      isRealtimeFteMode,
      useRealtimeChangeOnly,
      useRealtimeFteDelta,
    });
  }
  if (columnType === PLANNER_APPROVED) {
    return newApproverCell({
      lineItem,
      macroAllocation,
      isApprover,
      isEditable,
      approvalReasons,
    });
  }

  throw Error(`invalid columnType: ${columnType}`);
};
