import { get, mergeWith } from "lodash";

export const contactDetailsCopy = {
  phone: {
    available: "Contact via phone",
    unavailable: "No phone number available",
    errorMessage: "Unable to make a phone call",
  },
  email: {
    available: "Contact via email",
    unavailable: "No email available",
    successMessage: "Copied Email to clipboard",
  },
  microsoftTeams: {
    available: "Contact via Microsoft teams",
    unavailable: "Not available (requires email)",
  },
};

export const MS_TEAMS_CHAT_LINK = "https://teams.microsoft.com/l/chat/0/0?";

export const defaultConfig = {
  email: true,
  microsoftTeams: true,
  phone: true,
  googleChat: false,
  slack: false,
};

export const getContactDetailsConfig = (globalConfig) => {
  return mergeWith(
    defaultConfig,
    get(globalConfig, "config.featureFlags.individualContactOptions", {}),
    (objValue, srcValue) => (srcValue === null ? objValue : undefined)
  );
};
