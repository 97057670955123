import { trim } from "lodash";
import {
  Button,
  Flex,
  Modal,
  H3,
  Box,
  Spacer,
  TextInput,
  P,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

import ErrorNotification from "src/components/ErrorNotification";

import { RichTextContentViewer } from "src/shared/RichTextContent/RichTextContent";

const DetailsWrapper = styled(Box)`
  overflow-y: auto;
  max-height: 120px;
`;

const TagDisplayValueModal = ({ onClose, tag, tagTypeConfig, setNewTag }) => {
  const [displayValue, setDisplayValue] = useState(tag.displayValue);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const {
    name: tagTypeName,
    hasDetails,
    detailsParsed: parsedDetails,
  } = tagTypeConfig || {};

  const onDisplayValueChange = (e) => {
    const newValue = e.target.value;
    setDisplayValue(newValue);
  };

  const onSave = async () => {
    setIsSaving(true);
    try {
      await setNewTag(
        {
          ...tag,
          displayValue: trim(displayValue),
        },
        tagTypeConfig
      );

      setIsSaving(false);
      onClose();
    } catch (e) {
      setError(e);
      setIsSaving(false);
    }
  };

  const modalFooter = (
    <Flex>
      <Button
        onClick={onSave}
        isLoading={isSaving}
        disabled={isSaving}
        variant={isSaving ? "disabled" : "success"}
        mr="s"
      >
        Save
      </Button>
      <Button onClick={onClose} variant="ghost">
        Close
      </Button>
    </Flex>
  );

  return (
    <Modal
      width={["90vw", "90vw", "90vw", "80vw", "70vw", "50vw"]}
      maxWidth="90vw"
      maxHeight="90vh"
      visible
      onClose={onClose}
      footerContent={modalFooter}
    >
      <Flex width="100%" mb="r">
        <Box mr="r" width="50%">
          <H3 bold mb="r">
            Tag details
          </H3>
          <Spacer mt="r">
            <TextInput
              name="tag-type"
              type="text"
              id="tagType"
              label="Tag type"
              fullWidth
              value={tagTypeName}
              disabled
            />
            {hasDetails && (
              <Box>
                <P>Tag type description</P>
                <DetailsWrapper
                  mt="s"
                  p="s"
                  boxBorder="default"
                  borderRadius={2}
                >
                  <RichTextContentViewer
                    content={parsedDetails}
                    fontSize="14px"
                  />
                </DetailsWrapper>
              </Box>
            )}
          </Spacer>
        </Box>
        <Box width="50%">
          <H3 bold mb="r">
            Tag attributes
          </H3>
          <TextInput
            type="text"
            id="tag-display-value-input"
            label="Tag display value"
            data-testid="displayValueInput"
            placeholder="Not set"
            fullWidth
            value={displayValue}
            maxLength={120}
            onChange={onDisplayValueChange}
          />
        </Box>
        {error && <ErrorNotification message={error?.message} error={error} />}
      </Flex>
    </Modal>
  );
};

TagDisplayValueModal.propTypes = {
  onClose: PropTypes.func,
  tag: PropTypes.object,
  tagTypeConfig: PropTypes.object,
  setNewTag: PropTypes.func,
};

export default TagDisplayValueModal;
