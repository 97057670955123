import { filter, flatMap, uniq } from "lodash";

const findAllGroupings = (groupings, targetGroupId, sourceGroupId) => {
  const childGroupings = flatMap(groupings, "childGroupings");
  const foundChildGroupings =
    childGroupings.length > 0
      ? findAllGroupings(childGroupings, targetGroupId, sourceGroupId)
      : [];

  return [
    ...filter(groupings, (g) => {
      const groupingLineitem = g.lineItems[0];
      if (groupingLineitem) {
        const groupingCell = groupingLineitem.cells[0];
        if (groupingCell) {
          if (targetGroupId && sourceGroupId)
            return (
              groupingCell.sourceGroupId === sourceGroupId &&
              groupingCell.targetGroupId === targetGroupId
            );
          if (targetGroupId)
            return groupingCell.targetGroupId === targetGroupId;
          if (sourceGroupId)
            return groupingCell.sourceGroupId === sourceGroupId;
        }
      }

      return false;
    }),
    ...foundChildGroupings,
  ];
};

const getExpandIds = (grouping) => {
  const expandIds = [grouping.id];
  let { parent } = grouping;
  while (parent) {
    expandIds.push(parent.id);
    parent = parent.parent;
  }
  return expandIds;
};

const findAllGroupingsExpandIds = (groupings, targetGroupId, sourceGroupId) => {
  const foundGroupings = findAllGroupings(
    groupings,
    targetGroupId,
    sourceGroupId
  );
  const expandIds = uniq(flatMap(foundGroupings, (g) => getExpandIds(g)));
  const expandLineItemIds = uniq(flatMap(foundGroupings, "id"));

  return [expandIds, expandLineItemIds];
};

export default findAllGroupingsExpandIds;
