import { isEmpty } from "lodash";
import { Box, Small, P, FlexItem } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { TreeHeader, TreeHeaderTitle } from "src/shared/TreeView/TreeView";
import { RichTextContentViewer } from "src/shared/RichTextContent/RichTextContent";

const TreeText = styled(P)`
  word-break: break-word;
  z-index: 2;
`;

const TagTreeHeader = ({ node, onToggle, onActivate }) => {
  const {
    isTagType,
    isTagTypeDesc,
    name,
    tagValue,
    children,
    active,
    toggled,
  } = node;

  if (isTagType) {
    const onClickTagType = (e) => {
      e.stopPropagation();
      if (!isEmpty(children)) {
        onToggle(node, !node.toggled);
      } else {
        onActivate(node);
      }
    };

    const hasChildren = Boolean(node.children);

    return (
      <TreeHeader onClick={onClickTagType}>
        <TreeHeaderTitle
          fontWeight={toggled ? "bold" : "normal"}
          data-testid="treeheader-node-name"
          color="black"
          fontSize="1"
          hasChildren={hasChildren}
        >
          {name}
        </TreeHeaderTitle>
      </TreeHeader>
    );
  }

  if (isTagTypeDesc) {
    return (
      <Box ml="l" dataTestId="tag-type-desc-node">
        <RichTextContentViewer content={node.details} fontSize="1.2rem" />
      </Box>
    );
  }

  const { appliedCount } = tagValue || {};
  const fontWeight = active ? "bold" : "normal";

  const onClickTag = (e) => {
    e.stopPropagation();
    onActivate(node);
  };

  return (
    <TreeHeader onClick={onClickTag}>
      <TreeHeaderTitle
        fontWeight={toggled ? "bold" : "normal"}
        data-testid="treeheader-node-name"
      >
        <TreeText fontWeight={fontWeight} fontSize="1" color="black">
          {name}
        </TreeText>
        <FlexItem flex="0 0 auto">
          <Small fontWeight={fontWeight} color="greyDark">
            {appliedCount ? `${appliedCount} tagged` : ""}
          </Small>
        </FlexItem>
      </TreeHeaderTitle>
    </TreeHeader>
  );
};

TagTreeHeader.propTypes = {
  node: PropTypes.object,
  onToggle: PropTypes.func,
  onActivate: PropTypes.func,
};

export default TagTreeHeader;
