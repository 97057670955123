import { isNil, isFinite, replace } from "lodash";
import numeral from "numeral";

export const toReadableNumber = (num, currencySymbol = "$") => {
  return replace(numeral(num).format(`$0[.]0a`), "$", currencySymbol);
};

// If the financial forecasting flag is not enabled, dailyRate will be null,
// the totalCost will be null too
export const calculateTotalCost = ({ value, dailyRate, workingDays }) => {
  if (isNil(dailyRate)) {
    return null;
  }

  if (isFinite(dailyRate) && isFinite(workingDays) && isFinite(value)) {
    return value * dailyRate * workingDays;
  }

  return null;
};
