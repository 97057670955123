import { useState, useEffect } from "react";
import { BASE_URL, getFetchHttpOptions } from "src/util/appConfig";

import { isOnGenericUrl } from "src/util/url";
import { getItem, saveItem } from "src/services/sessionStorage";

const localStorageKey = "connections";

const useFetchConfig = () => {
  const [config, setConfig] = useState(getItem(localStorageKey) || null);
  const [isFetchingConfig, setIsFetchingConfig] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (isOnGenericUrl() || config) {
        setIsFetchingConfig(false);
        return;
      }

      try {
        const response = await fetch(
          `${BASE_URL}/functions/home-realm-discovery`,
          getFetchHttpOptions()
        );
        const data = await response.json();
        setConfig(data);
        saveItem(localStorageKey, data);
        setIsFetchingConfig(false);
      } catch (error) {
        setIsFetchingConfig(false);
      }
    };

    fetchData();
  }, [config]);

  return {
    config,
    isGenericDomain: !!config?.auth0Connection,
    isFetchingConfig,
  };
};

export default useFetchConfig;
