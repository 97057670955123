import React, { Suspense } from "react";
import PropTypes from "prop-types";
import themeGet from "@styled-system/theme-get";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import Sidebar from "src/sidebar/Sidebar";

import Loading from "src/components/LoadingPage";
import AppNavSimple from "../components/Nav/AppNavSimple";
import OutdatedBrowserMessage from "../components/OutdatedBrowserMessage";

const AppContent = styled.section`
  overflow-y: auto !important;
  height: calc(100vh - 108px);
  padding: ${themeGet("space.r")};
  background: ${themeGet("colors.greyLightest")};
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 900px) {
    height: calc(100vh - ${(props) => themeGet("appScale.navBarSize")(props)});
  }
`;

const AppLayout = ({ emptyMode = false, children, themeSwitcher }) => {
  return (
    <>
      <OutdatedBrowserMessage />
      <AppNavSimple emptyMode={emptyMode} />
      <main>
        <Sidebar themeSwitcher={themeSwitcher} />

        <AppContent id="appContent">
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </AppContent>
      </main>
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  emptyMode: PropTypes.bool,
  themeSwitcher: PropTypes.func,
};

export default withRouter(AppLayout); // TODO: move to react hooks
