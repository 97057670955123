import { get, isEmpty, map } from "lodash";
import {
  Notification,
  Avatar,
  Flex,
  FlexItem,
  StyledLink,
  Box,
  Divider,
  Badge,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import GroupTypeBadge from "src/components/Badge/GroupTypeBadge";
import GroupHierarchyBreadcrumb from "src/components/GroupHierarchyBreadcrumb";
import HighlightedTags from "src/components/HighlightedTags";
import AvatarPlaceholder from "src/components/Placeholder/AvatarPlaceholder";
import GroupPropType from "src/custom-prop-types/group";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import { ADD_ENTITY_TO_TEAM_SUCCESS_MESSAGE } from "src/consts/tags";
import TagBadge from "src/components/Badge/TagBadge";
import { AvatarWithGradient } from "src/styles/gradient";
import {
  getGroupBgImg,
  isGroupVisible,
  getGroupExternalId,
} from "src/util/group";
import { getTeamGradient } from "src/util/gradient";
import { getTeamsPath } from "src/util/paths";
import { getMemberCountDisplay } from "src/util/search";

const TeamListItem = ({
  group,
  loading,
  groupTypes,
  isLastItem,
  added = false,
  tags,
  showInPage = false,
  actionItem: ActionItem,
  cardView,
  customer,
  successNotificationMessage = ADD_ENTITY_TO_TEAM_SUCCESS_MESSAGE,
  renderFteBadge,
  renderPrincipleStatus,
  disableChildGroupPrinciples,
  workspace,
  showAssociationTagBadges = false,
  selectedString,
}) => {
  const groupId = get(group, "id");
  const bgImg = getGroupBgImg(group);
  const bgGradient = getTeamGradient(group, customer);

  if (!groupId || (!loading && !isGroupVisible(group))) {
    return null;
  }

  const memberCount = get(group, "memberCount");
  const hasHierarchy = !!get(group, "hierarchy");
  const workspaceFeatureFlags = get(workspace, "config.featureFlags", {});
  const externalId = getGroupExternalId(group);
  const showExternalIdBadge =
    workspaceFeatureFlags?.showTeamExternalIdAsBadge && externalId;

  let nameLink;

  if (hasHierarchy) {
    nameLink = showInPage ? (
      <Flex flexDirection="column">
        <GroupHierarchyBreadcrumb
          group={group}
          workspace={workspace}
          selectedString={selectedString}
        />
      </Flex>
    ) : (
      <GroupHierarchyBreadcrumb group={group} small={false}>
        <StyledLink
          aria-label={`Navigate to ${group.name}`}
          to={getTeamsPath(groupId)}
          fontSize="3"
        >
          <span>{group.name}</span>
        </StyledLink>
      </GroupHierarchyBreadcrumb>
    );
  } else {
    nameLink = (
      <StyledLink
        aria-label={`Navigate to ${group.name}`}
        to={getTeamsPath(groupId)}
      >
        <span>{group.name}</span>
      </StyledLink>
    );
  }

  const groupName = groupId && <Flex alignItems="center">{nameLink}</Flex>;

  let itemDivider = null;
  if (!isLastItem) {
    itemDivider = <Divider light />;
  }

  const hasAction = !!ActionItem;

  let boxPadding = "s";
  if (cardView) {
    boxPadding = " 0";
  }

  const teamBadges = (
    <>
      <Flex flexWrap="wrap" alignItems="center" role="list">
        <FlexItem mr="xs" mt="xs" flex="0 1 auto" role="listitem">
          <GroupTypeBadge
            group={group}
            groupTypes={groupTypes}
            selectedString={selectedString}
          />
        </FlexItem>
        {showExternalIdBadge && (
          <Badge mr="xs" mt="xs" role="listitem">
            {externalId}
          </Badge>
        )}
        {!isNaN(memberCount) && (
          <Badge mr="xs" mt="xs" role="listitem">
            {getMemberCountDisplay(memberCount)}
          </Badge>
        )}

        {renderFteBadge && (
          <Box mr="xs" mt="xs" role="listitem">
            {renderFteBadge(group)}
          </Box>
        )}
      </Flex>
      {!showAssociationTagBadges && !isEmpty(tags) && (
        <HighlightedTags
          tags={tags}
          tagConfig={get(workspace, "config.tagConfig", {})}
          flagType="isGroupBadge"
        />
      )}
      {showAssociationTagBadges && !isEmpty(tags) && (
        <Flex flexWrap="wrap" mt="xs" role="list">
          {map(tags, (tag) => {
            return (
              <TagBadge
                value={tag}
                withType={true}
                getTypeName={() => get(tag, "tagTypeMetadata.name", tag.type)}
                disabled
              />
            );
          })}
        </Flex>
      )}
      {renderPrincipleStatus && !disableChildGroupPrinciples && (
        <Box mt="s">{renderPrincipleStatus(group)}</Box>
      )}
    </>
  );

  const AvatarComponent = bgImg ? Avatar : AvatarWithGradient;

  return loading ? (
    <AvatarPlaceholder rows={1} />
  ) : (
    <>
      <Box py={boxPadding} px={cardView ? "0" : ""}>
        {added && (
          <Notification mb="r" colour="success" icon={["fas", "check-circle"]}>
            {successNotificationMessage}
          </Notification>
        )}
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexWrap="nowrap"
        >
          <FlexItem flex="1 1 auto">
            <AvatarComponent
              data-testid="cp-explore-team-list-item-panel"
              shape="square"
              sizing={cardView ? "large" : "small"}
              title={groupName}
              image={bgImg}
              imageAlt={`Avatar of ${group.name}`}
              bgGradient={bgImg ? undefined : bgGradient}
              type="default"
              subtitleContent={teamBadges}
            />
          </FlexItem>
          {hasAction && (
            <FlexItem flex="0 0 auto">
              <ActionItem team={group} tags={tags} />
            </FlexItem>
          )}
        </Flex>
      </Box>
      {cardView ? null : itemDivider}
    </>
  );
};

TeamListItem.propTypes = {
  group: PropTypes.oneOfType([GroupPropType, PropTypes.number]), // number for placeholder
  groupTypes: GroupTypesPropType,
  loading: PropTypes.bool,
  isLastItem: PropTypes.bool,
  showInPage: PropTypes.bool,
  cardView: PropTypes.bool,
  added: PropTypes.bool,
  tags: PropTypes.array,
  customer: PropTypes.string,
  actionItem: PropTypes.elementType,
  successNotificationMessage: PropTypes.string,
  renderFteBadge: PropTypes.func,
  renderPrincipleStatus: PropTypes.func,
  disableChildGroupPrinciples: PropTypes.bool,
  workspace: PropTypes.object,
  showAssociationTagBadges: PropTypes.bool,
  selectedString: PropTypes.string,
};

export default TeamListItem;
