import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Notification, StyledLink } from "orcs-design-system";

const Container = styled.div`
  @supports (display: flex) and (display: grid) {
    display: none;
  }
  > div {
    border-radius: 0;
    width: 100%;
  }
`;

// TODO: Confusing naming convention for floating! Use IsBooleanName convention
const OutdatedBrowserMessage = ({ floating }) => {
  return (
    <Container>
      <Notification
        floating={floating}
        colour="danger"
        icon={["far", "exclamation-triangle"]}
      >
        {`It looks like you're using an outdated browser. For an optimal
          experience, please view this site in `}
        <StyledLink
          href="https://www.google.com/chrome/"
          bold
          white
          target="_blank"
        >
          Chrome
        </StyledLink>
        ,&nbsp;
        <StyledLink
          href="https://www.microsoft.com/en-us/edge"
          bold
          white
          target="_blank"
        >
          Edge
        </StyledLink>
        or
        <StyledLink
          href="https://www.mozilla.org/en-US/firefox/new/"
          bold
          white
          target="_blank"
        >
          Firefox
        </StyledLink>
        .
      </Notification>
    </Container>
  );
};

OutdatedBrowserMessage.propTypes = {
  floating: PropTypes.bool,
};

export default OutdatedBrowserMessage;
