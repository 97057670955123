// import { colours } from "orcs-design-system";
// TODO: Remove duplication with similar named file in non-shared components (move to orcs?)

import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const gradient = {
  lightest: "rgba(0,0,0,0.1)",
  lighter: "rgba(0,0,0,0.05)",
  darkLightest: "rgba(255,255,255,0.1)",
  darkLighter: "rgba(255,255,255,0.05)",
};

/* Infinite Rotation */
export const COLOR_CHANGE = keyframes`
0% {
  background-position: -468px 0;
}
100% {
  background-position: 468px 0;
}
`;

const FADE_IN = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// use for placeholder lines
// TODO: Better method than putting in pixel value for dimensions?
export const Line = styled.div`
  border-radius: 8px;
  animation-delay: 0.2s;
  background: ${({ theme }) =>
    `linear-gradient(to right, ${
      theme === "dark" ? gradient.darkLightest : gradient.lightest
    } 5%, ${theme === "dark" ? gradient.darkLighter : gradient.lighter} 12%, ${
      theme === "dark" ? gradient.darkLightest : gradient.lightest
    } 55% )`};
  opacity: 0;
  animation: ${COLOR_CHANGE} 10s linear both infinite,
    ${FADE_IN} 300ms ease-in forwards ${({ delay }) => `${delay}`};
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : "0px")};
`;

Line.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  delay: PropTypes.string,
};
Line.defaultProps = {
  width: 200,
  height: 24,
  delay: "500ms",
};
