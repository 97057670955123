import themeGet from "@styled-system/theme-get";
import { css } from "styled-components";
import { VARIANT_COLORS } from "orcs-design-system";

const width = 24;
const height = 22;
const aliases = [];
const unicode = "f3a0";
const svgPathData = [
  [
    "M17.6079 16.9414C17.8188 16.9799 18.0361 17 18.2583 17H18.275C20.2319 17 21.8182 15.4368 21.8182 13.5085V8.45C21.8182 7.92532 21.3866 7.5 20.8542 7.5H17.3087C17.8348 7.51283 18.251 7.94306 18.2388 8.46151V14.231C18.2618 15.2069 18.0319 16.1307 17.6079 16.9414Z",
    "M19.0275 6.5C20.2885 6.5 21.3108 5.49264 21.3108 4.25C21.3108 3.00736 20.2885 2 19.0275 2C17.7665 2 16.7442 3.00736 16.7442 4.25C16.7442 5.49264 17.7665 6.5 19.0275 6.5Z",
    "M6.51282 16.5C7.34849 18.5078 9.32789 19.9435 11.6702 20C14.8273 19.9239 17.325 17.3421 17.2516 14.231V8.46151C17.2638 7.94306 16.8476 7.51283 16.3215 7.5H12.16V15.4169C12.16 16.0151 11.7064 16.5 11.1468 16.5H6.51282Z",
    "M8.63541 3.5H11.1469C11.7064 3.5 12.16 3.98494 12.16 4.58313V6.49181C13.8713 6.37253 15.222 4.9667 15.222 3.25C15.222 1.45508 13.7454 0 11.9239 0C10.1024 0 8.62579 1.45508 8.62579 3.25C8.62579 3.33412 8.62904 3.41749 8.63541 3.5Z",
    "M0.930059 4.5H10.2327C10.7464 4.5 11.1628 4.91033 11.1628 5.4165V14.5835C11.1628 15.0897 10.7464 15.5 10.2327 15.5H0.930059C0.416397 15.5 0 15.0897 0 14.5835V5.4165C0 4.91033 0.416407 4.5 0.930059 4.5Z",
  ].join(""),
  [
    "M8.02912 7.98899H6.16949V12.979H4.98472V7.98899H3.13373V7.021H8.02912V7.98899Z",
  ].join(""),
];

export const facMicrosoftTeams = {
  prefix: "fac",
  iconName: "microsoft-teams",
  icon: [width, height, aliases, unicode, svgPathData],
};

export const facMicrosoftTeamsStyles = css`
  .fa-microsoft-teams {
    path {
      transition: ${themeGet("transition.transitionDefault")};
    }
    path:first-child {
      opacity: 1;
    }
  }

  ${Object.entries(VARIANT_COLORS).map(([key, variant]) => {
    const className =
      key === "disabled" ? ":disabled" : `.variant-${key}:not(:disabled)`;

    return css`
      ${className} .fa-microsoft-teams {
        path:first-child {
          fill: ${themeGet(variant.color)};
        }

        path:last-child {
          fill: ${themeGet(variant.background)};
        }
      }

      ${className}:hover .fa-microsoft-teams {
        path:first-child {
          fill: ${themeGet(variant.hovered.color)};
        }
        path:last-child {
          fill: ${themeGet(variant.hovered.background)};
        }
      }
    `;
  })}
`;
