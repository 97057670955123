import React from "react";
import { Spacer, Typography, Badge, Flex, Box } from "orcs-design-system";
import PropTypes from "prop-types";
import i18next from "i18next";
import { Trans } from "react-i18next";
import { deleteGuidedTourFromLocalStorage } from "src/services/localStorage";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import {
  GROUPED_BY_DEMAND,
  GROUPED_BY_SUPPLY,
} from "src/allocation/pages/ForecastPage/context/reducer/viewModes";
import createActionsMenuHandler from "../util/actionsMenuUtil";

/**
 * TODO: Localize the remaining text in this file.
 */

const Component = ({ group = {}, supplyLabel, demandLabel, viewMode } = {}) => {
  const [openActionsMenu, closeActionsMenu] = createActionsMenuHandler(
    ".guided-tour-allocation-forecast-actions-menu"
  );

  const allocationProjectTrans = i18next.t("glossary:allocationProject");
  const allocationProjectsTrans = i18next.t("glossary:allocationProjects");
  const forecastTrans = i18next.t("glossary:Forecast");

  return {
    triggerInSidebar: true,
    autoStart: false,
    showProgress: false,
    showSkipButton: true,
    disableScrolling: true,
    dependsOn: [],
    onComplete: ({ auth }) => {
      // reset line item tour
      deleteGuidedTourFromLocalStorage(
        auth.username,
        "allocationPlannerLineItemTour"
      );
    },
    steps: [
      {
        target: ".guided-tour-allocation-forecast-welcome",
        placement: "center",
        content: (
          <Spacer my={3}>
            <Typography.H4 weight="bold" textAlign="center">
              <Trans ns="allocationForecastTour" i18nKey="welcome.heading">
                Welcome to {{ allocationProjectTrans }}
              </Trans>
            </Typography.H4>

            <Typography.P textAlign="center">
              <Trans ns="allocationForecastTour" i18nKey="welcome.line1">
                Where we plan the future state of our organisation.
              </Trans>
            </Typography.P>

            <Typography.P textAlign="center">
              <Trans ns="allocationForecastTour" i18nKey="welcome.line2">
                Let me show you around.
              </Trans>
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-main-panel",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              <Trans ns="allocationForecastTour" i18nKey="mainPanel.heading">
                This is the <strong>Main Panel</strong>
              </Trans>
            </Typography.H4>

            <Typography.P textAlign="left">
              <Trans ns="allocationForecastTour" i18nKey="mainPanel.line1">
                It has the <strong>Action Bar</strong> placed at the top.
              </Trans>
            </Typography.P>

            <Typography.P textAlign="left">
              <Trans ns="allocationForecastTour" i18nKey="mainPanel.line2">
                And the expanding <strong>{{ forecastTrans }} Panel</strong>{" "}
                below.
              </Trans>
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-action-bar",
        placement: "top",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              The <strong>Action Bar</strong>
            </Typography.H4>
            <Typography.P textAlign="left">
              Is where you perform various <strong>Actions</strong> and view the{" "}
              <strong>Forecast Column</strong> headers.
            </Typography.P>
            <Typography.P textAlign="left">
              Click next to step through each item on the{" "}
              <strong>Action Bar</strong>.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-actions-menu",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              This is the <strong>Actions Menu</strong>
            </Typography.H4>
            <Typography.P textAlign="left">
              By clicking the menu button, a list of menu items will be
              displayed.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-default-view",
        beforeAction: openActionsMenu,
        afterAction: closeActionsMenu,
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              The <strong>Reset</strong> back to default button
            </Typography.H4>
            <Typography.P textAlign="left">
              This will reset the <strong>Forecast Panel</strong> back to the
              original default view.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-add-team",
        beforeAction: openActionsMenu,
        afterAction: closeActionsMenu,
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              The <strong>Add Team</strong> button
            </Typography.H4>
            <Typography.P textAlign="left">
              Click this to add a <strong>New Team</strong> to{" "}
              <strong>TeamForm</strong>.
            </Typography.P>
            <Typography.P textAlign="left">
              When clicked, select which <strong>Hierarchy</strong> (or parent),
              for where the <strong>New Team</strong> will exist.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-download",
        beforeAction: openActionsMenu,
        afterAction: closeActionsMenu,
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              <strong>Download</strong> a CSV export using this button.
            </Typography.H4>
            <Typography.P textAlign="left">
              The export will give you all the current forecast information see
              this page, which can then be used for Pivot tables and other
              reporting if needed.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-view-mode",
        placement: "right",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              The <strong>View Mode</strong>
            </Typography.H4>
            <Typography.P textAlign="left">
              This changes the presentation of the{" "}
              <strong>Forecast Panel</strong> within your{" "}
              <GroupTypeBadge group={group} />
              <ul>
                <li>
                  <strong>{supplyLabel}:</strong> Organises the Forecast Panel
                  by your <Badge variant="secondary">Supply</Badge> types.
                </li>
                <li>
                  <strong>{demandLabel}</strong> Organises the Forecast Panel by
                  your <Badge variant="success">Demand</Badge> types.
                </li>
              </ul>
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target:
          ".guided-tour-allocation-forecast-allocation-project-current-column",
        placement: "left",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              This is the <strong>Current Column.</strong>
            </Typography.H4>
            <Typography.P textAlign="left">
              The <strong>Current Column</strong> shows current capacity in
              <strong> {supplyLabel}s</strong> and allocation to
              <strong> {demandLabel}s</strong> in
              <strong> FTE</strong> values.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-allocation-project-columns",
        placement: "left",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              This is a <strong>Forecast Column.</strong>
            </Typography.H4>
            <Typography.P textAlign="left">
              A <strong>Forecast Column</strong> is a{" "}
              <strong>{allocationProjectTrans}</strong> used as a time reference
              for the forecasted <strong>FTE</strong> values.
            </Typography.P>

            <Typography.P textAlign="left">
              To get more info on this <strong>{allocationProjectTrans}</strong>
              , mouse-over the <strong>Forecast Column</strong> to see more
              details in a tool-tip.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-panel",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              This is the <strong>Forecast Panel</strong>
            </Typography.H4>
            <Box>
              <Flex alignItems="center">
                <Typography.P textAlign="left">
                  This <strong>Forecast Panel</strong> provides a
                </Typography.P>
                <Box mx="3">
                  <Badge variant="success">Demand</Badge>
                </Box>
              </Flex>
              <Flex alignItems="center">
                <Typography.P textAlign="left">against</Typography.P>
                <Box mx="3">
                  <Badge variant="secondary">Supply</Badge>
                </Box>
                <Typography.P textAlign="left">
                  for the {group.type} for <strong>FTE</strong>
                </Typography.P>
              </Flex>
              <Typography.P textAlign="left">
                in future{" "}
                <strong>{i18next.t("glossary:allocationProjects")}.</strong>
              </Typography.P>
            </Box>

            <Box>
              <Flex alignItems="center">
                <Typography.P textAlign="left">The</Typography.P>
                <Box mx="3">
                  <Badge variant="success">Demand</Badge>
                </Box>
                <Typography.P textAlign="left">
                  values are captured on{" "}
                </Typography.P>
              </Flex>
              <Typography.P textAlign="left">
                this screen, which we will walk through soon.
              </Typography.P>
            </Box>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-grouping-header",
        content: (
          <Spacer my={3}>
            <Flex alignItems="center">
              <Typography.H4 textAlign="left">Your</Typography.H4>
              <Box mx="3">
                <GroupTypeBadge group={group} />
              </Box>
              <Typography.H4 textAlign="left">is the parent</Typography.H4>
            </Flex>
            <Typography.P textAlign="left">
              Child groups are listed underneath with corresponding{" "}
              <strong>Forecast Summaries</strong>.
            </Typography.P>
            <Typography.P textAlign="left">
              Click the anywhere on the row to expand and collapse the visible
              child groups.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-grouping-totals",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              <Trans
                ns="allocationForecastTour"
                i18nKey="forecastSummary.heading"
              >
                This is a <strong>{{ forecastTrans }} Summary</strong>
              </Trans>
            </Typography.H4>
            <Typography.P textAlign="left">
              <Trans
                ns="allocationForecastTour"
                i18nKey="forecastSummary.line1"
              >
                <strong>{{ forecastTrans }} Summaries</strong> appear under each
                of the
                {{ forecastTrans }} {{ allocationProjectsTrans }}
              </Trans>
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-panel",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="center">
              <strong>But wait, there&apos;s more.</strong>
            </Typography.H4>
            {viewMode === GROUPED_BY_SUPPLY && (
              <Typography.P textAlign="left">
                Try clicking and expanding some <strong>{supplyLabel}s</strong>{" "}
                to understand how to enter a forecast.
              </Typography.P>
            )}
            {viewMode === GROUPED_BY_DEMAND && (
              <Typography.P textAlign="left">
                Try clicking and expanding some <strong>{demandLabel}s</strong>{" "}
                to understand how to enter a forecast.
              </Typography.P>
            )}
            <Typography.Small textAlign="left">
              We love feedback, please leave any comments or suggestions in the
              help button below.
            </Typography.Small>
          </Spacer>
        ),
      },
    ],
  };
};

Component.propTypes = {
  group: PropTypes.object,
  supplyLabel: PropTypes.string,
  demandLabel: PropTypes.string,
  viewMode: PropTypes.string,
};

export default Component;
