import { saveAs } from "file-saver";
import moment from "moment";

export default (groupId, content, fileName) => {
  const filename = `${fileName}-${groupId}-${moment().format(
    "YYYYMMDD-HHmm"
  )}.csv`;

  saveAs(`data:text/csv;base64,${content}`, filename);
};
