import {
  Flex,
  Button,
  Divider,
  Icon,
  Toggle,
  Badge,
  H4,
  Spacer,
} from "orcs-design-system";
import { withRouter } from "react-router";
import React from "react";
import styled from "styled-components";
import { css } from "@styled-system/css";

import { VisualisationContext } from "../../../contexts/visualisationContext";
import { matchPaths } from "../../../consts/urlPaths";

const LegendGlyph = styled("div")((props) =>
  css({
    display: "flex",
    alignItems: "center",
    fontSize: "0",
    fontWeight: "2",
    "&::before": {
      content: `"#"`,
      color: "white",
      flex: "0 0 auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "22px",
      height: "22px",
      fontSize: "3",
      fontWeight: "1",
      borderRadius: "5px",
      bg: props.grey ? "greyDarkest" : "success",
      mr: "s",
    },
  })
);

const VisualisationSettingsSidebarContent = () => {
  const match = matchPaths();
  const [{ visualisation, forceShowEmptyTeams }, dispatch] =
    React.useContext(VisualisationContext);

  const dispatchApplyVisualSettings = (checkedTargetName) => (e) => {
    const toggleChecked = e.target.checked;

    dispatch({
      type: "applyVisualisationSettings",
      settings: {
        [checkedTargetName]: toggleChecked,
      },
    });
  };
  const dispatchApplyChartType = (e) => {
    const toggleChecked = e.target.checked;

    dispatch({
      type: "applyChartType",
      chartType: toggleChecked ? "hierarchy" : "organic",
    });
  };

  const inTeamVisualisation = match?.params?.pageType === "team";
  const shouldShowEmptyTeamsToggle =
    inTeamVisualisation && !forceShowEmptyTeams;

  return (
    <>
      <H4 bold white>
        View options
      </H4>
      <Spacer my="r">
        <Toggle
          id="chartOptions"
          label="Hierarchy view"
          small
          checked={visualisation.chartType === "hierarchy"}
          onChange={dispatchApplyChartType}
        />
        {inTeamVisualisation && (
          <Toggle
            id="showDescendants"
            label="Show All Descendants"
            small
            checked={visualisation.settings.showDescendants}
            onChange={dispatchApplyVisualSettings("showDescendants")}
          />
        )}
        {shouldShowEmptyTeamsToggle && (
          <Toggle
            id="includeEmptyTeams"
            label="Include empty teams"
            small
            checked={visualisation.settings.includeEmptyTeams}
            onChange={dispatchApplyVisualSettings("includeEmptyTeams")}
          />
        )}
        <Divider />
        <H4>Node options</H4>
        {inTeamVisualisation && (
          <Toggle
            id="people"
            label="Show people nodes"
            small
            checked={visualisation.settings.person}
            onChange={dispatchApplyVisualSettings("person")}
          />
        )}
        {(!inTeamVisualisation || visualisation.settings.person) && (
          <Toggle
            id="company"
            label="Show company nodes"
            small
            checked={visualisation.settings.company}
            onChange={dispatchApplyVisualSettings("company")}
          />
        )}
        {(!inTeamVisualisation || visualisation.settings.person) && (
          <Toggle
            id="division"
            label="Show division nodes"
            small
            checked={visualisation.settings.division}
            onChange={dispatchApplyVisualSettings("division")}
          />
        )}
        {(!inTeamVisualisation || visualisation.settings.person) && (
          <Toggle
            id="job"
            label="Show job nodes"
            small
            checked={visualisation.settings.job}
            onChange={dispatchApplyVisualSettings("job")}
          />
        )}
        {(!inTeamVisualisation || visualisation.settings.person) && (
          <Toggle
            id="location"
            label="Show location nodes"
            small
            checked={visualisation.settings.location}
            onChange={dispatchApplyVisualSettings("location")}
          />
        )}
        <Toggle
          id="groupAssociation"
          label="Show group associations"
          small
          checked={visualisation.settings.groupAssociation}
          onChange={dispatchApplyVisualSettings("groupAssociation")}
        />

        <Button
          fullWidth
          iconLeft
          onClick={visualisation.downloadChart}
          disabled={!visualisation.downloadChart}
        >
          <Icon icon={["fas", "download"]} /> Download view as image
        </Button>
        <Divider />
        <H4>Legend</H4>
        <Flex wrap="true" alignItems="center" flexWrap="wrap">
          <Spacer m="1">
            <Badge>
              <Icon icon={["far", "user"]} mr="xs" />
              Person
            </Badge>
            <Badge variant="success">
              <Icon icon={["far", "users"]} mr="xs" />
              Team
            </Badge>
            <Badge variant="secondary">
              <Icon icon={["far", "users"]} mr="xs" />
              Source team
            </Badge>
            <Badge variant="warning">
              <Icon icon={["far", "map-marker-alt"]} mr="xs" />
              Location
            </Badge>
            <Badge variant="danger">
              <Icon icon={["far", "address-card"]} mr="xs" />
              Job role
            </Badge>
            <Badge variant="primaryLight">
              <Icon icon={["far", "chart-pie"]} mr="xs" />
              Division
            </Badge>
            <Badge variant="primaryDark">
              <Icon icon={["far", "building"]} mr="xs" />
              Company
            </Badge>
          </Spacer>
        </Flex>
        <Spacer mb="r">
          <LegendGlyph grey>Total number of team members</LegendGlyph>
          {/* <LegendGlyph>Total number of teams</LegendGlyph> */}
        </Spacer>
      </Spacer>
    </>
  );
};

export default withRouter(VisualisationSettingsSidebarContent);
