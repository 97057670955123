import { map, sortBy, find } from "lodash";
import moment from "moment";

import { getForecastAllocationProjects } from "src/allocation/util/allocationProject";
import {
  CONSTRAINT,
  FORECAST,
  PLANNER_CURRENT,
  PLANNER_REQUESTED,
  PLANNER_APPROVED,
} from "../../columnTypes";
import newColumn from "./newColumn";

const getAllocationProjectColumns = (allocationProjects, columnType) =>
  map(
    sortBy(
      getForecastAllocationProjects(allocationProjects),
      (allocationProject) => moment(allocationProject.allocationDate)
    ),
    (allocationProject) => newColumn({ allocationProject, columnType })
  );

export const getConstraintColumns = (allocationProjects) => {
  return getAllocationProjectColumns(allocationProjects, CONSTRAINT);
};

export const getForecastColumns = (allocationProjects) => {
  const allocationProjectColumns = getAllocationProjectColumns(
    allocationProjects,
    FORECAST
  );

  return allocationProjectColumns;
};

export const getPlannerColumns = (allocationProjects) => {
  const allocationProject = find(allocationProjects, (ap) => ap.isActive);
  if (allocationProject) {
    return [
      newColumn({ allocationProject, columnType: PLANNER_CURRENT }),
      newColumn({ allocationProject, columnType: PLANNER_REQUESTED }),
      newColumn({ allocationProject, columnType: PLANNER_APPROVED }),
    ];
  }
  return [];
};
