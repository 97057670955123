import PropTypes from "prop-types";

export default PropTypes.shape({
  user_id: PropTypes.string,
  user_metadata: PropTypes.shape({
    groups: PropTypes.array,
    operatorId: PropTypes.string,
    tenantList: PropTypes.shape({
      tenants: PropTypes.array,
    }),
  }),
});
