import React from "react";
import { H1, P } from "orcs-design-system";
import { isNil } from "lodash";

import icons from "src/config/icons";
import { useAllocationConfig } from "src/contexts/global/WorkspaceContext/WorkspaceContext";

import { copywriting } from "./ErrorPage.config";
import ErrorPageWrapper from "./ErrorPageWrapper";

const GroupAccessUnauthorizedError = () => {
  const allocationConfig = useAllocationConfig();

  const {
    groupAccessUnauthorisedTitle,
    groupAccessUnauthorisedDescription,
    groupAccessUnauthorisedShowBackButton,
    groupAccessUnauthorisedShowSupportButton,
    groupAccessUnauthorisedShowLogoutButton,
  } = allocationConfig;

  const title =
    groupAccessUnauthorisedTitle ||
    copywriting.defaultGraphQLUnauthorizedErrorHeading;

  const description = isNil(groupAccessUnauthorisedDescription)
    ? copywriting.defaultGraphQLUnauthorizedErrorDetail
    : groupAccessUnauthorisedDescription;

  return (
    <ErrorPageWrapper
      icon={icons.lock}
      clientInfo={false}
      showHeader={false}
      showContainer={false}
      showBackButton={groupAccessUnauthorisedShowBackButton}
      showSupportButton={groupAccessUnauthorisedShowSupportButton}
      showLogoutButton={groupAccessUnauthorisedShowLogoutButton}
    >
      <H1 mb="between">{title}</H1>
      <P>{description}</P>
    </ErrorPageWrapper>
  );
};

export default GroupAccessUnauthorizedError;
