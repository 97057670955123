import { keyBy } from "lodash";
import * as ACTIONS from "../actionTypes";

import {
  activeAllocationProject,
  allocationProjectLookup,
  groupLookup,
} from "./mainQueryConversions";

import baselineGroupLookup from "./baselineQueryConversions/groupLookup";

export default (state, action) => {
  switch (action.type) {
    case ACTIONS.MAIN_QUERY_COMPLETE: {
      const { data } = action;
      return {
        ...state,
        groupLookup: groupLookup(state, data),
        activeAllocationProject: activeAllocationProject(data),
        allocationProjectLookup: allocationProjectLookup(data),
        historicAllocationProjectsLookup: keyBy(data.allocationProjects, "id"),
      };
    }
    case ACTIONS.BASELINE_DIFF_QUERY_COMPLETE: {
      const { data } = action;
      return {
        ...state,
        groupLookup: baselineGroupLookup(state, data),
      };
    }
    case ACTIONS.USER_ADD_ROLE: {
      const { role, supplyRoot } = action;
      const supplyRootGroup = supplyRoot
        ? { [supplyRoot.id]: { ...supplyRoot, isSource: true } }
        : {};

      return {
        ...state,
        groupLookup: {
          ...state.groupLookup,
          [role.id]: { ...role, isSource: true },
          ...supplyRootGroup,
        },
      };
    }
    case ACTIONS.USER_ADD_NEW_TEAM: {
      const { team } = action;
      return {
        ...state,
        groupLookup: {
          ...state.groupLookup,
          [team.id]: { ...team, isSource: false },
        },
      };
    }
    case ACTIONS.TEAM_RENAMED: {
      const { groupId, name } = action;
      return {
        ...state,
        groupLookup: {
          ...state.groupLookup,
          [groupId]: { ...state.groupLookup[groupId], name },
        },
      };
    }
    case ACTIONS.UPDATE_GROUPS_LOOKUP: {
      const { groups } = action;
      return {
        ...state,
        groupLookup: {
          ...state.groupLookup,
          ...groups,
        },
      };
    }
    default:
      return state;
  }
};
