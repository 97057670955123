import { reduce } from "lodash";

const buildGroupingLookup = (groupings) =>
  reduce(
    groupings,
    (obj, grouping) => {
      return {
        ...obj,
        ...buildGroupingLookup(grouping.childGroupings),
        [grouping.id]: grouping,
      };
    },
    {}
  );

export default buildGroupingLookup;
