import { get, debounce } from "lodash";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import * as sUtil from "src/services/segment";
import { createAuth } from "../services/auth";

const useSegment = (user, userPerson, routes) => {
  const location = useLocation();
  const pathname = get(location, "pathname");
  const workspace = useWorkspace();

  // We debounce the track page here because some routes will redirect to a sub-tab route straight away.
  const debouncedPageTracker = useRef(debounce(sUtil.trackPage, 100));

  useEffect(() => {
    sUtil.identify(user, userPerson);
  }, [user, userPerson]);

  useEffect(() => {
    const { operatorId, tenantId } = createAuth(user);
    const personId = userPerson?.aggregateId || operatorId;
    const workspaceId = workspace?.id;

    // Detect Page has changed.
    // We are only checking pathname changed.
    // This is trying to skip the history state change or search string change triggered location change.
    // For example in Planner, we are saving user opened columns to history state.
    debouncedPageTracker.current(pathname, {
      personId,
      tenantId,
      workspaceId,
      ...sUtil.extractPageInfoForSegment({ routes, pathname }),
    });
  }, [pathname, user, userPerson, workspace, routes]);
};

export default useSegment;
