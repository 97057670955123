import { reduce } from "lodash";

const findGrouping = (groupings, groupId) =>
  reduce(
    groupings,
    (hit, grouping) => {
      if (hit) {
        return hit;
      }

      if (grouping.groupId === groupId) {
        return grouping;
      }

      const childGroupingMatch = findGrouping(grouping.childGroupings, groupId);
      if (childGroupingMatch) {
        return childGroupingMatch;
      }

      return null;
    },
    null
  );

export default findGrouping;
