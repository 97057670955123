import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const CustomMenuList = styled.div`
  overflow-y: auto;
  padding-bottom: 1px;
  padding-top: 0;
  position: relative;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  max-height: 80vh;
  @media screen and (min-width: 700px) {
    max-height: 50vh;
  }
`;
