import React from "react";
import PropTypes from "prop-types";
import { Box } from "orcs-design-system";
import { useLocation } from "react-router-dom";
import LoadingWithErrorTimeout from "../LoadingWithErrorTimeout";

const TEN_MINUTES = 600000;

const LoadingPage = ({
  timeout = parseInt(process.env.REACT_APP_LOADING_DEFAULT_TIMEOUT, 10) ||
    TEN_MINUTES,
  waitingFor = "unknown page",
} = {}) => {
  const location = useLocation();

  return (
    <Box data-testid="page-loader" width="100%" py="xxl">
      <LoadingWithErrorTimeout
        timeout={timeout}
        waitingFor={waitingFor}
        location={location}
        large
        centered
        primary
      />
    </Box>
  );
};

LoadingPage.propTypes = {
  timeout: PropTypes.number,
  waitingFor: PropTypes.string,
};

export default LoadingPage;
