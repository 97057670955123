import React from "react";
import { H5, Small, Divider, Spacer, Toggle } from "orcs-design-system";
import {
  useFteMode,
  useApplyFteMode,
} from "src/contexts/filterAndFte/FilterAndFteContext";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";

const FteDataSidebarContent = () => {
  const fteMode = useFteMode();
  const applyFteMode = useApplyFteMode();
  return (
    <>
      <H5 weight="bold">View Options</H5>
      <Spacer my="r">
        <Divider light />
        <Toggle
          id="fteModeToggle"
          small
          label="FTE"
          checked={fteMode}
          onChange={(e) => {
            applyFteMode(e.target.checked);
            trackEvent(EVENT_TRACKING.SETTING_CHANGED, {
              setting: "fte_mode",
              value: e.target.checked ? "on" : "off",
            });
          }}
        />
        <Small>
          Turning on FTE mode will show numbers relative to Full Time Equivalent
          staff, rather than total head count.
        </Small>
      </Spacer>
    </>
  );
};

export default FteDataSidebarContent;
