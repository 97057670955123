import constate from "constate";

export const DEFAULT_FILTERS = {
  bands: [],
  divisions: {
    divisions: [],
    includeSubDivisions: false,
  },
  company: [],
  location: [],
  country: [],
  jobTitle: [],
  teams: {
    includeIndirectTeamMembers: false,
    includeSubTeams: false,
    teams: [],
    types: [],
  },
};

export const DEFAULT_FTE_MODE = false;

const useFiltersAndFteMode = (values) => values;

const [
  Provider,
  useFilters,
  useFiltersForQuery,
  useActiveFilterCount,
  useApplyFilters,
  useClearFilters,
  useFteMode,
  useApplyFteMode,
] = constate(
  useFiltersAndFteMode,
  (value) => value.filters,
  (value) => value.queryFilters,
  (value) => value.activeFilterCount,
  (value) => value.applyFilters,
  (value) => value.clearFilters,
  (value) => value.fteMode,
  (value) => value.applyFteMode
);

export {
  Provider,
  useFilters,
  useFiltersForQuery,
  useActiveFilterCount,
  useApplyFilters,
  useClearFilters,
  useFteMode,
  useApplyFteMode,
};
