// eslint-disable-next-line import/prefer-default-export
export const copyWriting = {
  phoneNumber: "Contact via Phone Number",
  email: "Contact via Email",
};

export const searchEntityType = {
  PERSON: "person",
  TEAM: "team",
  TAG: "tag",
};

export const iconSizing = "34px";
export const popoverWidth = "120px";
export const popoverPhoneWidth = "172px";
