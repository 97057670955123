import { get, map } from "lodash";
import { Box, Divider } from "orcs-design-system";
import React from "react";

import OptionBadge from "src/components/OptionBadge";
import OptionBadgeWithActions from "src/components/OptionBadgeWithActions";
import * as placeholder from "src/components/Placeholder/PlaceholderStyles";
import { createPlaceholderData } from "src/util/search";
import { heading } from "../Search.config";
import GroupHeading from "./GroupHeading";

export const PLACEHOLDER_ROWS = 3;

// custom react-select Loading Message component
const LoadingMessage = () => {
  // 3 rows placeholder
  const placeholderData = createPlaceholderData(PLACEHOLDER_ROWS);
  const peopleOptions = get(placeholderData[0], "options");
  const teamOptions = get(placeholderData[1], "options");

  return (
    <>
      <GroupHeading label={heading.people} />
      <Divider inverted />

      {map(peopleOptions, (person, index) => (
        <OptionBadgeWithActions
          key={`person-row-${index}`}
          isLoading={true}
          data={person.value}
          theme="light"
        />
      ))}

      <Box p="s" pt="r" m="0">
        <placeholder.Line
          data-testid="cp-option-placeholder-title"
          theme="light"
          width={250}
          height={22}
        />
      </Box>

      <Divider inverted />

      <GroupHeading label={heading.teams} />
      <Divider inverted />

      {map(teamOptions, (team, index) => (
        <OptionBadge
          key={`team-row-${index}`}
          isLoading={true}
          data={team.value}
          theme="light"
        />
      ))}

      <Box p="s" pt="r" m="0">
        <placeholder.Line
          data-testid="cp-option-placeholder-title"
          theme="light"
          width={250}
          height={22}
        />
      </Box>
    </>
  );
};

export default LoadingMessage;
