import { values, keyBy, forEach } from "lodash";

import {
  getForecastColumns,
  getForecastMacroAllocations,
  setGroupingsWithActualConstraints,
  setGroupingsWithDefaultConstraints,
  setGroupingsWithForecastCells,
  setGroupingsWithForecastDefaultCells,
  setCalculatedSumTotals,
  setGroupingsTotalCost,
  setNotifications,
  pruneEmptyLineItems,
  pruneEmptyGroupings,
  buildGroupingLookup,
  buildLineItemLookup,
  buildCellLookup,
  buildConstraintCellLookup,
  hashGrouping,
  buildSourceGroupLookup,
  setGroupingsBudget,
} from "../util";

import buildGroupings from "./buildGroupings/byDemand";
import setGroupingsWithLineItems from "./setLineItems/supplyLineItems";

const buildGroupedByDemand = ({
  rootGroup,
  data,
  hideHiddenTeams,
  showObjectives,
  groupTypes,
  shouldHash,
  isBudgetEnabled,
  overBudgetLimit,
  underBudgetLimit,
}) => {
  const allocationProjectLookup = keyBy(data.allocationProjects, "id");
  const columns = getForecastColumns(data.allocationProjects);
  const macroAllocations = getForecastMacroAllocations(
    data.macroAllocations,
    data.allocationProjects,
    hideHiddenTeams
  );

  const sourceGroupLookup = isBudgetEnabled
    ? buildSourceGroupLookup({
        macroAllocations,
        descendantSupplyGroups: data.descendantSupplyGroups,
        roleAreaGroups: data.roleAreaGroups,
        relatedSupplyGroups: data.relatedSupplyGroups,
      })
    : null;

  const groupings = buildGroupings({
    rootGroup,
    macroAllocations,
    descendantTargetGroups: data.descendantTargetGroups,
    relatedBudgetTargetGroups: data.relatedBudgetTargetGroups,
    hideHiddenTeams,
    showObjectives,
    groupTypes,
    columnCount: columns.length,
    isBudgetEnabled,
    sourceGroupLookup,
  });

  setGroupingsWithLineItems({
    groupings,
    macroAllocations,
    columnCount: columns.length,
    groupTypes,
    canRename: rootGroup.isSource,
    isBudgetEnabled,
    sourceGroupLookup,
  });

  setGroupingsWithForecastCells({
    groupings,
    macroAllocations,
    columns,
    groupedByDemand: true,
    rootGroup,
  });
  setGroupingsWithForecastDefaultCells(groupings, columns, true, rootGroup);

  if (isBudgetEnabled) {
    setGroupingsBudget(groupings, data.relatedSupplyGroups);
    setGroupingsTotalCost(groupings, overBudgetLimit, underBudgetLimit);
  }

  setCalculatedSumTotals(groupings, columns);
  setGroupingsWithActualConstraints(
    groupings,
    data.constraints,
    allocationProjectLookup,
    columns
  );
  setGroupingsWithDefaultConstraints(
    groupings,
    allocationProjectLookup,
    columns
  );
  setNotifications(groupings, columns);

  pruneEmptyLineItems(groupings);
  if (rootGroup.isSource) {
    pruneEmptyGroupings(groupings, isBudgetEnabled);
  }

  const columnLookup = keyBy(columns, "id");
  const groupingLookup = buildGroupingLookup(groupings);
  const lineItemLookup = buildLineItemLookup(values(groupingLookup));
  const cellLookup = buildCellLookup(values(lineItemLookup));
  const constraintCellLookup = buildConstraintCellLookup(
    values(groupingLookup)
  );

  // Hash groupings in case anything changed
  if (shouldHash) {
    forEach(groupings, hashGrouping);
  }

  return {
    columns,
    groupings,
    // lookup objects: for when we need fast access to objects in the tree
    lookups: {
      allocationProjectLookup,
      columnLookup,
      groupingLookup,
      lineItemLookup,
      cellLookup,
      constraintCellLookup,
    },
  };
};

export default buildGroupedByDemand;
