import { isEmpty, pullAll, isEqual, map, filter } from "lodash";
import { useEffect } from "react";

import {
  findTreeNodes,
  insertChildrenNodes,
  reopenTreeToGroupNode,
} from "src/shared/TreeView/TreeView.util";
import { filterOutNonVisibleGroups } from "./index.util";

const useAutoExpandTree = ({
  root,
  hierarchyIds,
  activeNodeHierarchyRef,
  groupTypes,
  loadChildTeams,
  refreshTree,
  sortOption,
  createTreeNodeImpl,
  convertChildrenToArrayImpl,
}) => {
  useEffect(() => {
    if (
      isEmpty(hierarchyIds) ||
      isEmpty(root) ||
      isEqual(activeNodeHierarchyRef.current, hierarchyIds)
    ) {
      return;
    }

    // eslint-disable-next-line no-param-reassign
    activeNodeHierarchyRef.current = hierarchyIds;
    // Find tree nodes by hierarchyIds, the children of the node may not be loaded
    const treeNodes = findTreeNodes(root, hierarchyIds);
    const loadedGroupIds = map(
      filter(treeNodes, (n) => !n.loading),
      "id"
    );

    // exclude the loaded groups
    const groupIds = pullAll([...hierarchyIds], loadedGroupIds);

    if (isEmpty(groupIds)) {
      reopenTreeToGroupNode(root, hierarchyIds);
      refreshTree();
      return;
    }

    loadChildTeams({
      groupIds,
    }).then(({ groups }) => {
      const filteredGroups = filterOutNonVisibleGroups(groups);
      insertChildrenNodes(
        root,
        hierarchyIds,
        filteredGroups,
        groupTypes,
        sortOption,
        createTreeNodeImpl,
        convertChildrenToArrayImpl
      );
      reopenTreeToGroupNode(root, hierarchyIds);
      refreshTree();
    });
  }, [
    root,
    hierarchyIds,
    activeNodeHierarchyRef,
    groupTypes,
    loadChildTeams,
    refreshTree,
    sortOption,
    createTreeNodeImpl,
    convertChildrenToArrayImpl,
  ]);
};

export default useAutoExpandTree;
