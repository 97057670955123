import { isEmpty, last } from "lodash";
import { useEffect } from "react";

import EE, { EVENTS } from "src/services/eventEmitter";
import { NOOP } from "src/util/consts";
import { findTreeNodes } from "src/shared/TreeView/TreeView.util";

const useGroupRenameListener = (root, refreshTree, eventEmitter = EE) => {
  useEffect(() => {
    if (isEmpty(root)) {
      return NOOP;
    }

    const handler = (group) => {
      Promise.resolve().then(() => {
        if (!group) {
          return;
        }
        const { hierarchyIds: ids } = group;
        // Find tree nodes by hierarchyIds, the children of the node may not be loaded
        const treeNodes = findTreeNodes(root, ids);

        // target group is not in the tree yet, no need to change anything
        if (treeNodes.length < ids.length) {
          return;
        }

        // Try to update the node
        const node = last(treeNodes);
        if (node && node.id === group.id) {
          node.name = group.name;
          node.group = group;
          refreshTree();
        }
      });
    };

    eventEmitter.on(EVENTS.GROUP_RENAMED, handler);

    return () => {
      eventEmitter.removeListener(EVENTS.GROUP_RENAMED, handler);
    };
  }, [root, refreshTree, eventEmitter]);
};

export default useGroupRenameListener;
