import { map, mapValues, keyBy, includes } from "lodash";

export default (
  state,
  {
    macroAllocations,
    descendantTargetGroups,
    roleAreaGroups,
    descendantSupplyGroups,
  }
) => {
  // get all targetGroups from macroAllocations
  //         sourceGroups from macroAllocations
  //         targetGroups from targetGroups
  const {
    parentGroupId,
    parentGroupType,
    groupLookup,
    approverTypes,
    roleTypes,
  } = state;

  const sourceGroups = mapValues(
    keyBy(macroAllocations, "sourceGroupId"),
    (macroAllocation) => ({
      ...macroAllocation.sourceGroup,
      isSource: true,
    })
  );

  return {
    ...mapValues(groupLookup, (g) => ({
      ...g,
      isSource: includes([...approverTypes, ...roleTypes], g.type),
    })),
    ...mapValues(
      keyBy(macroAllocations, "targetGroupId"),
      (macroAllocation) => ({
        ...macroAllocation.targetGroup,
        isSource: false,
      })
    ),
    ...sourceGroups,
    ...keyBy(
      map(descendantTargetGroups, (t) => ({ ...t, isSource: false })),
      "id"
    ),
    ...keyBy(
      map(roleAreaGroups, (t) => ({
        // Keep fields from source groups in allocation records
        ...(sourceGroups[t.id] || {}),
        ...t,
        isSource: true,
      })),
      "id"
    ),
    ...keyBy(
      map(descendantSupplyGroups, (t) => ({ ...t, isSource: true })),
      "id"
    ),
    ...keyBy(
      map(descendantSupplyGroups, (t) => ({
        id: `${t.id}-virtual-direct-constraint-grouping`,
        type: `Direct roles`,
        name: `Direct ${t.type} roles`,
        directRoles: true,
        isSource: true,
        isVirtual: true,
      })),
      "id"
    ),
    [`${parentGroupId}-virtual-direct-constraint-grouping`]: {
      id: `${parentGroupId}-virtual-direct-constraint-grouping`,
      type: `Direct roles`,
      name: `Direct ${parentGroupType} roles`,
      directRoles: true,
      isSource: true,
      isVirtual: true,
    },
  };
};
