import React from "react";
import { H5, Divider, Spacer, RadioButton } from "orcs-design-system";
import { useHistory } from "react-router-dom";
import { getSearchPath } from "src/util/paths";
import MatchPropType from "src/custom-prop-types/match";

import { useGlobalSearchContext } from "src/components/Search/GlobalSearchContext";
import {
  filterTypeLabels,
  filterTypes,
} from "../../../pages/SearchPage/SearchPage.config";
import { useWorkspaceFeatureFlags } from "../../../contexts/global/WorkspaceContext";

const FilterSearchResults = ({ match }) => {
  const { searchQuery } = useGlobalSearchContext();
  const { params } = match;
  const { type } = params;
  const history = useHistory();
  const workspaceFeatureFlags = useWorkspaceFeatureFlags();

  const onHandleNavigate = (searchType, query) => () => {
    history.push(getSearchPath(searchType, query));
  };

  return (
    <div id="keep-open-on-click-outside">
      <H5 fontWeight="bold">Search Filters</H5>
      <Divider mt="r" mb="r" />
      <Spacer mr="r" mb="xs">
        <RadioButton
          name="search-filter-btn"
          label={filterTypeLabels.ALL}
          checked={type === filterTypes.ALL}
          onClick={onHandleNavigate(filterTypes.ALL, searchQuery)}
          mt="s"
          mb="s"
        />
        <RadioButton
          name="search-filter-btn"
          label={filterTypeLabels.PEOPLE}
          checked={type === filterTypes.PEOPLE}
          onClick={onHandleNavigate(filterTypes.PEOPLE, searchQuery)}
          mt="s"
          mb="s"
        />
        <RadioButton
          name="search-filter-btn"
          label={filterTypeLabels.TEAM}
          checked={type === filterTypes.TEAMS}
          onClick={onHandleNavigate(filterTypes.TEAMS, searchQuery)}
          mt="s"
          mb="s"
        />
        {!workspaceFeatureFlags?.disableGlobalTagsSearch && (
          <RadioButton
            name="search-filter-btn"
            label={filterTypeLabels.TAGS}
            checked={type === filterTypes.TAGS}
            onClick={onHandleNavigate(filterTypes.TAGS, searchQuery)}
            mt="s"
            mb="s"
          />
        )}
      </Spacer>
    </div>
  );
};

FilterSearchResults.propTypes = {
  match: MatchPropType.isRequired,
};

export default FilterSearchResults;
