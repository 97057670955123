/* eslint-disable no-nested-ternary */
import { P, StyledLink } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { getExplorePath, getTeamsSearchPath } from "src/util/paths";

import {
  noOptionsLabel,
  noOptionsLinkLabel,
  searchErrorLabel,
  standardNoOptionsLabel,
} from "../Search.config";

// custom react-select No Options component
const NoOptionsMessage = ({
  isStandardSelect,
  searchError,
  showExploreLink = false,
}) => {
  const label = searchError ? (
    searchErrorLabel
  ) : isStandardSelect ? (
    standardNoOptionsLabel
  ) : (
    <>
      {noOptionsLabel}{" "}
      <StyledLink
        fontSize="1"
        to={showExploreLink ? getExplorePath() : getTeamsSearchPath()}
      >
        {noOptionsLinkLabel}
      </StyledLink>
    </>
  );
  return (
    <P color="black" fontSize="1">
      {label}
    </P>
  );
};

NoOptionsMessage.propTypes = {
  isStandardSelect: PropTypes.bool,
  searchError: PropTypes.object,
  showExploreLink: PropTypes.bool,
};
export default NoOptionsMessage;
