import PropTypes from "prop-types";

export const GroupTypePropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  isSupply: PropTypes.bool,
  isSupplySource: PropTypes.bool,
  isDemand: PropTypes.bool,
});

export const GroupTypesPropType = PropTypes.objectOf(GroupTypePropType);
