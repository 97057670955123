import { hashGrouping } from "./util";

const updateConstraintSaveStatus = (forecastModel, cellId, status) => {
  const {
    lookups: { constraintCellLookup },
  } = forecastModel;

  const constraintCell = constraintCellLookup[cellId];

  if (!constraintCell) {
    return forecastModel;
  }

  // Already saved
  if (constraintCell.constraintSaveStatus === status) {
    return forecastModel;
  }

  constraintCell.constraintSaveStatus = status;
  hashGrouping(constraintCell.grouping, {
    rehashParents: true,
  });

  return forecastModel;
};

export default updateConstraintSaveStatus;
