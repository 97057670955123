import { get } from "lodash";

import { isAdmin } from "src/util/auth0";

const useShowInvite = ({ workspace, user }) => {
  const tenantConfig = get(workspace, "config.tenantConfig", {});
  return isAdmin(user) && get(tenantConfig, "canInviteUser", false);
};

export default useShowInvite;
