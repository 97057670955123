export const filterTypeLabels = {
  ALL: "All matches",
  TEAM: "Teams",
  PEOPLE: "People",
  TAGS: "Tags",
};

export const filterTypes = {
  ALL: "all",
  PEOPLE: "people",
  TEAMS: "teams",
  TAGS: "tags",
};
