import { includes } from "lodash";
import * as VIEW_MODES from "../viewModes";

export default (
  viewMode,
  targetGroupSearchParamId,
  sourceGroupSearchParamId,
  previouslyExpandedGroupIds,
  previouslyExpandedLineItemsGroupIds
) => {
  return {
    targetGroupSearchParamId,
    sourceGroupSearchParamId,
    previouslyExpandedGroupIds,
    previouslyExpandedLineItemsGroupIds,
    focusedGroupId: null,
    viewMode: includes(VIEW_MODES, viewMode)
      ? viewMode
      : VIEW_MODES.DEFAULT_VIEW_MODE,
    searchMode: false,
    groupSearchMode: false,
    searchResult: null,
    expandedGroupIds: {},
    expandAllGroups: false,
    expandedLineItemsGroupIds: {},
    expandedObjectivesIds: {},
    expandedTagsIds: {},
    expandedMemberListIds: {}, // lineItemIds
    defaultExpandedGroupIds: {},
    addingRole: {
      isOpen: false,
      grouping: null,
      targetGroupId: null,
      parentSourceGroupId: null,
      macroAllocationType: null,
      targetView: null,
    },
    linkingObjectives: {
      isOpen: false,
      targetGroup: null,
      objectiveIds: null,
      grouping: null,
    },
    addingTeam: {
      isOpen: false,
      parentGroup: null,
    },
    hidingTeam: {
      isOpen: false,
      groupId: null,
    },
    viewingComment: {
      isOpen: false,
      cellId: null,
      pressedAt: null,
    },
    exportCsv: {
      isOpen: false,
      isDownloading: false,
    },
    renamingTeam: {
      isOpen: false,
      groupId: null,
    },
    editingTeamDetails: {
      isOpen: false,
      targetGroup: null,
    },
    viewingTeamDetails: {
      isOpen: false,
      targetGroup: null,
    },
    addingSkills: {
      isOpen: false,
      cell: null,
      updateInputValue: null,
    },
    editingAllocation: {
      isOpen: false,
      targetPerson: null,
      searchVariables: null,
    },
    moveMember: {
      isOpen: false,
      grouping: null,
      person: null,
      searchVariables: null,
    },
    removeMember: {
      isOpen: false,
      grouping: null,
      person: null,
      searchVariables: null,
    },
    membersFilter: null,
    moveGroup: {
      isOpen: false,
      grouping: null,
    },
    bulkAddingTag: {
      isOpen: false,
      grouping: null,
    },
    bulkMoveMembers: {
      isOpen: false,
      grouping: null,
    },
    bulkDeallocations: {
      isOpen: false,
      grouping: null,
    },
    addingVacantRole: {
      isOpen: false,
      lineItem: null,
      searchVariables: null,
      sourceGroupId: null,
      targetGroupId: null,
    },
    addMember: {
      isOpen: false,
      grouping: null,
      lineItem: null,
      targetFte: null,
      beforeAllocate: null,
    },
  };
};
