import {
  get,
  keyBy,
  filter,
  isEmpty,
  reject,
  chain,
  includes,
  map,
  sortBy,
  find,
  toLower,
  has,
  mapValues,
  concat,
} from "lodash";

import {
  parseRichTextContent,
  isRichTextContentEmpty,
} from "src/shared/RichTextContent/RichTextContent";
import { TAG_OPERATIONS } from "src/consts/tags";
import { TagFragment } from "./queries/fragments/tagFragment.graphql";
import {
  availableTags as availableTagsQuery,
  getTagsForEntity as getTagsForEntityQuery,
} from "./queries/tags.graphql";
import { getSortedTags } from "./sub-components/ExpandableBadgeList.utils";

export const createTagSelectorOption = (tag) => {
  return {
    label: `${tag.tagTypeConfig.name}: ${tag.displayValue}`,
    value: tag.id,
    isFixed: tag.tagTypeConfig.isReadonly,
    data: {
      tag,
    },
  };
};

const parseRichTextForTooltips = (tagTypeConfigs) => {
  return map(tagTypeConfigs, (tagTypeConfig) => {
    // todo: stop this function being called twice
    if (has(tagTypeConfig, "hasDetails")) {
      return tagTypeConfig;
    }
    const detailsParsed = parseRichTextContent(tagTypeConfig.details);
    const hasDetails = !isRichTextContentEmpty(detailsParsed);

    return { hasDetails, detailsParsed, ...tagTypeConfig };
  });
};

// ... to what :)
// todo: all this code that transforms between graphql backend and view model could be well tidier
export const transformTagTypeConfigs = (tagTypeConfigs) => {
  return parseRichTextForTooltips(tagTypeConfigs);
};

export const withValidTagTypeConfig = (entityTags, tagTypeConfigs) => {
  const tagTypeConfigById = keyBy(
    parseRichTextForTooltips(tagTypeConfigs),
    "id"
  );
  return chain(entityTags)
    .map((entityTag) => ({
      ...(entityTag.tag || {}),
      ...entityTag,
      tagTypeConfig: tagTypeConfigById[entityTag.type],
    }))
    .filter("tagTypeConfig")
    .value();
};

export const formatSelectedTagsForDisplay = (tags, tagTypeConfigs) => {
  const tagsWithConfig = withValidTagTypeConfig(tags, tagTypeConfigs);
  return sortBy(
    tagsWithConfig,
    ({ tagTypeConfig: { name: typeName, isReadonly }, displayValue }) => {
      return [!isReadonly, typeName, displayValue];
    }
  );
};

const withExistingTagCounts = (tagOptions, existingTags) => {
  const countsByTagTypeId = chain(existingTags)
    .reject((tag) => tag.status === TAG_OPERATIONS.REMOVED)
    .groupBy("type")
    .mapValues((tags) => tags.length)
    .value();
  return tagOptions.map((option) => {
    // TODO: avoid reaching into deeply nested structure
    // (to be fair, this whole module needs a rewrite)
    const {
      data: {
        tag: {
          tagTypeConfig: { id: tagTypeId },
        },
      },
    } = option;
    return { ...option, existingTagsOfType: countsByTagTypeId[tagTypeId] || 0 };
  });
};

export const createSelectableTagOptions = (
  availableTags,
  tagTypeConfigs,
  existingTags
) => {
  const tagsWithConfig = withValidTagTypeConfig(availableTags, tagTypeConfigs);
  const existingTagIds = map(
    filter(existingTags, (tag) => tag.status !== TAG_OPERATIONS.REMOVED),
    "tagId"
  );

  const selectableTags = reject(tagsWithConfig, ({ id, tagTypeConfig }) => {
    return tagTypeConfig.isReadonly || includes(existingTagIds, id);
  });

  const result = map(getSortedTags(selectableTags), createTagSelectorOption);
  return withExistingTagCounts(result, existingTags);
};

export const canAddMultipleTagsForTagType = (tagType, tagTypeConfig) => {
  return get(
    find(tagTypeConfig, { id: tagType }),
    "allowMultipleOnEntity",
    false
  );
};

export const getUpdatingStateForAllTagsOfType = (
  tagsByType,
  tagType,
  state
) => {
  return mapValues(
    keyBy(
      map(get(tagsByType, `[${tagType}]`) || [], (t) => ({ id: t.id, state })),
      "id"
    ),
    "state"
  );
};

export const canAddMultiTags = (tagTypesConfig) => {
  if (tagTypesConfig.length === 1) {
    return tagTypesConfig[0].allowMultipleOnEntity;
  }
  return true;
};

export const modifyTags = (tags, tag, replace) => {
  const { type, id } = tag;

  const filterFunction = replace
    ? (t) => t.type !== type
    : (t) => t.tagId !== id;
  const otherTags = filter(tags, filterFunction);

  return [...otherTags, tag];
};

export const createTagTypesConfigLookup = (tagTypeConfig) => {
  const tagTypeConfigById = keyBy(tagTypeConfig, "id");
  return (tagTypeId) => {
    const config = get(tagTypeConfigById, tagTypeId, {});
    return {
      id: tagTypeId,
      name: tagTypeId,
      allowMultipleOnEntity: true,
      isReadonly: false,
      ...config,
    };
  };
};

export const getNewOptionData = (inputValue, tagTypesConfig, options) => {
  const existingOption = find(options, {
    data: { tag: { value: toLower(inputValue) } },
  });
  const filteredTagTypesConfig = reject(tagTypesConfig, {
    id: existingOption?.data?.tag?.type,
  });
  if (!inputValue || isEmpty(filteredTagTypesConfig)) {
    return null;
  }
  return {
    label: inputValue,
    value: inputValue,
    options: filter(filteredTagTypesConfig, "isEditableByUsers").map(
      (tagTypeConfig) =>
        createTagSelectorOption({
          type: tagTypeConfig.id,
          displayValue: inputValue,
          isActive: true,
          value: inputValue,
          isUserCreatedTagValue: true,
          tagTypeConfig,
        })
    ),
  };
};

// tags could not be in the cache (or have updated attributes) so we need to do this
// regardless of whether it is a new tag or not
// TODO: Performance impls of updating a massive list each time (better query structure would help here!) - tagType(skills) {tags} or something
// TODO: Upgrade apollo to use updateQuery (3.5 from 3.1)
export const updateSearchTagsQueryCache = (cache, tagTypes, tag) => {
  const result = cache.readQuery({
    query: availableTagsQuery,
    variables: { tagTypes },
  });

  if (!result) {
    return;
  }

  const { tags: currentTags } = result;

  // see note above re perf
  const tagsToWrite = [...reject(currentTags, ["id", tag.id]), tag];
  cache.writeQuery({
    query: availableTagsQuery,
    variables: { tagTypes },
    data: { tags: tagsToWrite },
  });
};

export const updateTagFragment = (proxy, result) => {
  const { tagId, appliedCount } = result;

  const cached = proxy.readFragment({
    id: `Tag:${tagId}`,
    fragment: TagFragment,
    fragmentName: "TagFragment",
  });

  if (!cached) {
    return;
  }

  const tag = {
    ...cached,
    appliedCount,
  };

  proxy.writeFragment({
    id: `Tag:${tagId}`,
    fragment: TagFragment,
    data: tag,
  });
};

export const getComments = ({ id, name, type, metaData }) => {
  return metaData
    ? {
        ...metaData,
        entity: {
          entityId: id,
          entityName: name,
          entityType: type,
        },
      }
    : undefined;
};

export const makeComments = (comments, tag) => {
  return comments && { comments: { ...comments, tag } };
};

export const makeBulkComment = (
  tagId,
  tagType,
  tagDisplayValue,
  entityId,
  entityName,
  entityType,
  metaData
) => {
  return {
    entity: {
      entityId,
      entityName,
      entityType,
    },
    tag: {
      tagId,
      tagType,
      displayValue: tagDisplayValue,
    },
    ...metaData,
  };
};

export const getTagTypesWithShowingHistory = (tagTypesConfig) => {
  return tagTypesConfig
    .filter(({ showPreviousAppliedTags }) => showPreviousAppliedTags)
    .map((tagType) => tagType.id);
};

export const addTagToRemovedList = ({
  tag,
  cache,
  tagTypesWithShowingHistory = [],
  entityId,
  query = getTagsForEntityQuery,
}) => {
  if (tagTypesWithShowingHistory.includes(tag?.type)) {
    const removedTag = { ...tag, status: "REMOVED" };
    const variables = {
      entityId,
      tagTypes: tagTypesWithShowingHistory,
      tagStatus: "REMOVED",
    };
    const removedTagsCache = cache.readQuery({
      query,
      variables,
    });
    if (removedTagsCache) {
      cache.writeQuery({
        query,
        variables,
        data: {
          tags: [...removedTagsCache.tags, removedTag],
        },
      });
    }
  }
};

export const removeTagFromRemovedList = ({
  cache,
  entityId,
  tagTypesWithShowingHistory,
  tag,
  addedTags,
  query = getTagsForEntityQuery,
  replace,
}) => {
  const variables = {
    entityId,
    tagTypes: tagTypesWithShowingHistory,
    tagStatus: "REMOVED",
  };
  const removedTagsCache = cache.readQuery({
    query,
    variables,
  });
  if (removedTagsCache) {
    const foundRemovedTag = find(
      removedTagsCache.tags,
      (t) => t.tagId === tag.tagId
    );
    let { tags } = removedTagsCache;
    if (foundRemovedTag) {
      tags = tags.filter((t) => t.tagId !== tag.tagId);
    }
    if (replace && includes(tagTypesWithShowingHistory, tag.type)) {
      const prevTags = filter(addedTags?.tags, (t) => t.type === tag.type);
      const updatedTags = map(prevTags, (t) => ({
        ...t,
        status: "REMOVED",
      }));
      tags = concat(tags, updatedTags);
    }
    cache.writeQuery({
      query,
      variables,
      data: {
        tags,
      },
    });
  }
};

export const isTagDisplayValueEditable = (
  nameAttrTypes = [],
  otherAttrTypes = [],
  tagTypeConfig = {}
) => {
  return (
    tagTypeConfig?.allowedToEditTagDisplayValue &&
    isEmpty(nameAttrTypes) &&
    isEmpty(otherAttrTypes)
  );
};

export const canNavigate = ({ tagTypesConfig, tag }) => {
  const tagConfig = find(tagTypesConfig, (tagType) => tagType.id === tag.type);
  return includes(tagConfig?.visibleIn, "Navigation");
};

export const updateTagsForEntityCache =
  ({ entityId, tagTypes, entityTag }) =>
  (cache, { data: { tag: updatedTag } }) => {
    const cached = cache.readQuery({
      query: getTagsForEntityQuery,
      variables: { entityId, tagTypes },
    });

    const existingCachedTags = cached?.tags || [];

    const remaining = filter(existingCachedTags, (cachedTag) => {
      return cachedTag?.id !== entityTag?.id;
    });

    cache.writeQuery({
      query: getTagsForEntityQuery,
      variables: { entityId, tagTypes },
      data: {
        tags: concat(remaining, [{ ...updatedTag }]),
      },
    });
  };
