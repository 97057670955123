import { map, filter, isNil } from "lodash";
import { isDemandGroup } from "src/allocation/util/group";

export default (group, workspace) => {
  const {
    requestorTypes,
    approverTypes,
    roleTypes,
    targetTypes,
    supplyLabel,
    demandLabel,
    approvalReasons,
    hideBudgetColumn,
    hideBudgetTooltip,
    financialYear,
    enableForecastSubmitting,
    enableForecastConstraints,
    enableMembersInForecast,
    enableMembersInPlanner,
    enableAddNewRole,
    enableForecastSearchView,
    forecastSearchOptions,
    forecastSearchInvalidTags,
    forecastSearchLimit,
    showMemberTags,
    enableDeparturedPeopleTagEditing,
    enableBulkAddingTag,
    enableWorkspaceAccess,
    enableWorkspaceAccessForEveryone,
    initialChaptersTreeExpansionLevel,
    enableBulkMoveMembers,
    enableBulkDeallocations,
    enableBulkMoveWithUpdate,
    enableMoveGroupByTag,
    enableMoveGroups,
    enableMemberAllocation,
    enableAddMember,
    enableMembersFilter,
    showMemberMoveBadge,
    enableAddVacantRole,
    enableVacantRoleWithUpdate,
    enableMemberFteEdit,
    vacantRoleLabel,
    currentMemberFteLabel,
    totalChangeLabel,
    macroAllocationDeltaFteLabel,
    desiredInputAsDelta,
    allocateBtnLabel,
    hideAllocateButton,
    moveMemberBtnLabel,
    addTeamBtnLabel,
    groupDetailsLabelSuffix,
    groupingLineItemsLabel,
    groupingTagsLabel,
    initalExpansionLevel,
    useDeltaForBudget,
    hideApproverColumn,
    enableSearchView,
    enableSetGroupTags,
    plannerSearchOptions,
    plannerSearchInvalidTags,
    plannerSearchLimit,
    disableGroupTeamBuilder,
    enableTagsAppliedCount,
    showMacroAllocationInput,
  } = workspace.config.allocation;

  const rootTypes = map(filter(workspace.config.groupTypes, "isRoot"), "name");

  const supplyRootTypes = map(
    filter(workspace.config.groupTypes, "isSupplyRoot"),
    "name"
  );

  return {
    parentGroupId: group.id,
    parentGroupType: group.type,
    rootTypes,
    requestorTypes,
    approverTypes,
    supplyRootTypes,
    roleTypes,
    targetTypes,
    groupLookup: {
      [group.id]: {
        ...group,
        isSource: !isDemandGroup(group, workspace.config.allocation),
      },
    },
    activeAllocationProject: {},
    allocationProjectLookup: {},
    supplyLabel,
    demandLabel,
    hideBudgetColumn,
    hideBudgetTooltip,
    approvalReasons,
    financialYear,
    enableForecastSubmitting,
    enableForecastConstraints,
    enableMembersInForecast,
    enableMembersInPlanner: isNil(enableMembersInPlanner)
      ? true
      : enableMembersInPlanner,
    enableAddNewRole: isNil(enableAddNewRole) ? true : enableAddNewRole,
    showMemberTags,
    enableDeparturedPeopleTagEditing,
    enableBulkAddingTag,
    enableWorkspaceAccess,
    enableWorkspaceAccessForEveryone,
    initialChaptersTreeExpansionLevel,
    enableBulkMoveMembers,
    enableBulkDeallocations,
    enableBulkMoveWithUpdate,
    enableMoveGroups,
    enableMoveGroupByTag,
    enableMemberAllocation,
    enableAddMember,
    enableMembersFilter,
    showMemberMoveBadge,
    enableAddVacantRole,
    enableVacantRoleWithUpdate,
    enableMemberFteEdit,
    vacantRoleLabel,
    currentMemberFteLabel,
    totalChangeLabel,
    macroAllocationDeltaFteLabel,
    desiredInputAsDelta,
    allocateBtnLabel,
    hideAllocateButton,
    moveMemberBtnLabel,
    addTeamBtnLabel,
    disableGroupTeamBuilder,
    groupDetailsLabelSuffix,
    groupingLineItemsLabel,
    groupingTagsLabel,
    initalExpansionLevel,
    useDeltaForBudget,
    hideApproverColumn,
    enableSearchView,
    enableSetGroupTags,
    plannerSearchOptions,
    plannerSearchInvalidTags,
    plannerSearchLimit,
    enableForecastSearchView,
    enableTagsAppliedCount,
    forecastSearchOptions,
    forecastSearchInvalidTags,
    forecastSearchLimit,
    groupTypesLookup: workspace.config.groupTypes,
    showMacroAllocationInput,
  };
};
