import {
  Flex,
  Button,
  Icon,
  TextInput,
  Small,
  Spacer,
  H5,
  Divider,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { MAX_INVITE_NUMBERS } from "src/util/consts";
import isValidEmail from "src/util/email";
import icons from "src/config/icons";

const Header = ({ onEmailChange, curEmail, onAddEmail, mailCount }) => {
  return (
    <Spacer mb="r">
      <H5 fontWeight="bold">Invite users</H5>
      <Divider light />
      <Flex alignItems="flex-end">
        <TextInput
          fullWidth
          label="Who would you like to invite?"
          id="invite-user-email-input"
          data-testid="invite-user-email-input"
          onChange={onEmailChange}
          type="text"
          placeholder="Enter Email Address"
          value={curEmail}
          disabled={mailCount >= MAX_INVITE_NUMBERS}
        />

        <Button
          iconOnly
          disabled={!isValidEmail(curEmail) || mailCount >= MAX_INVITE_NUMBERS}
          ml="s"
          ariaLabel="Add email"
          height="38px"
          width="38px"
          onClick={onAddEmail}
          data-testid="add-new-email-btn"
        >
          <Icon icon={icons.plus} />
        </Button>
      </Flex>
      {mailCount >= MAX_INVITE_NUMBERS && (
        <Small mt="s" display="block">
          You can only invite {MAX_INVITE_NUMBERS} emails at a time.
        </Small>
      )}
    </Spacer>
  );
};
Header.propTypes = {
  curEmail: PropTypes.string,
  onAddEmail: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  mailCount: PropTypes.number,
};

export default Header;
