import { useApolloClient } from "@apollo/client";
import { debounce, get, filter, includes, isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useState, useMemo } from "react";

import Search from "src/components/Search";
import WorkspacePropType from "src/custom-prop-types/workspace";
import useTagsSearch from "src/hooks/useTagsSearch";
import { globalSearchQuery } from "src/queries/globalSearch.graphql";
import { TAGS_UI_COMPONENTS } from "src/consts/tags";
import { getSupportedGroupTypeIds } from "src/util/group";
import * as searchUtil from "src/util/search";
import { reportError } from "src/services/errorReporting";
import { isSearchPage } from "src/util/url";
import { matchPaths } from "src/consts/urlPaths";
import { useGlobalSearchContext } from "./GlobalSearchContext";

const SEARCH_COUNT_LIMIT = 3;
const SEARCH_PAGE_COUNT_LIMIT = 20;
const DEBOUNCE_SEARCH_TIME = 500;

const SearchContainer = ({
  onOptionSelect,
  isMulti = false,
  isCloseOnSelect = true,
  isStandardSelect = false,
  withPeople = true,
  withGroups = true,
  workspace,
  featureFlags,
  showExploreLink = false,
  tagsVisibleIn = TAGS_UI_COMPONENTS.DIRECTORY,
}) => {
  const match = matchPaths();
  const isForSearchPage = isSearchPage(match?.path);
  const [prevOptions, setPrevOptions] = useState();
  const [isFetchingAsyncQuery, setIsFetchingAsyncQuery] = useState(false);
  const [searchError, setSearchError] = useState();
  const client = useApolloClient();
  const groupTypes = get(workspace, "config.groupTypes", {});
  const customer = get(workspace, "config.customer");
  const tagTypesConfig = get(workspace, "config.tagConfig");
  const supportedGroupTypeIds = getSupportedGroupTypeIds(groupTypes);
  const {
    defaultSearchScope,
    setDefaultSearchScope,
    setSearchResults,
    setSearchData,
  } = useGlobalSearchContext();
  const isScopedSearchEnabled = get(
    featureFlags,
    "scoped-search-filter",
    false
  );

  /* hide tags from search that are not to be shown in navigation or are reserved or are type groupAssociation */
  const filteredTagTypesConfig = useMemo(() => {
    return filter(
      tagTypesConfig,
      (config) =>
        includes(config?.visibleIn, TAGS_UI_COMPONENTS.NAVIGATION) &&
        !config?.isReserved &&
        !config?.isGroupAssociation
    );
  }, [tagTypesConfig]);

  const { searchTags } = useTagsSearch({
    tagTypesConfig: filteredTagTypesConfig,
    limit: isForSearchPage ? SEARCH_PAGE_COUNT_LIMIT : SEARCH_COUNT_LIMIT,
    visibleIn: tagsVisibleIn,
    skip: workspace?.config?.featureFlags?.disableGlobalTagsSearch,
  });

  const getLoadOptions = useCallback(
    (data, tagsSearchResult, inputValue) => {
      const options = searchUtil.getFormattedSearchResults({
        data,
        tagsSearchResult,
        isHideLabels: isStandardSelect,
        isHideExtraLinks: isStandardSelect,
        searchTerm: inputValue,
        customer,
      });
      return options;
    },
    [customer, isStandardSelect]
  );

  const debouncedSearchQuery = useMemo(
    () =>
      debounce((scope, inputValue, callback, groupTypeIds) => {
        const sanitisedQuery = searchUtil.sanitiseString(inputValue);
        const promises = Promise.all([
          client.query({
            query: globalSearchQuery,
            variables: {
              size: isSearchPage ? SEARCH_COUNT_LIMIT : SEARCH_PAGE_COUNT_LIMIT,
              searchTerms: sanitisedQuery,
              groupTypes: groupTypeIds,
              withPeople,
              withGroups,
              includeEmptyGroups: true,
              filter:
                isScopedSearchEnabled && !isEmpty(scope)
                  ? {
                      teams: {
                        teams: [scope?.id],
                        includeSubTeams: true,
                        intersectingTeamMembers: true,
                      },
                    }
                  : null,
            },
          }),
          isScopedSearchEnabled && !isEmpty(scope)
            ? []
            : Promise.resolve(searchTags(inputValue)),
        ]);

        promises
          .then(([{ data }, tagsSearchResult]) => {
            const options = getLoadOptions(data, tagsSearchResult, inputValue);
            setSearchResults(options);
            setSearchData(data);
            setIsFetchingAsyncQuery(false);
            setSearchError(null);
            const dropdownOptions = options.map((item) => {
              const dropdownItem = { ...item };

              if (["People", "Teams", "Tags"].includes(dropdownItem.label)) {
                dropdownItem.options = dropdownItem.options.slice(0, 3);
              } else if (dropdownItem?.options?.length) {
                const firstOption = {
                  ...dropdownItem.options[0],
                  value: { ...dropdownItem.options[0].value },
                };
                if (firstOption.value.found > 3) {
                  firstOption.value.found = 3;
                }
                dropdownItem.options = [
                  firstOption,
                  ...dropdownItem.options.slice(1),
                ];
              }

              return dropdownItem;
            });
            setPrevOptions(dropdownOptions);
            callback(dropdownOptions);
          })
          .catch((error) => {
            setSearchResults([]);
            setSearchData(null);
            reportError(error);
            setIsFetchingAsyncQuery(false);
            setSearchError(error);
            callback([]);
          });
      }, DEBOUNCE_SEARCH_TIME),
    [
      client,
      withPeople,
      withGroups,
      isScopedSearchEnabled,
      searchTags,
      setSearchData,
      setSearchResults,
      getLoadOptions,
    ]
  );

  const loadOptions = (inputValue, callback, scope = defaultSearchScope) => {
    setIsFetchingAsyncQuery(true);
    debouncedSearchQuery(scope, inputValue, callback, supportedGroupTypeIds);
  };

  const onDefaultScopeClearCallback = (searchQuery) => {
    setDefaultSearchScope(null);
    loadOptions(searchQuery, () => {}, null);
  };

  return (
    <Search
      isForSearchPage={isForSearchPage}
      isMulti={isMulti}
      isCloseOnSelect={isCloseOnSelect}
      isLoading={isFetchingAsyncQuery}
      isStandardSelect={isStandardSelect}
      onOptionSelect={onOptionSelect}
      loadOptions={loadOptions}
      defaultOptions={prevOptions}
      searchError={searchError}
      groupTypes={groupTypes}
      featureFlags={featureFlags}
      workspace={workspace}
      showExploreLink={showExploreLink}
      onDefaultScopeClearCallback={onDefaultScopeClearCallback}
    />
  );
};

SearchContainer.propTypes = {
  isStandardSelect: PropTypes.bool,
  onOptionSelect: PropTypes.func,
  isCloseOnSelect: PropTypes.bool,
  isMulti: PropTypes.bool,
  withPeople: PropTypes.bool,
  withGroups: PropTypes.bool,
  featureFlags: PropTypes.object,
  workspace: WorkspacePropType,
  showExploreLink: PropTypes.bool,
  tagsVisibleIn: PropTypes.string,
};

// Wrap in react memo so state provider change doesn't re-render component
export default SearchContainer;
