// eslint-disable-next-line import/prefer-default-export
export const isTargetAreaGroup = (group, groupTypes) => {
  if (!group) {
    return false;
  }

  const groupType = groupTypes[group.type];

  return !!groupType && (groupType.isDemand || groupType.isRoot);
};
