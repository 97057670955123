import { get, startsWith, some } from "lodash";

export const RESPONSE_MSG_INDEX_NOT_FOUND = "[index_not_found_exception]";
export const RESPONSE_CODE_INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR";
export const RESPONSE_MSG_GRAPHQL_UNAUTHORISED = "Unauthorized";
export const RESPONSE_MSG_NETWORK_ERROR = "network error";
export const RESPONSE_SOURCE_AUTH0 = "auth0";
export const RESPONSE_MSG_PIT_NOT_FOUND_REGEX =
  /^unable to find person index in workspace ".+" before given date$/;
export const RESPONSE_STATUS_FORBIDDEN = 403;

export const ERROR_CODES = {
  WORKSPACE_NOT_FOUND: "WORKSPACE_NOT_FOUND",
  WORKSPACE_ACCESS_DENIED: "WORKSPACE_ACCESS_DENIED",
  WORKSPACE_UNDER_MAINTENANCE: "WORKSPACE_UNDER_MAINTENANCE",
};

export const messages = {
  notFoundError: "Not Found",
};

const isMatch = (value, regexPattern) => {
  // unable to find person index in workspace "tgijb4" before given date
  return value?.match(regexPattern);
};

export const isNetworkError = (error) => {
  return (
    error.message &&
    error.message.toLowerCase().includes(RESPONSE_MSG_NETWORK_ERROR)
  );
};

export const isUnauthorisedRoleError = (error) => {
  return error.message && error.message.includes("incorrect role");
};

export const isForbiddenError = (error) => {
  return (
    error.networkError &&
    error.networkError.response &&
    error.networkError.response.status === RESPONSE_STATUS_FORBIDDEN
  );
};

export const isGraphQLUnauthorizedError = (error = {}) => {
  const { graphQLErrors } = error;
  return some(graphQLErrors, { message: RESPONSE_MSG_GRAPHQL_UNAUTHORISED });
};

export const isIndexNotFoundError = (error) => {
  return (
    get(error, "graphQLErrors[0].extensions.code") ===
      RESPONSE_CODE_INTERNAL_SERVER_ERROR &&
    startsWith(
      get(error, "graphQLErrors[0].extensions.exception.msg"),
      RESPONSE_MSG_INDEX_NOT_FOUND
    )
  );
};

export const isPITNotFound = (error) => {
  return (
    get(error, "graphQLErrors[0].extensions.code") ===
      RESPONSE_CODE_INTERNAL_SERVER_ERROR &&
    isMatch(
      get(error, "graphQLErrors[0].message"),
      RESPONSE_MSG_PIT_NOT_FOUND_REGEX
    )
  );
};

export const isAuth0Error = (errorContext) => {
  return errorContext.source === RESPONSE_SOURCE_AUTH0;
};

export const isWorkspaceNotFoundError = (error) => {
  // GraphQL Error is getting mapped to networkError ?
  // https://github.com/apollographql/apollo-client/issues/6222#issuecomment-781518783
  const workspaceNotFoundError = get(
    error,
    "networkError.result.errors",
    []
  ).some((err) => err?.extensions?.code === ERROR_CODES.WORKSPACE_NOT_FOUND);
  return workspaceNotFoundError;
};

export const isWorkspaceAccessDeniedError = (error) => {
  return error.message && error.message === ERROR_CODES.WORKSPACE_ACCESS_DENIED;
};

export const isUnderMaintenance = (error) => {
  const statusCode = get(error, "networkError.response.status");
  if (statusCode === 503) {
    return true;
  }

  const code = get(error, "networkError.result.errors[0].extensions.code");
  return code === ERROR_CODES.WORKSPACE_UNDER_MAINTENANCE;
};

export const isWorkspaceNotFoundUIError = (error) => {
  return error.message && error.message === ERROR_CODES.WORKSPACE_NOT_FOUND;
};

export const isNotFoundError = (error) => {
  return error.message === messages.notFoundError;
};

export const isServerError = (error) => {
  return (
    error.graphQLErrors ||
    (error.networkError &&
      !isForbiddenError(error) &&
      !isUnderMaintenance(error))
  );
};
