/* eslint-disable import/prefer-default-export */
const { isEmpty, filter, keyBy } = require("lodash");

const filterTagTypes = ({ tagTypesConfig, tagTypes }) => {
  if (isEmpty(tagTypesConfig)) {
    return tagTypes;
  }
  const tagTypeConfigKeyMap = keyBy(tagTypesConfig, "id");
  return filter(tagTypes, (type) => {
    if (!tagTypeConfigKeyMap[type]?.isReadonly) {
      return true;
    }
    return false;
  });
};

export { filterTagTypes };
