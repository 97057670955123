import { css } from "@styled-system/css";
import { Box, Icon } from "orcs-design-system";
import styled from "styled-components";

export const ErrorIcon = styled(Icon)(
  css({
    color: "warning",
    fontSize: ["48px", "72px"],
    textAlign: "center",
    m: "r",
  })
);

export const ErrorBox = styled(Box)(
  css({
    bg: "white",
    borderRadius: "2",
    minWidth: "30vw",
    maxWidth: "750px",
    margin: "auto",
    py: "xxl",
    px: "l",
    boxShadow: "boxDefault",
  })
);

export const ErrorContainer = styled(Box)((props) => {
  return css({
    height: props.height ? props.height : "100%",
    background: props.background ? props.background : "transparent",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });
});

ErrorContainer.defaultProps = {
  allowSpaceForHeader: true,
};
