import PropTypes from "prop-types";

// eslint-disable-next-line import/prefer-default-export
export const OptionValuePropType = PropTypes.shape({
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      iconSize: PropTypes.string,
      icon: PropTypes.array,
      text: PropTypes.string,
      width: PropTypes.string,
    })
  ),
  badge: PropTypes.shape({
    initial: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    image: PropTypes.string,
  }),
  link: PropTypes.string,
  count: PropTypes.number,
  found: PropTypes.number,
  url: PropTypes.string,
  template: PropTypes.oneOf(["link", "option", "optionWithAction", "error"]),
  value: PropTypes.oneOf(["team", "people"]),
});
