import PropTypes from "prop-types";
import GroupPropType from "./group";

const BasePersonPropType = PropTypes.shape({
  aggregateId: PropTypes.string.isRequired,
  address: PropTypes.string,
  city: PropTypes.string,
  avatar: PropTypes.string,
  company: PropTypes.string,
  email: PropTypes.string,
  employeeId: PropTypes.string,
  firstName: PropTypes.string,
  surname: PropTypes.string,
  preferredName: PropTypes.string,
  fte: PropTypes.number,
  gender: PropTypes.string,
  jobTitle: PropTypes.string,
  mobileNumber: PropTypes.string,
  telephoneNumber: PropTypes.string,
  createdAt: PropTypes.string,
  managedByHierarchy: PropTypes.array,
  managing: PropTypes.array,
  division: GroupPropType,
  __typename: PropTypes.string,
});

const PersonPropType = PropTypes.shape({
  aggregateId: PropTypes.string.isRequired,
  address: PropTypes.string,
  city: PropTypes.string,
  avatar: PropTypes.string,
  company: PropTypes.string,
  email: PropTypes.string,
  employeeId: PropTypes.string,
  firstName: PropTypes.string,
  surname: PropTypes.string,
  preferredName: PropTypes.string,
  fte: PropTypes.number,
  gender: PropTypes.string,
  jobTitle: PropTypes.string,
  mobileNumber: PropTypes.string,
  telephoneNumber: PropTypes.string,
  createdAt: PropTypes.string,
  managedByHierarchy: PropTypes.arrayOf(BasePersonPropType),
  managing: PropTypes.arrayOf(BasePersonPropType),
  division: GroupPropType,
  __typename: PropTypes.string,
});

export default PersonPropType;
