import { Button, Flex, FlexItem, Small } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import { components } from "react-select";
import styled from "styled-components";

const StyledContainer = styled.div`
  background: white;
  &:hover {
    background: #d2eeff;
  }
`;

const MultiValueRemove = () => {
  return null;
};

export const getMenuList = (createNewTag) => {
  const MenuListComponent = ({ children, ...rest }) => {
    return (
      <>
        <StyledContainer onClick={() => createNewTag()}>
          <Flex
            style={{ padding: "10px" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <FlexItem flex="1 1 auto" mr="s">
              <Small>{`If desired tag does not already exist, you can create a
            new tag`}</Small>
            </FlexItem>
            <FlexItem flex="0 0 auto" ml="s">
              <Button onClick={() => createNewTag()} small variant="success">
                Create new tag
              </Button>
            </FlexItem>
          </Flex>
        </StyledContainer>
        <components.MenuList {...rest}>{children}</components.MenuList>
      </>
    );
  };
  MenuListComponent.propTypes = {
    children: PropTypes.elementType,
  };
  return MenuListComponent;
};

export default { MultiValueRemove };
