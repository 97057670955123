import { isFinite } from "lodash";

const setLineItemTotalCost = (lineItem) => {
  const { cells } = lineItem;

  let lineItemTotal = 0;
  for (let i = 0, j = cells.length; i < j; i += 1) {
    const cell = cells[i];

    // For active allocation project cell, skip it, not in calculation
    if (cell.isStatic) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const { totalCost } = cell;

    if (isFinite(totalCost)) {
      lineItemTotal += totalCost;
    }
  }

  // eslint-disable-next-line no-param-reassign
  lineItem.totalCost = lineItemTotal;
};

export default setLineItemTotalCost;
