import debug from "debug";

import { REPLACE_ALL_STATE } from "./actionTypes";
import mainQuery from "./mainQueryReducer";
import model from "./modelReducer";
import lookupData from "./lookupDataReducer";
import userInteraction from "./userInteractionReducer";

const debugLog = debug("orchestrated:forecast-planner:context:reducer");

export default (state, action) => {
  switch (action.type) {
    case REPLACE_ALL_STATE:
      return action.newState;
    default: {
      debugLog(`${action.type} - received`, action);
      const newState = {
        ...state,
        mainQuery: mainQuery(state.mainQuery, action),
        model: model(state.model, action),
        lookupData: lookupData(state.lookupData, action),
        userInteraction: userInteraction(state.userInteraction, action),
      };

      debugLog(`${action.type} - complete`, newState);
      return newState;
    }
  }
};
