/* eslint-disable import/prefer-default-export */
import { find, some } from "lodash";

import { TAG_ATTRIBUTE_TYPES } from "src/consts/tags";

const checkConfigHasNamedAttributes = ({ tagTypesConfig, id }) => {
  const tagTypeConfig = find(tagTypesConfig, { id });
  return some(tagTypeConfig?.attributes?.values, (val) => {
    return (
      val.type === TAG_ATTRIBUTE_TYPES.PERSON ||
      val.type === TAG_ATTRIBUTE_TYPES.GROUPTYPE ||
      val.type === TAG_ATTRIBUTE_TYPES.TAGTYPE
    );
  });
};

export { checkConfigHasNamedAttributes };
