import { get, isEmpty } from "lodash";
import {
  CHALLENGE_SELECT_WORKSPACE,
  CHALLENGE_SETUP_WORKSPACE,
  CHALLENGE_SHOW_PROVISIONING_PAGE,
  CHALLENGE_NONE,
} from "./WorkspaceContext";

const useDecidingWorkspaceAndChallenge = (
  workspacesData,
  activeWorkspaceData,
  selectedWorkspaceData,
  selectedWorkspaceId,
  isAdmin
) => {
  if (isAdmin) {
    if (selectedWorkspaceData && selectedWorkspaceData.workspace) {
      return {
        challenge: CHALLENGE_NONE,
        workspace: selectedWorkspaceData.workspace,
      };
    }
    if (selectedWorkspaceId) {
      return {
        challenge: CHALLENGE_SELECT_WORKSPACE,
        workspace: null,
      };
    }
    if (activeWorkspaceData && activeWorkspaceData.activeWorkspace) {
      return {
        challenge: CHALLENGE_NONE,
        workspace: activeWorkspaceData.activeWorkspace,
      };
    }

    const workspaces = get(workspacesData, "workspaces");

    const noWorkspaces = isEmpty(workspaces);
    if (noWorkspaces) {
      return {
        challenge: CHALLENGE_SETUP_WORKSPACE,
        workspace: null,
      };
    }
    return {
      challenge: CHALLENGE_SELECT_WORKSPACE,
      workspace: null,
    };
  }
  if (selectedWorkspaceData && selectedWorkspaceData.workspace) {
    return {
      challenge: CHALLENGE_NONE,
      workspace: selectedWorkspaceData.workspace,
    };
  }

  if (activeWorkspaceData && activeWorkspaceData.activeWorkspace) {
    return {
      challenge: CHALLENGE_NONE,
      workspace: activeWorkspaceData.activeWorkspace,
    };
  }

  return { challenge: CHALLENGE_SHOW_PROVISIONING_PAGE, workspace: null };
};

export default useDecidingWorkspaceAndChallenge;
