import { filter, forEach, findIndex } from "lodash";
import { newConstraintCell } from "../newCell";

const setGroupingWithActualConstraints = (
  grouping,
  constraints,
  allocationProjectLookup,
  columns
) => {
  const constraintsForGrouping = filter(constraints, (c) =>
    grouping.isVirtualDirectConstraintGrouping
      ? c.groupId === grouping.parent.groupId
      : c.groupId === grouping.groupId
  );

  forEach(constraintsForGrouping, (constraint) => {
    const cell = newConstraintCell(
      grouping,
      constraint,
      allocationProjectLookup[constraint.allocationProjectId]
    );
    const cellIndex = findIndex(columns, {
      allocationProjectId: cell.allocationProjectId,
    });

    // eslint-disable-next-line no-param-reassign
    grouping.constraints[cellIndex] = cell;
  });
};

export default setGroupingWithActualConstraints;
