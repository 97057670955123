import { forEach, reduce, isFinite } from "lodash";

import setGroupingTotalCost from "./setGroupingTotalCost";
import setLineItemTotalCost from "./setLineItemTotalCost";

export const setGroupingsTotalCost = (
  groupings,
  overBudgetLimit,
  underBudgetLimit
) => {
  forEach(groupings, (grouping) => {
    setGroupingTotalCost(grouping, {
      recalcChildren: true,
      overBudgetLimit,
      underBudgetLimit,
    });
  });
};

export const getLineItemsTotalCost = (lineItems) => {
  return reduce(
    lineItems,
    (totalCost, lineItem) => {
      if (isFinite(lineItem.totalCost)) {
        return totalCost + lineItem.totalCost;
      }

      return totalCost;
    },
    0
  );
};

export const updateGroupingTotalCost = (
  lineItem,
  overBudgetLimit,
  underBudgetLimit
) => {
  setLineItemTotalCost(lineItem);

  let parentGrouping = lineItem.grouping;
  while (parentGrouping) {
    setGroupingTotalCost(parentGrouping, { overBudgetLimit, underBudgetLimit });

    parentGrouping = parentGrouping.parent;
  }
};
