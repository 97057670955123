import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import PATHS from "../consts/urlPaths";
import Logout from "../pages/Logout";

const PublicRoutes = () => {
  return (
    <Switch>
      <Route exact path={PATHS.LOGOUT} render={() => <Logout />} />
      <Route>
        <Redirect to={{ pathname: "/" }} />
      </Route>
    </Switch>
  );
};

export default PublicRoutes;
