import { filter } from "lodash";
import { DIRECT } from "src/allocation/pages/ForecastPage/context/reducer/macroAllocationTypes";

export const getLineItemsWithType = (lineItems, type) => {
  const lineItemsWithType = filter(lineItems, (lineItem) => {
    if (!type) {
      return !lineItem.type;
    }
    return lineItem.type === type;
  });
  return lineItemsWithType;
};

export const getLineItemsTotalsByType = (grouping, type) => {
  const { lineItemTotalsByType } = grouping;
  if (!type) {
    return lineItemTotalsByType[DIRECT];
  }
  return lineItemTotalsByType[type] || [];
};

export const getLineItemsTotalCostsByType = (grouping, type) => {
  const { lineItemTotalsByType } = grouping;
  if (!type) {
    return lineItemTotalsByType[`totalCost-${DIRECT}`];
  }
  return lineItemTotalsByType[`totalCost-${type}`];
};
