import React from "react";
import PropTypes from "prop-types";

import { useLocation } from "react-router-dom";
import { matchPaths } from "../consts/urlPaths";
import * as vsUtils from "./utils/visualisationContext.utils";
import { useAuth } from "./global/GlobalContext";

export const VisualisationContext = React.createContext();

const reducer = (state, action) => {
  switch (action.type) {
    /**
     * @param {object} settings
     */
    case "applyVisualisationSettings": {
      const { username, tenantId } = state.auth;
      const newSettings = {
        ...state,
        visualisation: {
          ...state.visualisation,
          settings: {
            ...state.visualisation.settings,
            ...action.settings,
          },
        },
      };
      vsUtils.saveVisualisationToLocalStorage(
        vsUtils.localStorageVisualisationKey(username, tenantId),
        newSettings.visualisation,
        state.tipsNotification
      );
      return newSettings;
    }
    /**
     * @param {string} chartType
     */
    case "applyChartType": {
      return {
        ...state,
        visualisation: {
          ...state.visualisation,
          chartType: action.chartType,
        },
      };
    }
    /**
     * @param {function} downloadChart
     */
    case "setDownloadChartFn": {
      return {
        ...state,
        visualisation: {
          ...state.visualisation,
          downloadChart: action.downloadChart,
        },
      };
    }
    /**
     * @param {function} highlightTargetNode
     */
    case "setHighlightTargetFunction": {
      return {
        ...state,
        visualisation: {
          ...state.visualisation,
          highlightTargetNode: action.highlightTargetNode,
        },
      };
    }
    /**
     * @param {boolean} isEnabled
     */
    case "applyVisualisationSidebarEnable": {
      return {
        ...state,
        visualisationSidebarEnable: action.isEnabled,
      };
    }
    /**
     * @param {boolean} isTipsNotificationEnabled
     */
    case "setTipsNotification": {
      const { username, tenantId } = state.auth;
      vsUtils.saveVisualisationToLocalStorage(
        vsUtils.localStorageVisualisationKey(username, tenantId),
        state.visualisation,
        action.isTipsNotificationEnabled
      );
      return {
        ...state,
        tipsNotification: action.isTipsNotificationEnabled,
      };
    }
    /**
     * @param {array} searchTerms
     */
    case "setSearchTerms": {
      return {
        ...state,
        searchTerms: action.searchTerms,
      };
    }
    default:
      return state;
  }
};

export const VisualisationProvider = ({ children }) => {
  const match = matchPaths();
  const location = useLocation();
  const auth = useAuth();
  return (
    <VisualisationContext.Provider
      value={React.useReducer(
        reducer,
        vsUtils.getInitVisualisationSettings(auth, match, location)
      )}
    >
      {children}
    </VisualisationContext.Provider>
  );
};

VisualisationProvider.propTypes = {
  children: PropTypes.object,
};
