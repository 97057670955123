import { isEmpty } from "lodash";
import { Flex } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import TagSelectorContainer from "src/components/TagsEditor/sub-components/TagSelectorContainer";
import TagsEditor from "src/components/TagsEditor/TagsEditor";

import useHandleEntityTagClick from "src/hooks/useHandleEntityTagClick";
import { getHighlightedTags } from "./utils";

const HighlightedTags = ({ tagConfig, tags, flagType = "isPersonBadge" }) => {
  const handleTagClick = useHandleEntityTagClick();
  const highlightedTags = getHighlightedTags(tags, tagConfig, flagType);

  if (isEmpty(highlightedTags)) return null;

  return (
    <Flex alignItems="center" flexWrap="wrap" role="list">
      <TagsEditor
        readOnly
        tagTypesConfig={tagConfig}
        existingTags={highlightedTags}
        onEntityTagClick={handleTagClick}
        components={{ TagSelector: TagSelectorContainer }}
        small
        highlighted
        inline
      />
    </Flex>
  );
};

HighlightedTags.propTypes = {
  tagConfig: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  flagType: PropTypes.string,
};

export default HighlightedTags;
