import { Box, Spacer } from "orcs-design-system";
import React from "react";

import * as placeholder from "src/components/Placeholder/PlaceholderStyles";

const LoadingBlock = () => {
  return (
    <Box data-testid="groups-navigation-loading-block">
      <Spacer m="xs">
        <placeholder.Line width={250} height={24} />
        <placeholder.Line width={250} height={24} />
        <placeholder.Line width={250} height={24} />
      </Spacer>
    </Box>
  );
};

export default LoadingBlock;
