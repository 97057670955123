import { useQuery } from "@apollo/client";
import { isEmpty, map } from "lodash";
import { Select } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import { availableTags } from "src/queries/tags.graphql";

const getValue = (value, options) => {
  return options.find((option) => option.value === value) || null;
};

const TagDropdown = ({
  tagTypes,
  value,
  onSelectTag,
  label,
  disabled,
  invalid,
  mandatory,
  focus,
}) => {
  const { data } = useQuery(availableTags, {
    variables: {
      includeInactiveTags: false,
      tagTypes,
    },
    skip: isEmpty(tagTypes),
  });

  const options = map(data?.tags, (tag) => ({
    label: tag.displayValue,
    value: tag.id,
  }));

  return (
    <Select
      dataTestId="tag-dropdown-selector"
      inputId="tagDropdownSelector"
      label={label}
      placeholder="Select a default tag"
      options={options}
      value={getValue(value, options)}
      name="tagSelector"
      onChange={onSelectTag}
      isClearable={true}
      height="40px"
      isDisabled={disabled}
      invalid={invalid}
      mandatory={mandatory}
      focus={focus}
    />
  );
};

TagDropdown.propTypes = {
  label: PropTypes.string,
  tagTypes: PropTypes.array,
  value: PropTypes.string,
  onSelectTag: PropTypes.func,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  mandatory: PropTypes.bool,
  focus: PropTypes.bool,
};

export default TagDropdown;
