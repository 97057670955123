import { useAuth0 } from "src/contexts/auth0Provider";
import {
  hasAdminRole,
  hasPowerRole,
  hasDirectoryRole,
} from "src/services/auth";
import {
  DIRECTORY,
  POWER,
  ADMIN,
  UNKNOWN_USER,
} from "src/app/Restricted/consts";

const useUserRole = () => {
  const auth = useAuth0();

  if (!auth?.user) {
    return UNKNOWN_USER;
  }

  return hasAdminRole(auth.user)
    ? ADMIN
    : hasPowerRole(auth.user)
    ? POWER
    : hasDirectoryRole(auth.user)
    ? DIRECTORY
    : UNKNOWN_USER;
};

export default useUserRole;
