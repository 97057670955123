import { get } from "lodash";
import { H1, P } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import icons from "src/config/icons";
import { copywriting } from "./ErrorPage.config";

import ErrorPageWrapper from "./ErrorPageWrapper";

const AuthProviderError = ({ errorContext }) => {
  const { authProviderErrorSpecific: errorCopy } = copywriting;
  const { heading, detail } = get(errorCopy, errorContext.error, {
    heading: copywriting.authProviderDefaultHeading,
    detail: decodeURIComponent(errorContext.errorDescription),
  });

  return (
    <ErrorPageWrapper icon={icons.lock} showHeader={false}>
      <H1 mb="between" data-testid="cp-auth-provider-error-heading">
        {heading}
      </H1>
      <P data-testid="cp-auth-provider-error-detail">{detail}</P>
    </ErrorPageWrapper>
  );
};

export default AuthProviderError;

AuthProviderError.propTypes = {
  errorContext: PropTypes.shape({
    errorDescription: PropTypes.string,
    error: PropTypes.string,
  }),
};

AuthProviderError.defaultProps = {
  errorContext: {},
};
