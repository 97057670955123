import constate from "constate";

const useFeatureFlagsContext = (values) => values;

const [Provider, useFeatureFlags] = constate(
  useFeatureFlagsContext,
  (value) => value?.featureFlags
);

export { Provider, useFeatureFlags };
