import { values, keyBy, forEach, find } from "lodash";

import {
  getPlannerColumns,
  getPlannerMacroAllocations,
  checkForPending,
  setGroupingsWithPlannerCells,
  setCalculatedSumTotals,
  setNotifications,
  pruneEmptyGroupings,
  pruneEmptyLineItems,
  buildGroupingLookup,
  buildLineItemLookup,
  buildCellLookup,
  hashGrouping,
  buildSourceGroupLookup,
  setGroupingsBudget,
  setGroupingsTotalCost,
} from "../util";

import buildGroupings from "./buildGroupings/byDemand";
import setGroupingsWithLineItems from "./setLineItems/supplyLineItems";

// TODO: change signature to named props
const buildPlannerGroupedByDemand = ({
  rootGroup,
  data,
  hideHiddenTeams,
  showObjectives,
  groupTypes,
  shouldHash,
  isBudgetEnabled,
  useDeltaForBudget,
  overBudgetLimit,
  underBudgetLimit,
  isRealtimeFteMode,
  useRealtimeChangeOnly,
  useRealtimeFteDelta,
}) => {
  const allocationProjectLookup = keyBy(data.allocationProjects, "id");
  const activeAllocationProject = find(
    data.allocationProjects,
    (ap) => ap.isActive
  );
  const columns = getPlannerColumns(data.allocationProjects);
  const macroAllocations = getPlannerMacroAllocations(
    data.macroAllocations,
    data.allocationProjects,
    !rootGroup.isSource,
    hideHiddenTeams
  );
  const sourceGroupLookup = isBudgetEnabled
    ? buildSourceGroupLookup({
        macroAllocations,
        descendantSupplyGroups: data.descendantSupplyGroups,
        roleAreaGroups: data.roleAreaGroups,
        // For planner demand view, no need to do add this in, we are controlling the budget levels
        // relatedSupplyGroups: data.relatedSupplyGroups,
      })
    : null;

  const groupings = buildGroupings({
    rootGroup,
    macroAllocations,
    descendantTargetGroups: data.descendantTargetGroups,
    hideHiddenTeams,
    showObjectives,
    groupTypes,
    activeAllocationProject,
    isBudgetEnabled,
    sourceGroupLookup,
  });
  setGroupingsWithLineItems({
    groupings,
    macroAllocations,
    columnCount: columns.length,
    groupTypes,
    canRename: rootGroup.isSource,
    isBudgetEnabled,
    sourceGroupLookup,
  });
  setGroupingsWithPlannerCells({
    groupings,
    macroAllocations,
    allocationProjectLookup,
    isRequestor: !rootGroup.isSource,
    groupedByDemand: true,
    useDeltaForBudget,
    isRealtimeFteMode: rootGroup.isDemand && isRealtimeFteMode,
    useRealtimeChangeOnly: rootGroup.isDemand && useRealtimeChangeOnly,
    // Only use the realtime fte delta for demand root group
    useRealtimeFteDelta: rootGroup.isDemand && useRealtimeFteDelta,
  });

  if (isBudgetEnabled) {
    setGroupingsBudget(
      groupings,
      data.relatedSupplyGroups,
      data.roleAreaGroups
    );
    setGroupingsTotalCost(groupings, overBudgetLimit, underBudgetLimit);
  }

  setCalculatedSumTotals(groupings, columns);
  setNotifications(groupings, columns);

  pruneEmptyLineItems(groupings);
  if (rootGroup.isSource) {
    pruneEmptyGroupings(groupings, isBudgetEnabled);
  }

  const columnLookup = keyBy(columns, "id");
  const groupingLookup = buildGroupingLookup(groupings);
  const lineItemLookup = buildLineItemLookup(values(groupingLookup));
  const cellLookup = buildCellLookup(values(lineItemLookup));
  const isPendingSubmit = checkForPending(
    macroAllocations,
    !rootGroup.isSource
  );

  // Hash groupings in case anything changed
  if (shouldHash) {
    forEach(groupings, hashGrouping);
  }

  return {
    isPendingSubmit,
    columns,
    groupings,
    // lookup objects: for when we need fast access to objects in the tree
    lookups: {
      allocationProjectLookup,
      columnLookup,
      groupingLookup,
      lineItemLookup,
      cellLookup,
    },
  };
};

export default buildPlannerGroupedByDemand;
