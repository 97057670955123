import { buildRootGroup, getPageProps } from "./initialState.logic";

export default ({
  group,
  workspace,
  pageMode,
  hideHiddenTeams,
  showObjectives,
}) => {
  const { config } = workspace;
  const { groupTypes, allocation } = config;
  const { approvalReasons } = allocation;

  const [pageProps, forecastRootGroupProps] = getPageProps(
    allocation,
    pageMode,
    group
  );
  const rootGroup = buildRootGroup(group, groupTypes, forecastRootGroupProps);

  return {
    hideHiddenTeams,
    showObjectives,
    groupTypes,
    approvalReasons,
    rootGroup,
    ...pageProps,
    staticModel: { lookups: {} }, // static initial forecast model without any ui interaction changes
    dynamicModel: { lookups: {} }, // dynamic model with ui changes
    isGroupExtraInfoLoaded: false,
  };
};
