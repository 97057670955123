import PropTypes from "prop-types";
import React from "react";
import { components } from "react-select";

const Input = (props) => {
  const {
    selectProps: { maxLength = 250 },
  } = props;
  return (
    <components.Input
      {...props}
      maxLength={maxLength}
      data-testid="cp-global-search-input"
    />
  );
};

Input.propTypes = {
  selectProps: PropTypes.object,
};

export default {
  Input,
};
