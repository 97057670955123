import urlPaths, { url, matchPaths, TEAM_TAB } from "src/consts/urlPaths";
import getAllocationVisibility from "src/allocation/util/getAllocationVisibility";

const getTabType = ({ allocationVisibility }) => {
  const isPreviousPathTeamsPath = matchPaths()?.path === urlPaths.TEAM_DETAILS;
  const tabType = isPreviousPathTeamsPath
    ? matchPaths()?.params?.tabType
    : TEAM_TAB.DETAILS;
  let overrideTabType = tabType;

  if (
    tabType === TEAM_TAB.REQUEST_ALLOCATION_NUMBERS ||
    tabType === TEAM_TAB.PLANNER ||
    tabType === TEAM_TAB.APPROVE_ALLOCATION_NUMBERS
  ) {
    if (allocationVisibility.requestNumbers2) {
      overrideTabType =
        tabType === TEAM_TAB.PLANNER
          ? TEAM_TAB.PLANNER
          : TEAM_TAB.REQUEST_ALLOCATION_NUMBERS;
    } else if (allocationVisibility.approveNumbers2) {
      overrideTabType = TEAM_TAB.APPROVE_ALLOCATION_NUMBERS;
    }
  } else if (
    tabType === TEAM_TAB.FORECAST &&
    allocationVisibility.forecastNumbers
  ) {
    overrideTabType = TEAM_TAB.FORECAST;
  } else if (tabType === TEAM_TAB.INDIVIDUAL_ALLOCATIONS) {
    if (
      allocationVisibility.requestPeople ||
      allocationVisibility.individualAllocations
    ) {
      overrideTabType = TEAM_TAB.INDIVIDUAL_ALLOCATIONS;
    }
  }

  return overrideTabType;
};

export const getTeamPath = ({ group, workspace, activeAllocationProject }) => {
  const allocationVisibility = getAllocationVisibility({
    group,
    config: workspace?.config,
    activeAllocationProject,
  });

  const tabType = getTabType({ allocationVisibility });

  const pathname = url(urlPaths.TEAM_DETAILS, {
    id: group?.id,
    tabType,
  });

  return pathname;
};

export default getTeamPath;
