import { map, forEach, reduce, isEmpty, union, castArray } from "lodash";
import { newGroupLevelNotification } from "../newNotification";

export const higherNotificationStatus = (
  notificationA = {},
  notificationB = {}
) => {
  if (!notificationA.status) return notificationB;

  if (!notificationB.status) return notificationA;

  if (notificationA.status > notificationB.status) return notificationA;

  if (notificationA.status === notificationB.status) {
    if (notificationA.groupMessage !== notificationB.groupMessage) {
      return {
        ...notificationB,
        ...notificationA,
        groupMessage: union(
          castArray(notificationA.groupMessage),
          castArray(notificationB.groupMessage)
        ),
      };
    }
  }

  return notificationB;
};

const calculateLineItemNotifications = (lineItems, columns) =>
  reduce(
    lineItems,
    (notifications, lineItem) =>
      map(notifications, ({ columnId, columnType, notification }, index) => ({
        columnId,
        columnType,
        notification: higherNotificationStatus(
          notification,
          lineItem.cells[index].notification
        ),
      })),
    map(columns, ({ id, columnType }) => ({
      columnId: id,
      columnType,
      notification: {},
    }))
  );

const calculateChildGroupNotifications = (childGroupings, columns) =>
  map(
    reduce(
      childGroupings,
      (notifications, grouping) =>
        map(notifications, ({ columnId, columnType, notification }, index) => ({
          columnId,
          columnType,
          notification: higherNotificationStatus(
            notification,
            grouping.notifications[index].notification
          ),
        })),
      map(columns, ({ id, columnType }) => ({
        columnId: id,
        columnType,
        notification: {},
      }))
    )
  );

const calculateGroupLevelNotications = (grouping, columns) =>
  map(columns, ({ id, columnType }) => ({
    columnId: id,
    columnType,
    notification: newGroupLevelNotification(grouping, columnType),
  }));

const setGroupingWithNotifications = (
  grouping,
  columns,
  { recalcChildren = true, recalcParents = false } = {}
) => {
  if (recalcChildren && !isEmpty(grouping.childGroupings)) {
    forEach(grouping.childGroupings, (childGrouping) => {
      setGroupingWithNotifications(childGrouping, columns);
    });
  }

  const lineItemNotifications = calculateLineItemNotifications(
    grouping.lineItems,
    columns
  );
  const childGroupNotifications = calculateChildGroupNotifications(
    grouping.childGroupings,
    columns
  );

  // eslint-disable-next-line no-param-reassign
  grouping.lineItemNotifications = lineItemNotifications;

  const groupLevelNotifications = calculateGroupLevelNotications(
    grouping,
    columns
  );

  // eslint-disable-next-line no-param-reassign
  grouping.notifications = map(columns, ({ id, columnType }, index) => ({
    columnId: id,
    columnType,
    notification: higherNotificationStatus(
      higherNotificationStatus(
        lineItemNotifications[index].notification,
        childGroupNotifications[index].notification
      ),
      groupLevelNotifications[index].notification
    ),
  }));

  if (recalcParents && grouping.parent) {
    setGroupingWithNotifications(grouping.parent, columns, {
      recalcChildren: false,
      recalcParents: true,
    });
  }
};

export default setGroupingWithNotifications;
