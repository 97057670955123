import AllocationConfigForecastFields from "./AllocationConfigForecastFields.json";
import allocationForecastTour from "./allocationForecastTour.json";
import allocationPlannerLineItemTour from "./allocationPlannerLineItemTour.json";
import allocationPlannerTour from "./allocationPlannerTour.json";
import CommentModalTitle from "./CommentModalTitle.json";
import common from "./common.json";
import glossary from "./glossary.json";
import StrategyConfigEditor from "./StrategyConfigEditor.json";

export default {
  AllocationConfigForecastFields,
  allocationForecastTour,
  allocationPlannerLineItemTour,
  allocationPlannerTour,
  CommentModalTitle,
  common,
  glossary,
  StrategyConfigEditor,
};
