import { forEach, remove, every, isNil, isEmpty } from "lodash";

const lineItemWithEmptyGroupWithNoEdits = (l) =>
  l.memberCount === 0 && every(l.cells, (c) => isNil(c.value));

const lineItemWithHiddenGroupZeroedCellsAndNoWarnings = (l) =>
  l.group.isHidden === true &&
  every(l.cells, (c) => c.value === 0 && (c.notification.status || 0) <= 1);

const pruneEmptyLineItems = (groupings) => {
  forEach(groupings, (grouping) => {
    pruneEmptyLineItems(grouping.childGroupings);

    remove(grouping.lineItems, (l) => {
      if (lineItemWithEmptyGroupWithNoEdits(l)) return true;
      if (lineItemWithHiddenGroupZeroedCellsAndNoWarnings(l)) return true;

      return false;
    });

    // eslint-disable-next-line
    grouping.canToggleLineItems =
      !isEmpty(grouping.childGroupings) &&
      (!isEmpty(grouping.lineItems) > 0 || grouping.canAddLineItem);
  });
};

export default pruneEmptyLineItems;
