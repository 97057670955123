import { Flex, H1, Icon, Spacer, StyledLink } from "orcs-design-system";
import React from "react";

import icons from "src/config/icons";
import { copywriting } from "src/components/ErrorPage/ErrorPage.config";
import { shouldUseHomePage } from "src/util/featureFlags";
import { getHomePath, getExplorePath } from "src/util/paths";
import { useUserPerson } from "src/contexts/global/GlobalContext";
import ErrorPageWrapper from "./ErrorPageWrapper";

const NotFoundError = () => {
  const userPerson = useUserPerson();

  const useHomePage = shouldUseHomePage(userPerson);

  return (
    <ErrorPageWrapper showIcon={false}>
      <H1 mb="r">{copywriting.notFoundHeading}</H1>
      <Flex alignItems="center">
        <Spacer mr="s">
          {useHomePage && (
            <StyledLink white href={getHomePath()} button iconLeft>
              <Icon icon={icons.home} />
              {copywriting.backHomeButton}
            </StyledLink>
          )}
          <StyledLink white href={getExplorePath()} button iconLeft>
            <Icon icon={icons.search} />
            {copywriting.exploreButton}
          </StyledLink>
        </Spacer>
      </Flex>
    </ErrorPageWrapper>
  );
};

export default NotFoundError;
