import React from "react";
import { Spacer, Typography, Badge } from "orcs-design-system";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { deleteGuidedTourFromLocalStorage } from "src/services/localStorage";

import GroupTypeBadge from "src/components/GroupTypeBadge";
import {
  GROUPED_BY_DEMAND,
  GROUPED_BY_SUPPLY,
} from "src/allocation/pages/ForecastPage/context/reducer/viewModes";
import { NOTIFICATION_STATUS } from "src/allocation/pages/ForecastPage/context/ForecastContext";
import NotificationDot from "src/allocation/pages/ForecastPage/components/Shared/NotificationDot";
import createActionsMenuHandler from "../util/actionsMenuUtil";

/**
 * TODO: Localize the remaining text in this file.
 */

const tourOptions = ({
  group = {},
  supplyLabel,
  demandLabel,
  viewMode,
  isRequestorGroup,
  openFirstLineItem,
} = {}) => {
  const [openActionsMenu, closeActionsMenu] = createActionsMenuHandler(
    ".guided-tour-allocation-forecast-actions-menu"
  );
  const submitStr = isRequestorGroup ? "requests" : "responses";

  const allocationProjectTrans = t("glossary:allocationProject");

  return {
    triggerInSidebar: true,
    autoStart: false,
    showProgress: false,
    showSkipButton: true,
    disableScrolling: true,
    dependsOn: [],
    onComplete: ({ auth }) => {
      // reset line item tour
      deleteGuidedTourFromLocalStorage(
        auth.username,
        "allocationPlannerLineItemTour"
      );
    },
    steps: [
      {
        target: ".guided-tour-allocation-forecast-welcome",
        placement: "center",
        content: (
          <Spacer my={3}>
            <Typography.H4 weight="bold" textAlign="center">
              Welcome to Planner!
            </Typography.H4>
            <Typography.P textAlign="center">
              Where we plan the next state of our organisation.
            </Typography.P>
            <Typography.P textAlign="center">
              Let me show you around.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-main-panel",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              This is the <strong>Main Panel</strong>
            </Typography.H4>
            <Typography.P textAlign="left">
              It has the <strong>Action Bar</strong> placed at the top.
            </Typography.P>
            <Typography.P textAlign="left">
              And the expanding <strong>Planner Panel</strong> below.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-action-bar",
        placement: "top",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              The <strong>Action Bar</strong>
            </Typography.H4>
            <Typography.P textAlign="left">
              Is where you perform various <strong>Actions</strong> and view the{" "}
              <strong>Planner Column</strong> headers.
            </Typography.P>
            <Typography.P textAlign="left">
              Click next to step through each item on the{" "}
              <strong>Action Bar</strong>.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-view-mode",
        placement: "right",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              The <strong>View Mode</strong>
            </Typography.H4>
            <Typography.P textAlign="left">
              This changes the presentation of the{" "}
              <strong>Planner Panel</strong> within your{" "}
              <GroupTypeBadge group={group} />
              <ul>
                <li>
                  <strong>{supplyLabel}:</strong> Organises the Planner Panel by
                  your <Badge variant="secondary">Supply</Badge> types.
                </li>
                <li>
                  <strong>{demandLabel}</strong> Organises the Planner Panel by
                  your <Badge variant="success">Demand</Badge> types.
                </li>
              </ul>
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-planner-submit-button",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              The <strong>Submit {submitStr}</strong> button
            </Typography.H4>
            {!isRequestorGroup && (
              <Spacer my={3}>
                <Typography.P textAlign="left">
                  Once the approvals have been <strong>reviewed</strong> by the{" "}
                  {group.type} the <strong>Submit responses</strong> button will
                  send the responses to the appropriate requesting teams.
                </Typography.P>
                <Typography.P textAlign="left">
                  Noting that the &quot;Submit responses&quot; button will only{" "}
                  <strong>
                    be active if there are pending approvals to submit
                  </strong>
                  . This is also indicated by yellow status dots in the
                  approvals column.
                </Typography.P>
              </Spacer>
            )}
            {isRequestorGroup && (
              <Spacer my={3}>
                <Typography.P textAlign="left">
                  Once the demand request have been <strong>reviewed</strong> by
                  the {group.type} the <strong>Submit requests</strong> button
                  will send the requests to the appropriate supply areas.
                </Typography.P>
                <Typography.P textAlign="left">
                  Noting that the &quot;Submit requests&quot; button will only{" "}
                  <strong>
                    be active if there are pending requests to submit
                  </strong>
                  . This is also indicated by yellow status dots in the request
                  column.
                </Typography.P>
              </Spacer>
            )}
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-actions-menu",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              This is an <strong>Actions Menu</strong>
            </Typography.H4>
            <Typography.P textAlign="left">
              Clicking on an actions menu will provide a list of available
              actions relative to the action menus location.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-add-team",
        beforeAction: openActionsMenu,
        afterAction: closeActionsMenu,
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              This is an example of an Action Menu action
            </Typography.H4>
            <Typography.P textAlign="left">
              This allows the creation of a <strong>New Team</strong>, when
              selected it will guide which team type and where in the
              Hierarchy(parent) the team should be created.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target:
          ".guided-tour-allocation-forecast-allocation-project-current-column",
        placement: "left",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              <Trans ns="allocationPlannerTour" i18nKey="heading">
                This is a <strong>Planner Column.</strong>
              </Trans>
            </Typography.H4>
            <Typography.P textAlign="left">
              <Trans ns="allocationPlannerTour" i18nKey="line1">
                This example column currently displays the{" "}
                <strong>
                  FTE count at the start of an {{ allocationProjectTrans }}
                </strong>
                .
              </Trans>
            </Typography.P>
            <Typography.P textAlign="left">
              <Trans ns="allocationPlannerTour" i18nKey="line2">
                For details about the {{ allocationProjectTrans }} mouse-over
                the column headings.
              </Trans>
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-panel",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              This is the <strong>Planner Panel</strong>
            </Typography.H4>
            <Spacer my={3}>
              <Typography.P textAlign="left">
                Displayed here is the FTE{" "}
                <Badge variant="success" mx={1}>
                  Demand
                </Badge>{" "}
                requests and the{" "}
                <Badge variant="secondary" mx={1}>
                  Supply
                </Badge>{" "}
                responses for the {group.type}.
              </Typography.P>
              {!isRequestorGroup && (
                <Typography.P textAlign="left">
                  FTE{" "}
                  <Badge variant="secondary" mx={1}>
                    Supply
                  </Badge>{" "}
                  responses are entered on this screen, which we will walk
                  through soon.
                </Typography.P>
              )}
              {isRequestorGroup && (
                <Typography.P textAlign="left">
                  FTE{" "}
                  <Badge variant="success" mx={1}>
                    Demand
                  </Badge>{" "}
                  request are entered on this screen, which we will walk through
                  soon.
                </Typography.P>
              )}
            </Spacer>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-planner-grouping-header",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              Your <GroupTypeBadge group={group} /> is the parent of this
              Planner Panel
            </Typography.H4>
            <Typography.P textAlign="left">
              Clicking anywhere on a row will expand or collapse any visible
              children of this {group.type}.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-planner-objectives-header",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              Click here to show your <GroupTypeBadge group={group} />
              Objectives.
            </Typography.H4>
            <Typography.P textAlign="left">
              Objectives need to be associated to children of the{" "}
              <GroupTypeBadge group={group} />
              by clicking the <strong>Link Objectives</strong> button in their
              planner row.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-planner-tags-header",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              Click here to show your <GroupTypeBadge group={group} />
              Tags.
            </Typography.H4>
            <Typography.P textAlign="left">
              Here you can view and edit tags associated to this{" "}
              <GroupTypeBadge group={group} />.
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-notification-dot",
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="left">
              The status dots position
            </Typography.H4>
            <Typography.P textAlign="left">
              <strong>The status dots provide guidance</strong>, hovering over a
              status dot will give further information below is a general
              indicator:
            </Typography.P>
            <Typography.P textAlign="left">
              <NotificationDot
                notification={{
                  status: NOTIFICATION_STATUS.INFO,
                  message: "An example informational notification",
                }}
              />
              green - everything is up to date
            </Typography.P>
            <Typography.P textAlign="left">
              <NotificationDot
                notification={{
                  status: NOTIFICATION_STATUS.WARN,
                  message: "An example cautionary notification",
                }}
              />
              Yellow - this is a caution, example FTE values have been saved but
              not submitted
            </Typography.P>
            <Typography.P textAlign="left">
              <NotificationDot
                notification={{
                  status: NOTIFICATION_STATUS.DANGER,
                  message: "An example notification requiring action",
                }}
              />
              Red - There is something that requires actioning or attention
            </Typography.P>
          </Spacer>
        ),
      },
      {
        target: ".guided-tour-allocation-forecast-panel",
        afterAction: openFirstLineItem,
        content: (
          <Spacer my={3}>
            <Typography.H4 textAlign="center">
              <strong>But wait, there&apos;s more.</strong>
            </Typography.H4>
            {viewMode === GROUPED_BY_SUPPLY && (
              <Typography.P textAlign="left">
                Try clicking and expanding some <strong>{supplyLabel}s</strong>{" "}
                to understand how to enter a plan.
              </Typography.P>
            )}
            {viewMode === GROUPED_BY_DEMAND && (
              <Typography.P textAlign="left">
                Try clicking and expanding some <strong>{demandLabel}s</strong>{" "}
                to understand how to enter a plan.
              </Typography.P>
            )}
            <Typography.Small textAlign="left">
              We love feedback, please leave any comments or suggestions in the
              help button below.
            </Typography.Small>
          </Spacer>
        ),
      },
    ],
  };
};

export default tourOptions;
