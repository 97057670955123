import { includes, get } from "lodash";

import { isLineOfBusiness } from "src/util/customerConfig";

const getAllocationVisibility = ({
  group = {},
  config,
  activeAllocationProject,
}) => {
  const { type, isDeleted } = group;
  const isAllocationsClosed = !activeAllocationProject;
  const isDeniedAccess = !get(
    group,
    "permissions.areGroupsInAccessGroupIdsHierarchy"
  );
  if (isAllocationsClosed || isDeniedAccess || isDeleted) {
    return {
      requestNumbers2: false,
      requestPeople: false,
      approveNumbers2: false,
      individualAllocations: false,
      forecastNumbers: false,
      isAllocationsClosed,
      isDeniedAccess,
      isDeleted,
    };
  }
  const {
    requestorTypes,
    approverTypes,
    disableGroupPlanner,
    disableGroupForecast,
    disableGroupTeamBuilder,
  } = config.allocation;
  const isRequestorTypes = includes(requestorTypes, type);
  const isLineOfBusinessTypes = isLineOfBusiness(config.groupTypes, group);
  const isApproverTypes =
    includes(approverTypes, type) && !isLineOfBusinessTypes;
  return {
    requestNumbers2:
      !disableGroupPlanner && (isRequestorTypes || isLineOfBusinessTypes),
    requestPeople: !disableGroupTeamBuilder && isRequestorTypes,
    approveNumbers2: !disableGroupPlanner && isApproverTypes,
    individualAllocations: !disableGroupTeamBuilder && isApproverTypes,
    forecastNumbers:
      !disableGroupForecast && (isRequestorTypes || isApproverTypes),
    isAllocationsClosed,
    isDeniedAccess,
  };
};

export default getAllocationVisibility;
