import React from "react";
import PropTypes from "prop-types";
import { isNil, get, find } from "lodash";
import { useQuery } from "@apollo/client";

import Auth0UserProps from "src/custom-prop-types/auth0user";

import { ERROR_CODES } from "src/components/ErrorPage/detectErrorType";

import useRestricted from "src/app/Restricted/useRestricted";
import { ADMIN } from "src/app/Restricted/consts";
import useUserRole from "src/app/Restricted/useUserRole";
import { useWorkspaceId } from "../ApolloSettingsContext";
import {
  getWorkspaces as getWorkspacesQuery,
  getWorkspace as getWorkspaceQuery,
  getActiveWorkspace as getActiveWorkspaceQuery,
} from "../context.graphql";

import { Provider, CHALLENGE_NONE } from "./WorkspaceContext";
import useWorkspaceWithUpdatedTagTypesConfig from "./useWorkspaceWithUpdatedTagTypesConfig";
import useDecidingWorkspaceAndChallenge from "./useDecidingWorkspaceAndChallenge";

const throwErrorIfSelectedWorkspaceDoesNotExist = (
  workspacesData,
  selectedWorkspaceId
) => {
  const workspaces = get(workspacesData, "workspaces", []);
  if (!find(workspaces, { id: selectedWorkspaceId })) {
    throw new Error(ERROR_CODES.WORKSPACE_NOT_FOUND);
  }
};

const WorkspaceContextProvider = (props) => {
  const { children } = props;

  const [isRestricted] = useRestricted();
  const userRole = useUserRole();

  const isAdmin = userRole === ADMIN;

  const selectedWorkspaceId = useWorkspaceId();

  const {
    data: workspacesData,
    loading: loadingWorkspaces,
    error: workspacesQueryError,
  } = useQuery(getWorkspacesQuery);

  if (
    !loadingWorkspaces &&
    workspacesData &&
    selectedWorkspaceId &&
    !workspacesQueryError
  ) {
    throwErrorIfSelectedWorkspaceDoesNotExist(
      workspacesData,
      selectedWorkspaceId
    );
  }

  const {
    data: activeWorkspaceData,
    loading: loadingActiveWorkspace,
    error: activeWorkspaceDataError,
  } = useQuery(getActiveWorkspaceQuery, {
    skip: !isNil(selectedWorkspaceId),
  });

  const shouldGetWorkspaceReporting = !isRestricted;
  const {
    data: selectedWorkspaceData,
    loading: loadingSelectedWorkspace,
    error: selectedWorkspaceDataError,
  } = useQuery(getWorkspaceQuery, {
    variables: {
      id: selectedWorkspaceId,
      includeReportingInfraProvisioned: shouldGetWorkspaceReporting,
    },
    skip: isNil(selectedWorkspaceId),
  });

  const isWorkspaceDataLoading =
    loadingWorkspaces || loadingActiveWorkspace || loadingSelectedWorkspace;

  const decision = useDecidingWorkspaceAndChallenge(
    workspacesData,
    activeWorkspaceData,
    selectedWorkspaceData,
    selectedWorkspaceId,
    isAdmin
  );

  const workspaceWithUpdatedTagTypesConfig =
    useWorkspaceWithUpdatedTagTypesConfig(decision?.workspace);

  const error =
    activeWorkspaceDataError ||
    selectedWorkspaceDataError ||
    workspacesQueryError;

  const workspaces = get(workspacesData, "workspaces", []);

  const values = {
    challenge: decision?.challenge || CHALLENGE_NONE,
    workspace: workspaceWithUpdatedTagTypesConfig || null,
    error,
    workspaces,
    isWorkspaceDataLoading,
    isReportingResourceProvisioned:
      selectedWorkspaceData?.isReportingInfraProvisioned,
  };

  return <Provider {...values}>{children}</Provider>;
};

WorkspaceContextProvider.propTypes = {
  user: Auth0UserProps,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default WorkspaceContextProvider;
