import {
  chain,
  concat,
  filter,
  find,
  forEach,
  get,
  keyBy,
  reduce,
} from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

import { TAG_ATTRIBUTE_ACTION } from "src/consts/tags";

const getInvalidFields = (
  tagValue,
  tagType,
  includeEntityAttributes = false
) => {
  const {
    others = [],
    values = [],
    entityTag = [],
  } = get(tagType, "attributes") ?? {};
  const attrs = concat(others, values);
  if (includeEntityAttributes) {
    attrs.push(...entityTag);
  }
  const mandatortyAttributes = chain(attrs)
    .filter((attr) => attr.action === TAG_ATTRIBUTE_ACTION.MANDATORY)
    .map("id")
    .value();

  const fieldsWithDefaultValues = keyBy(
    filter(attrs, (attr) => {
      return !!attr.defaultValue;
    }),
    "id"
  );

  return filter(mandatortyAttributes, (id) => {
    if (
      tagValue?.attributes &&
      Object.prototype.hasOwnProperty.call(tagValue?.attributes, id)
    ) {
      if (!tagValue.attributes[id]) {
        return true;
      }
    } else {
      if (fieldsWithDefaultValues[id]) {
        return false;
      }
      return true;
    }
    return false;
  });
};

const useTagFormValidator = ({
  fields,
  tagType,
  tagValue,
  includeEntityAttributes = false,
}) => {
  const [fieldToBeFocused, setFieldToBeFocused] = useState(null);
  const [invalidFields, setInvalidFields] = useState([]);
  const [currentTagType, setCurrentTagType] = useState(tagType);
  const currentTag = useRef(tagValue);
  const initialData = useRef(fields || {});
  const fieldsInteracted = useRef({});

  const clearFieldToBeFocused = () => {
    setFieldToBeFocused(null);
  };

  const clearStates = useCallback(() => {
    setFieldToBeFocused(null);
    setInvalidFields([]);
    currentTag.current = tagValue;
    initialData.current = fields || {};
    fieldsInteracted.current = {};
  }, [fields, tagValue]);

  const onChangeTagType = (newTagType) => {
    setCurrentTagType(newTagType);
    clearStates();
  };

  useEffect(() => {
    if (currentTag.current && currentTag.current?.id !== tagValue?.id) {
      clearStates();
    }
  }, [clearStates, fields, currentTagType, tagValue]);

  const onFieldUpdate = (newFields) => {
    setFieldToBeFocused(null);
    const notValidFields = getInvalidFields(
      { attributes: newFields },
      currentTagType,
      includeEntityAttributes
    );
    forEach(newFields, (value, key) => {
      if (
        !fieldsInteracted.current[key] &&
        (!initialData?.current[key] || value !== initialData?.current[key])
      ) {
        fieldsInteracted.current[key] = true;
      }
    });
    setInvalidFields(notValidFields);
  };

  const getNextFieldTobeFocused = (notVaildFields) => {
    return find(
      [
        ...(currentTagType?.attributes?.values || []),
        ...(currentTagType?.attributes?.others || []),
        ...((includeEntityAttributes &&
          currentTagType?.attributes?.entityTag) ||
          []),
      ],
      (field) => find(notVaildFields, (id) => id === field.id)
    );
  };

  const onFormSave = (newTagValue) => {
    const notVaildFields = getInvalidFields(
      newTagValue,
      currentTagType,
      includeEntityAttributes
    );
    fieldsInteracted.current = reduce(
      notVaildFields,
      (acc, next) => {
        acc[next] = true;
        return acc;
      },
      {}
    );
    setFieldToBeFocused(getNextFieldTobeFocused(notVaildFields));
    setInvalidFields(notVaildFields);
    return notVaildFields;
  };

  const fieldsToShowInvalidity = reduce(
    invalidFields,
    (acc, next) => {
      if (fieldsInteracted?.current?.[next]) {
        acc[next] = true;
      }
      return acc;
    },
    {}
  );

  return {
    onFieldUpdate,
    onFormSave,
    fieldsToShowInvalidity,
    fieldToBeFocused,
    clearFieldToBeFocused,
    fieldsInteracted,
    invalidFields,
    onChangeTagType,
  };
};

export { useTagFormValidator, getInvalidFields };
