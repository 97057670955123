import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

// the useLazyQuery hook from apollo client does not return a promise,
// so creating a wrapper on client.query which make promise chaining easier.
// TODO: may be add fetchPolicy to the params?
const useLazyLoad = ({ query }) => {
  const client = useApolloClient();

  const load = useCallback(
    (variables) => {
      return new Promise((resolve, reject) => {
        client
          .query({
            query,
            variables,
          })
          .then(({ data, error }) => {
            if (error) {
              reject(error);
              return;
            }

            resolve(data);
          })
          .catch((err) => reject(err));
      });
    },
    [client, query]
  );

  return load;
};

export default useLazyLoad;
