import PropTypes from "prop-types";
import React from "react";

import { CustomMenu } from "./Menu.styled";

const Menu = ({ children, innerRef, innerProps }) => (
  <CustomMenu ref={innerRef} {...innerProps}>
    {children}
  </CustomMenu>
);

Menu.propTypes = {
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  children: PropTypes.node,
};

export default Menu;
