import { Link } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import useDefaultRoutePath from "src/app/Restricted/useDefaultRoutePath";

const HomeLink = ({ children, item }) => {
  const defaultRoutePath = useDefaultRoutePath();
  return (
    <Link to={defaultRoutePath} aria-label={item.name}>
      {children}
    </Link>
  );
};
HomeLink.propTypes = {
  item: PropTypes.object,
  children: PropTypes.node,
};

export default HomeLink;
