export default ({ allocationProject, columnType }) => {
  if (allocationProject) {
    return {
      id: `${allocationProject.id}-${columnType}`,
      allocationProjectId: allocationProject.id,
      allocationProject,
      columnType,
      notification: {},
    };
  }
  return {
    id: columnType,
    allocationProjectId: null,
    allocationProject: null,
    columnType,
    notification: {},
  };
};
