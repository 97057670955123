import { isEmpty } from "lodash";
import { Avatar } from "orcs-design-system";
import styled from "styled-components";

import getCustomerConfig from "src/config/customerConfig";

let gradients = null;

export const setGradients = (config) => {
  if (config && !isEmpty(config.gradients)) {
    gradients = config.gradients;
  }
};

export const getGradients = (customer) => {
  if (gradients) {
    return gradients;
  }

  return getCustomerConfig(customer).gradients;
};

export const placeholderGradient = `linear-gradient(
  to right,
  rgb(246, 247, 248) 0%,
  rgb(237, 238, 241) 20%,
  rgb(246, 247, 248) 40%,
  rgb(246, 247, 248) 100%
)`;

export const AvatarWithGradient = styled(Avatar)`
  > div:first-child {
    background-image: ${({ bgGradient, loading = false }) =>
      bgGradient && !loading
        ? bgGradient
        : `linear-gradient(
      to right,
      rgb(246, 247, 248) 0%,
      rgb(237, 238, 241) 20%,
      rgb(246, 247, 248) 40%,
      rgb(246, 247, 248) 100%
    )`};
    background-size: cover;
    background-position: center center;

    > span {
      color: #fff;
    }
  }
`;
