import "@fortawesome/fontawesome-pro/css/all.css";
import {
  faArrowUp,
  faArrowDown,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import {
  faArrowUp as farArrowUp,
  faArrowDown as farArrowDown,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faUser,
  faUsers,
  faLink,
  faTrashAlt,
  faArrowsAlt,
  faQuestionCircle,
  faExclamationTriangle,
  faExclamationCircle,
  faTransgenderAlt,
  faMapMarkerAlt,
  faBuilding,
  faGlobeAsia,
  faChartPie,
  faAddressCard,
  faLayerGroup,
  faSitemap,
  faSearch,
  faEye,
  faUserPlus,
  faUserTimes,
  faPencil,
  faBell,
  faCheckCircle,
  faClock,
  faFileImport,
  faFilePlus,
  faFilter,
  faHistory,
  faCog,
  faTrophy,
  faAngleLeft,
  faTimes,
  faUndo,
  faRedo,
  faStopwatch,
  faFile,
  faCalendarDay,
  faIdBadge,
  faUserClock,
  faLevelUp,
  faSyncAlt,
  faBox,
  faDatabase,
  faUsersClass,
  faChartNetwork,
  faShareSquare,
  faInfoCircle,
  faTasksAlt,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faCrosshairs,
  faAtomAlt,
  faThumbsUp,
  faThumbsDown,
  faBadgeCheck,
  faPlayCircle,
  faCommentAltLines,
  faBold,
  faItalic,
  faUnderline,
  faCode,
  faH1,
  faH2,
  faQuoteLeft,
  faListOl,
  faListUl,
  faEdit,
  faChartScatter,
  faEyeSlash,
  faCalendarAlt,
  faClone,
  faClipboardCheck,
  faLock as farLock,
  faHome as farHome,
  faPeopleArrows as farPeopleArrows,
  faExternalLink as farExternalLink,
  faArrowSquareRight as farArrowSquareRight,
  faTag as farTag,
  faBullseye as farBullseye,
  faBullseyeArrow as farBullseyeArrow,
  faSparkles as farSparkles,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faLock,
  faPlus,
  faEquals,
  faShare,
  faMinus,
  faCheck,
  faCheckCircle as fasCheckCircle,
  faTimesCircle as fasTimesCircle,
  faPercentage as fasPercentage,
  faExclamationCircle as fasExclamationCircle,
  faDotCircle,
  faSave,
  faDownload,
  faChartPieAlt,
  faExternalLinkAlt,
  faCloudUpload,
  faCommentAlt,
  faUserFriends,
  faExpandAlt,
  faRedo as fasRedo,
  faUsers as fasUsers,
  faDatabase as fasDatabase,
  faUser as fasUser,
  faTimes as fasTimes,
  faArrowUp as fasArrowUp,
  faArrowDown as fasArrowDown,
  faArrowAltLeft as fasArrowAltLeft,
  faAngleRight as fasAngleRight,
  faExclamationTriangle as fasExclamationTriangle,
  faUserTimes as fasUserTimes,
  faChevronRight as fasChevronRight,
  faChevronLeft as fasChevronLeft,
  faChevronDoubleRight as fasChevronDoubleRight,
  faChevronDoubleLeft as fasChevronDoubleLeft,
  faUserPlus as fasUserPlus,
  faUserMinus as fasUserMinus,
  faUserEdit as fasUserEdit,
  faCalendarAlt as fasCalendarAlt,
  faUsersClass as fasUsersClass,
  faLayerGroup as fasLayerGroup,
  faPencil as fasPencil,
  faBell as fasBell,
  faExclamation as fasExclamation,
  faInfoCircle as fasInfoCircle,
  faInfo as fasInfo,
  faQuestion as fasQuestion,
  faQuestionCircle as fasQuestionCircle,
  faUserSecret,
  faCircleNotch as fasCircleNotch,
  faPlug as fasPlug,
  faAddressBook as fasAddressBook,
  faUndoAlt as fasUndoAlt,
  faTag as fasTag,
  faSearchDollar as fasSearchDollar,
  faCloudDownload as fasCloudDownload,
  faUpload as fasUpload,
  faLongArrowAltRight as fasLongArrowAltRight,
  faLongArrowAltLeft as fasLongArrowAltLeft,
  faPen as fasPen,
  faCopy as fasCopy,
  faChevronDown as fasChevronDown,
  faChevronUp as fasChevronUp,
  faLocation as fasLocation,
  faBullseye as fasBullseye,
  faBullseyeArrow as fasBullseyeArrow,
  faMobileAlt as fasMobileAlt,
  faEnvelope as fasEnvelope,
  faClock as fasClock,
  faCheckSquare as fasCheckSquare,
  faAngleLeft as fasAngleLeft,
  faArrowLeft as fasArrowLeft,
  faArrowRight as fasArrowRight,
  faPoll as fasPoll,
  faLink as fasLink,
} from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { createGlobalStyle } from "styled-components";
import {
  facMicrosoftTeams,
  facMicrosoftTeamsStyles,
} from "./custom-svgs/facMicrosoftTeams";

export const GlobalCustomSVGStyles = createGlobalStyle`
${facMicrosoftTeamsStyles}

`;

const setupFontAwesome = () => {
  library.add(
    // fal
    faArrowUp,
    faArrowDown,
    faChevronRight,
    // far
    faLongArrowAltRight,
    faLongArrowAltLeft,
    faUsersClass,
    farArrowUp,
    farArrowDown,
    faAngleRight,
    faAngleUp,
    faAngleDown,
    faUser,
    faUsers,
    faLink,
    faTrashAlt,
    faArrowsAlt,
    faQuestionCircle,
    faExclamationTriangle,
    faExclamationCircle,
    faTransgenderAlt,
    faMapMarkerAlt,
    faBuilding,
    faGlobeAsia,
    faChartPie,
    faAddressCard,
    faLayerGroup,
    faSitemap,
    faSearch,
    faEye,
    faUserPlus,
    faUserTimes,
    faPencil,
    faBell,
    faCheckCircle,
    faClock,
    faFileImport,
    faFilePlus,
    faFilter,
    faHistory,
    faCog,
    faTrophy,
    faAngleLeft,
    faTimes,
    faUndo,
    faRedo,
    faStopwatch,
    faFile,
    faCalendarDay,
    faIdBadge,
    faUserClock,
    faLevelUp,
    faSyncAlt,
    faBox,
    faDatabase,
    faChartNetwork,
    faAtomAlt,
    faThumbsUp,
    faThumbsDown,
    faBadgeCheck,
    faPlayCircle,
    faCommentAltLines,
    faBold,
    faItalic,
    faUnderline,
    faCode,
    faH1,
    faH2,
    faQuoteLeft,
    faListOl,
    faListUl,
    faEdit,
    faChartScatter,
    faEyeSlash,
    faCalendarAlt,
    faClone,
    faClipboardCheck,
    farLock,
    farHome,
    farPeopleArrows,
    farExternalLink,
    farArrowSquareRight,
    farTag,
    farBullseye,
    farBullseyeArrow,
    farSparkles,
    // fas
    fasDatabase,
    fasUser,
    faLock,
    faPlus,
    faEquals,
    faMinus,
    faCheck,
    faSave,
    fasTimes,
    faShare,
    faDotCircle,
    faDownload,
    fasArrowUp,
    fasArrowDown,
    fasArrowAltLeft,
    fasAngleRight,
    faChartPieAlt,
    faUserFriends,
    fasRedo,
    fasUsers,
    fasExclamationTriangle,
    faShareSquare,
    faInfoCircle,
    fasInfo,
    faTasksAlt,
    faExternalLinkAlt,
    faCrosshairs,
    faCloudUpload,
    faCommentAlt,
    fasUserTimes,
    fasCheckCircle,
    fasTimesCircle,
    fasPercentage,
    fasExclamationCircle,
    fasChevronRight,
    fasChevronLeft,
    fasChevronDoubleRight,
    fasChevronDoubleLeft,
    fasUserPlus,
    fasUserMinus,
    fasUserEdit,
    faExpandAlt,
    fasCalendarAlt,
    fasUsersClass,
    fasLayerGroup,
    fasPencil,
    faUserSecret,
    fasExclamation,
    fasInfoCircle,
    fasQuestion,
    fasQuestionCircle,
    fasBell,
    fasCircleNotch,
    fasPlug,
    fasAddressBook,
    fasUndoAlt,
    fasTag,
    fasSearchDollar,
    fasCloudDownload,
    fasUpload,
    fasLongArrowAltRight,
    fasLongArrowAltLeft,
    fasPen,
    fasCopy,
    fasChevronDown,
    fasChevronUp,
    fasLocation,
    fasBullseye,
    fasBullseyeArrow,
    fasMobileAlt,
    fasEnvelope,
    fasClock,
    fasCheckSquare,
    fasAngleLeft,
    fasArrowLeft,
    fasArrowRight,
    fasPoll,
    fasLink,
    // Custom
    facMicrosoftTeams
  );
};

export default setupFontAwesome;
