import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import { Spacer } from "orcs-design-system";

import { useCheckReportingResourceProvisioned } from "src/contexts/global/WorkspaceContext/WorkspaceContext";
import AppSwitchLink from "./AppSwitchLink";
import shouldShowReportsLink from "./utils/reports.utils";

const InternalAppLinks = ({ user, roles, workspace }) => {
  const isReportingInfraProvisioned = useCheckReportingResourceProvisioned();
  const reportsURL = get(workspace, "config.tenantConfig.reportsUrl");

  const showReportsLink = shouldShowReportsLink({
    workspace,
    isReportingInfraProvisioned,
    user,
    roles,
  });

  return (
    <Spacer mb="r">
      {showReportsLink && (
        <AppSwitchLink
          label="Reports"
          description="Reporting dashboard shows you all insights and numbers from your data"
          href={reportsURL}
        />
      )}
    </Spacer>
  );
};

InternalAppLinks.propTypes = {
  user: PropTypes.object,
  workspace: PropTypes.object,
  roles: PropTypes.array,
};

export default InternalAppLinks;
