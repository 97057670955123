import { createMacroAllocationId } from "src/allocation/util/macroAllocation";
import { getForecastReadonlyFlag } from "src/allocation/util/allocationProject";
import { calculateTotalCost } from "src/allocation/util/budgetAndCost";
import newCell from "../newCell";
import getDefaultValueFromPreviousCell from "../../getDefaultValueFromPreviousCell";

export default (
  lineItem,
  previousCell,
  targetGroupId,
  sourceGroupId,
  column,
  rootGroup
) => {
  const { allocationProject } = column;
  const { dailyRate } = lineItem;
  const {
    id: allocationProjectId,
    isActive,
    enableRequirementInput,
    workingDays,
  } = allocationProject;
  const isReadonly = getForecastReadonlyFlag(allocationProject, rootGroup);
  const defaultedValue = getDefaultValueFromPreviousCell(
    previousCell,
    allocationProject
  );
  const totalCost = isActive
    ? null
    : calculateTotalCost({ value: defaultedValue, dailyRate, workingDays });

  const macroAllocationId = createMacroAllocationId(
    allocationProjectId,
    sourceGroupId,
    targetGroupId,
    lineItem.type
  );
  return newCell({
    lineItem,
    column,
    columnType: column.columnType,
    id: macroAllocationId,
    macroAllocationId,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    isStatic: isActive,
    isEditable: !isActive && !isReadonly,
    isRequirementInputEnabled: enableRequirementInput,
    defaultedValue,
    totalCost,
    dailyRate,
    workingDays,
  });
};
