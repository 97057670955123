import { values, keyBy, forEach } from "lodash";

import {
  getForecastColumns,
  getForecastMacroAllocations,
  setGroupingsWithActualConstraints,
  setGroupingsWithDefaultConstraints,
  setGroupingsWithForecastCells,
  setGroupingsWithForecastDefaultCells,
  setCalculatedSumTotals,
  setGroupingsTotalCost,
  setNotifications,
  pruneEmptyGroupings,
  buildGroupingLookup,
  buildLineItemLookup,
  buildCellLookup,
  buildConstraintCellLookup,
  hashGrouping,
  setGroupingsBudget,
} from "../util";

import buildGroupings from "./buildGroupings/bySupply";
import rebuildWithExtraDemandGroupings from "./buildGroupings/rebuildWithExtraDemandGrouping";
import setGroupingsWithLineItems from "./setLineItems/demandLineItems";
import setGroupingsWithIndirectForecastLineItems from "./setLineItems/indirectForecastLineItems";

const buildGroupedBySupply = ({
  rootGroup,
  data,
  insertExtraDemandGroupings = false,
  hideHiddenTeams,
  showObjectives,
  groupTypes,
  shouldHash,
  isBudgetEnabled,
  overBudgetLimit,
  underBudgetLimit,
}) => {
  const allocationProjectLookup = keyBy(data.allocationProjects, "id");

  const columns = getForecastColumns(data.allocationProjects);
  const macroAllocations = getForecastMacroAllocations(
    data.macroAllocations,
    data.allocationProjects,
    hideHiddenTeams
  );

  const groupings = buildGroupings({
    rootGroup,
    macroAllocations,
    descendantSupplyGroups: data.descendantSupplyGroups,
    roleAreaGroups: data.roleAreaGroups,
    relatedSupplyGroups: data.relatedSupplyGroups,
    hideHiddenTeams,
    showObjectives,
    groupTypes,
    columnCount: columns.length,
    isBudgetEnabled,
  });

  // Building demand line items, dailyRate should inherit from parentGrouping
  setGroupingsWithLineItems({
    groupings,
    macroAllocations,
    columnCount: columns.length,
    groupTypes,
    canRename: !rootGroup.isSource,
    canHide: !rootGroup.isSource,
    isBudgetEnabled,
  });
  setGroupingsWithForecastCells({
    groupings,
    macroAllocations,
    columns,
    groupedByDemand: false,
    rootGroup,
  });
  setGroupingsWithForecastDefaultCells(groupings, columns, false, rootGroup);

  if (insertExtraDemandGroupings) {
    // extra business req: add extra hierarchy groups for demand line items.
    rebuildWithExtraDemandGroupings({
      groupings,
      hideHiddenTeams,
      showObjectives,
      groupTypes,
      isBudgetEnabled,
    });
  }

  if (isBudgetEnabled) {
    setGroupingsBudget(groupings);
    setGroupingsTotalCost(groupings, overBudgetLimit, underBudgetLimit);
  }

  setCalculatedSumTotals(groupings, columns);
  setGroupingsWithActualConstraints(
    groupings,
    data.constraints,
    allocationProjectLookup,
    columns
  );
  setGroupingsWithDefaultConstraints(
    groupings,
    allocationProjectLookup,
    columns
  );
  setNotifications(groupings, columns);
  // pruneEmptyLineItems(groupings);
  pruneEmptyGroupings(groupings, isBudgetEnabled);

  // Add indirect placeholder forecast lineitems for requestor side
  if (rootGroup.isDemand) {
    setGroupingsWithIndirectForecastLineItems({
      groupings,
      columns,
      groupTypes,
      rootGroup,
      isBudgetEnabled,
    });
  }

  const columnLookup = keyBy(columns, "id");
  const groupingLookup = buildGroupingLookup(groupings);
  const lineItemLookup = buildLineItemLookup(values(groupingLookup));
  const cellLookup = buildCellLookup(values(lineItemLookup));
  const constraintCellLookup = buildConstraintCellLookup(
    values(groupingLookup)
  );
  // Hash groupings in case anything changed
  if (shouldHash) {
    forEach(groupings, hashGrouping);
  }

  return {
    columns,
    groupings,
    // lookup objects: for when we need fast access to objects in the tree
    lookups: {
      allocationProjectLookup,
      columnLookup,
      groupingLookup,
      lineItemLookup,
      cellLookup,
      constraintCellLookup,
    },
  };
};

export default buildGroupedBySupply;
