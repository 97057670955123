/* eslint-disable no-param-reassign */
import { reduce } from "lodash";

export const expandGroupings = (groupings) => {
  const expandedGroupings = reduce(
    groupings,
    (prev, g) => {
      prev[g.id] = true;
      return prev;
    },
    {}
  );

  return expandedGroupings;
};

export const expandIds = (ids, mapping = {}) => {
  return reduce(
    ids,
    (prev, groupId) => {
      prev[groupId] = true;
      return prev;
    },
    mapping
  );
};

export const expandParent = (grouping) => {
  const ids = [grouping.id];
  let { parent } = grouping;
  while (parent) {
    ids.push(parent.id);
    parent = parent.parent;
  }
  return ids;
};

export const tryPush = (result, property, value) => {
  if (result[property]) {
    result[property].push(value);
  } else {
    result[property] = [value];
  }
};
