import { themeGet } from "@styled-system/theme-get";

export const noOptionsLabel = "Not sure what to search for?";
export const noOptionsLinkLabel = "Click here to go to explore view";
export const standardNoOptionsLabel = "Start typing to search..";
export const searchErrorLabel =
  "Sorry, we are unable to retrieve search results at this time";
export const placeholderText = "Search...";

export const heading = {
  people: "PEOPLE",
  teams: "TEAMS",
  tags: "TAGS",
};

export const reactSelectStyles = (theme, paddingRight = 0, isLoading) => ({
  // when creating our custom option and passing inner ref and inherited select props
  // opacity is set to 0 on option click
  // bug: https://github.com/JedWatson/react-select/issues/3068
  input: (provided) => ({
    ...provided,
    opacity: "1 !important",
    input: {
      opacity: "1 !important",
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    flexDirection: "row-reverse",
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    position: "absolute",
    right: `${paddingRight}px`,
  }),
  control: (provided, state) => ({
    ...provided,
    position: "relative",
    flexDirection: "row-reverse",
    boxShadow: state.isFocused
      ? `0 0 0 2px ${themeGet("colors.primary")({ theme })}`
      : "0",
    "&:hover": {
      borderColor: themeGet("colors.primary")({ theme }),
    },
    cursor: "text",
    borderColor: state.isFocused
      ? themeGet("colors.primary")({ theme })
      : themeGet("colors.black")({ theme }),
    outline: state.isFocused
      ? themeGet("colors.primary")({ theme })
      : themeGet("colors.black")({ theme }),
    backgroundColor: themeGet("colors.white")({ theme }),
    color: themeGet("colors.greyDarkest")({ theme }),
    borderRadius: themeGet("radii.2")({ theme }),
    transition: "all 200ms ease-in-out",
    paddingRight: isLoading
      ? `${paddingRight + 40}px`
      : `${paddingRight + 10}px`,
  }),
  group: (provided) => ({
    ...provided,
    padding: "0",
  }),
  loadingMessage: (provided) => ({
    ...provided,
    padding: themeGet("space.s")({ theme }),
    paddingTop: themeGet("space.r")({ theme }),
    margin: "0",
    color: themeGet("colors.greyLight")({ theme }),
  }),
  groupHeading: (provided) => ({
    ...provided,
    padding: "0",
    margin: "0",
  }),
});
