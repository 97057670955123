// eslint-disable-next-line import/prefer-default-export
export const copywriting = {
  directMember: "Member",
  member: "Member",
  nonBadgedMembers: "Not currently in a",
  defaultGroupType: "Team",
  addMembersToTeamButton: "Add members to team",
  childGroup: "Team",
  associatedGroup: "Associated Team",
};
