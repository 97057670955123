import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Box, Flex } from "orcs-design-system";
import OutdatedBrowserMessage from "../OutdatedBrowserMessage";
import Logo from "../Logo";

const Background = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  > div {
    min-height: 100vh;
    height: 100%;
  }
`;

const AuthLayout = (props) => {
  const {
    children,
    customWidth = "50%",
    noPanel = false,
    small = false,
  } = props;
  return (
    <>
      <OutdatedBrowserMessage floating centered />
      <Background>
        {!noPanel && (
          <Flex
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              m="xl"
              bg="white"
              borderRadius={2}
              shadow="default"
              width={small ? "320px" : customWidth}
            >
              <Box bg="greyDarkest" p="r" borderRadius="6px 6px 0 0">
                <Flex justifyContent="center" alignItems="center">
                  <Logo />
                </Flex>
              </Box>
              <Box p="r">{children}</Box>
            </Box>
          </Flex>
        )}
      </Background>
    </>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  customWidth: PropTypes.string,
  noPanel: PropTypes.bool,
  small: PropTypes.bool,
};

export default AuthLayout;
