import React from "react";
import PropTypes from "prop-types";
import themeGet from "@styled-system/theme-get";
import { StyledLink } from "orcs-design-system";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const CustomStyledLink = styled(StyledLink)`
  transition: background-color 200ms ease-in-out;
  border-radius: ${themeGet("radii.2")};
  padding: ${themeGet("space.s")};
  &:hover,
  &:focus {
    text-decoration: none;
    background-color: ${themeGet("colors.primary10")};
  }
`;

const SettingsLink = ({ to, id, indented, children }) => {
  const location = useLocation();
  // Check if the current location matches the 'to' prop
  const isActive = location.pathname === to;

  // Render the design system's Link component with the added style
  return (
    <CustomStyledLink
      id={id}
      to={to}
      display="block"
      my="xxs"
      fontSize={indented ? "1" : "2"}
      ml={indented ? "s" : "0"}
      fontWeight={isActive ? "bold" : "normal"}
    >
      {children}
    </CustomStyledLink>
  );
};

SettingsLink.propTypes = {
  to: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  indented: PropTypes.bool,
};

export default SettingsLink;
