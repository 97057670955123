import { setGroupingWithNotifications, hashGrouping } from "./util";

const updateModelWithObjectivesVerified = (dynamicModel, groupId) => {
  const {
    lookups: { groupingLookup },
  } = dynamicModel;

  const grouping = groupingLookup[groupId];

  if (!grouping) {
    return dynamicModel;
  }

  grouping.isObjectivesVerified = true;

  setGroupingWithNotifications(grouping, dynamicModel.columns, {
    recalcChildren: false,
    recalcParents: true,
  });

  hashGrouping(grouping, {
    rehashChildren: false,
    rehashParents: true,
  });

  return dynamicModel;
};

export default updateModelWithObjectivesVerified;
