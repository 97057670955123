import mainQuery from "./mainQueryReducer/initialState";
import model from "./modelReducer/initialState";
import lookupData from "./lookupDataReducer/initialState";
import userInteraction from "./userInteractionReducer/initialState";

export default ({
  group,
  workspace,
  viewMode,
  pageMode,
  targetGroupSearchParamId,
  sourceGroupSearchParamId,
  previouslyExpandedGroupIds,
  previouslyExpandedLineItemsGroupIds,
  hideHiddenTeams,
  showObjectives,
  withPerspective,
  activeAllocationProject,
}) => ({
  pageMode,
  activeAllocationProject,
  previouslyExpandedGroupIds,
  mainQuery: mainQuery({
    group,
    workspace,
    pageMode,
    queryWithPerspective: withPerspective,
    viewMode,
    activeAllocationProject,
  }),
  model: model({
    group,
    workspace,
    pageMode,
    viewMode,
    hideHiddenTeams,
    showObjectives,
  }),
  lookupData: lookupData(group, workspace),
  userInteraction: userInteraction(
    viewMode,
    targetGroupSearchParamId,
    sourceGroupSearchParamId,
    previouslyExpandedGroupIds,
    previouslyExpandedLineItemsGroupIds
  ),
});
