import { Box, Small, StyledLink } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import OptionBadge from "src/components/OptionBadge";
import OptionBadgeWithActions from "src/components/OptionBadgeWithActions";
import OptionTagBadge from "src/components/OptionTagBadge";
import { OptionValuePropType } from "src/custom-prop-types/search";

const WithReactSelectProps = ({ children, innerRef, innerProps }) => (
  <div ref={innerRef} {...innerProps}>
    {children}
  </div>
);

// custom react-select option component
const Option = ({
  data,
  isLoading,
  innerProps,
  innerRef,
  groupTypes,
  workspace,
  selectedString,
}) => {
  const { value } = data;
  const { template } = value;

  const getLink = (linkValue) => (
    <WithReactSelectProps innerRef={innerRef} innerProps={innerProps}>
      <Box p="s" bg="transparent" style={{ textAlign: "center" }}>
        <Small display="block">
          Showing {linkValue.found} of {linkValue.count} results.{" "}
          <StyledLink to={linkValue.url} fontSize="1">
            View more results
          </StyledLink>
        </Small>
      </Box>
    </WithReactSelectProps>
  );

  if (template === "optionWithAction") {
    return (
      <WithReactSelectProps innerRef={innerRef} innerProps={innerProps}>
        <OptionBadgeWithActions
          data={value}
          isLoading={isLoading}
          theme="light"
          selectedString={selectedString}
        />
      </WithReactSelectProps>
    );
  }

  if (template === "option") {
    return (
      <WithReactSelectProps innerRef={innerRef} innerProps={innerProps}>
        <OptionBadge
          data={value}
          isLoading={isLoading}
          theme="light"
          groupTypes={groupTypes}
          workspace={workspace}
          selectedString={selectedString}
        />
      </WithReactSelectProps>
    );
  }

  if (template === "optionTag") {
    return (
      <WithReactSelectProps innerRef={innerRef} innerProps={innerProps}>
        <OptionTagBadge
          data={value}
          isLoading={isLoading}
          theme="light"
          selectedString={selectedString}
        />
      </WithReactSelectProps>
    );
  }

  if (template === "link") {
    return getLink(value);
  }

  return null;
};

WithReactSelectProps.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.func,
};

Option.propTypes = {
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string,
  ]),
  isLoading: PropTypes.bool,
  data: PropTypes.shape({
    value: OptionValuePropType,
  }),
  groupTypes: PropTypes.object,
  workspace: PropTypes.object,
  selectedString: PropTypes.string,
};

export default Option;
