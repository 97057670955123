/* eslint-disable react/prop-types */
import { Popover } from "orcs-design-system";
import React from "react";

const TagTypeHelpButton = ({ tagTypeConfig, displayTagTypeHelp }) => {
  const onTooltipClick = (e) => {
    e.stopPropagation();
    displayTagTypeHelp(tagTypeConfig);
  };
  return (
    <Popover
      text="Click here for tag type description"
      onClick={onTooltipClick}
      direction="left"
      variant="tooltip"
    />
  );
};

export default TagTypeHelpButton;
