import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Restricted from "src/app/Restricted";
import PATHS, { url } from "src/consts/urlPaths";

const PeopleLink = ({ children, item }) => (
  <Restricted>
    <Link id="people-nav-link" to={url(PATHS.PEOPLE)} aria-label={item.name}>
      {children}
    </Link>
  </Restricted>
);
PeopleLink.propTypes = {
  item: PropTypes.object,
  children: PropTypes.node,
};

export default PeopleLink;
