import { filter, forEach, find } from "lodash";
import { newPlannerMacroAllocationCell } from "../newCell";

import {
  PLANNER_CURRENT,
  PLANNER_REQUESTED,
  PLANNER_APPROVED,
} from "../../../columnTypes";

const setLineItemWithPlannerCells = ({
  lineItem,
  targetGroupId,
  sourceGroupId,
  macroAllocations,
  isRequestor,
  allocationProjectLookup,
  approvalReasons,
  useDeltaForBudget,
  isRealtimeFteMode,
  useRealtimeChangeOnly,
  useRealtimeFteDelta,
}) => {
  const macroAllocationsForLineItem = filter(
    macroAllocations,
    (ma) =>
      ma.targetGroupId === targetGroupId && ma.sourceGroupId === sourceGroupId
  );

  forEach(macroAllocationsForLineItem, (macroAllocation) => {
    const allocationProject = find(
      allocationProjectLookup,
      (project) => project.id === macroAllocation.allocationProjectId
    );
    const currentCell = newPlannerMacroAllocationCell({
      lineItem,
      macroAllocation,
      columnType: PLANNER_CURRENT,
      isRequestor,
      isEditable: false,
    });
    const requestorCell = newPlannerMacroAllocationCell({
      lineItem,
      macroAllocation,
      allocationProject,
      columnType: PLANNER_REQUESTED,
      isRequestor,
      isEditable: isRequestor && !allocationProject.isRequestorPlannerReadonly,
      useDeltaForBudget,
      isRealtimeFteMode,
      useRealtimeChangeOnly,
      useRealtimeFteDelta,
    });
    const approverCell = newPlannerMacroAllocationCell({
      lineItem,
      macroAllocation,
      columnType: PLANNER_APPROVED,
      isApprover: !isRequestor,
      isEditable: !isRequestor && !allocationProject.isApproverPlannerReadonly,
      approvalReasons,
    });

    // eslint-disable-next-line no-param-reassign
    lineItem.cells = [currentCell, requestorCell, approverCell];
  });
};

export default setLineItemWithPlannerCells;
