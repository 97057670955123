/* eslint react/prop-types: 0 */
import React from "react";
import PropTypes from "prop-types";
import { teamDetailsPath } from "src/util/routing";

const WithTeamDetailsPath = (WrappedComponent) => {
  const Wrapped = (props) => {
    const { teamUrl, team, teamTab = null } = props;
    const to = teamUrl || teamDetailsPath(team, teamTab);

    return <WrappedComponent {...props} to={to} teamUrl={to} />;
  };

  return Wrapped;
};

WithTeamDetailsPath.propTypes = {
  WrappedComponent: PropTypes.element,
};

export default WithTeamDetailsPath;
