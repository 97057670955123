import React from "react";
import PropTypes from "prop-types";
import { StyledLink } from "orcs-design-system";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";

const LinkElement = ({ attributes, children, element, white }) => {
  return (
    <StyledLink
      bold
      white={white}
      {...attributes}
      href={element.url}
      target="_blank"
      onClick={() => {
        trackEvent(EVENT_TRACKING.URL_CLICKED, {
          action: "rich_text_link_clicked",
        });
      }}
    >
      {children}
    </StyledLink>
  );
};

LinkElement.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  element: PropTypes.object,
  white: PropTypes.bool,
};

export default LinkElement;
