import { mapValues, filter, keyBy, forEach, find, get } from "lodash";

import { newLineItem, getDailyRateByFlag } from "../../util";

const setDemandGroupingsWithSupplyLineItems = ({
  groupings,
  macroAllocations,
  columnCount,
  groupTypes = {},
  canRename = false,
  isBudgetEnabled,
  sourceGroupLookup,
} = {}) => {
  forEach(groupings, (grouping) => {
    const macroAllocationsForGrouping = filter(macroAllocations, {
      targetGroupId: grouping.groupId,
    });
    const lineItemGroupLookup = mapValues(
      keyBy(macroAllocationsForGrouping, "sourceGroupId"),
      "sourceGroup"
    );

    forEach(macroAllocationsForGrouping, (macroAllocation) => {
      if (
        !find(grouping.lineItems, (lineItem) => {
          return (
            lineItem.groupId === macroAllocation.sourceGroupId &&
            lineItem.type === macroAllocation.type
          );
        })
      ) {
        const group = lineItemGroupLookup[macroAllocation.sourceGroupId];
        const lineItem = newLineItem({
          group,
          grouping,
          groupId: macroAllocation.sourceGroupId,
          columnCount,
          memberCount: group.memberCount,
          canViewInDetails: true,
          canViewInTeamBuilder: true,
          canRename:
            canRename && get(groupTypes[group.type], "canRename", false),
          canHide: false,
          isSupply: true,
          isSkillsMandatory: grouping.isSkillsMandatory,
          type: macroAllocation.type,
          dailyRate: getDailyRateByFlag(
            isBudgetEnabled,
            group,
            grouping,
            sourceGroupLookup
          ),
        });
        grouping.lineItems.push(lineItem);
        grouping.lineItems.sort((a, b) =>
          a.group.name.localeCompare(b.group.name)
        );
      }
    });

    setDemandGroupingsWithSupplyLineItems({
      groupings: grouping.childGroupings,
      macroAllocations,
      columnCount,
      groupTypes,
      canRename,
      isBudgetEnabled,
      sourceGroupLookup,
    });
  });
};

export default setDemandGroupingsWithSupplyLineItems;
