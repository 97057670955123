import { forEach, keyBy } from "lodash";

import newColumn from "src/allocation/pages/ForecastPage/context/reducer/modelReducer/util/newColumn";
import { PLANNER_REQUESTED } from "src/allocation/pages/ForecastPage/context/reducer/columnTypes";
import {
  buildGroupingLookup,
  hashGrouping,
  setCalculatedSumTotals,
  setNotifications,
} from "../util";

import buildGroupings from "./buildGroupings/byDemand";

const buildPlannerLineOfBusiness = ({
  rootGroup,
  data,
  hideHiddenTeams,
  showObjectives,
  groupTypes,
  shouldHash,
}) => {
  // we need a column so we have a place to put the notification.
  const columns = [newColumn({ columnType: PLANNER_REQUESTED })];

  const macroAllocations = [];
  const groupings = buildGroupings({
    rootGroup,
    macroAllocations,
    descendantTargetGroups: data.descendantTargetGroups,
    hideHiddenTeams,
    showObjectives,
    groupTypes,
  });
  setCalculatedSumTotals(groupings, columns);
  setNotifications(groupings, columns);

  const columnLookup = keyBy(columns, "id");
  const groupingLookup = buildGroupingLookup(groupings);
  const isPendingSubmit = false;

  // Hash groupings in case anything changed
  if (shouldHash) {
    forEach(groupings, hashGrouping);
  }

  return {
    isPendingSubmit,
    columns,
    groupings,
    // lookup objects: for when we need fast access to objects in the tree
    lookups: {
      columnLookup,
      groupingLookup,
    },
  };
};

export default buildPlannerLineOfBusiness;
