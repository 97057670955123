import { filter, flatMap, isEmpty, map, get, forEach, isFinite } from "lodash";

import {
  CURRENT_GROUP_ONLY_VIEW,
  GROUPED_BY_CONSTRAINTS,
  GROUPED_BY_SUPPLY,
  QUICK_ACTIVITY_VIEW,
} from "../../viewModes";

const nonLeafNodeIds = (groupings) => {
  const nonLeafNodes = filter(
    groupings,
    (grouping) => !isEmpty(grouping.childGroupings)
  );
  return map(nonLeafNodes, "id");
};

const getExpandedGroupIds = (groupings, depth, maxDepth) => {
  if (isEmpty(groupings) || depth >= maxDepth) {
    return [];
  }
  const groupingIds = nonLeafNodeIds(groupings);
  return [
    ...groupingIds,
    ...flatMap(groupings, (g) =>
      getExpandedGroupIds(g.childGroupings, depth + 1, maxDepth)
    ),
  ];
};

const expandAllGroupings = (groupings) => {
  if (isEmpty(groupings)) {
    return [];
  }
  const groupingIds = map(groupings, (g) => g.id);
  return [
    ...groupingIds,
    ...flatMap(groupings, (g) => expandAllGroupings(g.childGroupings)),
  ];
};

const findAllGroupWithBudgets = (groupings, targetIds) => {
  forEach(groupings, (grouping) => {
    const { childGroupings, parent } = grouping;

    const targetChildIds = findAllGroupWithBudgets(childGroupings, []);

    if (!isEmpty(targetChildIds) || isFinite(grouping.budget)) {
      targetIds.push(...targetChildIds);
      if (parent) {
        targetIds.push(parent.id);
      }
    }
  });

  return targetIds;
};

const expandGroupsWithBudgets = (groupings, groupingIds) => {
  // Expand all groupings that have budgets
  const targetGroupingsIds = findAllGroupWithBudgets(groupings, []);

  return [...groupingIds, ...targetGroupingsIds];
};

// All groups are expanded, but only leaf nodes have their roles expanded
const getIdsForDefaultExpansion = ({
  groupings,
  viewMode,
  showBudgets,
  initalExpansionLevel,
}) => {
  if (
    viewMode === QUICK_ACTIVITY_VIEW ||
    viewMode === CURRENT_GROUP_ONLY_VIEW
  ) {
    // For QUICK_ACTIVITY_VIEW mode, expand all the groups
    return expandAllGroupings(groupings);
  }

  if (viewMode === GROUPED_BY_CONSTRAINTS) {
    return [get(groupings, "[0].id")];
  }

  let maxDepth;
  if (initalExpansionLevel > 0) {
    maxDepth = initalExpansionLevel;
  } else {
    maxDepth = viewMode === GROUPED_BY_SUPPLY ? 1 : 2;
  }

  const groupingIds = getExpandedGroupIds(groupings, 0, maxDepth);

  // initalExpansionLevel takes priority
  if (showBudgets && !initalExpansionLevel) {
    return expandGroupsWithBudgets(groupings, groupingIds);
  }

  return groupingIds;
};

export default getIdsForDefaultExpansion;
