export default ({
  status,
  message,
  groupMessage,
  columnMessage,
  isGroupNotificationDisplayedOnRoot = true,
  isGroupNotificationDisplayedNextToName = false,
}) => ({
  status,
  message,
  groupMessage,
  columnMessage,
  isGroupNotificationDisplayedOnRoot,
  isGroupNotificationDisplayedNextToName,
});
