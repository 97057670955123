import { forEach } from "lodash";

import setGroupingWithNotifications from "./setGroupingWithNotifications";

export default (groupings, columns) => {
  forEach(groupings, (grouping) => {
    setGroupingWithNotifications(grouping, columns);
  });

  if (groupings[0].isRoot) {
    forEach(columns, (column, index) => {
      // eslint-disable-next-line no-param-reassign
      column.notification = groupings[0].notifications[index].notification;
    });
  }
};
