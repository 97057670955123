import { reduce, reverse } from "lodash";

import findGrouping from "./findGrouping";

const findGroupingFromParentIds = (groupings, parentIds) => {
  const reversedIds = reverse([...parentIds]); // take copy of parentIds as reverse mutates array.
  return reduce(
    reversedIds,
    (hit, id) => {
      if (hit) {
        return hit;
      }

      return findGrouping(groupings, id);
    },
    null
  );
};

export default findGroupingFromParentIds;
