/* eslint-disable */
import React from "react";
import { SidebarContext, SidebarContextProvider } from "./context";

import * as ACTIONS from "./actionTypes";
export { ACTIONS };

// a helpful custom hook to easily use the forecast context
export const useSidebarContext = () => React.useContext(SidebarContext);

// a helpful simple HOC to easily provide the context
export const withSidebarContextProvider = (WrappedComponent) => {
  return class extends React.Component {
    render() {
      return (
        <SidebarContextProvider {...this.props}>
          <WrappedComponent {...this.props} />
        </SidebarContextProvider>
      );
    }
  };
};
