import { forEach } from "lodash";

import setGroupingWithActualConstraints from "./setGroupingWithActualConstraints";

const setGroupingsWithActualConstraints = (
  groupings,
  constraints,
  allocationProjectLookup,
  columns
) => {
  forEach(groupings, (grouping) => {
    setGroupingsWithActualConstraints(
      grouping.childGroupings,
      constraints,
      allocationProjectLookup,
      columns
    );

    setGroupingWithActualConstraints(
      grouping,
      constraints,
      allocationProjectLookup,
      columns
    );
  });
};

export default setGroupingsWithActualConstraints;
