import { H1, P } from "orcs-design-system";
import React from "react";

import { copywriting } from "src/components/ErrorPage/ErrorPage.config";

import ErrorPageWrapper from "./ErrorPageWrapper";

const ServerError = () => {
  return (
    <ErrorPageWrapper>
      <H1 mb="r">{copywriting.serverErrorHeading}</H1>
      <P>{copywriting.serverErrorDetail}</P>
    </ErrorPageWrapper>
  );
};

export default ServerError;
