import React from "react";
import { Spacer, Typography } from "orcs-design-system";
import i18next from "i18next";

import {
  GROUPED_BY_DEMAND,
  GROUPED_BY_SUPPLY,
} from "src/allocation/pages/ForecastPage/context/reducer/viewModes";

const tourOptions = ({ demandLabel, supplyLabel, viewMode }) => ({
  triggerInSidebar: false,
  autoStart: true,
  showProgress: false,
  showSkipButton: true,
  disableScrolling: true,
  dependsOn: ["allocationForecastTour"],
  steps: [
    {
      target: ".guided-tour-allocation-forecast-panel",
      placement: "center",
      content: (
        <Spacer my={3}>
          <Typography.H4 weight="bold" textAlign="center">
            You&apos;ve just opened your first forecast items.
          </Typography.H4>
          <Typography.P textAlign="center">
            Let me show you how they work.
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-line-item",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            <strong>FTE</strong> demand requests are made to{" "}
            <strong>Suppliers</strong> by the <strong>Demand</strong> groupings
            and teams. This line highlights a requestor and its demands.
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-line-item-role",
      content: (
        <Spacer my={3}>
          {viewMode === GROUPED_BY_DEMAND && (
            <Typography.P textAlign="left">
              This is the <strong>{supplyLabel}</strong> that a demand request
              is made against by the parent <strong>{demandLabel}</strong>.
            </Typography.P>
          )}
          {viewMode === GROUPED_BY_SUPPLY && (
            <Typography.P textAlign="left">
              This is the <strong>{demandLabel}</strong> that is making the
              demand for given parent <strong>{supplyLabel}</strong>.
            </Typography.P>
          )}
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-cell-read-only",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            The first <strong>Forecast Column</strong> provides visibility from
            the currently active{" "}
            <strong>{i18next.t("glossary:allocationProject")}</strong>.
          </Typography.P>
          <Typography.P textAlign="left">
            These values can only be edited using the <strong>Planner</strong>{" "}
            page as this{" "}
            <strong>{i18next.t("glossary:allocationProject")}</strong> is
            currently in progress.
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-cell-can-edit",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            The next <strong>Forecast Column</strong> is an editable forecast
            cell, it is a combination of forecast <strong>FTE</strong>,{" "}
            <strong>Notes</strong> and <strong>Skills</strong>.
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-cell-forecast",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            The first part of forecasting a demand is entering an{" "}
            <strong>FTE</strong> value of needed resource for the{" "}
            <strong>{i18next.t("glossary:allocationProject")}</strong>.
          </Typography.P>
          <Typography.P textAlign="left">
            If no value is entered it will be automatically populated by the
            number in the previous column.
          </Typography.P>
          <Typography.P textAlign="left">
            Automatically populated values are easily identifiable as they are
            faded.
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-cell-comment",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            General <strong>Notes</strong> provides a place to see all related
            forecast interactions for this demand forecast.
          </Typography.P>
          <Typography.P textAlign="left">
            The <strong>Notes</strong> also provide a good way to capture any
            other contextual information about the demand forecast.
          </Typography.P>
          <Typography.P textAlign="left">
            This excludes <strong>Skills</strong> which we will cover next.
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-cell-skills",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            <strong>Skill</strong> requirements capture of any specific skill
            needs that are known.
          </Typography.P>
          <Typography.P textAlign="left">
            Again, please only enter <strong>Skill</strong> requirements in this
            box, all other notes or comments are captured in the previously
            mentioned <strong>Notes.</strong>
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-add-role",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            <strong>Add Role</strong> allows for a new{" "}
            <strong>{supplyLabel}</strong> to be added against a{" "}
            <strong>{demandLabel}</strong> so that demands against this new{" "}
            <strong>{supplyLabel}</strong> can be made.
          </Typography.P>
          <Typography.P textAlign="left">
            On selection this will provide navigation of the Supply structure.
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-panel",
      placement: "center",
      content: (
        <Spacer my={3}>
          <Typography.H4 bold center>
            <strong>Happy forecasting!</strong>
          </Typography.H4>
          <Typography.P textAlign="left">
            You&apos;ve now learnt all about forecasting, try clicking around
            and adding some forecasts when your ready :)
          </Typography.P>
          <Typography.Small textAlign="left">
            We also love feedback, please leave any comments or suggestions in
            the help button below.
          </Typography.Small>
        </Spacer>
      ),
    },
  ],
});

export default tourOptions;
