import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { styleLink } from "orcs-design-system";
import { themeGet } from "@styled-system/theme-get";
import GroupPropType from "../../custom-prop-types/group";
import WithTeamDetailsPath from "../WorkspaceLinks/WithTeamDetailsPath";

const StyledRouterLink = styleLink(Link);

const StyledTeamLink = styled(
  ({
    muted,
    white,
    noWrap,
    small,
    large,
    bold,
    marginTop,
    isHidden,
    ...rest
  }) => <StyledRouterLink {...rest} />
)`
  word-break: break-word;
  color: ${(props) =>
    props.muted
      ? themeGet("colors.greyDark")(props)
      : props.white
      ? themeGet("colors.white")(props)
      : themeGet("colors.primary")(props)};
  font-size: ${(props) =>
    props.small
      ? themeGet("fontSizes.0")(props)
      : props.large
      ? themeGet("fontSizes.3")(props)
      : "inherit"};
  font-weight: ${(props) =>
    props.bold
      ? themeGet("fontWeights.2")(props)
      : themeGet("fontWeights.1")(props)};
  margin-top: ${(props) =>
    props.marginTop ? themeGet("space.xs")(props) : "0"};
  white-space: ${(props) => (props.noWrap ? "nowrap" : "normal")};
  text-decoration: ${(props) => (props.isHidden ? "line-through" : "none")};
  &:hover {
    text-decoration: ${(props) =>
      props.isHidden ? "line-through" : "underline"};
  }
`;

const TeamLink = ({
  className,
  team,
  teamName,
  muted,
  white,
  small,
  bold,
  large,
  marginTop,
  noWrap,
  onLinkClick,
  isHidden,
  to,
  "data-testid": dataTestid = "cp-teamlink",
}) => {
  const onClick = useCallback(
    (e) => {
      if (onLinkClick) {
        e.preventDefault();
        onLinkClick(e);
      }
    },
    [onLinkClick]
  );

  return (
    <StyledTeamLink
      className={className}
      data-testid={dataTestid}
      muted={muted}
      white={white}
      small={small}
      bold={bold}
      large={large}
      marginTop={marginTop}
      noWrap={noWrap}
      onClick={onClick}
      isHidden={isHidden}
      to={to}
    >
      {teamName || (team && team.name ? team.name : null)}
    </StyledTeamLink>
  );
};

TeamLink.propTypes = {
  team: GroupPropType,
  className: PropTypes.string,
  teamName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  muted: PropTypes.bool,
  white: PropTypes.bool,
  bold: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  marginTop: PropTypes.bool,
  noWrap: PropTypes.bool,
  onLinkClick: PropTypes.func,
  isHidden: PropTypes.bool,
  to: PropTypes.string,
  "data-testid": PropTypes.string,
};

export default WithTeamDetailsPath(TeamLink);
