/* eslint-disable react/jsx-curly-brace-presence */
import React from "react";
import { H1, P, Button } from "orcs-design-system";
import PropTypes from "prop-types";
import { deleteDateSettingsFromLocalStorage } from "../../services/localStorage";
import { reloadPage } from "../../util/url";
import { copywriting } from "./ErrorPage.config";
import ErrorPageWrapper from "./ErrorPageWrapper";

const onPITButtonClick = () => {
  deleteDateSettingsFromLocalStorage();
  reloadPage();
};

const PITNotFoundError = ({ clientInfo }) => {
  return (
    <ErrorPageWrapper showHeader={false} clientInfo={clientInfo}>
      <H1 mb="between">{copywriting.pitNotFoundErrorHeading}</H1>
      <P>{copywriting.pitNotFoundErrorDetail}</P>
      <Button
        leftIcon={["fas", "redo"]}
        display="inline-block"
        onClick={onPITButtonClick}
      >
        Reset selected date
      </Button>
    </ErrorPageWrapper>
  );
};

PITNotFoundError.propTypes = {
  clientInfo: PropTypes.string,
};

export default PITNotFoundError;
