/* eslint-disable react/display-name */
import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

import { Spacer } from "orcs-design-system";

import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "../../services/segment";
import AppSwitchLink from "./AppSwitchLink";

const ExternalAppLinks = React.memo(({ links }) => {
  return (
    <Spacer mb="r">
      {map(links, ({ title, description, href }) => {
        return (
          <AppSwitchLink
            key={title}
            label={title}
            description={description}
            href={href}
            onClick={() => {
              trackEvent(EVENT_TRACKING.URL_CLICKED, {
                action: "external_app_link_clicked",
              });
            }}
          />
        );
      })}
    </Spacer>
  );
});

ExternalAppLinks.propTypes = {
  links: PropTypes.array,
};

export default ExternalAppLinks;
