import React from "react";
import PropTypes from "prop-types";

import { H1, P } from "orcs-design-system";
import { copywriting } from "./ErrorPage.config";
import ErrorPageWrapper from "./ErrorPageWrapper";

const GenericError = ({ clientInfo, showHeader, showContainer }) => {
  return (
    <ErrorPageWrapper
      clientInfo={clientInfo}
      showHeader={showHeader}
      showContainer={showContainer}
    >
      <H1 mb="between">{copywriting.genericErrorHeading}</H1>
      <P>{copywriting.genericErrorDetail}</P>
    </ErrorPageWrapper>
  );
};

GenericError.propTypes = {
  clientInfo: PropTypes.string,
  showHeader: PropTypes.bool,
  showContainer: PropTypes.bool,
};

export default GenericError;
