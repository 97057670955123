const resetAppHeight = (windowWidth, theme) => {
  const { appScale, space } = theme || {};

  const navBarSize =
    ((appScale &&
      appScale.navBarSize &&
      appScale.navBarSize.replace("px", "")) ||
      0) * 1;

  const appContentPadding =
    ((space && space[4] && space[4].replace("px", "")) || 0) * 2;
  const isMobileSize = windowWidth < 900;

  // headers on top of the app
  // env bar is the one that shows the env info
  // notification bar is the one that shows the global/workspace notification
  const envBarEl = document.querySelector("#appEnvBar");
  const notificationBarEl = document.querySelector("#notification-top-banner");

  if (!envBarEl && !notificationBarEl) {
    return;
  }

  let offsetHeight = 0;
  if (envBarEl && notificationBarEl) {
    offsetHeight = envBarEl.offsetHeight + notificationBarEl.offsetHeight;
  } else if (envBarEl) {
    offsetHeight = envBarEl.offsetHeight;
  } else if (notificationBarEl) {
    offsetHeight = notificationBarEl.offsetHeight;
  }

  const appContentNewHeight = isMobileSize
    ? null
    : `calc(100vh - ${navBarSize + offsetHeight}px)`;
  const sideNavNewHeight = isMobileSize
    ? null
    : `calc(100vh - ${navBarSize + appContentPadding + offsetHeight}px)`;

  const appContentEl = document.querySelector("#appContent");
  if (appContentEl) {
    appContentEl.style.height = appContentNewHeight;
  }

  const sideNavEl = document.querySelector(
    "div[class^=SideNav__SideNavWrapper]"
  );

  if (sideNavEl) {
    // set SideNav height
    sideNavEl.style.minHeight = sideNavNewHeight;
    sideNavEl.style.height = sideNavNewHeight;
  }
};

export default resetAppHeight;
