import PropTypes from "prop-types";
// eslint-disable-next-line import/prefer-default-export
export const TagTypeConfigPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  allowMultipleOnEntity: PropTypes.bool,
  isGroupAssociation: PropTypes.bool,
  isEditableByUsers: PropTypes.bool,
  isReadonly: PropTypes.bool,
});
