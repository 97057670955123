import { P } from "orcs-design-system";
import React from "react";
import PropTypes from "prop-types";

import MentionElement from "src/comments/components/RichTextEditor/plugins/mentions/MentionElement";

import LinkElement from "./LinkElement";

const Element = (props) => {
  const { attributes, children, element, fontSize } = props;

  switch (element.type) {
    case "bulleted-list":
      return (
        <ul data-testid="cp-element-ul" {...attributes}>
          {children}
        </ul>
      );
    case "list-item":
      return (
        <li data-testid="cp-element-li" {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol data-testid="cp-element-ol" {...attributes}>
          {children}
        </ol>
      );
    case "link":
      return <LinkElement {...props} />;
    case "mention":
      return <MentionElement {...props} />;
    // case 'block-quote':
    //   return <blockquote {...attributes}>{children}</blockquote>;
    // case 'heading-one':
    //   return <h1 {...attributes}>{children}</h1>;
    // case 'heading-two':
    //   return <h2 {...attributes}>{children}</h2>;
    default:
      return (
        <P
          fontSize={fontSize}
          data-testid="cp-element-paragraph"
          mb="s"
          {...attributes}
        >
          {children}
        </P>
      );
  }
};

Element.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  fontSize: PropTypes.string,
  element: PropTypes.object,
};

export default Element;
