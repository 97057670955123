import { useApolloClient } from "@apollo/client";
import { useCallback } from "react";

import { CachedTagFragment } from "src/fragments/tagFragments.graphql";

/* eslint-disable import/prefer-default-export */
export const useCachedTag = () => {
  const client = useApolloClient();

  const getCachedTag = useCallback(
    (tagId) => {
      return client.readFragment({
        id: `Tag:${tagId}`,
        fragment: CachedTagFragment,
        fragmentName: "CachedTagFragment",
      });
    },
    [client]
  );

  return {
    getCachedTag,
  };
};
