import { filter, forEach, findIndex } from "lodash";
import { newMacroAllocationCell } from "../newCell";

const setLineItemWithForecastCells = ({
  lineItem,
  targetGroupId,
  sourceGroupId,
  macroAllocations,
  columns,
  rootGroup,
}) => {
  const macroAllocationsForLineItem = filter(
    macroAllocations,
    (ma) =>
      ma.targetGroupId === targetGroupId &&
      ma.sourceGroupId === sourceGroupId &&
      ma.type === lineItem.type
  );

  forEach(macroAllocationsForLineItem, (macroAllocation) => {
    const columnIndex = findIndex(columns, {
      allocationProjectId: macroAllocation.allocationProjectId,
    });

    const cell = newMacroAllocationCell({
      lineItem,
      column: columns[columnIndex],
      macroAllocation,
      allocationProject: columns[columnIndex].allocationProject,
      rootGroup,
    });
    // eslint-disable-next-line no-param-reassign
    lineItem.cells[columnIndex] = cell;
  });
};

export default setLineItemWithForecastCells;
