/* eslint-disable no-param-reassign */
import { isNil } from "lodash";

const setDefaultConstraintFromPreviousCell = (
  previousCell = { constraint: null },
  cell,
  allocationProject = {}
) => {
  if (
    previousCell &&
    allocationProject.disablePrefillFutureForecastFromPrevious
  ) {
    return cell;
  }

  if (!isNil(previousCell.constraint)) {
    cell.defaultedConstraint = previousCell.constraint;
    cell.isDefaultFromInput = true;
  } else if (!isNil(previousCell.defaultedConstraint)) {
    cell.defaultedConstraint = previousCell.defaultedConstraint;
    cell.isDefaultFromInput = previousCell.isDefaultFromInput;
  } else if (!isNil(previousCell.capacityFte)) {
    cell.defaultedConstraint = previousCell.capacityFte;
    cell.isDefaultFromInput = false;
  } else {
    cell.defaultedConstraint = 0;
    cell.isDefaultFromInput = false;
  }

  return cell;
};

export default setDefaultConstraintFromPreviousCell;
