import { isNil } from "lodash";
import { calculateTotalCost } from "src/allocation/util/budgetAndCost";
import { PENDING } from "../../../../../saveStatus";
import { PLANNER_REQUESTED } from "../../../../../columnTypes";
import { newPlannerRequestorCellNotification } from "../../../newNotification";
import { calculateCellDelta } from "../../../cellDelta";

import newCell from "../../newCell";
import getFte from "./getFte";

const getPrepopulatedValueFromForecast = (
  forecastedAt,
  requestSavedAt,
  forecast,
  value
) => {
  // we have a forecasted value, without saving anything yet, and it's different from original
  if (forecastedAt && !requestSavedAt && forecast !== value) {
    return [forecast, PENDING];
  }

  return [null, null];
};

const getTargetValue = (useDeltaForBudget, delta, prepopulatedValue, value) => {
  if (useDeltaForBudget) {
    return delta;
  }

  return isNil(prepopulatedValue) ? value : prepopulatedValue;
};

export default ({
  lineItem,
  macroAllocation,
  allocationProject,
  isRequestor,
  isEditable,
  useDeltaForBudget,
  isRealtimeFteMode,
  useRealtimeChangeOnly,
  useRealtimeFteDelta,
}) => {
  const {
    id,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    requested,
    requestSavedAt,
    requestSubmitted,
    allocatedMemberFte, // current fte
    forecast,
    forecastedAt,
    note, // skills
    realtimeCurrentMemberFte: realtimeFte,
  } = macroAllocation;
  const { workingDays, disablePlannerRequirementInput } = allocationProject;
  const { dailyRate, isSkillsMandatory, grouping } = lineItem;
  const { isMovedOut } = grouping;

  const fte = isRequestor ? requested : requestSubmitted; // approver only sees submitted

  const [value, defaultedValue] = isMovedOut
    ? [null, null]
    : [getFte(fte), getFte(allocatedMemberFte) || 0];

  const realtimeCurrentMemberFte = getFte(
    isNil(realtimeFte) ? defaultedValue : realtimeFte
  );

  const delta = isMovedOut
    ? null
    : calculateCellDelta({
        isRealtimeFteMode,
        useRealtimeChangeOnly,
        useRealtimeFteDelta,
        value,
        realtimeCurrentMemberFte,
        defaultedValue,
      });

  const notification = newPlannerRequestorCellNotification(
    macroAllocation,
    isRequestor,
    isSkillsMandatory
  );
  const [prepopulatedValue, valueSaveStatus] = getPrepopulatedValueFromForecast(
    forecastedAt,
    requestSavedAt,
    forecast,
    value
  );

  // Calculate totalCost with values by flag
  const totalCost = calculateTotalCost({
    value: getTargetValue(useDeltaForBudget, delta, prepopulatedValue, value),
    dailyRate,
    workingDays,
  });

  return newCell({
    lineItem,
    id: `${id}-${PLANNER_REQUESTED}`,
    columnType: PLANNER_REQUESTED,
    macroAllocationId: id,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    isStatic: false,
    isEditable: !isMovedOut && isEditable,
    value,
    defaultedValue,
    prepopulatedValue,
    valueSaveStatus,
    delta,
    skills: note,
    isRequirementInputEnabled: !disablePlannerRequirementInput,
    notification,
    totalCost,
    dailyRate,
    workingDays,
    realtimeCurrentMemberFte,
  });
};
