import { Flex, Box, Spacer } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import icons from "src/config/icons";
import SideNavWithOnlyLogout from "src/components/SideNav/SideNavWithOnlyLogout";
import EmptyAppNav from "../Nav/EmptyAppNav";
import { useAuth0 } from "../../contexts/auth0Provider";
import { ErrorBox, ErrorContainer, ErrorIcon } from "./ErrorPageStyles";
import ErrorFooter from "./ErrorFooter";

const TransparentContainer = ({ children }) => {
  return (
    <Flex height="100%" alignItems="center">
      {children}
    </Flex>
  );
};

TransparentContainer.propTypes = {
  children: PropTypes.node,
};
// because for some reason adding AppNav to ErrorPageWrapper causes circular
// dependency that was not quick to figure out.
const EmptyAppNavWithAuth = () => {
  const { logout, user } = useAuth0();
  return <EmptyAppNav user={user} logout={logout} />;
};

const ErrorPageWrapper = ({
  children,
  showIcon = true,
  showHeader = true,
  showContainer = true,
  showSideNav = false,
  showBackButton,
  showSupportButton,
  showLogoutButton,
  clientInfo,
  selectWorkspace,
  icon = icons.exclamationTriangle,
}) => {
  const Container = showContainer ? ErrorContainer : TransparentContainer;

  return (
    <>
      {showHeader && <EmptyAppNavWithAuth />}
      <Container>
        {showSideNav && <SideNavWithOnlyLogout />}
        <ErrorBox>
          <Flex
            flexDirection="row"
            justifyContent="start"
            alignItems="flex-start"
          >
            {showIcon && <ErrorIcon icon={icon} />}
            <Box px="r">
              <Spacer my="r">
                {children}
                <ErrorFooter
                  clientInfo={clientInfo}
                  selectWorkspace={selectWorkspace}
                  showBackButton={showBackButton}
                  showSupportButton={showSupportButton}
                  showLogoutButton={showLogoutButton}
                />
              </Spacer>
            </Box>
          </Flex>
        </ErrorBox>
      </Container>
    </>
  );
};

ErrorPageWrapper.propTypes = {
  children: PropTypes.node,
  showIcon: PropTypes.bool,
  showHeader: PropTypes.bool,
  showContainer: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showSupportButton: PropTypes.bool,
  showLogoutButton: PropTypes.bool,
  icon: PropTypes.arrayOf(PropTypes.string),
  clientInfo: PropTypes.string,
  selectWorkspace: PropTypes.bool,
  showSideNav: PropTypes.bool,
};

export default ErrorPageWrapper;
