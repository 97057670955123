import constate from "constate";

const useApolloSettingsContext = (values) => values;

const [
  Provider,
  useApolloSettings,
  useActiveTenant,
  useTenantId,
  useWorkspaceId,
  useSetWorkspaceId,
  useDateSettings,
  useSetDateSettings,
  useClearDateSettings,
  useSetTenantId,
  useWorkspaceChanged,
  useSetWorkspaceChanged,
  apiUrl,
] = constate(
  useApolloSettingsContext,
  (value) => value,
  (value) => value.overrideTenant,
  (value) => value.tenantId,
  (value) => value.workspaceId,
  (value) => value.setWorkspaceId,
  (value) => value.dateSettings,
  (value) => value.setDateSettings,
  (value) => value.clearDateSettings,
  (value) => value.setTenantId,
  (value) => value.workspaceChanged,
  (value) => value.setWorkspaceChanged,
  (value) => value.apiUrl
);

export {
  Provider,
  useApolloSettings,
  useActiveTenant,
  useTenantId,
  useWorkspaceId,
  useSetWorkspaceId,
  useDateSettings,
  useSetDateSettings,
  useClearDateSettings,
  useSetTenantId,
  useWorkspaceChanged,
  useSetWorkspaceChanged,
  apiUrl,
};
