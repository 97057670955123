import { update, get } from "lodash";
import { isMacroAllocationRejected } from "src/allocation/util/macroAllocation";
import { WARN, DANGER } from "../notificationStatus";
import { SUCCESS, FAILED } from "../saveStatus";
import { PLANNER_REQUESTED, PLANNER_APPROVED, FORECAST } from "../columnTypes";

import {
  setGroupingWithNotifications,
  hashCell,
  newNotification,
  newPlannerApproverCellNotification,
  newPlannerRequestorCellNotification,
  newForecastNotification,
} from "./util";
import { calculateDelta } from "./util/cellDelta";

const refreshedGenericSavedNotification = (cell) => {
  const { columnType } = cell;
  const groupMessage =
    columnType === PLANNER_REQUESTED
      ? "Requests to submit"
      : columnType === PLANNER_APPROVED
      ? "Approvals to submit"
      : null;
  return newNotification({
    status: WARN,
    message: "Saved",
    groupMessage,
  });
};

const refreshedNotificationFromMacroAllocation = ({
  cell,
  macroAllocation,
  approvalReasons,
  rootGroup,
}) => {
  const { columnType, lineItem } = cell;
  const {
    grouping: { root: rootGrouping },
  } = lineItem;

  if (columnType === PLANNER_APPROVED) {
    return newPlannerApproverCellNotification(
      macroAllocation,
      rootGrouping.isSupply,
      approvalReasons
    );
  }

  if (columnType === PLANNER_REQUESTED) {
    return newPlannerRequestorCellNotification(
      macroAllocation,
      rootGrouping.isDemand,
      lineItem.isSkillsMandatory
    );
  }

  if (columnType === FORECAST) {
    return newForecastNotification(macroAllocation, rootGroup);
  }

  return refreshedGenericSavedNotification(cell);
};

const newRefreshedNotification = ({
  cell,
  cellStatusFieldName,
  newStatus,
  newMacroAllocation,
  approvalReasons,
  rootGroup,
}) => {
  if (newStatus === FAILED) {
    return newNotification({
      status: DANGER,
      message: "Saved failed, please retry",
      groupMessage: "A saved failed, please retry",
    });
  }

  if (newMacroAllocation) {
    return refreshedNotificationFromMacroAllocation({
      cell,
      macroAllocation: newMacroAllocation,
      approvalReasons,
      rootGroup,
    });
  }

  if (cellStatusFieldName === "valueSaveStatus") {
    return refreshedGenericSavedNotification(cell);
  }

  return null;
};

const checkIsAllocationStatusSubmitable = (cell, newMacroAllocation) => {
  if (cell.columnType === PLANNER_REQUESTED) {
    return get(newMacroAllocation, "requestStatus") === "SAVED";
  }
  if (cell.columnType === PLANNER_APPROVED) {
    return get(newMacroAllocation, "approvalStatus") === "SAVED";
  }
  return true;
};

const updateFieldsForValueSave = (
  cellStatusFieldName,
  macroAllocation,
  cell,
  approvalReasons
) => {
  if (cellStatusFieldName !== "valueSaveStatus" || !macroAllocation) {
    return;
  }

  /* eslint-disable no-param-reassign */

  if (cell.columnType === PLANNER_APPROVED) {
    const isRejection = isMacroAllocationRejected(
      macroAllocation,
      approvalReasons
    );

    if (isRejection) {
      cell.value = macroAllocation.approved;
      cell.delta = calculateDelta(cell.value, cell.defaultedValue);
      cell.prepopulatedValue = null;
    }
  }

  // Update approvalReason with new macroAllocation
  cell.approvalReasonId = macroAllocation.approvalReasonId;
  cell.approvalReasonCustomText = macroAllocation.approvalReasonCustomText;
  cell.requestSubmittedAt = macroAllocation.requestSubmittedAt;
  cell.approvalSavedAt = macroAllocation.approvalSavedAt;

  /* eslint-enable no-param-reassign */
};

const updateModelWithNewCellSaveStatus = ({
  dynamicModel,
  cellId,
  cellStatusFieldName,
  newStatus,
  newMacroAllocation,
  approvalReasons,
  rootGroup,
}) => {
  const {
    lookups: { cellLookup },
  } = dynamicModel;

  const cell = cellLookup[cellId];
  update(cell, cellStatusFieldName, () => newStatus);

  updateFieldsForValueSave(
    cellStatusFieldName,
    newMacroAllocation,
    cell,
    approvalReasons
  );

  const refreshedNotification = newRefreshedNotification({
    cell,
    cellStatusFieldName,
    newStatus,
    newMacroAllocation,
    approvalReasons,
    rootGroup,
  });

  if (refreshedNotification) {
    cell.notification = refreshedNotification;

    // cascade update notifications
    setGroupingWithNotifications(cell.lineItem.grouping, dynamicModel.columns, {
      recalcChildren: false,
      recalcParents: true,
    });
  }

  // recalc hash keys for cache memoize keys
  hashCell(cell, {
    rehashParents: true,
  });

  const isAllocationStatusSubmitable = checkIsAllocationStatusSubmitable(
    cell,
    newMacroAllocation
  );

  const isPendingSubmit = isAllocationStatusSubmitable && newStatus === SUCCESS;

  return {
    ...dynamicModel,
    isPendingSubmit,
  };
};

export default updateModelWithNewCellSaveStatus;
