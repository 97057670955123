import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  filter,
  find,
  flatMap,
  get,
  keyBy,
  map,
  reduce,
  sortBy,
  trim,
  includes,
  toLower,
} from "lodash";

import { getGroupType } from "src/util/groupType";
import { reportError } from "src/services/errorReporting";

export const UNKNOWN_NAME = "Unknown";

export const getName = (person) => {
  let name = "";
  if (get(person, "preferredName")) {
    name += person.preferredName;
  } else if (get(person, "firstName")) {
    name += person.firstName;
  }

  if (name.length > 0) {
    name += " ";
  }

  if (get(person, "surname")) {
    name += person.surname;
  }

  return trim(name) || UNKNOWN_NAME;
};

export const getInitials = (person) => {
  let initials = "";

  if (person.preferredName) {
    initials += person.preferredName[0];
  } else if (person.firstName) {
    initials += person.firstName[0];
  }

  if (person.surname) {
    initials += person.surname[0];
  }

  return initials || "?";
};

export const getPreferredFirstName = (person) => {
  return getName(person).split(" ")[0];
};

export const getDemandGroupIds = (userPerson, groupTypes) => {
  if (!userPerson || !groupTypes) {
    return [];
  }

  // find demand teams types from config
  const demandTypes = filter(groupTypes, "isDemand");
  const demandTypeNames = map(demandTypes, "name");
  // filter memberOf by team types
  const demandGroups = filter(userPerson.memberOf, (group) =>
    demandTypeNames.includes(group.type)
  );
  const demandGroupIds = map(demandGroups, "id");
  return demandGroupIds;
};

export const sortMembersByName = (members) =>
  sortBy(members, (member) => toLower(getName(member)));

export const getUniqueWorkmates = (userPerson, groups) => {
  const demandTeamMates = flatMap(groups, "members");
  const teamMatesById = keyBy(demandTeamMates, "aggregateId");
  // remove the user from the results
  delete teamMatesById[userPerson.aggregateId];
  const uniqueWorkmates = Object.values(teamMatesById);
  return sortMembersByName(uniqueWorkmates);
};

export const getMapperAllocationsLink = (person) => {
  return `${process.env.REACT_APP_PEOPLE_MAPPER_URL}/people/${person.aggregateId}/allocations`;
};

export const getPersonEmailAttribute = (person) =>
  find(get(person, "attributes"), { attributeType: "id", subtype: "email" });

export const getPersonTelephoneAttribute = (person) =>
  find(get(person, "attributes"), { attributeType: "phone", subtype: "work" });

export const getPersonMobileNumberAttribute = (person) =>
  find(get(person, "attributes"), {
    attributeType: "phone",
    subtype: "mobile",
  });

export const getEmailsFromPersonAttributes = (emailAttributes) =>
  reduce(
    emailAttributes,
    (result, { value }) => {
      if (value.attributes) {
        return [...result, get(getPersonEmailAttribute(value), "value")];
      }
      return result;
    },
    []
  );

export const onPersonEmailClick = (person) => (event) => {
  event.preventDefault();
  const emailAttribute = getPersonEmailAttribute(person);

  navigator.clipboard.writeText(emailAttribute?.value || "");
};

export const onPersonTelephoneClick = (person, onError) => (event) => {
  event.preventDefault();
  const telephoneAttribute = getPersonTelephoneAttribute(person);
  const mobileNumber = getPersonMobileNumberAttribute(person);

  if (telephoneAttribute || mobileNumber) {
    // prefer telephone over mobile
    // use lodash get, either of attributes could be null
    const phoneNumber =
      get(telephoneAttribute, "value") || get(mobileNumber, "value");
    try {
      // parsePhoneNumber can throw error if invalid format
      const telephoneUri = parsePhoneNumberFromString(phoneNumber).getURI();
      window.open(telephoneUri);
    } catch (err) {
      if (onError) onError(err);
      reportError(err);
    }
  }
};

export const hasTelephoneAttribute = (person) => {
  const telephoneAttribute = getPersonTelephoneAttribute(person);
  const mobileNumber = getPersonMobileNumberAttribute(person);

  return telephoneAttribute || mobileNumber;
};

export const getTagsByTypes = (person, tagTypes) =>
  filter(get(person, "tags"), (t) => includes(tagTypes, t.type));

export const findAllocatedFte = (member, team) =>
  get(
    find(
      get(member, "allocations"),
      (allocation) => get(allocation, "targetGroupId") === get(team, "id")
    ),
    "fte",
    null
  );

// only display fte if team is demand and fte is not 0 or equal to the member's fte.
export const findFteToDisplay = (member, team, groupTypes) => {
  const groupType = getGroupType(team, groupTypes);
  if (get(groupType, "isDemand")) {
    const fte = findAllocatedFte(member, team);
    if (fte !== member.fte) {
      return fte;
    }
  }
  return null;
};

export const isValidPerson = (personDetails) => {
  if (!personDetails) {
    return false;
  }

  const personName = getName(personDetails);
  return personName !== UNKNOWN_NAME;
};

export const fullLegalName = ({ firstName, surname }) => {
  const first = firstName || "<Unknown>";
  const last = surname || "<Unknown>";
  return `${first} ${last}`.trim();
};
