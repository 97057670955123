/* eslint-disable no-param-reassign */
import { get, forEach } from "lodash";

import setDefaultConstraintFromPreviousCell from "../setDefaultConstraintFromPreviousCell";
import { newDefaultedConstraintCell } from "../newCell";

const setGroupingWithDefaultConstraints = (
  grouping,
  allocationProjectLookup,
  columns
) => {
  forEach(grouping.constraints, (cell, index) => {
    const previousCell = grouping.constraints[index - 1];
    const { isDemand, summedTotals } = grouping;
    const allocationProject =
      allocationProjectLookup[columns[index].allocationProjectId];

    if (!cell) {
      const defaultCell = newDefaultedConstraintCell(
        grouping,
        previousCell,
        allocationProject
      );
      if (isDemand && !previousCell) {
        // we're resetting an already calculated field here ...
        // should we move this logic further down into newDefaultedConstraintCell?
        defaultCell.defaultedConstraint = get(summedTotals[index], "total", 0);
      }

      grouping.constraints[index] = defaultCell;
    } else if (previousCell) {
      setDefaultConstraintFromPreviousCell(
        previousCell,
        cell,
        allocationProject
      );
    } else {
      cell.defaultedConstraint = isDemand
        ? get(summedTotals[index], "total", 0)
        : get(cell, "capacityFte", 0);
      cell.isDefaultFromInput = false;
    }
  });
};

export default setGroupingWithDefaultConstraints;
