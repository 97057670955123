import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { first, get, size } from "lodash";
import { TAG_ATTRIBUTE_TYPES } from "src/consts/tags";
import { getTagsPath, personDetailsPath } from "../util/routing";
import { EVENT_TRACKING } from "../consts/eventTracking";
import { trackEvent } from "../services/segment";

const useHandleEntityTagClick = () => {
  const history = useHistory();
  return useCallback(
    (tag) => {
      if ((tag?.tagId || tag?.id) && history) {
        const values = get(tag, "tagTypeConfig.attributes.values");
        const attribute = first(values);
        const hasOnlyPersonAttribute =
          size(values) === 1 && attribute?.type === TAG_ATTRIBUTE_TYPES.PERSON;
        const personId = get(tag, `attributes.[${attribute?.id}]`);
        const url =
          hasOnlyPersonAttribute && personId
            ? personDetailsPath({ aggregateId: personId })
            : getTagsPath(tag.tagId || tag.id);
        trackEvent(EVENT_TRACKING.TAG_CLICKED, {
          action: "tag_badge_selected",
        });
        history.push(url);
      }
    },
    [history]
  );
};

export default useHandleEntityTagClick;
