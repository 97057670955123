import { Box, Flex, Divider } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const Layout = ({ children, hideDivider }) => {
  return (
    <Box px="s">
      <Box py="s" bg="transparent" width="100%">
        <Flex justifyContent="space-between" alignItems="center">
          {children}
        </Flex>
      </Box>
      {!hideDivider && <Divider light />}
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  hideDivider: PropTypes.bool,
};

export default Layout;
