import { isNil } from "lodash";
import { roundFte } from "src/util/roundingStrategy";
import { getForecastReadonlyFlag } from "src/allocation/util/allocationProject";
import { calculateTotalCost } from "src/allocation/util/budgetAndCost";

import newCell from "../newCell";
import { newForecastNotification } from "../../newNotification";

const getFteValue = ({
  isActive,
  requestSubmittedAt,
  requestSubmitted,
  allocatedMemberFte,
  forecast,
}) => {
  if (isActive) {
    if (!isNil(requestSubmittedAt) && !isNil(requestSubmitted)) {
      return requestSubmitted;
    }
    return allocatedMemberFte;
  }
  return forecast;
};

export default ({
  lineItem,
  column,
  macroAllocation,
  allocationProject,
  rootGroup,
}) => {
  const {
    id,
    allocationProjectId,
    targetGroupId,
    sourceGroupId,
    requestSubmittedAt,
    requestSubmitted,
    allocatedMemberFte,
    forecast,
  } = macroAllocation;

  const { isActive, enableRequirementInput, workingDays } = allocationProject;
  const { dailyRate } = lineItem;
  const isReadonly = getForecastReadonlyFlag(allocationProject, rootGroup);

  const fte = getFteValue({
    isActive,
    requestSubmittedAt,
    requestSubmitted,
    allocatedMemberFte,
    forecast,
  });

  const value = isNil(fte) ? null : roundFte(fte);
  const notification = newForecastNotification(
    macroAllocation,
    rootGroup,
    isActive
  );
  const totalCost = isActive
    ? null
    : calculateTotalCost({ value, dailyRate, workingDays });

  return newCell({
    lineItem,
    column,
    columnType: column.columnType,
    id,
    macroAllocationId: id,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    isStatic: isActive,
    isEditable: !isActive && !isReadonly,
    value,
    skills: macroAllocation.note,
    notification,
    isRequirementInputEnabled: enableRequirementInput,
    totalCost,
    workingDays,
    dailyRate,
  });
};
