/* eslint-disable no-console */
import React, { useEffect } from "react";

import PropTypes from "prop-types";
import AuthLayout from "src/components/AuthLayout";
import SelectTenantPrompt from "src/components/SelectTenantPrompt";
import { useAuth0 } from "src/contexts/auth0Provider";
import { PUBLIC_URLS } from "src/consts/urlPaths";
import { isUserWithTenantPrompt, isOnboardingUser } from "src/services/auth";
import { getActiveTenant } from "src/services/localStorage";

import AuthenticatedApp from "./AuthenticatedApp";
import UnauthenticatedApp from "./UnauthenticatedApp";
import PublicRoutes from "./PublicRoutes";

const App = ({ themeSwitcher }) => {
  const {
    auth0ErrorContext,
    isAccessDenied,
    isGenericDomain,
    getTokenSilently,
    isAuthenticated,
    loading,
    loginWithRedirect,
    user,
  } = useAuth0();
  const isPublicUrl = PUBLIC_URLS.includes(window.location.pathname);

  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    if (!isAccessDenied && !isPublicUrl) {
      const fn = async () => {
        // if the url is not a public URL, always redirect to
        // the original requested url including query params
        await loginWithRedirect({
          appState: {
            targetUrl: window.location.pathname,
          },
          redirect_uri: window.location.origin,
        });
      };
      fn();
    }
  }, [
    loading,
    isAuthenticated,
    loginWithRedirect,
    isAccessDenied,
    isPublicUrl,
  ]);

  if (loading) {
    return <AuthLayout noPanel={true} />;
  }

  if (isAccessDenied) {
    return <UnauthenticatedApp errorContext={auth0ErrorContext} />;
  }

  if (isPublicUrl) {
    return <PublicRoutes />;
  }

  if (isOnboardingUser(user)) {
    window.location.href = process.env.REACT_APP_TEAM_DIRECTORY_DOMAIN;
  }

  if (isAuthenticated) {
    const savedActiveTenant = getActiveTenant(user.email);
    const isDisplayTenantListPrompt =
      isUserWithTenantPrompt(user) && !savedActiveTenant;

    if (isDisplayTenantListPrompt) {
      return (
        <SelectTenantPrompt user={user} isFullScreen>
          <AuthenticatedApp
            user={user}
            isGenericDomain={isGenericDomain}
            getTokenSilently={getTokenSilently}
            themeSwitcher={themeSwitcher}
          />
        </SelectTenantPrompt>
      );
    }

    if (
      process.env.REACT_APP_LOCAL_BUNDLE === "true" &&
      !isUserWithTenantPrompt(user)
    ) {
      return (
        <UnauthenticatedApp
          errorContext={{
            error: "local-dev-user-invalid",
            errorDescription:
              "If running start:bundle, you must log in with your support user",
            source: "local-dev",
          }}
        />
      );
    }

    return (
      <AuthenticatedApp
        user={user}
        isGenericDomain={isGenericDomain}
        getTokenSilently={getTokenSilently}
        themeSwitcher={themeSwitcher}
      />
    );
  }

  return <AuthLayout noPanel={true} />;
};

App.propTypes = {
  themeSwitcher: PropTypes.func,
};

export default App;
