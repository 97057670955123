import { useState } from "react";

import useAddNewGroupListener from "./useAddNewGroupListener";
import useGroupRemovedListener from "./useGroupRemovedListener";
import useGroupRenameListener from "./useGroupRenameListener";
import useUpdateCurrentGroupListener from "./useUpdateCurrentGroupListener";
import useGroupsTreeData from "./useGroupsTreeData";

const useGroupsNavigationData = ({
  workspace,
  featureFlags,
  isSelected,
  eventEmitter,
}) => {
  const [hierarchyIds, setHierarchyIds] = useState(null);
  const {
    root,
    treeData,
    rootGroupsLoading,
    groupTypes,
    refreshTree,
    sortOption,
  } = useGroupsTreeData({
    workspace,
    featureFlags,
    isSelected,
  });

  // Listen to current group change and set hierarchyIds
  useUpdateCurrentGroupListener(
    root,
    setHierarchyIds,
    refreshTree,
    eventEmitter
  );

  // Listen to new group added event and add new group to the tree
  useAddNewGroupListener(root, groupTypes, refreshTree, eventEmitter);

  // Listen to group rename event and update tree node
  useGroupRenameListener(root, refreshTree, eventEmitter);

  // Listen to group removed event and update parent tree node children
  useGroupRemovedListener(root, refreshTree, eventEmitter);

  return {
    root,
    treeData,
    rootGroupsLoading,
    groupTypes,
    hierarchyIds,
    setHierarchyIds,
    sortOption,
  };
};

export default useGroupsNavigationData;
