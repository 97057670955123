import React from "react";
import { Box } from "orcs-design-system";
import { withRouter } from "react-router";
import { useMutation } from "@apollo/client";
import { get } from "lodash";

import { useAuth0 } from "src/contexts/auth0Provider";
import ErrorPage from "../../components/ErrorPage";
import LoadingPage from "../../components/LoadingPage";
import WorkspaceForm from "../../components/Workspaces/Form";

import { createWorkspace as createWorkspaceMutation } from "./workspace.graphql";

const CreateWorkspacePage = () => {
  const { user } = useAuth0();

  const onCreateWorkspaceCompleted = (data) => {
    window.location.href = `https://${window.location.hostname}:${window.location.port}/${data.workspace.id}/teams`;
  };

  const [createWorkspace, { error, loading }] = useMutation(
    createWorkspaceMutation,
    {
      onCompleted: (data) => onCreateWorkspaceCompleted(data),
    }
  );
  const onCreateWorkspace = ({ name, description, configType }) => {
    createWorkspace({
      variables: {
        input: {
          name,
          description,
          configType: get(configType, "value"),
        },
      },
    });
  };

  // TODO: actual loading and error context for this page
  if (loading) {
    return <LoadingPage waitingFor="createWorkspace" />;
  }
  if (error) {
    return <ErrorPage error={error} />;
  }

  const { family_name: familyName, given_name: givenName, nickname } = user;
  const username =
    familyName && givenName ? `${givenName} ${familyName}` : nickname;

  return (
    <Box p="r">
      <WorkspaceForm
        preferredUsername={username}
        onSubmit={onCreateWorkspace}
      />
    </Box>
  );
};

export default withRouter(CreateWorkspacePage);
