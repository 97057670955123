import moment from "moment";

import { isMacroAllocationRejected } from "src/allocation/util/macroAllocation";
import newNotification from "../newNotification";
import { DANGER, WARN, INFO } from "../../../../notificationStatus";

export default (macroAllocation, isApprover, approvalReasons) => {
  const {
    approved,
    approvalSavedAt,
    approvalSubmitted,
    approvalSubmittedAt,
    requestSavedAt,
    requestSubmitted,
    requestSubmittedAt,
    allocatedMemberFte,
  } = macroAllocation;

  if (isApprover) {
    // approver, approvalCell notifications
    if (
      requestSubmittedAt &&
      !approvalSavedAt &&
      (requestSubmitted !== allocatedMemberFte ||
        approved !== allocatedMemberFte)
    ) {
      return newNotification({
        status: DANGER,
        message: "Request waiting for Response",
        groupMessage: "Responses to confirm",
      });
    }

    if (requestSubmittedAt && approvalSavedAt) {
      if (
        moment(requestSubmittedAt).isAfter(moment(approvalSavedAt)) &&
        requestSubmitted !== approved
      ) {
        return newNotification({
          status: DANGER,
          message: "Request waiting for Response",
          groupMessage: "Responses to confirm",
        });
      }
    }

    const isRejection = isMacroAllocationRejected(
      macroAllocation,
      approvalReasons
    );

    if (approvalSavedAt && !approvalSubmittedAt) {
      return newNotification({
        status: WARN,
        message: `${isRejection ? "Rejection" : "Response"} saved ${moment(
          approvalSavedAt
        ).fromNow()} waiting for submission`,
        groupMessage: "Responses to submit",
      });
    }

    if (approvalSavedAt && approvalSubmittedAt) {
      if (moment(approvalSavedAt).isAfter(moment(approvalSubmittedAt))) {
        return newNotification({
          status: WARN,
          message: `${isRejection ? "Rejection" : "Response"} saved ${moment(
            approvalSavedAt
          ).fromNow()} waiting for submission`,
          groupMessage: "Responses to submit",
        });
      }

      return newNotification({
        status: INFO,
        message: `Response submitted ${moment(approvalSubmittedAt).fromNow()}`,
        groupMessage: "Responses submitted",
      });
    }

    return newNotification({
      status: null,
      message: null,
      groupMessage: null,
      columnMessage: null,
    });
  }

  // requestor, approvalCell notifications
  if (
    requestSubmittedAt &&
    !approvalSubmittedAt &&
    requestSubmitted !== allocatedMemberFte
  ) {
    return newNotification({
      status: WARN,
      message: "No Response",
      groupMessage: "Waiting for responses",
    });
  }

  if (requestSubmittedAt && approvalSubmittedAt) {
    if (
      moment(requestSubmittedAt).isAfter(moment(approvalSubmittedAt)) &&
      requestSubmitted !== approvalSubmitted
    ) {
      return newNotification({
        status: WARN,
        message: `Previous Response submitted ${moment(
          approvalSubmittedAt
        ).fromNow()}`,
        groupMessage: "Waiting for Responses",
      });
    }

    return newNotification({
      status: INFO,
      message: `Response submitted ${moment(approvalSubmittedAt).fromNow()}`,
      groupMessage: "Responses submitted",
    });
  }

  if (
    approvalSubmittedAt &&
    !requestSavedAt &&
    approvalSubmitted !== allocatedMemberFte
  ) {
    return newNotification({
      status: WARN,
      message: `Response submitted without request ${moment(
        approvalSubmittedAt
      ).fromNow()}`,
      groupMessage: "Responses submitted without requests",
    });
  }

  return newNotification({
    status: null,
    message: null,
    groupMessage: null,
    columnMessage: null,
  });
};
