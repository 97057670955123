import { forEach } from "lodash";

import setLineItemWithForecastDefaultCells from "./setLineItemWithForecastDefaultCells";

const setGroupingsWithForecastDefaultCells = (
  groupings,
  columns,
  groupedByDemand,
  rootGroup
) => {
  forEach(groupings, (grouping) => {
    forEach(grouping.lineItems, (lineItem) => {
      setLineItemWithForecastDefaultCells({
        lineItem,
        targetGroupId: groupedByDemand ? grouping.groupId : lineItem.groupId,
        sourceGroupId: groupedByDemand ? lineItem.groupId : grouping.groupId,
        columns,
        rootGroup,
      });
    });

    setGroupingsWithForecastDefaultCells(
      grouping.childGroupings,
      columns,
      groupedByDemand,
      rootGroup
    );
  });
};

export default setGroupingsWithForecastDefaultCells;
