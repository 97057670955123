import PropTypes from "prop-types";
import React from "react";

import TagDropdown from "src/components/TagDropdown";
import TagSearchSelect from "src/components/TagSearchDropdown";

import { checkConfigHasNamedAttributes } from "./utils";

const TagAttributeTagSelect = ({
  value,
  onSelectTag,
  label,
  associatedValue,
  disabled,
  invalid,
  mandatory,
  focus,
  tagTypesConfig,
}) => {
  const hasNamedAttributes = checkConfigHasNamedAttributes({
    tagTypesConfig,
    id: associatedValue,
  });

  if (!hasNamedAttributes) {
    return (
      <TagSearchSelect
        label={label}
        tagTypes={[associatedValue]}
        value={value}
        onSelectTag={onSelectTag}
        disabled={disabled}
        invalid={invalid}
        mandatory={mandatory}
        focus={focus}
      />
    );
  }

  return (
    <TagDropdown
      label={label}
      tagTypes={[associatedValue]}
      value={value}
      onSelectTag={onSelectTag}
      disabled={disabled}
      invalid={invalid}
      mandatory={mandatory}
      focus={focus}
    />
  );
};

TagAttributeTagSelect.propTypes = {
  label: PropTypes.string,
  associatedValue: PropTypes.string,
  value: PropTypes.string,
  onSelectTag: PropTypes.func,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  mandatory: PropTypes.bool,
  focus: PropTypes.bool,
  tagTypesConfig: PropTypes.array,
};

export default TagAttributeTagSelect;
