import { isObject, map } from "lodash";
import React from "react";
import { Node } from "slate";

export const getFormattedMsg = (content) => {
  if (isObject(content)) {
    return content;
  }

  try {
    return JSON.parse(content);
  } catch {
    // if JSON parse fails, it's just a normal string
    return content;
  }
};

export const serializeToPlainText = (msg) => {
  try {
    return map(msg, (n) => Node.string(n)).join("\n");
  } catch (error) {
    return "";
  }
};

export const isContentEmpty = (msg) => {
  // Replace all separators (space, \n, \r, \t)
  const plainInput = serializeToPlainText(msg).replace(/\s/g, "");
  return !plainInput;
};

export const isTagMention = (node) => {
  return node.type === "mention" && !!node.tag;
};

export const parseLeaf = (obj) => {
  const { text, bold, italic, underline } = obj;

  if (!text) {
    return null;
  }

  let component = <span>{text}</span>;

  if (bold) {
    component = <strong>{component}</strong>;
  }

  if (italic) {
    component = <em>{component}</em>;
  }

  if (underline) {
    component = <u>{component}</u>;
  }

  return component;
};
