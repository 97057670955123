import { includes, get, endsWith } from "lodash";
import { ADMIN_ROLE } from "../consts/auth";

const hasRole = (auth, role) => {
  const roles = get(auth, "roles");
  return includes(roles, role);
};

// eslint-disable-next-line import/prefer-default-export
export const hasAdminRole = (auth) => {
  return hasRole(auth, ADMIN_ROLE);
};

// eslint-disable-next-line import/prefer-default-export
export const isSupportUser = (email) =>
  endsWith(email, "@support.orchestrated.io") ||
  endsWith(email, "@support.teamform.co");
