import { filter, isEmpty, last } from "lodash";
import { useEffect } from "react";

import EE, { EVENTS } from "src/services/eventEmitter";
import { NOOP } from "src/util/consts";
import { findTreeNodes } from "src/shared/TreeView/TreeView.util";

const useGroupRemovedListener = (root, refreshTree, eventEmitter = EE) => {
  useEffect(() => {
    if (isEmpty(root)) {
      return NOOP;
    }

    const handler = (group) => {
      Promise.resolve().then(() => {
        if (!group) {
          return;
        }
        const { hierarchyIds } = group;

        const targetIds = [...hierarchyIds];

        // Need to find the direct parent, so pop the last item
        targetIds.pop();

        if (isEmpty(targetIds)) {
          // There is a possibility the targetIds is empty, in that case,
          // the removed group should be the first level of root
          // in fact, I don't think this is possible... Let's just reload the page then...
          window.location.reload();
          return;
        }

        // Find tree nodes by hierarchyIds, the children of the node may not be loaded
        const treeNodes = findTreeNodes(root, targetIds);

        // target group is not in the tree yet, no need to change anything
        if (treeNodes.length < targetIds.length) {
          return;
        }

        const directParentNode = last(treeNodes);

        // Try to remove the node
        if (directParentNode) {
          directParentNode.children = filter(
            directParentNode.children,
            (n) => n.id !== group.id
          );
          refreshTree();
        }
      });
    };

    eventEmitter.on(EVENTS.GROUP_REMOVED, handler);

    return () => {
      eventEmitter.removeListener(EVENTS.GROUP_REMOVED, handler);
    };
  }, [root, refreshTree, eventEmitter]);
};

export default useGroupRemovedListener;
