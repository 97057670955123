/* eslint-disable react/prop-types */
import { Button, Box, Modal, H3 } from "orcs-design-system";
import React from "react";

import { RichTextContentViewer } from "src/shared/RichTextContent/RichTextContent";
//  TODO: Add back props validation here when we are not rushing

const TagTypeHelpModal = ({ onClose, tagType }) => {
  const modalHeader = <H3>{tagType?.name} - Tag type description</H3>;
  const modalFooter = <Button onClick={onClose}>Close</Button>;
  return (
    <Modal
      height="auto"
      width={["90vw", "90vw", "80vw", "70vw", "50vw"]}
      maxHeight="90vh"
      maxWidth="90vw"
      visible={!!tagType}
      onClose={onClose}
      footerContent={modalFooter}
      headerContent={modalHeader}
    >
      <Box p="s">
        <RichTextContentViewer content={tagType?.detailsParsed} />
      </Box>
    </Modal>
  );
};

export default TagTypeHelpModal;
