/* istanbul ignore file */
import { Flex, Button, P, Modal } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const ProdConfirmationDialog = ({ visible, onConfirm, onClose }) => {
  const modalFooter = (
    <Flex mt="r">
      <Button variant="success" onClick={onConfirm}>
        Yes, allow changes
      </Button>
      <Button variant="ghost" onClick={onClose} ml="r">
        Cancel
      </Button>
    </Flex>
  );
  return (
    <Modal
      visible={visible}
      handleOnConfirm={onConfirm}
      onClose={onClose}
      footerContent={modalFooter}
      width="50vw"
      height="40vh"
      maxHeight="90vh"
      maxWidth="90vw"
    >
      <P weight="bold" fontSize={3}>
        This is a customer prod environment.
      </P>
      <P mt="r" fontSize={3}>
        Are you sure you want to turn off read-only mode and allow changes?
      </P>
      <P my="r" fontSize={3}>
        This will only apply to your current signed in user.
      </P>
    </Modal>
  );
};

ProdConfirmationDialog.propTypes = {
  visible: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProdConfirmationDialog;
