import { get, isEqual } from "lodash";
import { useEffect } from "react";

import EE, { EVENTS } from "src/services/eventEmitter";
import { expandTreeNodes } from "src/shared/TreeView/TreeView.util";

const useUpdateCurrentGroupListener = (
  root,
  setHierarchyIds,
  refreshTree,
  eventEmitter = EE
) => {
  useEffect(() => {
    const handler = (group) => {
      // Activate the group by hierarchyIds
      Promise.resolve().then(() => {
        const pathIds = get(group, "hierarchyIds", []);
        setHierarchyIds((hIds) => {
          if (!group) {
            expandTreeNodes(root, hIds, false);
            refreshTree();
          }

          if (isEqual(hIds, pathIds)) {
            return hIds;
          }
          return pathIds;
        });
      });
    };

    eventEmitter.on(EVENTS.CURRENT_GROUP_UPDATED, handler);

    return () => {
      eventEmitter.removeListener(EVENTS.CURRENT_GROUP_UPDATED, handler);
    };
  }, [setHierarchyIds, root, refreshTree, eventEmitter]);
};

export default useUpdateCurrentGroupListener;
