import moment from "moment";

import { PENDING } from "../../../../../saveStatus";
import { PLANNER_APPROVED } from "../../../../../columnTypes";
import { newPlannerApproverCellNotification } from "../../../newNotification";
import { calculateDelta } from "../../../cellDelta";

import newCell from "../../newCell";
import getFte from "./getFte";

const getPrepopulatedValueFromRequestSubmitted = (
  requestSubmittedAt,
  approvalSavedAt,
  requestSubmitted,
  value
) => {
  // nothing submitted from requestor
  if (!requestSubmittedAt) {
    return [null, null];
  }

  // requestor submitted value, but already used
  if (requestSubmitted === value) {
    return [null, null];
  }

  // we have not approved anything yet, prepopulate with new request
  if (!approvalSavedAt) {
    return [requestSubmitted, PENDING];
  }

  // user has a new requestSubmitted value, but has already saved something
  // prepopulate with original saved value. This gives user ability to save original value again (responding to request)
  if (moment(requestSubmittedAt).isAfter(moment(approvalSavedAt))) {
    return [value, PENDING];
  }

  // nothing to pre-populate
  return [null, null];
};

export default ({
  lineItem,
  macroAllocation,
  isApprover,
  isEditable,
  approvalReasons,
}) => {
  const {
    id,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    requestSubmitted,
    requestSubmittedAt,
    approved,
    approvalSubmitted,
    approvalSavedAt,
    allocatedMemberFte, // current fte
    note, // skills
    approvalReasonId,
    approvalReasonCustomText,
  } = macroAllocation;

  const { grouping } = lineItem;
  const fte = isApprover ? approved : approvalSubmitted; // requestor only sees submitted
  const [value, defaultedValue] = grouping.isMovedOut
    ? [null, null]
    : [getFte(fte), getFte(allocatedMemberFte) || 0];
  const delta = calculateDelta(value, defaultedValue);
  const notification = newPlannerApproverCellNotification(
    macroAllocation,
    isApprover,
    approvalReasons
  );
  const [prepopulatedValue, valueSaveStatus] =
    getPrepopulatedValueFromRequestSubmitted(
      requestSubmittedAt,
      approvalSavedAt,
      requestSubmitted,
      value
    );

  return newCell({
    lineItem,
    id: `${id}-${PLANNER_APPROVED}`,
    columnType: PLANNER_APPROVED,
    macroAllocationId: id,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    isStatic: false,
    isEditable,
    value,
    defaultedValue,
    prepopulatedValue,
    valueSaveStatus,
    delta,
    skills: note,
    notification,
    approvalReasonId,
    approvalReasonCustomText,
    requestSubmittedAt,
    approvalSavedAt,
  });
};
