const createActionsMenuHandler = (selector) => {
  const suppressActionsMenuClose = (e) => {
    e.stopImmediatePropagation();
  };

  const openActionsMenu = () => {
    const menu = document.querySelector(selector);
    if (!menu) {
      return;
    }

    // Find the control button
    const ctrlBtn = document.querySelector(`${selector} > button`);
    if (!ctrlBtn) {
      return;
    }

    // Have to disable transition to make the react-joyride get the right width of Action item
    const wrapper = document.querySelector(`${selector} > div`);
    if (!wrapper) {
      return;
    }
    wrapper.style.transition = "none";

    // Avoid clicking somewhere to close the ActionsMenu
    document.addEventListener("click", suppressActionsMenuClose);

    // Click the control button to show ActionsMenu
    ctrlBtn.click();
  };

  const closeActionsMenu = () => {
    // Remove suppress listener first
    document.removeEventListener("click", suppressActionsMenuClose);

    // Cleanup added styles
    const wrapper = document.querySelector(`${selector} > div`);
    if (!wrapper) {
      return;
    }

    // By clicking other places, close actions menu
    document.body.click();
    wrapper.style.transition = "";
  };

  return [openActionsMenu, closeActionsMenu];
};

export default createActionsMenuHandler;
