/* istanbul ignore file */
import { themeGet } from "@styled-system/theme-get";

const customStyles = ({ theme }) => ({
  menu: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
    color: themeGet("colors.white")({ theme }),
    fontSize: themeGet("fontSizes.0")({ theme }),
    border: "none",
    borderRadius: "0",
    overflow: "visible",
    zIndex: 12,
    boxShadow: "none",
    marginTop: "11px",
    marginBottom: "0",
  }),
  menuList: (provided) => ({
    ...provided,
    overflow: "auto",
    color: themeGet("colors.white")({ theme }),
    width: "fit-content",
    backgroundColor: themeGet("colors.greyDarkest")({ theme }),
    borderRadius: themeGet("radii.2")({
      theme,
    }),
    padding: themeGet("space.s")({
      theme,
    }),
  }),
  control: (provided) => ({
    ...provided,
    cursor: "pointer",
    height: "15px",
    minHeight: "15px",
    border: "none",
    backgroundColor: "transparent",
    fontSize: themeGet("fontSizes.0")({ theme }),
    overflow: "hidden",
    boxShadow: "none",
  }),
  container: (provided) => ({
    ...provided,
    fontSize: themeGet("fontSizes.0")({ theme }),
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: themeGet("fontSizes.0")({ theme }),
    color: themeGet("colors.white")({ theme }),
    marginLeft: "0",
    maxWidth: "225px",
    marginRight: "0",
    position: "relative",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0",
    height: "15px",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: themeGet("space.xxs")({ theme }),
    color: themeGet("colors.white")({ theme }),
    "&:hover": {
      color: themeGet("colors.danger")({ theme }),
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    "> div": {
      padding: "0",
      height: "auto",
      "> svg": {
        height: "15px",
        width: "15px",
      },
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: themeGet("space.xxs")({ theme }),
    color: themeGet("colors.white")({ theme }),
    "&:hover": {
      color: themeGet("colors.white")({ theme }),
    },
  }),
  option: (provided) => ({
    ...provided,
    cursor: "pointer",
    width: "max-content",
    minWidth: "100%",
    backgroundColor: "transparent",
    fontSize: themeGet("fontSizes.0")({ theme }),
    color: themeGet("colors.white")({ theme }),
    whiteSpace: "break-spaces",
    padding: `${themeGet("space.xs")({ theme })} ${themeGet("space.s")({
      theme,
    })}`,
    marginBottom: themeGet("space.xs")({ theme }),
    transition: "all 200ms ease-in-out",
    "&:hover": {
      backgroundColor: themeGet("colors.primaryDark")({ theme }),
      borderRadius: themeGet("radii.2")({ theme }),
    },
    "&:last-of-type": {
      marginBottom: "0",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: themeGet("colors.white")({ theme }),
    fontSize: themeGet("fontSizes.0")({ theme }),
  }),
});

export default customStyles;
