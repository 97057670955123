import { Button, Icon } from "orcs-design-system";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import React from "react";

import icons from "src/config/icons";

const Footer = ({ isDisabled, onInviteUser, isProcessing, mailCount }) => {
  return (
    <Button
      iconLeft
      variant={isDisabled}
      onClick={onInviteUser}
      isLoading={isProcessing}
      data-testid="cp-allocate-team-add-btn"
    >
      <Icon icon={icons.invite} />
      Send {pluralize("Invite", mailCount)}
    </Button>
  );
};

Footer.propTypes = {
  isProcessing: PropTypes.bool,
  onInviteUser: PropTypes.func.isRequired,
  isDisabled: PropTypes.string,
  mailCount: PropTypes.number,
};

export default Footer;
