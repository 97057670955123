import { debounce } from "lodash";
import { useEffect } from "react";

import { NOOP } from "src/consts/global";

const useWindowResizeListener = (handler, disable, theme, containerRef) => {
  useEffect(() => {
    if (disable) {
      return NOOP;
    }

    const eventHandler = debounce(() => {
      // Trigger handler in another running cycle, instead of the resize handler
      Promise.resolve().then(() => handler(window.innerWidth, theme));
    }, 200);

    // If there is
    window.addEventListener("resize", eventHandler);

    // Trigger handler for the first time render
    handler(window.innerWidth, theme);

    return () => {
      window.removeEventListener("resize", eventHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler, disable, theme, containerRef?.current]);
};

export default useWindowResizeListener;
