import { isNil } from "lodash";

const getDefaultValueFromPreviousCell = (
  previousCell = { value: 0 },
  allocationProject
) => {
  if (allocationProject.disablePrefillFutureForecastFromPrevious) {
    return 0;
  }
  return (
    (!isNil(previousCell.value)
      ? previousCell.value
      : previousCell.defaultedValue) || 0
  );
};

export default getDefaultValueFromPreviousCell;
