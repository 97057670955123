import { every } from "lodash";

import { getGuidedTourFromLocalStorage } from "src/services/localStorage";
import joyRideTourComplete from "./joyRideTourComplete";

const tourDependenciesComplete = (username, tourDependencies) =>
  every(tourDependencies, (tourDependency) =>
    joyRideTourComplete(getGuidedTourFromLocalStorage(username, tourDependency))
  );

export default tourDependenciesComplete;
