import {
  filter,
  get,
  values,
  mapValues,
  keyBy,
  forEach,
  partition,
  size,
} from "lodash";

import { newConstraintGrouping, findGroupingFromParentIds } from "../../util";

export default ({
  rootGroup,
  macroAllocations,
  constraints,
  extraSupplyGroups,
  columnCount,
  hideHiddenTeams,
  groupTypes,
}) => {
  // collect all our possible source groups
  const sourceGroupLookup = {
    ...mapValues(keyBy(macroAllocations, "sourceGroupId"), "sourceGroup"),
    ...mapValues(keyBy(constraints, "groupId"), "group"),
    ...keyBy(extraSupplyGroups, "id"),
  };
  delete sourceGroupLookup[rootGroup.id];

  // find all unallocatable source groups
  const unallocateableSourceGroupTypes = keyBy(
    filter(
      values(groupTypes),
      (gt) => gt.isSupply && !gt.isSupplySource && !size(gt.childTypes) > 0
    ),
    "id"
  );

  let sourceGroupsYetToInsert = values(sourceGroupLookup);
  let sourceGroupsToInsert = [];
  const rootGrouping = newConstraintGrouping({
    group: rootGroup,
    parentGrouping: null,
    groupId: rootGroup.id,
    nestedLevel: 0,
    isSupply: rootGroup.isSource,
    isDemand: !rootGroup.isSource,
    isRoot: true,
    memberCount: rootGroup.memberCount,
    capacityFte: get(rootGroup, "totalCapacityFte", 0),
    isVirtualDirectConstraintGrouping: false,
    canAllocate: !unallocateableSourceGroupTypes[rootGroup.type],
    columnCount,
  });
  rootGrouping.root = rootGrouping;
  // insert the groups in order from highest hierarchy level to lowest.
  for (let hierarchyLevel = 0; hierarchyLevel < 999; hierarchyLevel += 1) {
    [sourceGroupsToInsert, sourceGroupsYetToInsert] = partition(
      sourceGroupsYetToInsert,
      (s) => s.parentIds.length === hierarchyLevel
    );

    forEach(sourceGroupsToInsert, (sourceGroup) => {
      if (!(hideHiddenTeams && sourceGroup.isHidden)) {
        const groupingParent =
          findGroupingFromParentIds(
            rootGrouping.childGroupings,
            sourceGroup.parentIds
          ) || rootGrouping;
        const [nestedLevel, groupingPool] = [
          groupingParent.nestedLevel + 1,
          groupingParent.childGroupings,
        ];

        const grouping = newConstraintGrouping({
          group: sourceGroup,
          parentGrouping: groupingParent,
          groupId: sourceGroup.id,
          nestedLevel,
          memberCount: sourceGroup.memberCount,
          isSupply: true,
          capacityFte: get(sourceGroup, "totalCapacityFte", 0),
          isVirtualDirectConstraintGrouping: false,
          canAllocate: !unallocateableSourceGroupTypes[sourceGroup.type],
          columnCount,
        });
        if (groupingPool.length === 0) {
          // push a direct member grouping
          const isRootParent = groupingParent.groupId === rootGroup.id;
          const directMembersGrouping = newConstraintGrouping({
            group: groupingParent.group,
            parentGrouping: groupingParent,
            groupId: groupingParent.groupId,
            nestedLevel,
            memberCount: groupingParent.memberCount,
            isSupply: true,
            capacityFte: isRootParent
              ? get(rootGroup, "directMembersCapacityFte", 0)
              : get(groupingParent.group, "directMembersCapacityFte", 0),
            isVirtualDirectConstraintGrouping: true,
            canAllocate: false,
            columnCount,
          });
          groupingPool.push(directMembersGrouping);
        }
        groupingPool.push(grouping);
      }
    });

    if (sourceGroupsYetToInsert.length === 0) {
      break;
    }
  }

  const sortGroupings = (groupings) => {
    // sort priority:
    //   1: virtual direct member grouping first
    //   2: by name

    groupings.sort((a, b) => {
      if (a.isVirtualDirectConstraintGrouping) {
        return -1;
      }

      if (b.isVirtualDirectConstraintGrouping) {
        return 1;
      }

      // sort by name third
      return a.group.name.localeCompare(b.group.name);
    });

    forEach(groupings, (grouping) => {
      if (grouping.childGroupings) {
        sortGroupings(grouping.childGroupings);
      }
    });
  };

  sortGroupings(rootGrouping.childGroupings);

  return [rootGrouping];
};
