import { newPlannerDefaultedCell } from "../newCell";

import {
  PLANNER_CURRENT,
  PLANNER_REQUESTED,
  PLANNER_APPROVED,
} from "../../../columnTypes";

const setLineItemWithPlannerDefaultCells = ({
  lineItem,
  targetGroupId,
  sourceGroupId,
  allocationProject,
  isRequestor,
}) => {
  const { id: allocationProjectId, workingDays } = allocationProject;

  const currentCell = newPlannerDefaultedCell({
    lineItem,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    columnType: PLANNER_CURRENT,
    isEditable: false,
  });
  const requestorCell = newPlannerDefaultedCell({
    lineItem,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    columnType: PLANNER_REQUESTED,
    isEditable: isRequestor, // not checking feature flag here as this will always be editable if possible to add new row on demand side
    dailyRate: lineItem.dailyRate,
    workingDays,
  });

  const approverCell = newPlannerDefaultedCell({
    lineItem,
    targetGroupId,
    sourceGroupId,
    allocationProjectId,
    columnType: PLANNER_APPROVED,
    isEditable: !isRequestor, // not checking feature flag here as this will always be editable if possible to add new row on supply side
  });

  // eslint-disable-next-line no-param-reassign
  lineItem.cells = [currentCell, requestorCell, approverCell];
};

export default setLineItemWithPlannerDefaultCells;
