import { map } from "lodash";
import { Flex, Button, Icon, Small, Popover } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const EmailRow = ({ email, onRemoveEmail, isProcessing }) => {
  return (
    <Flex
      mt="r"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
    >
      <Small fontWeight="bold">{email}</Small>
      <Popover text="Remove email" direction="left" width="100px" ml="s">
        <Button
          iconOnly
          small
          height="20px"
          width="20px"
          ariaLabel={`Remove ${email}`}
          variant="danger"
          onClick={onRemoveEmail}
          disabled={isProcessing}
          data-testid="cp-allocate-team-remove-btn"
        >
          <Icon icon={["fas", "times"]} />
        </Button>
      </Popover>
    </Flex>
  );
};

EmailRow.propTypes = {
  email: PropTypes.string,
  onRemoveEmail: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool,
};

const EmailList = ({ emails, onRemoveEmail, isProcessing }) => {
  return map(emails, (email, index) => (
    <EmailRow
      key={email}
      email={email}
      index={index}
      onRemoveEmail={onRemoveEmail(index)}
      isProcessing={isProcessing}
    />
  ));
};

EmailList.propTypes = {
  emails: PropTypes.arrayOf(PropTypes.string),
  onRemoveEmail: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool,
};

export default EmailList;
