export const OBJECTIVE_GROUP_TYPE = "group";
export const OBJECTIVE_GROUP_KIND = "objective";

export const BUSINESS_OUTCOME_FIELD = {
  CLARITY_PPM: "clarityPPM",
};

export const OBJECTIVE_SOURCE = {
  Jira: "jira",
  StrategyFromFile: "StrategyFromFile",
  Planview: "planview",
  Clarity: "clarityTeamsAndObjectives",
};

export const OBJECTIVE_ATTRIBUTE_TYPE = {
  PROGRESS_PERCENTAGE: "progressPercentage",
  DESCRIPTION: "description",
  TYPE: "type",
  LEVEL: "level",
  STATUS: "status",
  STATUS_COLOR: "statusColor",
  UPDATED_AT: "updatedAt",
  EXTERNAL_URL: "externalUrl",
};

export const NO_OBJECTIVES_ASSOCIATED_ID = "NULL_OBJECTIVE";

export const OBJECTIVE_THEME = {
  [OBJECTIVE_SOURCE.StrategyFromFile]: {
    variant: "success",
    variantLight: "success70",
  },
  [OBJECTIVE_SOURCE.Jira]: {
    variant: "primary",
    variantLight: "primary70",
  },
  [OBJECTIVE_SOURCE.Planview]: {
    variant: "danger",
    variantLight: "danger70",
  },
  [OBJECTIVE_SOURCE.Clarity]: {
    variant: "warning",
    variantLight: "warning70",
  },
};

export const LINK_OBJECTIVE_DATASOURCE = {
  IMPORT: "import",
  PLANNER: "planner",
};
