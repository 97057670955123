import { times, constant, get } from "lodash";
import { roundFte } from "src/util/roundingStrategy";
import { getDailyRateByFlag } from "./getDailyRate";

export const newGrouping = ({
  id,
  groupId,
  parentGrouping,
  rootGrouping,
  intersectingGrouping,
  nestedLevel,
  group = null,
  memberCount = null,
  isDemand = false,
  isSupply = false,
  isLineOfBusiness = false,
  isRoot = false,
  isVirtual = false,
  isSkillsMandatory = false,
  canHide = false,
  canAddLineItem = false,
  canViewInTeamBuilder = false,
  canViewInDetails = false,
  canRename = false,
  canAllocate = true,
  canEditObjectives = false,
  objectiveTypes = [],
  showOnlyLinkedObjectives = true,
  isObjectivesImported = false,
  isObjectivesVerified = true,
  canSubmit = false,
  canOnlySubmitWhenAllValid = false,
  canHaveConstraints = false,
  showObjectives,
  columnCount,
  dailyRate = null,
  budget = null,
  totalCost = null,
  isMovedOut = false,
  isMovedIn = false,
  isMovedInternally = false,
  movedParent = null,
}) => {
  return {
    id: id || groupId, // we occasionally need a unqiue ID if multiple groupings with the same groupId exist, otherwise default to groupId.
    group,
    groupId,
    nestedLevel,
    parent: parentGrouping,
    root: rootGrouping,
    intersecting: intersectingGrouping, // we occasionally have a tree within a tree, which is intersecting. (ie functions and groups under a chapter)
    lineItems: [],
    childGroupings: [],
    constraints: canHaveConstraints ? times(columnCount, constant(null)) : [],
    summedTotals: [],
    lineItemTotals: [],
    notifications: [],
    lineItemNotifications: [],
    memberCount,
    isDemand,
    isSupply,
    isLineOfBusiness,
    isRoot,
    isVirtual,
    isSkillsMandatory, // any change in FTE requires a skills edit
    canAddLineItem, // add new role
    canToggleLineItems: canAddLineItem,
    canHide,
    canViewInTeamBuilder,
    canViewInDetails,
    canRename,
    canAllocate, // some (source) groups (or their descendants) cannot have allocations against them
    canEditObjectives,
    objectiveTypes,
    showOnlyLinkedObjectives,
    isObjectivesImported,
    isObjectivesVerified,
    canSubmit,
    canOnlySubmitWhenAllValid,
    canHaveConstraints,
    showObjectives,
    dailyRate,
    budget,
    totalCost,
    budgetPercentage: null,
    budgetStatus: null,
    isMovedOut,
    isMovedIn,
    isMovedInternally,
    movedParent,
  };
};

export const newConstraintGrouping = ({
  groupId,
  isVirtualDirectConstraintGrouping,
  capacityFte,
  columnCount,
  ...args
}) => {
  const constraintGroupId = isVirtualDirectConstraintGrouping
    ? `${groupId}-virtual-direct-constraint-grouping`
    : groupId;
  return {
    ...newGrouping({
      ...args,
      groupId: constraintGroupId,
    }),
    isVirtualDirectConstraintGrouping,
    capacityFte: capacityFte && roundFte(capacityFte),
    constraints: times(columnCount, constant(null)),
  };
};

const shouldVerifyObjectives = (group, activeAllocationProject) => {
  if (get(activeAllocationProject, "shouldVerifyObjectives")) {
    return (
      get(group, "objectiveVerifiedFor") === get(activeAllocationProject, "id")
    );
  }

  // As if objectives already verified
  return true;
};

export const newGroupingOptions = ({
  group,
  groupType,
  parentGrouping,
  activeAllocationProject,
  isBudgetEnabled,
  sourceGroupLookup,
}) => {
  return {
    group,
    groupId: group.id,
    isSupply: get(groupType, "isSupply", false),
    isDemand: get(groupType, "isDemand", false),
    isLineOfBusiness: get(groupType, "isLineOfBusiness", false),
    canRename: get(groupType, "canRename", false),
    canHide: get(groupType, "planning.canHide", false) && !group.isHidden,
    canSubmit: get(groupType, "planning.canSubmit", false),
    canOnlySubmitWhenAllValid: get(
      groupType,
      "planning.canOnlySubmitWhenAllValid",
      false
    ),
    canEditObjectives: get(groupType, "planning.canEditObjectives", false),
    canHaveConstraints: get(groupType, "planning.canHaveConstraints", false),
    isSkillsMandatory: get(groupType, "planning.isSkillsMandatory", false),
    isObjectivesImported: group.isObjectiveImported,
    isObjectivesVerified: shouldVerifyObjectives(
      group,
      activeAllocationProject
    ),
    dailyRate: getDailyRateByFlag(
      isBudgetEnabled,
      group,
      parentGrouping,
      sourceGroupLookup
    ),
  };
};
