// @ts-check
import { get, filter, orderBy, head, map } from "lodash";
import {
  SAVED,
  SUBMITTED,
  NONE,
  APPROVAL_REASON_TYPES,
} from "src/allocation/consts";

/**
 * @typedef {import('./group').Group} Group
 * Macro allocation object
 * @typedef {Object} MacroAllocation
 * @property {string} id
 * @property {string} [allocationProjectId]
 * @property {string} [requestorGroupId]
 * @property {string} [approverGroupId]
 * @property {string} [targetGroupId]
 * @property {Group} [targetGroup]
 * @property {Group} sourceGroup
 * @property {string} [sourceGroupId]
 * @property {number} approved
 * @property {string} [approvalStatus]
 * @property {number} requested
 * @property {string} [requestStatus]
 * @property {number} [approvalSubmitted] // float value for allocation
 * @property {number} [approvalSubmittedAt] // unix timestamp
 * @property {number} [approvalSavedAt]
 * @property {number} [requestSubmitted] // float value for allocation
 * @property {number} [requestSubmittedAt] // unix timestamp
 * @property {number} [requestSavedAt]
 */

/**
 * getSubmittedMacroAllocationValuesForApprover
 * @param {MacroAllocation[]} allocations macro allocation
 * @returns {MacroAllocation[]} submitted allocations with approvalStatus and approved calculated
 */
export const getSubmittedMacroAllocationValuesForApprover = (allocations) => {
  return map(
    filter(
      allocations,
      (allocation) =>
        !(
          allocation.approvalStatus === SAVED &&
          !allocation.approvalSubmitted &&
          !allocation.requested
        ) // filter out any saved with nothing submitted
    ),
    (allocation) => ({
      // map to submitted only values
      ...allocation,
      approvalStatus: allocation.approvalSubmitted ? SUBMITTED : NONE,
      approved: allocation.approvalSubmitted,
    })
  );
};

/**
 * getSubmittedMacroAllocationValuesForRequestor
 * @param {MacroAllocation[]} allocations macro allocation
 * @returns {MacroAllocation[]} submitted allocations with requestStatus and requested calculated
 */
export const getSubmittedMacroAllocationValuesForRequestor = (allocations) => {
  return map(
    filter(
      allocations,
      (allocation) =>
        !(
          allocation.requestStatus === SAVED &&
          !allocation.requestSubmitted &&
          !allocation.approved
        ) // filter out any saved with nothing submitted
    ),
    (allocation) => ({
      ...allocation,
      requestStatus: allocation.requestSubmitted ? SUBMITTED : NONE,
      requested: allocation.requestSubmitted,
    })
  );
};

/**
 * merge multiple macro allocations into on by taking the latest value from both approval and request side
 * make sure target and source group are same in all allocations.
 * If no macroAllocations exist, create a default preallocated macro allocation
 * @param {MacroAllocation[]} [macroAllocations=[]]
 * @returns {MacroAllocation} merged macro allocation
 */
export const mergeMacroAllocations = (macroAllocations = []) => {
  if (macroAllocations.length === 0) {
    // fail hard, we shouldn't be here
    // we cannot merge nothing into an imaginary macro allocation
    throw new Error("this method requires at least 1 macro allocation");
  } else if (macroAllocations.length === 1) {
    return macroAllocations[0];
  }

  const orderedByApprovedDates = orderBy(
    filter(
      macroAllocations,
      ({ approvalSubmittedAt, approvalSavedAt }) =>
        approvalSubmittedAt || approvalSavedAt
    ),
    ["approvalSubmittedAt", "approvalSavedAt"],
    ["desc", "desc"]
  );

  const orderedByRequestedDates = orderBy(
    filter(
      macroAllocations,
      ({ requestSubmittedAt, requestSavedAt }) =>
        requestSubmittedAt || requestSavedAt
    ),
    ["requestSubmittedAt", "requestSavedAt"],
    ["desc", "desc"]
  );

  const approved = get(orderedByApprovedDates, "[0].approved");
  const approvalStatus = get(orderedByApprovedDates, "[0].approvalStatus");

  const requested = get(orderedByRequestedDates, "[0].requested");
  const requestStatus = get(orderedByRequestedDates, "[0].requestStatus");

  const approvalSubmittedAt = get(
    orderedByApprovedDates,
    "[0].approvalSubmittedAt"
  );
  const approvalSavedAt = get(orderedByApprovedDates, "[0].approvalSavedAt");
  const requestSubmittedAt = get(
    orderedByRequestedDates,
    "[0].requestSubmittedAt"
  );
  const requestSavedAt = get(orderedByRequestedDates, "[0].requestSavedAt");

  const headMacroAllocation = head(macroAllocations);
  const {
    id,
    requestorGroupId,
    approverGroupId,
    targetGroupId,
    targetGroup,
    sourceGroup,
    sourceGroupId,
  } = headMacroAllocation;

  return {
    id,
    requestorGroupId,
    approverGroupId,
    targetGroupId,
    targetGroup,
    sourceGroup,
    sourceGroupId,
    approved,
    approvalStatus,
    requested,
    requestStatus,
    approvalSubmittedAt,
    approvalSavedAt,
    requestSubmittedAt,
    requestSavedAt,
  };
};

/**
 * create Macro Allocation Id
 *
 * @param {String} allocationProjectId (ie, Agile Team)
 * @param {String} sourceGroupId (ie, Chapter)
 * @param {String} targetGroupId (ie, Agile Team)
 * @param {String} type (ie, INDIRECT_PLACEHOLDER)
 * @returns {String} Macro Allocation Id
 */
export const createMacroAllocationId = (
  allocationProjectId,
  sourceGroupId,
  targetGroupId,
  type
) => {
  const typePostfix = type ? `-${type}` : "";
  return `macro-allocation-${allocationProjectId}-${sourceGroupId}-${targetGroupId}${typePostfix}`;
};

/**
 * Check whether macroallocation is rejected or not by comapring approvalReason
 *
 * @param {MacroAllocation} macroAllocation
 * @param {Object} approvalReasons
 * @returns {Boolean} indicate whether macroAllocation is rejected or not
 */
export const isMacroAllocationRejected = (macroAllocation, approvalReasons) => {
  const reasonId = get(macroAllocation, "approvalReasonId");
  if (!reasonId) {
    return false;
  }

  const reason = get(approvalReasons, reasonId);
  if (!reason) {
    return false;
  }

  return reason.type === APPROVAL_REASON_TYPES.REJECTION;
};
