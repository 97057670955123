import { Button, Icon, Popover } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const ActionButton = ({
  callback,
  isDisabled,
  iconSize,
  icon,
  text,
  width,
  showPopover,
}) => {
  const variantAsDisabled = isDisabled ? { variant: "disabled" } : null;

  const ButtonWithAction = () => (
    <Button
      ariaLabel={text}
      data-testid="cp-option-action-btn"
      small
      iconOnly
      width={iconSize}
      onClick={callback}
      disabled={isDisabled}
      {...variantAsDisabled}
    >
      <Icon icon={icon} />
    </Button>
  );

  return showPopover ? (
    <Popover text={text} direction="top" textAlign="center" width={width}>
      <ButtonWithAction />
    </Popover>
  ) : (
    <ButtonWithAction />
  );
};

ActionButton.propTypes = {
  callback: PropTypes.func,
  isDisabled: PropTypes.bool,
  iconSize: PropTypes.string,
  icon: PropTypes.array,
  text: PropTypes.string,
  width: PropTypes.string,
  showPopover: PropTypes.bool,
};

export default ActionButton;
