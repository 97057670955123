import * as Sentry from "@sentry/react";
import { useEffect } from "react";

const useSentryReplayHook = () => {
  useEffect(() => {
    Sentry.getCurrentHub()
      .getClient()
      .addIntegration(
        new Sentry.Replay({
          maskAllText: true,
          blockAllMedia: true,
          replaysSessionSampleRate: 0,
          replaysOnErrorSampleRate: 1,
        })
      );
  }, []);
};

export default useSentryReplayHook;
