import React from "react";
import { get, partial } from "lodash";

import { ADMIN, DIRECTORY } from "src/app/Restricted/consts";
import { withAccess } from "src/components/Can";
import { isInternalSupportUser } from "src/services/auth";
import { isLocalEnvironment } from "src/services/localSettings";

import PATHS from "../../consts/urlPaths";

import {
  CreateWorkspacePage,
  InternalDashboard,
  WorkspacesPage,
  WorkspaceConfigurationPage,
  AdminConfiguration,
} from "../adminPages";

import { PAGE_TITLE_CONFIGS } from "../Helmet";

const ExplorePage = React.lazy(() => import("../../pages/ExplorePage"));
const TagDetailsPage = React.lazy(() => import("../../pages/TagDetailsPage"));
const PersonDetailPage = React.lazy(() =>
  import("../../pages/PersonDetailPage")
);
const TeamDetailPage = React.lazy(() => import("../../pages/TeamDetailPage"));
const InsightsPage = React.lazy(() => import("../../pages/InsightsPage"));
const PeopleListPage = React.lazy(() => import("../../pages/PeopleListPage"));
const VisualisationPage = React.lazy(() =>
  import("../../components/VisualisationPage")
);
const DirectoryUserHomePage = React.lazy(() =>
  import("src/pages/HomePage/HomePageContainer")
);
const ObjectivesPage = React.lazy(() => import("../../pages/ObjectivesPage"));
const TeamListPage = React.lazy(() => import("../../pages/TeamListPage"));
const SearchPage = React.lazy(() => import("src/pages/SearchPage"));
const Logout = React.lazy(() => import("../../pages/Logout"));

const ApiDocs = React.lazy(() => import("../../pages/ApiDocs"));

const getRoutes = (props, featureFlags, userPerson) => {
  const { user } = props;

  const { visualisation } = featureFlags;

  const withAdminAccess = partial(withAccess, user, [ADMIN]);

  const hasUserId = !!get(userPerson, "aggregateId");
  const showDevTools = isInternalSupportUser(user) || isLocalEnvironment();

  return [
    {
      exact: true,
      title: "Search",
      path: PATHS.SEARCH,
      Component: SearchPage,
    },
    {
      title: PAGE_TITLE_CONFIGS.tagDetailPage,
      path: PATHS.TAG_DETAILS,
      Component: TagDetailsPage,
    },
    {
      title: PAGE_TITLE_CONFIGS.teamDetailPage,
      path: PATHS.TEAM_DETAILS,
      Component: TeamDetailPage,
    },
    { exact: true, path: PATHS.LOGOUT, Component: Logout },
    {
      exact: true,
      title: "Api documentation",
      path: PATHS.API_DOCS,
      Component: ApiDocs,
    },
    {
      exact: true,
      title: PAGE_TITLE_CONFIGS.personDetailPage,
      path: PATHS.PERSON_DETAILS,
      Component: PersonDetailPage,
    },
    // Restricted routes
    {
      exact: true,
      isRestricted: true,
      allowedRoles: [DIRECTORY],
      title: "Explore",
      path: PATHS.EXPLORE,
      Component: ExplorePage,
    },
    {
      exact: true,
      isRestricted: true,
      title: "Summary",
      path: PATHS.SUMMARY,
      Component: InsightsPage,
    },
    {
      exact: true,
      isRestricted: true,
      title: "Teams",
      path: PATHS.TEAMS,
      Component: TeamListPage,
    },
    {
      exact: true,
      isRestricted: true,
      title: "People",
      path: PATHS.PEOPLE,
      Component: PeopleListPage,
    },
    {
      exact: true,
      isRestricted: true,
      title: PAGE_TITLE_CONFIGS.dashboard,
      path: PATHS.USER_HOME,
      Component: PersonDetailPage,
    },
    {
      exact: true,
      isRestricted: true,
      title: "Configuration",
      path: PATHS.DATASOURCE,
      Component: withAdminAccess(WorkspaceConfigurationPage, props),
    },
    {
      exact: true,
      isRestricted: true,
      title: "Configuration",
      path: PATHS.WORKSPACE_CONFIGURATION,
      Component: withAdminAccess(WorkspaceConfigurationPage, props),
    },

    {
      exact: true,
      isRestricted: true,
      title: "New workspace",
      path: PATHS.CREATE_WORKSPACE,
      Component: withAdminAccess(CreateWorkspacePage, props),
    },
    {
      exact: true,
      isRestricted: true,
      title: "Workspaces",
      path: PATHS.WORKSPACES,
      Component: withAdminAccess(WorkspacesPage, props),
    },
    {
      isRestricted: true,
      title: "Objectives",
      path: PATHS.OBJECTIVES,
      Component: ObjectivesPage,
    },
    {
      exact: true,
      isDisabled: !hasUserId,
      allowedRoles: [DIRECTORY],
      isRestricted: true,
      title: PAGE_TITLE_CONFIGS.dashboard,
      path: PATHS.DIRECTORY_USER_HOME,
      Component: DirectoryUserHomePage,
    },
    {
      isDisabled: !visualisation,
      isRestricted: true,
      title: "Visualisation",
      path: [PATHS.VISUALISATION_WITH_ID, PATHS.VISUALISATION],
      Component: VisualisationPage,
    },
    {
      exact: true,
      isDisabled: !showDevTools,
      isRestricted: true,
      title: "Internal dashboard",
      path: PATHS.INTERNAL_DASHBOARD,
      Component: InternalDashboard,
    },
    {
      exact: true,
      isRestricted: true,
      title: "Settings",
      path: PATHS.ADMIN_DASHBOARD,
      Component: withAdminAccess(AdminConfiguration, props),
    },
  ];
};

export default getRoutes;
