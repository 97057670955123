import {
  setGroupingWithDefaultConstraints,
  setGroupingWithCalculatedSumTotals,
  hashGrouping,
} from "./util";

const updateModelWithConstraint = (forecastModel, id, newConstraint) => {
  const {
    lookups: { constraintCellLookup, allocationProjectLookup },
    columns,
  } = forecastModel;

  const constraintCell = constraintCellLookup[id];
  if (constraintCell.constraint === newConstraint) {
    return forecastModel;
  }

  constraintCell.constraint = newConstraint;

  setGroupingWithDefaultConstraints(
    constraintCell.grouping,
    allocationProjectLookup,
    columns
  );

  setGroupingWithCalculatedSumTotals(
    constraintCell.grouping,
    forecastModel.columns,
    {
      recalcChildren: false,
      recalcParents: true,
    }
  );
  hashGrouping(constraintCell.grouping, {
    rehashParents: true,
  });

  return forecastModel;
};

export default updateModelWithConstraint;
