export const messages = {
  notFoundError: "Not Found",
};

export const copywriting = {
  notFoundHeading: "Sorry, we cannot find this person, team or tag",
  searchAgainButton: "Search Again",
  exploreButton: "Explore Organisation",
  // This is used when the whole error page handlers explode and we really just need the user to reload the page.
  safeErrorHeading: "Couldn't complete that action.",
  safeErrorDetail:
    "We apologise for any inconvenience caused. Please refresh the page. If there are still issues please contact support for further assistance.",
  genericErrorHeading: "Couldn't complete that action.",
  genericErrorDetail:
    "We apologise for any inconvenience caused. Please refresh the page or log out and log back in again, and then try again. If there are still issues please contact support for further assistance.",
  serverErrorHeading: "Be right back!",
  serverErrorDetail: "Something went wrong on our side. Please try again soon.",
  forbiddenErrorHeading: "Sorry, you don't have access to that function.",
  forbiddenErrorDetail:
    "We are unable to connect to the app at this time. This may be because you do not have the appropriate permissions for access, or you may have launched the application from a non-authorised network.",
  networkErrorHeading: "Network error.",
  networkErrorDetail:
    "Sorry, a network error has occurred. Please try again later.",
  pitNotFoundErrorHeading: "Selected point in time not available.",
  pitNotFoundErrorDetail:
    "We are unable retrieve data for the selected historical date.",
  unauthorisedRoleErrorHeading:
    "Sorry, you don't have access to this feature right now.",
  unauthorisedRoleErrorDetail:
    "If you need access, please contact our support team.",
  defaultGraphQLUnauthorizedErrorHeading: "Unauthorised",
  defaultGraphQLUnauthorizedErrorDetail:
    "This isn't the group you are looking for. Move along",
  workspaceNotFoundErrorHeading:
    "Sorry, this workspace has either been removed or you do not have access to it.",
  workspaceSwitcherLabel: "Please select another workspace:",
  workspaceNotFoundCreateMessage:
    "Alternatively, you can create a new workspace:",

  authProviderErrorSpecific: {
    unauthorized: {
      heading:
        "Looks like you're trying to get somewhere you don't currently have access to.",
      detail:
        "Please contact your internal business application owner to see if you can get access.",
    },
  },
  authProviderDefaultHeading:
    "Looks like you're trying to get somewhere you don't currently have access to.",
  maintenancePage: {
    heading: "TeamForm is currently undergoing maintenance.",
    detail:
      "We're adding some updates, but we'll be back shortly! Thanks for your patience.",
  },
};
