import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import {
  Flex,
  Button,
  Icon,
  Popover,
  StyledLink,
  Spacer,
} from "orcs-design-system";
import { useAuth0 } from "src/contexts/auth0Provider";

const ErrorFooter = ({
  clientInfo,
  selectWorkspace,
  showBackButton = false,
  showSupportButton = true,
  showLogoutButton = true,
}) => {
  const auth0 = useAuth0();
  const history = useHistory();

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const { isAuthenticated, logout } = auth0 || {};

  return (
    <Flex justifyContent="flex-start" alignItems="center" my="l">
      <Spacer mr="s">
        {selectWorkspace && (
          <Button
            display="inline-block"
            onClick={() => {
              window.location.href = process.env.REACT_APP_DOMAIN;
            }}
            leftIcon={["fas", "database"]}
          >
            Select workspace
          </Button>
        )}

        {showBackButton && (
          <Button
            display="inline-block"
            onClick={goBack}
            leftIcon={["fa", "arrow-alt-left"]}
          >
            Back
          </Button>
        )}
        {showSupportButton && (
          <StyledLink
            button
            href={process.env.REACT_APP_HELP_URL}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <Icon icon={["fas", "question-circle"]} mr="xs" />
            Support
          </StyledLink>
        )}
        {isAuthenticated && logout && showLogoutButton && (
          <Button
            variant="ghost"
            display="inline-block"
            onClick={logout}
            leftIcon={["fas", "lock"]}
          >
            Logout
          </Button>
        )}
        {clientInfo && (
          <Popover
            direction="top"
            width="160px"
            textAlign="center"
            text={clientInfo}
          >
            <Icon icon={["fas", "info-circle"]} color="primary" />
          </Popover>
        )}
      </Spacer>
    </Flex>
  );
};

export default ErrorFooter;

ErrorFooter.propTypes = {
  clientInfo: PropTypes.string,
  showBackButton: PropTypes.bool,
  showSupportButton: PropTypes.bool,
  showLogoutButton: PropTypes.bool,
  selectWorkspace: PropTypes.bool,
};
