import React from "react";
import PropTypes from "prop-types";
import useTimeout from "use-timeout";
import { Loading } from "orcs-design-system";

const LoadingWithErrorTimeout = ({
  timeout,
  waitingFor,
  location = {},
  ...props
}) => {
  useTimeout(() => {
    throw new Error(
      `timed out after ${timeout} millisecs, loading: '${waitingFor}', path: '${location.pathname}'`
    );
  }, timeout);

  return <Loading {...props} />;
};

LoadingWithErrorTimeout.propTypes = {
  timeout: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  waitingFor: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default LoadingWithErrorTimeout;
