import { ApolloProvider } from "@apollo/client";
import React from "react";
import PropTypes from "prop-types";
import { useApolloSettings } from "../contexts/global/ApolloSettingsContext";

import apolloClient from "./apolloClient";

const TenantApolloProvider = ({ children }) => {
  const settings = useApolloSettings();
  const client = apolloClient(settings);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

TenantApolloProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TenantApolloProvider;
