import { isEmpty } from "lodash";
import { setGroupingWithNotifications, hashGrouping } from "./util";

const updateModelWithGroupObjectives = (
  dynamicModel,
  groupId,
  linkedStrategies,
  objectiveIds
) => {
  const {
    lookups: { groupingLookup },
  } = dynamicModel;

  const grouping = groupingLookup[groupId];

  if (!grouping) {
    return dynamicModel;
  }

  const currentLinkedObjectives = grouping.group.linkedStrategies;
  grouping.group.linkedStrategies = linkedStrategies;

  const currentObjectives = grouping.group.objectiveIds;
  grouping.group.objectiveIds = objectiveIds;

  if (
    isEmpty(currentLinkedObjectives) ||
    isEmpty(linkedStrategies) ||
    isEmpty(currentObjectives) ||
    isEmpty(objectiveIds)
  ) {
    setGroupingWithNotifications(grouping, dynamicModel.columns, {
      recalcChildren: false,
      recalcParents: true,
    });
  }

  hashGrouping(grouping, {
    rehashChildren: false,
    rehashParents: true,
  });

  return dynamicModel;
};

export default updateModelWithGroupObjectives;
