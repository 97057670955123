import { isArray, map } from "lodash";
import { Text } from "orcs-design-system";
import React from "react";
import { Node } from "slate";

const toPlainText = (richTextContent) => {
  if (isArray(richTextContent)) {
    return map(richTextContent, toPlainText).join("\n");
  }
  return Node.string(richTextContent);
};

export const isRichTextContentEmpty = (richTextContent) => {
  return toPlainText(richTextContent).trim() === "";
};

export const parseLeaf = (obj, styles = {}) => {
  const { text, bold, italic, underline } = obj;

  if (!text) {
    return null;
  }

  let component = <span>{text}</span>;

  if (bold) {
    component = <strong>{component}</strong>;
  }

  if (italic) {
    component = <em>{component}</em>;
  }

  if (underline) {
    component = <u>{component}</u>;
  }

  return <Text {...styles}>{component}</Text>;
};
