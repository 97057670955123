import React from "react";
import { Flex, Notification, Box, P } from "orcs-design-system";

import { useGlobalConfig } from "src/contexts/global/GlobalContext";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import useRestricted from "src/app/Restricted/useRestricted";

const GlobalNotification = () => {
  const globalConfig = useGlobalConfig();
  const workspace = useWorkspace();
  const [showDirectoryNotification] = useRestricted();

  const workspaceNotification = showDirectoryNotification
    ? workspace?.config?.notifications?.directoryNotification || {}
    : workspace?.config?.notifications?.mapperNotification || {};

  const globalNotification = showDirectoryNotification
    ? globalConfig?.config?.directoryNotification || {}
    : globalConfig?.config?.mapperNotification || {};

  const notification = workspaceNotification?.isActive
    ? workspaceNotification
    : globalNotification;

  const { message, colour, isActive, visualTreatment } = notification || {};

  if (!isActive) {
    return null;
  }

  if (visualTreatment === "top-banner") {
    const colourConvert =
      colour === "default" ? "primaryDark" : `${colour}Dark`;
    return (
      <Box
        bg={colourConvert}
        dataTestId="notification-top-banner"
        id="notification-top-banner"
        py="xs"
      >
        <Flex width="100%" justifyContent="center" px="s">
          <P color="white" fontWeight="600" fontSize="1.3rem">
            {message}
          </P>
        </Flex>
      </Box>
    );
  }

  return (
    <Notification
      floating
      centered
      bottom="20px"
      colour={colour}
      closable={false}
    >
      {message}
    </Notification>
  );
};

export default GlobalNotification;
