import React from "react";

import { H1, P } from "orcs-design-system";
import PropTypes from "prop-types";
import { copywriting } from "./ErrorPage.config";
import ErrorPageWrapper from "./ErrorPageWrapper";

const UnauthorisedRoleError = ({ clientInfo }) => {
  return (
    <ErrorPageWrapper clientInfo={clientInfo}>
      <H1 mb="between">{copywriting.unauthorisedRoleErrorHeading}</H1>
      <P>{copywriting.unauthorisedRoleErrorDetail}</P>
    </ErrorPageWrapper>
  );
};

UnauthorisedRoleError.propTypes = {
  clientInfo: PropTypes.string,
};

export default UnauthorisedRoleError;
