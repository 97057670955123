/* eslint-disable no-continue */
/* eslint no-param-reassign: "error" */
import { values, trim, find, lowerFirst } from "lodash";
import moment from "moment";

import { ALLOCATION_THREAD_ID } from "src/consts/comments";

import { PLANNER_APPROVED } from "../columnTypes";
import { DANGER } from "../notificationStatus";
import { hashCell } from "./util";

const updateApproverCellNotifications = (forecastModel, payload) => {
  const { lookups } = forecastModel;
  const allCells = values(lookups.cellLookup);
  const { data, activeAllocationProject } = payload;

  for (let i = 0, j = allCells.length; i < j; i += 1) {
    const cell = allCells[i];
    if (cell.columnType !== PLANNER_APPROVED) {
      continue;
    }

    if (!cell.notification || cell.notification.status !== DANGER) {
      continue;
    }

    const { sourceGroupId, targetGroupId } = cell;
    const threadId = ALLOCATION_THREAD_ID(
      activeAllocationProject.id,
      sourceGroupId,
      targetGroupId
    );

    // Comments are already sorted in back end, find the first one with threadId
    const comment = find(data, (c) => c.threadId === threadId);
    if (!comment) {
      continue;
    }

    const { authorTitle, content, timestamp } = comment;

    // TODO: Maybe we can return the the author name from back end
    const author = trim(authorTitle.split("(")[0]);

    const submittedMoment = moment(timestamp);
    const message = `${submittedMoment.fromNow()}, ${author} ${lowerFirst(
      content
    )}`;

    // Set cell notification message to the submitted comment
    cell.notification.message = message;

    // Update hash to update UI
    hashCell(cell, { rehashParents: true });
  }

  return forecastModel;
};

export default updateApproverCellNotifications;
