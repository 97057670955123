import React from "react";
import PropTypes from "prop-types";
import { isNil } from "lodash";

import LoadingPage from "src/components/LoadingPage";
import ErrorPage from "src/components/ErrorPage";
import {
  isWorkspaceNotFoundError,
  ERROR_CODES,
} from "src/components/ErrorPage/detectErrorType";
import CreateWorkspacePage from "src/pages/Workspaces/CreateWorkspacePage";
import { useFeatureFlags } from "src/contexts/global/FeatureFlagsContext";
import {
  useGlobalContextError,
  useIsContextReady,
} from "src/contexts/global/GlobalContext";
import ErrorMessage from "src/components/ErrorPage/ErrorMessage";

import {
  useIsWorkspaceLoading,
  useWorkspaceChallenge,
  useWorkspaceError,
  CHALLENGE_SELECT_WORKSPACE,
  CHALLENGE_SETUP_WORKSPACE,
} from "./WorkspaceContext";

const WorkspaceChallenge = ({ children }) => {
  const { isFeatureFlagsLoaded } = useFeatureFlags();
  const isWorkspaceLoading = useIsWorkspaceLoading();
  const challenge = useWorkspaceChallenge();
  const workspaceError = useWorkspaceError();
  const globalContextError = useGlobalContextError();
  const isContextReady = useIsContextReady();

  if (!isFeatureFlagsLoaded || isWorkspaceLoading || isNil(challenge)) {
    return <LoadingPage waitingFor="getWorkspace" />;
  }
  if (isWorkspaceNotFoundError(workspaceError)) {
    throw workspaceError;
  }
  if (workspaceError) {
    return <ErrorPage error={workspaceError} />;
  }
  if (challenge === CHALLENGE_SELECT_WORKSPACE) {
    throw new Error(ERROR_CODES.WORKSPACE_ACCESS_DENIED);
  }
  if (challenge === CHALLENGE_SETUP_WORKSPACE) {
    return <CreateWorkspacePage />;
  }

  // Evaluate Workspace Errors before showing context errors
  if (!isContextReady) {
    return <LoadingPage waitingFor="getContextQuery" />;
  }
  if (globalContextError) {
    return <ErrorMessage error={globalContextError} />;
  }

  return children;
};

WorkspaceChallenge.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WorkspaceChallenge;
