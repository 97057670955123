import PropTypes from "prop-types";
import React from "react";
import * as Sentry from "@sentry/react";
import ErrorMessage from "./ErrorPage/ErrorMessage";
import SafeErrorMessage from "./ErrorPage/SafeErrorMessage";

/**
 * All errors caught by this boundary will have sidebar and the header.
 */
export const NotFoundErrorBoundary = ({ children }) => {
  return (
    <StandardErrorBoundary showHeader={false}>{children}</StandardErrorBoundary>
  );
};

export const StandardErrorBoundary = ({ children, showHeader = true }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ ...props }) => {
        return (
          <ErrorMessage
            showHeader={showHeader}
            showContainer={true}
            showClientInfo={false}
            {...props}
          />
        );
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

// todo: should actually be different somehow
export const FailsafeErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ ...props }) => <SafeErrorMessage {...props} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

StandardErrorBoundary.propTypes = {
  ...propTypes,
  showHeader: PropTypes.bool,
};
FailsafeErrorBoundary.propTypes = propTypes;
NotFoundErrorBoundary.propTypes = propTypes;
