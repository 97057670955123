/* eslint-disable react/no-array-index-key */
import { Flex, FlexItem, Spacer, Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import * as placeholder from "src/components/Placeholder/PlaceholderStyles";

const AvatarPlaceholder = ({ rows }) => {
  const rowsData = Array(rows).fill(0);

  return rowsData.map((_row, index) => (
    <Box key={`cp-panel-placeholder-${index}`} py="r">
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        data-testid="cp-panel-placeholder-wrapper"
      >
        <placeholder.Circle />
        <FlexItem>
          <Spacer m="xs">
            <placeholder.Line
              data-testid="cp-panel-placeholder-line"
              width={140}
              height={16}
            />
            <placeholder.Line
              data-testid="cp-panel-placeholder-line"
              width={80}
              height={12}
            />
          </Spacer>
        </FlexItem>
      </Flex>
      <placeholder.Line
        data-testid="cp-panel-placeholder-line"
        width={100}
        height={20}
      />
    </Box>
  ));
};

AvatarPlaceholder.propTypes = {
  rows: PropTypes.number,
};

export default AvatarPlaceholder;
