import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useContext,
} from "react";
import Helmet from "react-helmet";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { useUserPerson } from "src/contexts/global/GlobalContext";

export { PAGE_TITLE_CONFIGS } from "./pageTitleConfig";

const helmetContext = createContext({});

const PageTitlePropTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ query: PropTypes.object, mapper: PropTypes.func }),
  ]),
};

const QueriedPageTitle = ({ title, id }) => {
  const userPerson = useUserPerson();
  const { setPageTitle } = useContext(helmetContext);

  const { query, mapper, isDashboard } = title;

  const { data } = useQuery(query, {
    variables: { id },
    fetchPolicy: "cache-only",
    skip: isDashboard,
  });

  useEffect(() => {
    setPageTitle(mapper(isDashboard ? userPerson : data, id));
  }, [data, mapper, id, setPageTitle, isDashboard, userPerson]);

  return null;
};

QueriedPageTitle.propTypes = PageTitlePropTypes;

export const PageTitle = (props) => {
  const { title } = props;
  const { setPageTitle } = useContext(helmetContext);

  useEffect(() => {
    if (!title?.query) {
      setPageTitle(title);
    }
  }, [title, setPageTitle]);

  if (title?.query) return <QueriedPageTitle {...props} />;

  return null;
};

PageTitle.propTypes = PageTitlePropTypes;

const AppHelmet = ({ children }) => {
  const [pageTitle, setPageTitle] = useState("");

  const value = useMemo(() => ({ setPageTitle }), []);

  return (
    <helmetContext.Provider value={value}>
      <Helmet>
        <title>
          {process.env.REACT_APP_TITLE}
          {pageTitle ? ` - ${pageTitle}` : ""}
        </title>
      </Helmet>

      {children}
    </helmetContext.Provider>
  );
};

AppHelmet.propTypes = {
  children: PropTypes.node,
};

export default AppHelmet;
