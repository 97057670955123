import flagSmith from "flagsmith";
import { isInternalSupportUser, createAuth } from "src/services/auth";

const environmentID = process.env.REACT_APP_BULLET_TRAIN_ENVIRONMENT_ID;
const defaultFlags = {
  visualisation: { enabled: false, value: null },
  unsplash: { enabled: false, value: null },
  "member-count-badges": { enabled: false, value: null },
  "fte-badges": { enabled: false, value: null },
  "disable-maps": { enabled: true, value: null },
};

const loadFeatureFlags = (user) => {
  const { username, email } = createAuth(user);
  const isSupportUser = isInternalSupportUser(user);

  return new Promise((resolve) => {
    const cleanedUsername =
      username && username.includes("-")
        ? `${username.replace(/-/g, "")}fixed`
        : username;
    flagSmith.init({
      environmentID,
      // making change so that feature toggles can be turned on for support users to test features under development
      identity: (isSupportUser && email) || cleanedUsername,
      traits: {
        username: cleanedUsername,
        ...(isSupportUser && { email }),
      },
      defaultFlags,
      api: "https://edge.api.flagsmith.com/api/v1/",
      cacheFlags: true,
      cacheOptions: {
        ttl: 1000 * 3600 * 2, // 2 hours in ms
        skipAPI: true,
      },
      onError: () => {
        resolve(defaultFlags);
      },
      onChange: () => {
        const featureFlags = flagSmith.getAllFlags();
        resolve(featureFlags);
      },
    });
  });
};

export default loadFeatureFlags;
