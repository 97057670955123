import moment from "moment";
import { DatePicker, Box } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import getInputLabel from "src/util/getInputLabel";

const TagAttributeDateInput = ({
  id,
  name,
  onDateChange,
  value,
  label,
  displayFormat = "DD/MM/YYYY",
  placeholder,
  invalid,
  mandatory,
  focus,
}) => {
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (focus) {
      setFocused(true);
    }
  }, [focus]);

  const handleDateChange = (selectedDate) => {
    onDateChange(selectedDate && selectedDate.format(displayFormat));
  };

  return (
    <Box>
      <DatePicker
        single
        id={id}
        name={name}
        date={value && moment(value, displayFormat)}
        focused={focused}
        placeholder={placeholder}
        onDateChange={handleDateChange}
        onFocusChange={(el) => {
          setFocused(el.focused);
        }}
        label={getInputLabel(label, invalid)}
        invalid={invalid}
        required={mandatory}
        numberOfMonths={1}
        daySize={31}
        displayFormat={displayFormat}
        readOnly
        width="100%"
        zIndex="12"
      />
    </Box>
  );
};

TagAttributeDateInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  displayFormat: PropTypes.string,
  placeholder: PropTypes.string,
  onDateChange: PropTypes.func,
  invalid: PropTypes.bool,
  mandatory: PropTypes.bool,
  focus: PropTypes.bool,
};

export default TagAttributeDateInput;
