import { forEach, isArray, join, map, has, isEmpty, isEqual } from "lodash";

import { fullDisplayName } from "src/util/personName";

import { TAG_ATTRIBUTE_TYPES, TAG_ATTRIBUTE_ACTION } from "src/consts/tags";

export const buildDisplayValue = ({
  attrTypeValues,
  attributes,
  separator,
  getCachedGroup,
  getCachedPerson,
  getCachedTag,
}) => {
  const combinedValue = join(
    map(attrTypeValues, (a) => {
      const { id, label, defaultValue, type } = a;

      let value = defaultValue;

      if (has(attributes, id)) {
        value = attributes[id];
      }

      if (type === TAG_ATTRIBUTE_TYPES.GROUPTYPE && value) {
        const group = getCachedGroup(value);

        if (group) {
          return group.name;
        }
      }

      if (type === TAG_ATTRIBUTE_TYPES.PERSON && value) {
        const person = getCachedPerson(value);

        if (person) {
          return `${fullDisplayName(person)}${separator}${value}`;
        }
      }

      if (type === TAG_ATTRIBUTE_TYPES.TAGTYPE && value) {
        const tag = getCachedTag(value);

        if (tag) {
          return tag.value;
        }
      }
      return value || `{${label}}`;
    }),
    separator
  );

  return combinedValue;
};

export const getLabel = (label, isReadOnly) => {
  let labelStr = label;

  if (isArray(label)) {
    labelStr = join(label, ", ");
  }

  return `${labelStr}${isReadOnly ? " - readonly" : ""}`;
};

const isReadonlyAttr = (type) => {
  return type.action === TAG_ATTRIBUTE_ACTION.READONLY;
};

export const filterTagAttributeTypes = (values, others, entityTagAttrTypes) => {
  const readonlyTypes = [];
  const editableTypes = [];
  const uniqueAttrTypes = [];

  forEach([...values, ...others], (t) => {
    if (isReadonlyAttr(t)) {
      readonlyTypes.push(t);
    } else {
      editableTypes.push(t);
    }
  });

  forEach(entityTagAttrTypes, (t) => {
    const attr = {
      ...t,
      isEntityTagAttr: true,
    };

    uniqueAttrTypes.push(attr);
  });

  return [readonlyTypes, editableTypes, uniqueAttrTypes];
};

export const isAttributesChanged = (origin, updated) => {
  return (
    (isEmpty(origin) && !isEmpty(updated)) ||
    (!isEmpty(origin) && !isEmpty(updated) && !isEqual(origin, updated))
  );
};
