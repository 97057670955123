import { React, createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

// GlobalSearchDefaultScopeContext
// The default scope state is separated from the SearchContext because it is used in many places to one-off set the
// default scope but we don't want to re-render all those components every time the search query/data/results changes
export const GlobalSearchDefaultScopeContext = createContext();

export const useGlobalSearchDefaultScopeContext = () =>
  useContext(GlobalSearchDefaultScopeContext);

export const GlobalSearchDefaultScopeProvider = ({ children }) => {
  const [defaultSearchScope, setDefaultSearchScope] = useState();

  return (
    <GlobalSearchDefaultScopeContext.Provider
      value={{
        defaultSearchScope,
        setDefaultSearchScope,
      }}
    >
      {children}
    </GlobalSearchDefaultScopeContext.Provider>
  );
};

GlobalSearchDefaultScopeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// GlobalSearchContext
// (depends on GlobalSearchDefaultScopeContext)

export const GlobalSearchContext = createContext();

export const useGlobalSearchContext = () => useContext(GlobalSearchContext);

export const GlobalSearchProvider = ({ children }) => {
  const { defaultSearchScope, setDefaultSearchScope } =
    useGlobalSearchDefaultScopeContext();

  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [searchData, setSearchData] = useState(null);

  return (
    <GlobalSearchContext.Provider
      value={{
        defaultSearchScope,
        setDefaultSearchScope,
        searchResults,
        setSearchResults,
        searchQuery,
        setSearchQuery,
        searchData,
        setSearchData,
      }}
    >
      {children}
    </GlobalSearchContext.Provider>
  );
};

GlobalSearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
