import { forEach, isEmpty, filter, sortBy } from "lodash";
import {
  newGrouping,
  newGroupingOptions,
  setGroupingWithCalculatedSumTotals,
  setGroupingWithNotifications,
  hashGrouping,
} from "./util";

const clearAllMovedGroups = (movedGroupingsMap) => {
  forEach(movedGroupingsMap, (saved) => {
    const { grouping, parentGrouping } = saved;

    if (!grouping) {
      return;
    }

    if (grouping.isMovedOut) {
      grouping.isMovedOut = false;
      grouping.newParent = null;
    }

    if (grouping.isMovedIn && parentGrouping) {
      grouping.isMovedIn = false;
      grouping.newParent = null;

      parentGrouping.childGroupings = filter(
        parentGrouping.childGroupings,
        (g) => g !== grouping
      );
    }

    hashGrouping(grouping, { rehashParents: true });
  });
};

const updateGroupExtraInfo = (
  state,
  { rootGroup, movedGroups, newGroups, groupTags }
) => {
  const { rootGroup: currentRoot, dynamicModel, movedGroupingsMap } = state;
  const { lookups, columns } = dynamicModel;
  const { groupingLookup } = lookups;

  if (
    !rootGroup ||
    !currentRoot ||
    rootGroup.id !== currentRoot.id ||
    !groupingLookup
  ) {
    return {
      dynamicModel,
      isGroupExtraInfoLoaded: false,
    };
  }

  if (!isEmpty(movedGroupingsMap)) {
    clearAllMovedGroups(movedGroupingsMap);
  }

  forEach(groupTags, (tags, groupId) => {
    const grouping = groupingLookup[groupId];
    grouping.tags = tags;

    hashGrouping(grouping, { rehashParents: true, rehashChildren: false });
  });

  const newMovedGroupingsMap = [];

  // Make sure parent comes later
  const sortedMovedGroups = sortBy(movedGroups, (g) => {
    const { hierarchyIds = [] } = g.group;
    return hierarchyIds.length;
  }).reverse();

  // Add a flag to moved groups with new parent
  forEach(sortedMovedGroups, (item) => {
    const { id, newParent } = item;
    const grouping = groupingLookup[id];

    grouping.isMovedOut = true;
    grouping.isDirectMove = true;
    grouping.newParent = newParent;

    newMovedGroupingsMap.push({ grouping });

    // Add moved out badge to all its children
    const children = [...(grouping.childGroupings || [])];

    while (children.length > 0) {
      const currentChild = children.shift();

      if (!currentChild.isMovedOut) {
        currentChild.isMovedOut = true;
        currentChild.newParent = newParent;

        newMovedGroupingsMap.push({ grouping: currentChild });

        if (!isEmpty(currentChild.childGroupings)) {
          children.push(...currentChild.childGroupings);
        }
      }
    }

    hashGrouping(grouping, { rehashParents: true, rehashChildren: true });
  });

  forEach(newGroups, (item) => {
    const { newGroup, parentGroup } = item;

    // Find parent grouping
    const grouping = groupingLookup[parentGroup.id];

    const childGrouping = newGrouping({
      ...newGroupingOptions({ group: newGroup, groupType: newGroup.type }),
      parentGrouping: grouping,
      rootGrouping: grouping.root,
      nestedLevel: grouping.nestedLevel + 1,
      canAddLineItem: false,
      canViewInTeamBuilder: true,
      canViewInDetails: true,
      isMovedIn: true,
    });

    childGrouping.newParent = grouping.group;
    childGrouping.tags = newGroup.tags;

    // Fill in other fields
    setGroupingWithCalculatedSumTotals(childGrouping, columns);
    setGroupingWithNotifications(childGrouping, columns);

    grouping.childGroupings.push(childGrouping);

    newMovedGroupingsMap.push({
      grouping: childGrouping,
      parentGrouping: grouping,
    });

    hashGrouping(childGrouping, { rehashParents: true });
  });

  return {
    dynamicModel,
    isGroupExtraInfoLoaded: true,
    movedGroupingsMap: newMovedGroupingsMap,
  };
};

export default updateGroupExtraInfo;
