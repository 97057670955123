import { forEach } from "lodash";

import setLineItemWithPlannerCells from "./setLineItemWithPlannerCells";

const setGroupingsWithPlannerCells = ({
  groupings,
  macroAllocations,
  allocationProjectLookup,
  isRequestor,
  groupedByDemand,
  approvalReasons,
  useDeltaForBudget,
  isRealtimeFteMode,
  useRealtimeChangeOnly,
  useRealtimeFteDelta,
}) => {
  forEach(groupings, (grouping) => {
    forEach(grouping.lineItems, (lineItem) => {
      setLineItemWithPlannerCells({
        lineItem,
        targetGroupId: groupedByDemand ? grouping.groupId : lineItem.groupId,
        sourceGroupId: groupedByDemand ? lineItem.groupId : grouping.groupId,
        macroAllocations,
        isRequestor,
        allocationProjectLookup,
        approvalReasons,
        useDeltaForBudget,
        isRealtimeFteMode,
        useRealtimeChangeOnly,
        useRealtimeFteDelta,
      });
    });

    setGroupingsWithPlannerCells({
      groupings: grouping.childGroupings,
      macroAllocations,
      allocationProjectLookup,
      isRequestor,
      groupedByDemand,
      approvalReasons,
      useDeltaForBudget,
      isRealtimeFteMode,
      useRealtimeChangeOnly,
      useRealtimeFteDelta,
    });
  });
};

export default setGroupingsWithPlannerCells;
