import React from "react";
import { H5, Divider } from "orcs-design-system";
import { useAuth0 } from "src/contexts/auth0Provider";
import PATHS, { url } from "src/consts/urlPaths";
import { isInternalSupportUser } from "src/services/auth";
import { isLocalEnvironment } from "src/services/localSettings";
import { ADMIN } from "src/app/Restricted/consts";
import useUserRole from "src/app/Restricted/useUserRole";
import SettingsLink from "./components/SettingsLink";
import StrategyStructureLink from "./components/StrategyStructureLink";

const SettingsContent = () => {
  const { user } = useAuth0();
  const userRole = useUserRole();
  const showDevTools = isInternalSupportUser(user) || isLocalEnvironment();
  const isAdmin = userRole === ADMIN;

  return (
    <>
      <H5 fontWeight="bold" mb="r">
        Settings
      </H5>
      <Divider light mb="s" />
      {isAdmin && (
        <SettingsLink id="admin-dashboard" to={`${url(PATHS.ADMIN)}/branding`}>
          Global settings
        </SettingsLink>
      )}
      <SettingsLink id="manage-workspaces" to={url(PATHS.WORKSPACES)}>
        Manage workspaces
      </SettingsLink>
      <Divider light mt="s" mb="r" />
      <H5 fontSize="1.4rem" fontWeight="bold" color="greyDark" uppercase mb="s">
        Configure workspace
      </H5>
      <SettingsLink
        indented
        id="workspace-configuration-grouptypes"
        to={url(PATHS.WORKSPACE_CONFIGURATION, { tabType: "org-structure" })}
      >
        Org structure
      </SettingsLink>
      <StrategyStructureLink />
      <SettingsLink
        indented
        id="workspace-configuration-tags"
        to={url(PATHS.WORKSPACE_CONFIGURATION, { tabType: "tags" })}
      >
        Manage tags
      </SettingsLink>
      {isAdmin && (
        <SettingsLink
          indented
          id="manage-data-integrations-header-link"
          to={url(PATHS.WORKSPACE_CONFIGURATION, { tabType: "datasources" })}
        >
          Data integrations
        </SettingsLink>
      )}
      <SettingsLink
        indented
        id="workspace-configuration-allocation"
        to={url(PATHS.WORKSPACE_CONFIGURATION, {
          tabType: "allocation",
        })}
      >
        Allocation config
      </SettingsLink>
      <SettingsLink
        indented
        id="workspace-configuration-planner"
        to={url(PATHS.WORKSPACE_CONFIGURATION, {
          tabType: "planner",
        })}
      >
        Planner config
      </SettingsLink>
      <SettingsLink
        indented
        id="workspace-configuration-forecast"
        to={url(PATHS.WORKSPACE_CONFIGURATION, {
          tabType: "forecast",
        })}
      >
        Forecast config
      </SettingsLink>
      <SettingsLink
        indented
        id="workspace-configuration-strategy"
        to={url(PATHS.WORKSPACE_CONFIGURATION, {
          tabType: "strategy",
        })}
      >
        Strategy config
      </SettingsLink>
      <SettingsLink
        indented
        id="workspace-configuration-groupaccess"
        to={url(PATHS.WORKSPACE_CONFIGURATION, {
          tabType: "group-access",
        })}
      >
        Group access
      </SettingsLink>
      <SettingsLink
        indented
        id="workspace-configuration-workspaceaccess"
        to={url(PATHS.WORKSPACE_CONFIGURATION, {
          tabType: "workspace-access",
        })}
      >
        Workspace access
      </SettingsLink>
      <SettingsLink
        indented
        id="workspace-configuration-principles"
        to={url(PATHS.WORKSPACE_CONFIGURATION, {
          tabType: "principle-sets",
        })}
      >
        Principle sets
      </SettingsLink>
      <SettingsLink
        indented
        id="workspace-configuration-notifications"
        to={url(PATHS.WORKSPACE_CONFIGURATION, {
          tabType: "notifications",
        })}
      >
        Workspace notifications
      </SettingsLink>
      <SettingsLink
        indented
        id="workspace-configuration-features"
        to={url(PATHS.WORKSPACE_CONFIGURATION, {
          tabType: "features",
        })}
      >
        Workspace feature toggles
      </SettingsLink>
      <SettingsLink
        indented
        id="workspace-configuration-other"
        to={url(PATHS.WORKSPACE_CONFIGURATION, {
          tabType: "details",
        })}
      >
        Workspace details
      </SettingsLink>
      {showDevTools && (
        <>
          <Divider light my="s" />
          <SettingsLink
            id="internal-dashboard"
            to={url(PATHS.INTERNAL_DASHBOARD_GENERAL)}
          >
            Internal devtool
          </SettingsLink>
        </>
      )}
    </>
  );
};

export default SettingsContent;
