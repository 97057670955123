import { filter, map } from "lodash";

import { isGroupVisible } from "src/util/group";

export const convertSortTeamsByGroupTypeFlag = (sortTeamsByGroupType) =>
  sortTeamsByGroupType === true && "groupByType";

// eslint-disable-next-line import/prefer-default-export
export const filterOutNonVisibleGroups = (groups) => {
  return filter(
    map(groups, (g) => {
      const isVisible = isGroupVisible(g);

      if (isVisible) {
        return {
          ...g,
          childTeams: filter(g.childTeams, (t) => isGroupVisible(t)),
        };
      }

      return null;
    })
  );
};
