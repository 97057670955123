import React from "react";
import { get } from "lodash";
import PATHS, { url } from "src/consts/urlPaths";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import SettingsLink from "../SettingsLink";

const StrategyStructureLink = () => {
  const workspace = useWorkspace();

  const enableStrategyStructure = get(
    workspace,
    "config.featureFlags.enableStrategyStructure"
  );

  if (!enableStrategyStructure) {
    return null;
  }

  return (
    <SettingsLink
      indented
      id="workspace-configuration-strategy-types"
      to={url(PATHS.WORKSPACE_CONFIGURATION, { tabType: "strategy-structure" })}
    >
      Strategy structure
    </SettingsLink>
  );
};

export default StrategyStructureLink;
