import React, { useMemo, useEffect } from "react";
import PropTypes from "prop-types";

import { get, map, isEmpty } from "lodash";
import { useQuery } from "@apollo/client";
import { Select } from "orcs-design-system";

import { getWorkspaceConfigTypes } from "src/pages/Workspaces/workspace.graphql";

const ConfigTypeSelect = ({ selectedConfigType, onConfigTypeSelect }) => {
  const { data, loading } = useQuery(getWorkspaceConfigTypes);

  const options = useMemo(
    () =>
      map(get(data, "types"), (t) => {
        const { id, displayName } = t;
        return {
          label: `${displayName}`,
          value: id,
        };
      }),
    [data]
  );

  useEffect(() => {
    if (selectedConfigType || isEmpty(options)) {
      return;
    }

    // select the first option
    onConfigTypeSelect(options[0]);
  }, [selectedConfigType, options, onConfigTypeSelect]);

  return (
    <Select
      inputId="select-config-type"
      label="Select config type"
      cacheOptions
      isLoading={loading}
      options={options}
      onChange={onConfigTypeSelect}
      value={selectedConfigType}
    />
  );
};

ConfigTypeSelect.propTypes = {
  selectedConfigType: PropTypes.object,
  onConfigTypeSelect: PropTypes.func,
};

export default ConfigTypeSelect;
