import { reduce, keyBy } from "lodash";

export default (flattenedGroupings) => {
  return reduce(
    flattenedGroupings,
    (obj, g) => {
      return {
        ...obj,
        ...keyBy(g.lineItems, "id"),
      };
    },
    {}
  );
};
