import { pickBy, includes, get, cloneWith } from "lodash";

import useTagsVisibleInFilter from "src/app/Restricted/useTagsVisibleInFilter";

const useWorkspaceWithUpdatedTagTypesConfig = (workspace) => {
  const tagsVisibleIn = useTagsVisibleInFilter();

  const tagTypesConfig = pickBy(
    get(workspace, ["config", "tagConfig"]),
    (tagConfig) => includes(tagConfig?.visibleIn, tagsVisibleIn)
  );

  const workspaceConfig = cloneWith(workspace?.config, (config) => ({
    ...config,
    tagConfig: tagTypesConfig,
  }));

  return cloneWith(workspace, (clonedWorkspace) => ({
    ...clonedWorkspace,
    config: workspaceConfig,
  }));
};

export default useWorkspaceWithUpdatedTagTypesConfig;
