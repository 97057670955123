import {
  map,
  mapValues,
  keyBy,
  includes,
  startsWith,
  flatMap,
  filter,
  keys,
} from "lodash";

export default (state, payload) => {
  const {
    baselineDescendantGroups,
    parentsForMovedOutGroups,
    baselineParentsForMovedInGroups,
  } = payload;
  const macroAllocationKeys = filter(keys(payload), (k) =>
    startsWith(k, "macro")
  );
  const macroAllocations = flatMap(macroAllocationKeys, (k) => payload[k]);
  const { groupLookup, approverTypes, roleTypes } = state;
  return {
    ...keyBy(
      map(baselineDescendantGroups, (g) => ({
        ...g,
        isSource: includes([...approverTypes, ...roleTypes], g.type),
      })),
      "id"
    ),
    ...keyBy(
      map(parentsForMovedOutGroups, (g) => ({
        ...g,
        isSource: includes([...approverTypes, ...roleTypes], g.type),
      })),
      "id"
    ),
    ...keyBy(
      map(baselineParentsForMovedInGroups, (g) => ({
        ...g,
        isSource: includes([...approverTypes, ...roleTypes], g.type),
      })),
      "id"
    ),
    ...mapValues(
      keyBy(macroAllocations, "targetGroupId"),
      (macroAllocation) => ({
        ...macroAllocation.targetGroup,
        isSource: false,
      })
    ),
    ...mapValues(
      keyBy(macroAllocations, "sourceGroupId"),
      (macroAllocation) => ({
        ...macroAllocation.sourceGroup,
        isSource: true,
      })
    ),
    ...mapValues(groupLookup, (g) => ({
      ...g,
      isSource: includes([...approverTypes, ...roleTypes], g.type),
    })),
  };
};
