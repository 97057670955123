import { filter, keyBy, find, isNil, get } from "lodash";
import { getForecastAllocationProjects } from "src/allocation/util/allocationProject";

export const getForecastMacroAllocations = (
  macroAllocations,
  allocationProjects,
  hideHiddenTeams
) => {
  const validAllocationProjects =
    getForecastAllocationProjects(allocationProjects);

  const validAllocationProjectsLookup = keyBy(validAllocationProjects, "id");

  return filter(
    macroAllocations,
    (ma) =>
      validAllocationProjectsLookup[ma.allocationProjectId] &&
      (hideHiddenTeams ? get(ma, "targetGroup.isHidden") !== true : true)
  );
};

export const getPlannerMacroAllocations = (
  macroAllocations,
  allocationProjects,
  includeNullStatus = true,
  hideHiddenTeams
) => {
  const activeAllocationProject = find(allocationProjects, (ap) => ap.isActive);

  if (!activeAllocationProject) return [];

  return filter(
    macroAllocations,
    (ma) =>
      ma.allocationProjectId === activeAllocationProject.id &&
      (hideHiddenTeams ? get(ma, "targetGroup.isHidden") !== true : true) &&
      (includeNullStatus ||
        !isNil(ma.approvalStatus) ||
        !isNil(ma.requestStatus))
  );
};
