/* eslint-disable no-param-reassign */
import { forEach } from "lodash";
import { GROUPED_BY_DEMAND } from "../viewModes";
import { expandParent, expandIds, tryPush } from "./util/expandGroupings";

const expandLineItems = ({
  member,
  targetLineItemId,
  lineItemLookup,
  expandedGroupIds,
  expandedLineItemsGroupIds,
  expandedMemberListIds,
  membersMap,
}) => {
  const lineItem = lineItemLookup[targetLineItemId];

  // If not found... do nothing
  if (!lineItem) {
    return;
  }

  // Expand lineItem groupings
  const groupingIds = expandParent(lineItem.grouping);

  // Expand all parent groupings
  expandIds(groupingIds, expandedGroupIds);

  // If lineItem exists in the grouping lineItems
  if (lineItem.grouping && lineItem.grouping.canToggleLineItems) {
    expandIds(groupingIds, expandedLineItemsGroupIds);
  }

  // Expand the member list
  expandedMemberListIds[targetLineItemId] = true;

  // Add person into lineItem members => it will be used for members list
  tryPush(membersMap, targetLineItemId, member);
};

const addMembersForDemandView = ({
  member,
  groupingLookup,
  lineItemLookup,
  expandedGroupIds,
  expandedLineItemsGroupIds,
  expandedMemberListIds,
  membersMap,
}) => {
  const { allocations, sourceGroupIds } = member;

  forEach(allocations, ({ targetGroupId }) => {
    if (!groupingLookup[targetGroupId]) {
      return;
    }

    // Always use the first one as back end
    const sourceGroupId = sourceGroupIds[0];
    const targetLineItemId = `${targetGroupId}-${sourceGroupId}`;
    expandLineItems({
      member,
      targetLineItemId,
      lineItemLookup,
      expandedGroupIds,
      expandedLineItemsGroupIds,
      expandedMemberListIds,
      membersMap,
    });
  });
};

const addMembersForSupplyView = ({
  member,
  groupingLookup,
  lineItemLookup,
  expandedGroupIds,
  expandedLineItemsGroupIds,
  expandedMemberListIds,
  membersMap,
}) => {
  const { allocations, sourceGroupIds } = member;

  // Always use the first one as back end
  const sourceGroupId = sourceGroupIds[0];

  if (!groupingLookup[sourceGroupId]) {
    return;
  }

  forEach(allocations, ({ targetGroupId }) => {
    const targetLineItemId = `${sourceGroupId}-${targetGroupId}`;
    expandLineItems({
      member,
      targetLineItemId,
      lineItemLookup,
      expandedGroupIds,
      expandedLineItemsGroupIds,
      expandedMemberListIds,
      membersMap,
    });
  });
};

export const updateWithSearchResult = ({
  members,
  groupingLookup,
  lineItemLookup,
  error,
  viewMode,
}) => {
  // Expand all groupings
  const expandedGroupIds = {};
  const expandedLineItemsGroupIds = {};
  const expandedMemberListIds = {};
  const membersMap = {};

  const addMembersToView =
    viewMode === GROUPED_BY_DEMAND
      ? addMembersForDemandView
      : addMembersForSupplyView;

  forEach(members, (member) => {
    addMembersToView({
      member,
      groupingLookup,
      lineItemLookup,
      expandedGroupIds,
      expandedLineItemsGroupIds,
      expandedMemberListIds,
      membersMap,
    });
  });

  return {
    expandedGroupIds,
    expandedLineItemsGroupIds,
    expandedMemberListIds,
    searchResult: {
      error,
      members: membersMap,
      expandedGroupIdsForSearch: { ...expandedGroupIds },
      targetLineItems: { ...expandedMemberListIds },
    },
  };
};

export const updateGroupSearchResult = ({ targetGroupings }) => {
  // Expand all groupings
  const expandedGroupIds = {};
  const expandedGroupIdsForSearch = {};

  forEach(targetGroupings, (grouping) => {
    let current = grouping.parent;
    expandedGroupIdsForSearch[grouping.id] = true;

    while (current) {
      expandedGroupIds[current.id] = true;
      expandedGroupIdsForSearch[current.id] = true;

      current = current.parent;
    }
  });

  return {
    expandedGroupIds,
    expandedLineItemsGroupIds: {},
    expandedMemberListIds: {},
    searchResult: {
      expandedGroupIdsForSearch,
      targetLineItems: null,
    },
  };
};

export const updateLineItemSearchResult = ({ targetGroupings }) => {
  // Expand all groupings
  const expandedGroupIds = {};
  const expandedLineItemsGroupIds = {};
  const targetLineItems = {};

  forEach(targetGroupings, (lineItem) => {
    targetLineItems[lineItem.id] = true;

    // Expand lineItem groupings
    const groupingIds = expandParent(lineItem.grouping);

    // Expand all parent groupings
    expandIds(groupingIds, expandedGroupIds);

    // If lineItem exists in the grouping lineItems
    if (lineItem.grouping && lineItem.grouping.canToggleLineItems) {
      expandIds(groupingIds, expandedLineItemsGroupIds);
    }
  });

  return {
    expandedGroupIds,
    expandedLineItemsGroupIds,
    expandedMemberListIds: {},
    searchResult: {
      expandedGroupIdsForSearch: { ...expandedGroupIds },
      targetLineItems,
    },
  };
};
