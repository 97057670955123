import { getItem, saveItem } from "src/services/sessionStorage";

const getPreviouslyExpandedIdsStorageKey = (group, pageMode, viewMode) => {
  return `${group.id}-${pageMode}-${viewMode}-previously-expanded-ids`;
};

export const getPersistedExpandedIds = (group, pageMode, viewMode) => {
  const storageKey = getPreviouslyExpandedIdsStorageKey(
    group,
    pageMode,
    viewMode
  );

  return getItem(storageKey) || {};
};

export const saveExpandedIds = ({ group, pageMode, viewMode, value }) => {
  const storageKey = getPreviouslyExpandedIdsStorageKey(
    group,
    pageMode,
    viewMode
  );

  saveItem(storageKey, value);
};
