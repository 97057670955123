import { forEach } from "lodash";

import setGroupingWithDefaultConstraints from "./setGroupingWithDefaultConstraints";

const setGroupingsWithDefaultConstraints = (
  groupings,
  allocationProjectLookup,
  columns
) => {
  forEach(groupings, (grouping) => {
    setGroupingsWithDefaultConstraints(
      grouping.childGroupings,
      allocationProjectLookup,
      columns
    );

    setGroupingWithDefaultConstraints(
      grouping,
      allocationProjectLookup,
      columns
    );
  });
};

export default setGroupingsWithDefaultConstraints;
