import { useMemo } from "react";
import canPerform from "./canPerform";

const useCan = (user, roles, allowedRoles) => {
  const can = useMemo(
    () => canPerform(user, roles, allowedRoles),
    [user, roles, allowedRoles]
  );

  return can;
};

export default useCan;
