/* eslint-disable no-param-reassign */
import {
  get,
  has,
  forEach,
  toLower,
  isEmpty,
  values,
  sortBy,
  find,
  filter,
  isArray,
} from "lodash";

export const updateTreeObject = (tree) => {
  if (isArray(tree)) {
    return [...tree];
  }

  return { ...tree };
};

const byName = (g) => toLower(get(g, "name"));
const byType = (g) => toLower(get(g, "group.type"));
const isBranchExpanded = (n) => n.toggled || n.active;

export const canHaveChildren = (type, groupTypes) => {
  const groupType = get(groupTypes, type);

  // If a type doesn't exist in group types... suppose it has children
  if (!groupType || !has(groupType, "childTypes")) {
    return true;
  }

  return !isEmpty(get(groupType, "childTypes"));
};

export const createTreeNode = (group, groupTypes, parentNode) => {
  const { id, name, type } = group;
  const isParentType = canHaveChildren(type, groupTypes);
  const isLoaded = isParentType ? has(group, "childTeams") : true;

  return {
    id,
    name,
    group,
    active: false,
    toggled: false,
    isParentType,
    loading: !isLoaded,
    children: null,
    parentNode,
  };
};

export const addToChildren = (
  parent,
  group,
  groupTypes,
  createTreeNodeImpl = createTreeNode
) => {
  const { children } = parent;
  const { id } = group;
  const groupNode = createTreeNodeImpl(group, groupTypes, parent);

  // Parent has no children
  if (!children) {
    parent.children = {
      [id]: groupNode,
    };
  } else if (!children[id]) {
    // Has children but children doesn't have group
    children[id] = groupNode;
  }

  return groupNode;
};

export const shouldIgnoreGroupByType = (type, targetGroupTypes) => {
  if (isEmpty(targetGroupTypes)) {
    return false;
  }

  return !targetGroupTypes[type];
};

const addGroupsToRoots = (
  root,
  groups,
  groupTypes,
  targetGroupTypes,
  createTreeNodeImpl
) => {
  forEach(groups, (group) => {
    const { type } = group;
    if (shouldIgnoreGroupByType(type, targetGroupTypes)) {
      return;
    }

    const groupNode = addToChildren(
      root,
      group,
      groupTypes,
      createTreeNodeImpl
    );

    const { childTeams } = group;
    forEach(childTeams, (child) => {
      if (shouldIgnoreGroupByType(child.type, targetGroupTypes)) {
        return;
      }

      addToChildren(groupNode, child, groupTypes, createTreeNodeImpl);
    });
  });
};

export const convertChildrenToArray = (parent, sortOption) => {
  if (isEmpty(parent.children)) {
    parent.children = parent.isParentType && parent.loading ? [] : null;
  } else {
    if (sortOption === "none") {
      parent.children = values(parent.children);
    } else {
      const sortFunction =
        sortOption === "groupByType" ? [byType, byName] : [byName];
      parent.children = sortBy(values(parent.children), sortFunction);
    }

    parent.children.forEach((child) =>
      convertChildrenToArray(child, sortOption)
    );
  }

  return parent;
};

export const buildTree = (
  groups,
  groupTypes,
  sortOption,
  targetGroupTypes,
  createTreeNodeImpl,
  convertChildrenToArrayImpl = convertChildrenToArray,
  addGroupsToRootsImpl = addGroupsToRoots
) => {
  const virtualRoot = {
    active: false,
    toggled: false,
    children: null,
  };

  addGroupsToRootsImpl(
    virtualRoot,
    groups,
    groupTypes,
    targetGroupTypes,
    createTreeNodeImpl
  );
  convertChildrenToArrayImpl(virtualRoot, sortOption);

  return virtualRoot;
};

export const addChildTeamsToNode = (
  group,
  groupNode,
  groupTypes,
  sortOption,
  targetGroupTypes,
  createTreeNodeImpl = createTreeNode,
  convertChildrenToArrayImpl = convertChildrenToArray,
  addGroupsToRootsImpl = addGroupsToRoots
) => {
  if (!group) {
    return;
  }

  const { childTeams } = group;

  if (isEmpty(childTeams)) {
    groupNode.children = [
      {
        noActivation: true,
        isPlaceholder: true,
        name: "No child items",
        group: {},
      },
    ];
    groupNode.loading = false;
    return;
  }

  groupNode.children = {};
  addGroupsToRootsImpl(
    groupNode,
    childTeams,
    groupTypes,
    targetGroupTypes,
    createTreeNodeImpl
  );

  convertChildrenToArrayImpl(groupNode, sortOption);
  groupNode.loading = false;
};

export const closeTreeNodes = (root) => {
  const parent = root;

  const expandedNodes = filter(parent.children, isBranchExpanded);

  if (isEmpty(expandedNodes)) {
    return;
  }

  forEach(expandedNodes, (node) => {
    node.toggled = false;
    node.active = false;

    closeTreeNodes(node);
  });
};

export const expandTreeNodes = (root, ids, active = true) => {
  let parent = root;
  let activeNode = null;

  // expand parent first
  parent.toggled = true;

  if (parent && parent.children && !isEmpty(ids)) {
    for (let i = 0, j = ids.length; i < j; i += 1) {
      const node = find(parent.children, ["id", ids[i]]);

      if (parent && node) {
        node.toggled = true;
        node.active = active;
        parent.active = false;
        activeNode = node;

        parent = node;
      }
    }
  }

  return activeNode;
};

export const reopenTreeToNodePaths = (root, nodePaths) => {
  closeTreeNodes(root);

  forEach(nodePaths, (path) => {
    return expandTreeNodes(root, path);
  });
};

export const reopenTreeToGroupNode = (root, ids) => {
  closeTreeNodes(root);
  return expandTreeNodes(root, ids);
};

export const findTreeNodes = (root, ids) => {
  let parent = root;
  const nodes = [];

  if (parent && parent.children && !isEmpty(ids)) {
    for (let i = 0, j = ids.length; i < j; i += 1) {
      const node = find(parent.children, ["id", ids[i]]);

      if (parent && node) {
        nodes.push(node);
        parent = node;
      }
    }
  }

  return nodes;
};

export const insertChildrenNodes = (
  root,
  ids,
  groups,
  groupTypes,
  sortOptions,
  createTreeNodeImpl = createTreeNode,
  convertChildrenToArrayImpl = convertChildrenToArray
) => {
  let parent = root;

  if (parent && parent.children && !isEmpty(ids)) {
    for (let i = 0, j = ids.length; i < j; i += 1) {
      const node = find(parent.children, ["id", ids[i]]);

      if (!node) {
        return;
      }

      if (node.loading) {
        const group = find(groups, ["id", node.id]);
        if (group) {
          addChildTeamsToNode(
            group,
            node,
            groupTypes,
            sortOptions,
            null,
            createTreeNodeImpl,
            convertChildrenToArrayImpl
          );
        }
      }

      parent = node;
    }
  }
};

const EmptyTree = {
  root: {},
  treeData: [],
  activeNode: null,
};

export const buildTreeStructure = (
  groups,
  groupTypes,
  ids,
  sortOption,
  targetGroupTypes,
  createTreeNodeImpl,
  convertChildrenToArrayImpl,
  addGroupsToRootsImpl
) => {
  if (isEmpty(groups)) {
    return EmptyTree;
  }
  const root = buildTree(
    groups,
    groupTypes,
    sortOption,
    targetGroupTypes,
    createTreeNodeImpl,
    convertChildrenToArrayImpl,
    addGroupsToRootsImpl
  );

  const treeData = root.children;

  closeTreeNodes(root);

  let activeNode;

  // If in a team page, ids should not be empty
  if (!isEmpty(ids)) {
    activeNode = expandTreeNodes(root, ids);
  } else if (!isEmpty(treeData) && treeData.length === 1) {
    // If there is only one child, open the first level
    treeData[0].toggled = true;
  }

  return {
    root,
    treeData,
    groups,
    activeNode,
  };
};

export const buildTreeFromGroups = (
  groups,
  sortOption,
  convertChildrenToArrayImpl = convertChildrenToArray
) => {
  const root = {};

  forEach(groups, (group) => {
    const { id, name, parentGroups = [] } = group;

    let parentNode = root;
    forEach(parentGroups, (p) => {
      const { id: parentId, name: parentName } = p;

      // Already added in the tree
      if (parentNode[parentId]) {
        parentNode = parentNode[parentId].children;
        return;
      }

      const node = {
        id: parentId,
        name: parentName,
        active: false,
        toggled: true,
        loading: false,
        children: {},
        group: p,
        isParentType: true,
      };

      parentNode[parentId] = node;
      parentNode = node.children;
    });

    const existingNode = parentNode[id] || {};

    parentNode[id] = {
      id,
      name,
      active: true,
      toggled: true,
      loading: false,
      children: {},
      group,
      isParentType: false,
      ...existingNode,
    };
  });

  const tree = convertChildrenToArrayImpl({ children: root }, sortOption);

  return tree?.children || [];
};
