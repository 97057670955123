/* eslint-disable no-param-reassign */
import { find, keyBy, forEach, isEmpty } from "lodash";

import { newGrouping, newGroupingOptions } from "../../util";
import { isTargetAreaGroup } from "../../util/isTargetAreaGroup";

const buildUniqueId = (parentGrouping, id) => {
  let newId = id;
  let grouping = parentGrouping;
  while (grouping) {
    newId = `${grouping.groupId}-${newId}`;
    grouping = grouping.parent;
  }
  return newId;
};

const buildGrouping = ({
  targetGroup,
  parentGrouping,
  groupTypes,
  nestedLevel,
  showObjectives,
}) => {
  const grouping = newGrouping({
    ...newGroupingOptions({
      group: targetGroup,
      groupType: groupTypes[targetGroup.type],
    }),
    parentGrouping,
    id: buildUniqueId(parentGrouping, targetGroup.id),
    nestedLevel,
    showObjectives,
    isDemand: true,
    isSupply: false,
    canHide: false,
    canRename: false,
    canAddLineItem: false,
    canViewInTeamBuilder: false,
    canViewInDetails: true,
    isVirtual: true,
  });

  return grouping;
};

const setBudget = (budgetsMap, grouping) => {
  const targetGroupId = grouping.group.id;

  if (budgetsMap[targetGroupId]) {
    grouping.budget = budgetsMap[targetGroupId].value;
  }
};

const setupTemplatedDemandGrouping = ({
  parent,
  rootGrouping,
  groupings,
  offset,
  budgetsMap,
}) => {
  forEach(groupings, (grouping) => {
    grouping.id = buildUniqueId(grouping.parent, grouping.groupId);

    grouping.nestedLevel += offset;
    grouping.root = rootGrouping;
    grouping.intersecting = parent;

    if (budgetsMap) {
      setBudget(budgetsMap, grouping);
    }

    setupTemplatedDemandGrouping({
      parent,
      rootGrouping,
      groupings: grouping.childGroupings,
      offset: offset + 1,
      budgetsMap,
    });
  });
};

const sortGroupings = (groupings) => {
  return groupings.sort((a, b) => a.group.name.localeCompare(b.group.name));
};

const insertDemandTree = ({
  rootGrouping,
  groupings,
  showObjectives,
  groupTypes,
  isBudgetEnabled,
}) => {
  forEach(groupings, (grouping) => {
    insertDemandTree({
      rootGrouping,
      groupings: grouping.childGroupings,
      showObjectives,
      groupTypes,
      isBudgetEnabled,
    });

    if (grouping.lineItems.length > 0) {
      // transplant lineItems into new demand grouping tree

      const tempLineItems = grouping.lineItems;
      grouping.lineItems = []; // eslint-disable-line

      const demandGroupings = {};
      const topLevelGroupings = [];

      // add demand grouping tree
      forEach(tempLineItems, (lineItem) => {
        const { group } = lineItem;
        const { hierarchyIds, parentGroups } = group;

        let parentGrouping = null;

        forEach(hierarchyIds, (groupId, index) => {
          let current;
          if (groupId === group.id) {
            current = group;
          } else {
            current = find(parentGroups, (g) => g.id === groupId);
          }

          if (!current || !isTargetAreaGroup(current, groupTypes)) {
            return;
          }

          let currentGrouping;

          if (demandGroupings[groupId]) {
            currentGrouping = demandGroupings[groupId];
          } else {
            currentGrouping = buildGrouping({
              targetGroup: current,
              parentGrouping,
              groupTypes,
              nestedLevel: index,
              showObjectives,
            });

            demandGroupings[groupId] = currentGrouping;

            if (!parentGrouping) {
              topLevelGroupings.push(currentGrouping);
            } else {
              parentGrouping.childGroupings.push(currentGrouping);
              sortGroupings(parentGrouping.childGroupings);
            }
          }

          parentGrouping = currentGrouping;
        });

        if (parentGrouping) {
          parentGrouping.lineItems.push(lineItem);
        }

        lineItem.grouping = parentGrouping; // eslint-disable-line
      });

      // Add top level demand groupings to childGroupings
      sortGroupings(topLevelGroupings);
      forEach(topLevelGroupings, (topGrouping) => {
        topGrouping.parent = grouping;
        topGrouping.nestedLevel = grouping.nestedLevel + 1;
      });

      grouping.childGroupings.push(...topLevelGroupings);

      let budgetsMap = null;
      if (isBudgetEnabled && !isEmpty(grouping.group.budgets)) {
        budgetsMap = keyBy(grouping.group.budgets, "targetGroupId");
      }

      setupTemplatedDemandGrouping({
        parent: grouping,
        rootGrouping,
        groupings: topLevelGroupings,
        offset: grouping.nestedLevel,
        budgetsMap,
      });
    }
  });
};

export default ({
  groupings,
  showObjectives,
  groupTypes = {},
  isBudgetEnabled,
} = {}) => {
  insertDemandTree({
    rootGrouping: groupings[0],
    groupings,
    showObjectives,
    groupTypes,
    isBudgetEnabled,
  });

  return groupings;
};
