import React from "react";
import PropTypes from "prop-types";
import { Popover, Badge, Box, Spacer, Icon } from "orcs-design-system";
import { get, map, filter, values, includes } from "lodash";
import GroupPropType from "src/custom-prop-types/group";

import { useGroupTypes } from "src/contexts/global/WorkspaceContext";

const getVariant = (variant, groupType) => {
  if (variant) {
    return variant;
  }

  if (!groupType) {
    return "success";
  }

  const { styleVariant, isSupply } = groupType;
  if (styleVariant) {
    return styleVariant;
  }

  return isSupply ? "secondary" : "success";
};

const GroupTypeInnerBadge = ({ groupType, variant }) => (
  <Badge
    display="block"
    noWrap={true}
    zIndex="2"
    data-testid="cp-teamlist-row-badge"
    variant={getVariant(variant, groupType)}
  >
    {groupType.displayName || groupType.name}
  </Badge>
);

GroupTypeInnerBadge.propTypes = {
  groupType: PropTypes.object.isRequired,
  variant: PropTypes.string,
};

const GroupTypeBadgeToolTipContent = ({
  groupType,
  parentTypes,
  childTypes,
}) => {
  return (
    <Spacer my={3}>
      {!!groupType.shortDesc && <Box m={1}>{groupType.shortDesc}</Box>}
      {parentTypes.length > 0 && (
        <>
          <span>Part of:</span>
          {map(parentTypes, (type) => (
            <Box p={1} key={type.name}>
              <GroupTypeInnerBadge groupType={type} />
            </Box>
          ))}
        </>
      )}
      {childTypes.length > 0 && (
        <>
          <span>Contains:</span>
          {map(childTypes, (type) => (
            <Box p={1} key={type.name}>
              <GroupTypeInnerBadge groupType={type} />
            </Box>
          ))}
        </>
      )}
      {groupType.isDemand && (
        <Box>
          <Icon icon={["fas", "check-circle"]} /> Provides Demand
        </Box>
      )}
      {groupType.isSupply && (
        <Box>
          <Icon icon={["fas", "check-circle"]} /> Provides Supply
        </Box>
      )}
    </Spacer>
  );
};

GroupTypeBadgeToolTipContent.propTypes = {
  groupType: PropTypes.object.isRequired,
  childTypes: PropTypes.array.isRequired,
  parentTypes: PropTypes.array.isRequired,
};

const GroupTypeBadge = ({
  group,
  showToolTip = false,
  toolTipPosition = "bottom",
  variant,
  ...props
}) => {
  const groupTypes = useGroupTypes();
  const groupType = get(groupTypes, group.type, {
    name: group.type,
    noConfig: true,
  });

  if (!group.type) {
    return null;
  }

  let tooltip;

  if (showToolTip && !groupType.noConfig) {
    const parentTypes = filter(values(groupTypes), (type) =>
      includes(type.childTypes, groupType.name)
    );

    const childTypes = map(
      groupType.childTypes,
      (typeName) => groupTypes[typeName]
    );

    tooltip = (
      <GroupTypeBadgeToolTipContent
        groupType={groupType}
        childTypes={childTypes}
        parentTypes={parentTypes}
      />
    );
  }

  return tooltip ? (
    <Popover
      text={tooltip}
      direction={toolTipPosition}
      textAlign="left"
      data-testid="group-type-badge-tooltip"
      {...props}
    >
      <GroupTypeInnerBadge groupType={groupType} variant={variant} />
    </Popover>
  ) : (
    <GroupTypeInnerBadge groupType={groupType} variant={variant} />
  );
};

GroupTypeBadge.propTypes = {
  group: GroupPropType.isRequired,
  showToolTip: PropTypes.bool,
  toolTipPosition: PropTypes.string,
  variant: PropTypes.string,
};

export default GroupTypeBadge;
