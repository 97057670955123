import PropTypes from "prop-types";
import React from "react";
import * as Sentry from "@sentry/react";
import Auth0UserProps from "src/custom-prop-types/auth0user";
import ErrorMessage from "./ErrorPage/ErrorMessage";
import {
  isWorkspaceNotFoundUIError,
  isWorkspaceNotFoundError,
  isWorkspaceAccessDeniedError,
} from "./ErrorPage/detectErrorType";

class WorkspaceErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidCatch(error) {
    if (
      !isWorkspaceNotFoundUIError(error) &&
      !isWorkspaceNotFoundError(error) &&
      !isWorkspaceAccessDeniedError(error)
    ) {
      throw error;
    }
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    const { user, children } = this.props;
    if (error) {
      return (
        <Sentry.ErrorBoundary
          fallback={({ ...props }) => (
            <ErrorMessage
              user={user}
              showHeader={true}
              showContainer={true}
              showClientInfo={false}
              {...props}
            />
          )}
        >
          {children}
        </Sentry.ErrorBoundary>
      );
    }
    return children;
  }
}

WorkspaceErrorBoundary.propTypes = {
  user: Auth0UserProps,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default WorkspaceErrorBoundary;
