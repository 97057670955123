const { partition, concat } = require("lodash");
const { useState } = require("react");

const {
  getCombinedSortedTags,
} = require("./sub-components/ExpandableBadgeList.utils");

const useSelectedValues = (tags) => {
  const [newTags, setNewTags] = useState([]);
  const [existingTags, newlyAddedTags] = partition(
    tags,
    (tag) => !newTags.find((newTag) => newTag.id === tag.tagId)
  );
  const addTag = (tag) => {
    setNewTags([...newTags, tag]);
  };

  return {
    addTag,
    newTags,
    tags: concat(getCombinedSortedTags(existingTags), newlyAddedTags),
  };
};

export default useSelectedValues;
