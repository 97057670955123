import { values, keyBy, forEach } from "lodash";

import {
  getPlannerColumns,
  getPlannerMacroAllocations,
  checkForPending,
  setGroupingsWithPlannerCells,
  setCalculatedSumTotals,
  setNotifications,
  pruneEmptyGroupings,
  buildGroupingLookup,
  buildLineItemLookup,
  buildCellLookup,
  hashGrouping,
  setGroupingsBudget,
  setGroupingsTotalCost,
} from "../util";

import buildGroupings from "./buildGroupings/bySupply";
import rebuildWithExtraDemandGroupings from "./buildGroupings/rebuildWithExtraDemandGrouping";
import setGroupingsWithLineItems from "./setLineItems/demandLineItems";

const buildPlannerGroupedBySupply = ({
  rootGroup,
  data,
  insertExtraDemandGroupings = false,
  hideHiddenTeams,
  showObjectives,
  groupTypes,
  approvalReasons,
  shouldHash,
  isBudgetEnabled,
  useDeltaForBudget,
  overBudgetLimit,
  underBudgetLimit,
}) => {
  const allocationProjectLookup = keyBy(data.allocationProjects, "id");
  const columns = getPlannerColumns(data.allocationProjects);
  const macroAllocations = getPlannerMacroAllocations(
    data.macroAllocations,
    data.allocationProjects,
    !rootGroup.isSource,
    hideHiddenTeams
  );

  const groupings = buildGroupings({
    rootGroup,
    macroAllocations,
    descendantSupplyGroups: data.descendantSupplyGroups,
    roleAreaGroups: data.roleAreaGroups,
    relatedSupplyGroups: data.relatedSupplyGroups,
    hideHiddenTeams,
    showObjectives,
    groupTypes,
    isBudgetEnabled,
  });
  setGroupingsWithLineItems({
    groupings,
    macroAllocations,
    columnCount: columns.length,
    groupTypes,
    canRename: !rootGroup.isSource,
    canHide: !rootGroup.isSource,
    isBudgetEnabled,
  });
  setGroupingsWithPlannerCells({
    groupings,
    macroAllocations,
    allocationProjectLookup,
    isRequestor: !rootGroup.isSource,
    groupedByDemand: false,
    approvalReasons,
    useDeltaForBudget,
  });

  if (insertExtraDemandGroupings) {
    // extra business req: add extra hierarchy groups for demand line items.
    rebuildWithExtraDemandGroupings({
      groupings,
      hideHiddenTeams,
      showObjectives,
      groupTypes,
      isBudgetEnabled,
    });
  }

  if (isBudgetEnabled) {
    setGroupingsBudget(groupings);
    setGroupingsTotalCost(groupings, overBudgetLimit, underBudgetLimit);
  }

  setCalculatedSumTotals(groupings, columns);
  setNotifications(groupings, columns);

  pruneEmptyGroupings(groupings, isBudgetEnabled);

  const columnLookup = keyBy(columns, "id");
  const groupingLookup = buildGroupingLookup(groupings);
  const lineItemLookup = buildLineItemLookup(values(groupingLookup));
  const cellLookup = buildCellLookup(values(lineItemLookup));
  const isPendingSubmit = checkForPending(
    macroAllocations,
    !rootGroup.isSource
  );

  // Hash groupings in case anything changed
  if (shouldHash) {
    forEach(groupings, hashGrouping);
  }

  return {
    isPendingSubmit,
    columns,
    groupings,
    // lookup objects: for when we need fast access to objects in the tree
    lookups: {
      allocationProjectLookup,
      columnLookup,
      groupingLookup,
      lineItemLookup,
      cellLookup,
    },
  };
};

export default buildPlannerGroupedBySupply;
