import React from "react";

import { isEmpty, get, filter } from "lodash";
import { Spacer, H5, Divider, Small } from "orcs-design-system";
import { useAuth, useGlobalConfig } from "src/contexts/global/GlobalContext";

import {
  useWorkspace,
  useWorkspaces,
} from "src/contexts/global/WorkspaceContext";

import ExternalAppLinks from "src/components/Nav/ExternalAppLinks";
import InternalAppLinks from "src/components/Nav/InternalAppLinks";
import WorkspaceSwitcher from "src/components/Nav/WorkspaceSwitcher";
import { isInternalSupportUser } from "src/services/auth";
import { useAuth0 } from "src/contexts/auth0Provider";
import { isLocalEnvironment } from "src/services/localSettings";
import Restricted from "src/app/Restricted";

const AppSwitcherContent = () => {
  const auth = useAuth();
  const { user } = useAuth0();
  const { roles } = auth;
  const globalConfig = useGlobalConfig();
  const workspaces = useWorkspaces();
  const workspace = useWorkspace();
  const { enableWorkspaceSwitcher = false } =
    globalConfig?.config?.featureFlags || {};
  const showDevTools = isInternalSupportUser(user) || isLocalEnvironment();
  const externalContents = get(workspace, "config.externalContents");
  const externalLinks = filter(externalContents, (c) => c.type === "link");
  let externalAppLinks = null;
  if (!isEmpty(externalLinks)) {
    externalAppLinks = <ExternalAppLinks links={externalLinks} />;
  }
  return (
    <Spacer mb="r">
      <H5 fontWeight="bold">Apps</H5>
      <Divider light />
      {enableWorkspaceSwitcher && (
        <>
          <Small display="block" fontWeight="bold" mb="xs">
            Current workspace
          </Small>
          <WorkspaceSwitcher
            workspaces={workspaces}
            isSupportUser={showDevTools}
          />
        </>
      )}
      <Restricted>
        <InternalAppLinks user={user} roles={roles} workspace={workspace} />
        {externalAppLinks}
      </Restricted>
    </Spacer>
  );
};

export default AppSwitcherContent;
