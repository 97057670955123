import React, { useReducer, createContext } from "react";
import PropTypes from "prop-types";
import debug from "debug";

const debugLog = debug("orchestrated:page-refresh:context:reducer");

export const PageRefreshContext = createContext();

export const ACTIONS = {
  SET_REFRESH_FN: "SET_REFRESH_FN",
  SET_IS_REFRESHING: "SET_IS_REFRESHING",
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_REFRESH_FN: {
      const { refreshFn, showRefreshButton = true } = action;
      const { refreshFn: existingRefreshFn } = state;

      if (refreshFn !== existingRefreshFn) {
        return { ...state, isRefreshing: false, refreshFn, showRefreshButton };
      }
      return state; // do nothing
    }
    case ACTIONS.SET_IS_REFRESHING: {
      const { isRefreshing } = action;
      const { isRefreshing: existingIsRefreshing } = state;

      if (isRefreshing !== existingIsRefreshing) {
        return { ...state, isRefreshing };
      }
      return state; // do nothing
    }
    default:
      throw new Error();
  }
};

const reducerWithLogging = (state, action) => {
  debugLog(`${action.type} - received`, action);
  const newState = reducer(state, action);
  debugLog(`${action.type} - complete`, newState);
  return newState;
};

export const PageRefreshContextProvider = ({ children }) => {
  return (
    <PageRefreshContext.Provider
      value={useReducer(reducerWithLogging, {
        showRefreshButton: true,
        isRefreshing: false,
        refreshFn: null,
      })}
    >
      {children}
    </PageRefreshContext.Provider>
  );
};

PageRefreshContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};
