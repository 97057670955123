import PropTypes from "prop-types";
import React from "react";

import TagsNavigation from "./TagsNavigation";

const TagsNavigationWrapper = ({ tagTypesConfig, selectedTab }) => {
  return (
    <TagsNavigation
      tagTypesConfig={tagTypesConfig}
      isSelected={selectedTab === "tagsTree"}
    />
  );
};

TagsNavigationWrapper.propTypes = {
  tagTypesConfig: PropTypes.object,
  selectedTab: PropTypes.string,
};

export default TagsNavigationWrapper;
