import { useEffect } from "react";

const SIDE_NAV_SELECTOR = "div[class^=SideNav__SideNavWrapper]";

const useSideNavClickListener = () => {
  useEffect(() => {
    const handler = (e) => {
      const wasSideNavClicked = document
        .querySelector(SIDE_NAV_SELECTOR)
        .contains(e.target);

      if (!wasSideNavClicked) {
        const expandedElementsList = document.querySelectorAll(
          `${SIDE_NAV_SELECTOR} [aria-expanded='true']`
        );
        const ele = expandedElementsList.length > 0 && expandedElementsList[0];

        if (
          ele &&
          !document.querySelector(
            `${SIDE_NAV_SELECTOR} #keep-open-on-click-outside`
          )
        ) {
          ele.click();
        }
      }
    };

    window.addEventListener("click", handler, true);

    return () => {
      window.removeEventListener("click", handler, true);
    };
  }, []);
};

export default useSideNavClickListener;
