/* eslint-disable import/prefer-default-export */
import { round } from "lodash";

/**
 * This function rounds the FTE to the specified decimals.
 * By default, the decimal places is 2.
 *
 * @param {*} fteNum
 * @param {*} decimalplaces
 */
const roundFte = (fteNum, decimalplaces = 2) => {
  return round(fteNum, decimalplaces);
};

export { roundFte };
