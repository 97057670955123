import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useSearchPerson, useLoadPeople } from "src/hooks/useSearchPeople";
import TeamDropdown from "src/components/TeamDropdown";
import { fullDisplayName } from "src/util/personName";

import PersonFormatOptionLabel from "./PersonSelect/PersonFormatOptionLabel";

const TagAttributePersonSelect = ({
  id,
  label,
  onSelectPerson,
  value,
  person,
  disabled,
  invalid,
  mandatory,
  focus,
  featureFlags,
}) => {
  const [selectedOption, setSelectedOption] = useState();
  const { loadPersonOptions, isSearching } = useSearchPerson({
    fetchPolicy: "cache-first",
  });
  const { loadPeople, isLoading } = useLoadPeople();

  const formatOptionLabel = featureFlags?.showPersonIdInTags
    ? PersonFormatOptionLabel
    : undefined;

  useEffect(() => {
    if (person) {
      setSelectedOption({
        label: fullDisplayName(person),
        value,
      });
    } else if (value) {
      loadPeople([value]).then((loadedPeople) => {
        if (isEmpty(loadedPeople)) {
          return;
        }
        setSelectedOption({
          label: fullDisplayName(loadedPeople[0]),
          value,
        });
      });
    } else {
      setSelectedOption(null);
    }
  }, [loadPeople, person, value]);

  return (
    <TeamDropdown
      key={id}
      id={id}
      label={label}
      placeholder="Search person by name or id"
      isSearchable
      isLoading={isSearching || isLoading}
      isDisabled={disabled}
      options={true}
      loadOptions={loadPersonOptions}
      onSelectTeam={onSelectPerson}
      value={selectedOption}
      isClearable={!disabled}
      formatOptionLabel={formatOptionLabel}
      invalid={invalid}
      mandatory={mandatory}
      focus={focus}
    />
  );
};

TagAttributePersonSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  person: PropTypes.object,
  value: PropTypes.string,
  onSelectPerson: PropTypes.func,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  mandatory: PropTypes.bool,
  focus: PropTypes.bool,
  featureFlags: PropTypes.object,
};
export default TagAttributePersonSelect;
