import { Icon, Box } from "orcs-design-system";
import React from "react";

import icons from "src/config/icons";

const DropdownIndicator = () => {
  return (
    <Box px="10px">
      <Icon icon={icons.search} color="greyDark" />
    </Box>
  );
};

export default DropdownIndicator;
