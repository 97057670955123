import PropTypes from "prop-types";

const ParamsPropType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  searchTerm: PropTypes.string,
});

const MatchPropType = PropTypes.shape({
  params: ParamsPropType,
});

export default MatchPropType;
