import { InMemoryCache, defaultDataIdFromObject } from "@apollo/client/cache";

const TypePerson = "Person";
const Membership = "Membership";
const IndividualAllocation = "IndividualAllocation";

// eslint-disable-next-line no-unused-vars
const merge = (existing = [], incoming) => {
  return incoming;
};

const cache = new InMemoryCache({
  typePolicies: {
    LinkedStrategy: {
      keyFields: false,
    },
    Group: {
      fields: {
        tags: {
          merge,
        },
      },
    },
    ObjectiveAttribute: {
      keyFields: false,
    },
    GroupAttribute: {
      keyFields: false,
    },
    Person: {
      fields: {
        memberOf: {
          merge,
        },
        memberships: {
          merge,
        },
        allocations: {
          merge,
        },
        tags: {
          merge,
        },
      },
    },
  },
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case TypePerson:
        return `${TypePerson}:${object.aggregateId}`;
      case IndividualAllocation:
        return `${IndividualAllocation}:${object.allocationId}`;
      case Membership:
        return `${Membership}:${object.currentCompositeId}`;
      default:
        return defaultDataIdFromObject(object);
    }
  },
  cacheRedirects: {
    Query: {
      getPerson: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: TypePerson, aggregateId: args.aggregateId }),
    },
  },
});

export default cache;
