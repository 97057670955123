import { get, includes } from "lodash";
import flagsmith from "flagsmith";
import { DIRECTORY, POWER, ADMIN } from "src/app/Restricted/consts";
import { ADMIN_ROLE, POWER_ROLE } from "src/consts/auth";

const INTERNAL_SUPPORT_EMAIL_DOMAINS = [
  "support.orchestrated.io",
  "support.teamform.co",
];

const auth0Namespace = "http://orchestrated.io/2020/03/identity/claims";
// TODO: Move relevant metadata from user to app metadata object
// export const auth0AppMetadataKey = `${auth0Namespace}/app_metadata`;
export const auth0UserMetadataKey = `${auth0Namespace}/user_metadata`;

// this is compiled, no risk of timing attack
export const isProdAuth0Tenant =
  process.env.REACT_APP_AUTH0_DOMAIN === "id.orchestrated.io";

export const completeLogout = () => {
  flagsmith.logout();
  window.localStorage.clear();
  window.location.replace(process.env.REACT_APP_DOMAIN);
};

export const getAuth0ConnectionFromHostname = (connection) => {
  return connection
    ? {
        connection,
      }
    : null;
};

export const getApiUrlFromAuth0 = (apiUrl, tenantId, overrideTenant) => {
  if (overrideTenant) {
    return `${overrideTenant.tenantUrl}/${overrideTenant.tenantId}`;
  }

  const baseUrl = apiUrl || process.env.REACT_APP_GRAPHQL_URL;
  return process.env.REACT_APP_IS_OFFLINE === "true" || !tenantId
    ? `${process.env.REACT_APP_GRAPHQL_URL}`
    : `${baseUrl}/${tenantId}`;
};

const getFromCustomClaims = (user, key) =>
  get(user, `${auth0Namespace}/${key}`);

export const getAuth0UserMetadata = (user) =>
  getFromCustomClaims(user, "user_metadata");

const getFromUserMetadata = (user, key, defaultValue) =>
  get(getAuth0UserMetadata(user), key, defaultValue);

export const isInternalSupportUser = (user) => {
  const isInternalSupportEmail = INTERNAL_SUPPORT_EMAIL_DOMAINS.includes(
    user.email.split("@")[1]
  );

  return (
    isInternalSupportEmail && Boolean(getFromUserMetadata(user, "tenantList"))
  );
};

export const isOnboardingUser = (user) =>
  getFromUserMetadata(user, "tenantStatus") === "ONBOARDING_START";

export const isUserWithTenantPrompt = (user) => {
  const isCypressTestUser = /^cypress-.*test\.orchestrated.io$/.test(
    user.email
  );
  const isSupportUser = isInternalSupportUser(user);
  const isRunCypressLocally = process.env.REACT_APP_CYPRESS_LOCAL === "true";
  return (!isRunCypressLocally && isCypressTestUser) || isSupportUser;
};

export const hasAdminRole = (user) =>
  getFromUserMetadata(user, "roles", []).includes(ADMIN);

export const hasPowerRole = (user) =>
  getFromUserMetadata(user, "roles", []).includes(POWER);

export const hasDirectoryRole = (user) =>
  getFromUserMetadata(user, "roles", []).includes(DIRECTORY);

export const getPreferredUsername = (user) =>
  getFromCustomClaims(user, "preferred_name");

export const createAuth = (user) => ({
  preferredUsername: getPreferredUsername(user),
  username: get(user, "name"),
  email: get(user, "email"),
  createdAt: getFromCustomClaims(user, "created_at"),
  loginsCount: getFromCustomClaims(user, "logins_count"),
  operatorId: getFromUserMetadata(user, "operatorId") || get(user, "sub"),
  tenantId: getFromUserMetadata(user, "tenantId"),
  groups: getFromUserMetadata(user, "groups"),
  roles: getFromUserMetadata(user, "roles", []),
});

export const isPowerOrAdmin = (user) => {
  const roles = get(getAuth0UserMetadata(user), "roles");
  return includes(roles, POWER_ROLE) || includes(roles, ADMIN_ROLE);
};
