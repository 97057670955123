import {
  isDemandGroup,
  isLineOfBusiness,
  isSupplyGroup,
} from "src/util/customerConfig";
import { FORECAST, PLANNER } from "../pageModes";

export const buildRootGroup = (group, groupTypes, forecastRootGroupProps) => {
  return {
    ...group,
    isSource: isSupplyGroup(groupTypes, group),
    isDemand: isDemandGroup(groupTypes, group),
    isLineOfBusiness: isLineOfBusiness(groupTypes, group),
    ...forecastRootGroupProps,
  };
};

export const getPageProps = (allocation, pageMode, group) => {
  const {
    enableFinancialForecasting,
    financialYear,
    overBudgetLimit,
    underBudgetLimit,
    enableForecastSubmitting,
    enablePlannerBudgetTarget,
    useDeltaForBudget,
    plannerOverBudgetLimitPercentage,
    plannerUnderBudgetLimitPercentage,
    enableMemberFteEdit,
    desiredInputAsDelta,
    showMacroAllocationInput,
  } = allocation;

  let pageProps = {};
  let forecastRootGroupProps = {};

  if (pageMode === PLANNER) {
    pageProps = {
      isBudgetEnabled: enablePlannerBudgetTarget,
      useDeltaForBudget,
      overBudgetLimit: plannerOverBudgetLimitPercentage,
      underBudgetLimit: plannerUnderBudgetLimitPercentage,
      isRealtimeFteMode: enableMemberFteEdit,
      useRealtimeChangeOnly: enableMemberFteEdit && !showMacroAllocationInput,
      useRealtimeFteDelta: enableMemberFteEdit && desiredInputAsDelta,
    };
  } else if (pageMode === FORECAST) {
    pageProps = {
      isBudgetEnabled: enableFinancialForecasting,
      overBudgetLimit,
      underBudgetLimit,
    };

    forecastRootGroupProps = {
      enableForecastSubmitting,
      forecastSubmittedAt:
        group.forecastSubmittedFor === financialYear
          ? group.forecastSubmittedAt
          : 0,
    };
  }

  return [pageProps, forecastRootGroupProps];
};
