import { values, forEach, partition, filter, isNil } from "lodash";

import {
  newGrouping,
  newGroupingOptions,
  findGroupingFromParentIds,
  buildSourceGroupLookup,
  getDailyRateByFlag,
  buildBudgetLookup,
} from "../../util";

export default ({
  rootGroup,
  macroAllocations,
  descendantSupplyGroups,
  roleAreaGroups,
  relatedSupplyGroups,
  hideHiddenTeams = false,
  showObjectives = false,
  groupTypes = {},
  columnCount,
  isBudgetEnabled,
} = {}) => {
  // collect all our source groups
  const sourceGroupLookup = buildSourceGroupLookup({
    macroAllocations,
    descendantSupplyGroups,
    roleAreaGroups,
    relatedSupplyGroups,
  });
  delete sourceGroupLookup[rootGroup.id]; // make sure our root group is not inside our collection of unique source groups

  let setGroupingBudget = null;
  if (isBudgetEnabled) {
    setGroupingBudget = buildBudgetLookup(sourceGroupLookup, rootGroup);
  }

  let sourceGroupsYetToInsert = filter(
    values(sourceGroupLookup),
    (s) => !isNil(s.parentIds)
  );
  let sourceGroupsToInsert = [];
  const rootGrouping = newGrouping({
    ...newGroupingOptions({
      group: rootGroup,
      groupType: groupTypes[rootGroup.type],
    }),
    parentGrouping: null,
    rootGrouping: null,
    nestedLevel: 0,
    isRoot: true,
    canAddLineItem: false,
    canViewInTeamBuilder: true,
    canViewInDetails: false,
    showObjectives,
    columnCount,
    dailyRate: getDailyRateByFlag(
      rootGroup.isSource && isBudgetEnabled, // Only set for supply rootGroup
      rootGroup,
      null,
      sourceGroupLookup
    ),
  });
  rootGrouping.root = rootGrouping;
  // insert the groups in order from highest hierarchy level to lowest.
  for (let hierarchyLevel = 0; hierarchyLevel < 999; hierarchyLevel += 1) {
    [sourceGroupsToInsert, sourceGroupsYetToInsert] = partition(
      sourceGroupsYetToInsert,
      (s) => s.parentIds.length === hierarchyLevel
    );

    forEach(sourceGroupsToInsert, (sourceGroup) => {
      if (hideHiddenTeams && sourceGroup.isHidden) {
        return;
      }

      const parentGrouping =
        findGroupingFromParentIds(
          rootGrouping.childGroupings,
          sourceGroup.parentIds
        ) || rootGrouping;
      const [nestedLevel, groupingPool] = [
        parentGrouping.nestedLevel + 1,
        parentGrouping.childGroupings,
      ];

      const grouping = newGrouping({
        ...newGroupingOptions({
          group: sourceGroup,
          groupType: groupTypes[sourceGroup.type],
          parentGrouping,
          isBudgetEnabled,
          sourceGroupLookup,
        }),
        parentGrouping,
        rootGrouping,
        nestedLevel,
        memberCount: sourceGroup.memberCount,
        isSupply: true,
        isDemand: false,
        canAddLineItem: false,
        canViewInTeamBuilder: rootGroup.isSource,
        canViewInDetails: true,
        showObjectives,
        columnCount,
      });

      if (isBudgetEnabled) {
        setGroupingBudget(grouping, "isDemand");
      }
      groupingPool.push(grouping);
      groupingPool.sort((a, b) => a.group.name.localeCompare(b.group.name));
    });

    if (sourceGroupsYetToInsert.length === 0) {
      break;
    }
  }

  return [rootGrouping];
};
