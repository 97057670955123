import { remove } from "lodash";

const splitSearchTerms = (searchString) => {
  const searchTerms =
    searchString && typeof searchString === "string"
      ? searchString.trim().split(" ")
      : [];
  return remove(searchTerms, (term) => term.length !== 0);
};

export default splitSearchTerms;
