import { useEffect } from "react";

import { getSearchTerm } from "src/util/paths";

// Clear search input when navigated to another page, If search term in URL, populate that.
const useOnLocationChange = (history, setSearchQuery) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      const search = getSearchTerm();
      setSearchQuery(search || "");
    });
    return () => {
      unlisten();
    };
  }, [history, setSearchQuery]);
};

export default useOnLocationChange;
