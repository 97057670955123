import { useQuery, useLazyQuery } from "@apollo/client";
import { size, sortBy, uniqBy, isEmpty, filter } from "lodash";
import { useCallback, useEffect, useState } from "react";

import { pagedSearchTags, getTagDetails } from "src/queries/tags.graphql";
import { TAG_LOAD_LIMIT } from "src/util/consts";

const getUniqueTags = (tags) => {
  return sortBy(uniqBy(tags, "id"), "displayValue");
};

const getOffset = ({ initialLoad, tags, tagType }) => {
  if (initialLoad) {
    return 0;
  }

  if (!isEmpty(tags[tagType]?.tags)) {
    return size(tags[tagType]?.tags);
  }

  return 0;
};

const useLoadTags = ({ activeTagId, isSelected }) => {
  const [tagsLoading, setTagsLoading] = useState({});
  const [allTags, setAllTags] = useState({});
  const [tags, setTags] = useState({});

  const { data: tagData } = useQuery(getTagDetails, {
    variables: {
      tagId: activeTagId,
    },
    skip: !isSelected,
  });

  const [lazyLoadTags] = useLazyQuery(pagedSearchTags);

  const activeTag = (tagData?.tag || [])[0];

  const loadTags = useCallback(
    async (tagType, initialLoad = false) => {
      setTagsLoading((loadedTags) => {
        return {
          ...loadedTags,
          [tagType]: true,
        };
      });
      const offset = getOffset({ initialLoad, tags, tagType });

      lazyLoadTags({
        variables: {
          input: {
            filter: {
              tagTypes: [tagType],
            },
            offset,
            limit: TAG_LOAD_LIMIT,
          },
          showAppliedCount: true,
        },
      }).then(({ data }) => {
        setTagsLoading((loadedTags) => {
          return {
            ...loadedTags,
            [tagType]: false,
          };
        });

        setTags((tag) => ({
          ...tag,
          [tagType]: {
            tags: getUniqueTags([
              ...(tags[tagType]?.tags || []),
              ...(data?.results?.tags || []),
            ]),
            totalCount: data?.results?.totalCount,
          },
        }));

        setAllTags((tag) => ({
          ...tag,
          [tagType]: {
            tags: getUniqueTags([
              ...(tags[tagType]?.tags || []),
              ...(data?.results?.tags || []),
              ...(filter([activeTag], { type: tagType }) || []),
            ]),
            totalCount: data?.results?.totalCount,
          },
        }));
      });
    },
    [activeTag, lazyLoadTags, tags]
  );

  useEffect(() => {
    if (activeTag?.type && isEmpty(tags[activeTag?.type]?.tags)) {
      loadTags(activeTag?.type);
    }
  }, [activeTag?.type, loadTags, tags]);

  return {
    tags: allTags,
    activeTag,
    loadTags,
    tagsLoading,
  };
};

export default useLoadTags;
