import { get } from "lodash";

export const localStorageVisualisationKey = (username, tenantId) =>
  `visualisation-${username}-${tenantId}`;

const getFromLocalStorage = (storageKey) => {
  if (window.localStorage) {
    return JSON.parse(window.localStorage.getItem(storageKey));
  }
  return null;
};

export const saveVisualisationToLocalStorage = (
  storageKey,
  visualisation,
  tipsNotification
) => {
  const localStorageValues = {
    showDescendants: visualisation.settings.showDescendants,
    includeEmptyTeams: visualisation.settings.includeEmptyTeams || false,
    person: visualisation.settings.person,
    company: visualisation.settings.company,
    location: visualisation.settings.location,
    job: visualisation.settings.job,
    division: visualisation.settings.division,
    groupAssociation: visualisation.settings.groupAssociation,
    chartType: visualisation.chartType,
    tipsNotification,
  };

  if (window.localStorage) {
    window.localStorage.setItem(storageKey, JSON.stringify(localStorageValues));
  }
};

export const getInitVisualisationSettings = (auth, match, location) => {
  const localStorageValues = auth
    ? getFromLocalStorage(
        localStorageVisualisationKey(auth.username, auth.tenantId)
      )
    : null;
  const includeEmptyGroups = true;

  if (localStorageValues) {
    // TODO: helper funcs to create visual context obj
    return {
      auth,
      match,
      location,
      searchTerms: "",
      visualisation: {
        chartType: localStorageValues.chartType,
        settings: {
          showDescendants: localStorageValues.showDescendants,
          includeEmptyTeams:
            includeEmptyGroups ||
            get(localStorageValues, "includeEmptyTeams", false),
          person: localStorageValues.person,
          company: localStorageValues.company,
          location: localStorageValues.location,
          job: localStorageValues.job,
          division: localStorageValues.division,
          groupAssociation: localStorageValues.groupAssociation,
        },
      },
      visualisationSidebarEnable: false,
      tipsNotification: localStorageValues.tipsNotification,
      forceShowEmptyTeams: includeEmptyGroups,
    };
  }
  return {
    auth,
    match,
    location,
    searchTerms: "",
    visualisation: {
      chartType: "organic",
      settings: {
        showDescendants: false,
        includeEmptyTeams: includeEmptyGroups,
        person: true,
        company: true,
        location: true,
        job: true,
        division: true,
        groupAssociation: true,
      },
    },
    visualisationSidebarEnable: false,
    tipsNotification: true,
    forceShowEmptyTeams: includeEmptyGroups,
  };
};
