import React from "react";
import PropTypes from "prop-types";

import { H1, P } from "orcs-design-system";
import { copywriting } from "./ErrorPage.config";
import ErrorPageWrapper from "./ErrorPageWrapper";

const NetworkError = ({ clientInfo }) => {
  return (
    <ErrorPageWrapper clientInfo={clientInfo}>
      <H1 mb="between">{copywriting.networkErrorHeading}</H1>
      <P>{copywriting.networkErrorDetail}</P>
    </ErrorPageWrapper>
  );
};

NetworkError.propTypes = {
  clientInfo: PropTypes.string,
};

export default NetworkError;
