import { isNil } from "lodash";
import { roundFte } from "src/util/roundingStrategy";

import setDefaultConstraintFromPreviousCell from "../setDefaultConstraintFromPreviousCell";

const newCell = ({
  grouping,
  id,
  constraintId,
  groupId,
  allocationProjectId,
  isStatic,
  isDirectConstraint,
  constraint = null,
  defaultedConstraint = null,
  isDefaultFromInput = false,
  capacityFte = null,
}) => ({
  grouping,
  id,
  constraintId,
  groupId,
  allocationProjectId,
  isStatic,
  isDirectConstraint,
  constraint,
  defaultedConstraint,
  isDefaultFromInput,
  capacityFte,
  constraintSaveStatus: null,
});

const newConstraintCell = (grouping, constraint, allocationProject) => {
  const constraintValue = grouping.isVirtualDirectConstraintGrouping
    ? constraint.directConstraint
    : constraint.hierarchyConstraint;

  const id = grouping.isVirtualDirectConstraintGrouping
    ? `${constraint.id}-virtual-direct-constraint-cell`
    : constraint.id;

  const roundedConstraintValue = isNil(constraintValue)
    ? null
    : roundFte(constraintValue);

  return newCell({
    grouping,
    id,
    constraintId: constraint.id,
    groupId: constraint.groupId,
    allocationProjectId: constraint.allocationProjectId,
    isStatic: allocationProject.isActive,
    constraint: roundedConstraintValue,
    isDirectConstraint: grouping.isVirtualDirectConstraintGrouping,
    capacityFte: allocationProject.isActive ? grouping.capacityFte : null,
  });
};

const newDefaultedConstraintCell = (
  grouping,
  previousCell,
  allocationProject
) => {
  const groupId = grouping.isVirtualDirectConstraintGrouping
    ? grouping.parent.groupId
    : grouping.groupId;

  const constraintId = `constraint-${allocationProject.id}-${groupId}`;
  const id = grouping.isVirtualDirectConstraintGrouping
    ? `${constraintId}-virtual-direct-constraint-cell`
    : constraintId;

  const values = {
    grouping,
    id,
    constraintId,
    allocationProjectId: allocationProject.id,
    isStatic: allocationProject.isActive,
    defaultedConstraint: 0,
    isDefaultFromInput: false,
    isDirectConstraint: grouping.isVirtualDirectConstraintGrouping,
    capacityFte: allocationProject.isActive ? grouping.capacityFte : null,
  };

  if (allocationProject.isActive) {
    values.defaultedConstraint = grouping.capacityFte;
  } else {
    setDefaultConstraintFromPreviousCell(
      previousCell,
      values,
      allocationProject
    );
  }

  return newCell(values);
};

export { newConstraintCell, newDefaultedConstraintCell };
