import { Flex, Spacer, FlexItem } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import * as placeholder from "src/components/Placeholder/PlaceholderStyles";

import Layout from "./Layout";

const SearchRowPlaceholder = styled(Flex)`
  min-height: 52px;
`;

const Placeholder = ({ theme }) => (
  <Layout theme={theme}>
    <SearchRowPlaceholder
      alignItems="center"
      justifyContent="space-between"
      data-testid="cp-option-placeholder-wrapper"
    >
      <placeholder.Circle theme={theme} />
      <FlexItem>
        <Spacer m="xs">
          <placeholder.Line
            data-testid="cp-option-placeholder-title"
            theme={theme}
            width={140}
            height={22}
          />
          <placeholder.Line
            data-testid="cp-option-placeholder-subtitle"
            theme={theme}
            width={100}
            height={16}
          />
        </Spacer>
      </FlexItem>
    </SearchRowPlaceholder>
    <SearchRowPlaceholder
      alignItems="center"
      data-testid="cp-option-placeholder-action-wrapper"
    >
      <Spacer ml="s">
        <placeholder.Line width={32} height={28} theme={theme} />
        <placeholder.Line width={32} height={28} theme={theme} />
      </Spacer>
    </SearchRowPlaceholder>
  </Layout>
);

Placeholder.propTypes = {
  theme: PropTypes.string,
};

export default Placeholder;
