import { useApolloClient } from "@apollo/client";
import { debounce, without, split, get, map } from "lodash";
import { useCallback, useState, useMemo } from "react";

import {
  peopleSearch,
  getBasePeopleByIds,
  PersonBaseFragment,
} from "src/queries/person.graphql";
import { fullDisplayName } from "src/util/personName";

export const createPersonOptions = (people) => {
  return map(people, (person) => {
    return {
      label: fullDisplayName(person),
      value: person.aggregateId,
      person,
    };
  });
};

export const useSearchPerson = ({ fetchPolicy = "no-cache" } = {}) => {
  const client = useApolloClient();
  const [isSearching, setIsSearching] = useState(false);

  const getPersonQueryVars = useCallback(
    (keywordArray = "") => ({
      searchTerms: without(split(keywordArray, " "), "") || [keywordArray],
      size: 10,
    }),
    []
  );

  const loadPersonOptions = useMemo(
    () =>
      debounce((input, callback) => {
        setIsSearching(true);
        client
          .query({
            query: peopleSearch,
            variables: getPersonQueryVars(input),
            fetchPolicy,
          })
          .then(({ data: newData, error: newError }) => {
            if (newError) {
              return;
            }

            callback(createPersonOptions(get(newData, "result.people", [])));
            setIsSearching(false);
          });
      }, 300),
    [client, getPersonQueryVars, fetchPolicy]
  );

  return {
    loadPersonOptions,
    isSearching,
    client,
  };
};

export const useLoadPeople = () => {
  const client = useApolloClient();
  const [isLoading, setIsLoading] = useState(false);

  const loadPeople = useCallback(
    async (ids) => {
      setIsLoading(true);
      const {
        data: { people },
      } = await client.query({
        query: getBasePeopleByIds,
        variables: {
          ids,
        },
      });

      setIsLoading(false);
      return people;
    },
    [client]
  );

  return {
    loadPeople,
    isLoading,
    client,
  };
};

export const useCachedPerson = () => {
  const client = useApolloClient();

  const getCachedPerson = useCallback(
    (aggregateId) => {
      const person = client.readFragment({
        id: `Person:${aggregateId}`,
        fragment: PersonBaseFragment,
        fragmentName: "PersonBaseFragment",
      });

      return person;
    },
    [client]
  );

  return {
    getCachedPerson,
    client,
  };
};
