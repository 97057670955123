import { forEach, remove, every, isEmpty, isFinite } from "lodash";

export const pruneEmptyGroupings = (groupings, isBudgetEnabled) => {
  forEach(groupings, (grouping) => {
    pruneEmptyGroupings(grouping.childGroupings, isBudgetEnabled);

    // eslint-disable-next-line
    grouping.canToggleLineItems =
      !isEmpty(grouping.childGroupings) &&
      (!isEmpty(grouping.lineItems) > 0 || grouping.canAddLineItem);
  });

  remove(groupings, (g) => {
    if (isBudgetEnabled && isFinite(g.budget)) {
      return false;
    }
    return (
      (g.childGroupings.length === 0 && g.lineItems.length === 0) ||
      (g.memberCount === 0 && every(g.summedTotals, (t) => t.total === 0))
    );
  });
};

export const pruneEmptyConstraintGroupings = (groupings) => {
  forEach(groupings, (grouping) => {
    pruneEmptyConstraintGroupings(grouping.childGroupings);

    // eslint-disable-next-line
    grouping.canToggleLineItems =
      !isEmpty(grouping.childGroupings) &&
      (!isEmpty(grouping.lineItems) > 0 || grouping.canAddLineItem);
  });

  remove(
    groupings,
    (g) => g.memberCount === 0 && every(g.summedTotals, (t) => t.total === 0)
  );
};
