import React from "react";
import { Spacer, Typography } from "orcs-design-system";

const tourOptions = () => ({
  triggerInSidebar: false,
  autoStart: true,
  showProgress: false,
  showSkipButton: false,
  hideBackButton: true,
  hideFooter: true,
  steps: [
    {
      target: ".guided-tour-sidebar-icon",
      hideFooter: true,
      content: (
        <Spacer my={3}>
          <Typography.P weight="bold" textAlign="center">
            Hi there, did you know there is a guided tour for this page?
          </Typography.P>
          <Typography.Small textAlign="center">
            Click this icon to get started!
          </Typography.Small>
        </Spacer>
      ),
    },
  ],
});

export default tourOptions;
