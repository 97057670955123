import { systemtheme, systemThemeCollapsed } from "orcs-design-system";
import { useState, useCallback } from "react";

import { getActiveTheme, saveActiveTheme } from "src/services/localStorage";

const defaultSelectedTheme = "compact";
const useThemeSwitching = () => {
  const activeTheme = getActiveTheme();
  const selectedTheme =
    activeTheme === "compact" ||
    (!activeTheme && defaultSelectedTheme === "compact")
      ? systemThemeCollapsed
      : systemtheme;

  const compactThemeSelected =
    activeTheme === "compact" ||
    (!activeTheme && defaultSelectedTheme === "compact");

  const [theme, setTheme] = useState(selectedTheme);
  const [isCompactTheme, setIsCompactTheme] = useState(compactThemeSelected);

  const themeSwitcher = useCallback((switchTheme) => {
    if (switchTheme) {
      setTheme(systemThemeCollapsed);
    } else {
      setTheme(systemtheme);
    }
  }, []);

  const onToggleTheme = useCallback((switchToCompact) => {
    if (switchToCompact) {
      saveActiveTheme("compact");
      setIsCompactTheme(true);
    } else {
      saveActiveTheme("default");
      setIsCompactTheme(false);
    }
  }, []);

  return { onToggleTheme, themeSwitcher, theme, isCompactTheme };
};

export default useThemeSwitching;
