import React from "react";
import PropTypes from "prop-types";
import { Box } from "orcs-design-system";
import { useSelected, useFocused } from "slate-react";

import HashTag from "src/components/Tag";

const MentionElement = (props) => {
  const { attributes, element, children } = props;
  const selected = useSelected();
  const focused = useFocused();

  return (
    <Box
      display="inline-block"
      mr="xs"
      {...attributes}
      contentEditable={false}
      data-testid="cp-rte-plugin-mention"
    >
      <HashTag element={element} selected={selected} focused={focused}>
        {children}
      </HashTag>
    </Box>
  );
};

MentionElement.propTypes = {
  element: PropTypes.object,
  attributes: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default MentionElement;
