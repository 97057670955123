/* eslint-disable no-param-reassign */
import { reduce, find, isFinite, isEmpty, forEach } from "lodash";

export const setSupplyGroupingTotalBudget = (grouping) => {
  const { budgets } = grouping.group;

  if (isEmpty(budgets)) {
    return;
  }

  const totalBudgets = reduce(
    budgets,
    (total, budget) => {
      if (!isFinite(budget.value)) {
        return total;
      }
      return total + budget.value;
    },
    0
  );

  grouping.budget = totalBudgets !== 0 ? totalBudgets : null;
};

const addAllBudgets = (groupings, totalBudgets) => {
  if (isEmpty(groupings)) {
    return;
  }

  forEach(groupings, (grouping) => {
    if (isFinite(grouping.budget)) {
      totalBudgets.sum += grouping.budget;
    }

    // Find all childGroupings budgets
    addAllBudgets(grouping.childGroupings, totalBudgets);
  });
};

export const setDemandGroupingTotalBudget = (grouping) => {
  const { childGroupings } = grouping;

  const totalBudgets = { sum: 0 };
  addAllBudgets(childGroupings, totalBudgets);

  grouping.budget = totalBudgets.sum !== 0 ? totalBudgets.sum : null;
};

export const calculateBudgetsByGroupList = (grouping, relatedSupplyGroups) => {
  // In demand group view, we are loading all the budgets in relatedSupplyGroups list.
  // The budgets is filtered in back end, so the first one is always for the rootGroup
  const totalBudgets = reduce(
    relatedSupplyGroups,
    (total, g) => {
      const { budgets } = g;
      if (isEmpty(budgets)) {
        return total;
      }

      if (!isFinite(budgets[0].value)) {
        return total;
      }
      return total + budgets[0].value;
    },
    0
  );

  grouping.budget = totalBudgets !== 0 ? totalBudgets : null;
};

const calculateBudgetsForChildGroupings = (childGroupings, roleAreaGroups) => {
  forEach(childGroupings, (grouping) => {
    // Calculate childGrouping first
    calculateBudgetsForChildGroupings(grouping.childGroupings, roleAreaGroups);

    const { groupId } = grouping;
    // Find the budget and sum all up
    const totalBudgets = reduce(
      roleAreaGroups,
      (total, supplyGroup) => {
        const { budgets } = supplyGroup;
        if (isEmpty(budgets)) {
          return total;
        }

        const targetBudget = find(
          budgets,
          (budget) => budget.targetGroupId === groupId
        );

        if (!targetBudget || !isFinite(targetBudget.value)) {
          return total;
        }
        return total + targetBudget.value;
      },
      0
    );

    grouping.budget = totalBudgets !== 0 ? totalBudgets : null;
  });
};

export const setGroupingsBudget = (
  groupings,
  relatedSupplyGroups,
  roleAreaGroups
) => {
  forEach(groupings, (grouping) => {
    if (grouping.isSupply) {
      setSupplyGroupingTotalBudget(grouping);
    } else if (relatedSupplyGroups) {
      // if relatedSupplyGroups provided, use it
      calculateBudgetsByGroupList(grouping, relatedSupplyGroups);
      if (roleAreaGroups) {
        // set all children groupings with budget, without using the intersection
        calculateBudgetsForChildGroupings(
          grouping.childGroupings,
          roleAreaGroups
        );
      }
    } else {
      setDemandGroupingTotalBudget(grouping);
    }
  });
};
