import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  H5,
  H6,
  Small,
  Spacer,
  Toggle,
  DatePicker,
} from "orcs-design-system";
import moment from "moment";
import { isEmpty, isEqual } from "lodash";
import { useApolloClient } from "@apollo/client";

import {
  useDateSettings,
  useSetDateSettings,
  useClearDateSettings,
} from "src/contexts/global/ApolloSettingsContext";
import { useOldestPersonIndexDate } from "src/contexts/global/GlobalContext";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";
import usePrevious from "src/util/usePrevious";

import {
  getTeamDetail as getTeamDetailQuery,
  getMemberCountForTeams as getMemberCountForTeamsQuery,
} from "src/pages/TeamDetailPage/team.graphql";
import { getPersonQuery } from "src/queries/person.graphql";
import { peopleSearch as peopleSearchQuery } from "src/pages/PeopleListPage/peopleSearch.graphql";
import { listTeamsInTeamListPage as listTeamsQuery } from "src/pages/TeamListPage/queries.graphql";
import { getValueGraph as getValueGraphQuery } from "src/queries/valueGraph.graphql";

// Remove react-date dep
// https://github.com/react-dates/react-dates/issues/1973#issuecomment-615041579
// https://github.com/react-dates/react-dates/blob/master/src/utils/isBeforeDay.js
function isBeforeDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;

  const aYear = a.year();
  const aMonth = a.month();

  const bYear = b.year();
  const bMonth = b.month();

  const isSameYear = aYear === bYear;
  const isSameMonth = aMonth === bMonth;

  if (isSameYear && isSameMonth) return a.date() < b.date();
  if (isSameYear) return aMonth < bMonth;
  return aYear < bYear;
}

const QUERIES_TO_REFETCH = [
  getTeamDetailQuery,
  getMemberCountForTeamsQuery,
  getPersonQuery,
  peopleSearchQuery,
  listTeamsQuery,
  getValueGraphQuery,
];

const HistoricalDataSidebarContent = () => {
  const dateSettings = useDateSettings();
  const setDateSettings = useSetDateSettings();
  const clearDateSettings = useClearDateSettings();
  const [toDateFocused, updateToDateFocused] = useState(false);
  const [toDateFocusedInDateRangePicker, updateToDateFocusedInDateRangePicker] =
    useState(null);

  const oldestPersonIndexDate = useOldestPersonIndexDate();
  const oldestPersonIndexDateMoment = oldestPersonIndexDate
    ? moment(oldestPersonIndexDate)
    : moment();

  const onDatesChange = (datesChanged) => {
    const dates = datesChanged;
    if (isBeforeDay(datesChanged.endDate, datesChanged.startDate)) {
      // Switch startDate and endDate if endDate is earlier than startDate
      // eslint-disable-next-line prefer-destructuring
      dates.startDate = [dates.endDate, (dates.endDate = dates.startDate)][0];
    }
    setDateSettings({
      fromDate: dates.startDate,
      toDate: dates.endDate,
    });
    trackEvent(EVENT_TRACKING.SETTING_CHANGED, {
      setting: "historical_date",
      value: "date_range",
    });
  };

  const isDateSettingsActive = !isEmpty(dateSettings);

  const apolloClient = useApolloClient();

  const prevDateSettings = usePrevious(dateSettings);
  useEffect(() => {
    const hasChanged = !isEqual(prevDateSettings?.toDate, dateSettings?.toDate);

    // If prevDateSettings is undefined then the component will just have mounted and we don't want to refetch
    if (prevDateSettings && hasChanged) {
      apolloClient.refetchQueries({ include: QUERIES_TO_REFETCH });
    }
  }, [dateSettings, prevDateSettings, apolloClient]);

  return (
    <>
      <H5 fontWeight="bold">Historical data</H5>
      <Spacer my="r">
        <Divider light />
        <Toggle
          small
          id="compareModeToggle"
          label="Compare mode"
          checked={dateSettings.comparisonMode === true}
          onChange={(e) => {
            setDateSettings({ comparisonMode: e.target.checked });
          }}
        />
        <Small>
          Turning on compare mode will allow you to compare two dates in the
          past.
        </Small>
        {dateSettings.comparisonMode ? (
          <Spacer my="r">
            <Divider light />
            <H6 weight="bold">Compare dates</H6>
            <DatePicker
              range
              startDate={dateSettings.fromDate || moment()}
              startDateId="startDate"
              endDate={dateSettings.toDate || moment()}
              endDateId="endDate"
              startDatePlaceholderText="Start Date"
              endDatePlaceholderText="End Date"
              onDatesChange={onDatesChange}
              focusedInput={toDateFocusedInDateRangePicker}
              onFocusChange={updateToDateFocusedInDateRangePicker}
              displayFormat="DD/MM/YYYY"
              orientation="horizontal"
              verticalHeight={380}
              horizontalMargin={100}
              daySize={31}
              numberOfMonths={1}
              isOutsideRange={(day) =>
                isBeforeDay(day, oldestPersonIndexDateMoment)
              }
            />
          </Spacer>
        ) : (
          <Spacer my="r">
            <Divider light />
            <H6 weight="bold">View data on</H6>
            <DatePicker
              single
              id="historic-date-picker"
              focused={toDateFocused}
              date={dateSettings.toDate || moment()}
              onDateChange={(toDate) => {
                setDateSettings({ toDate });
                trackEvent(EVENT_TRACKING.SETTING_CHANGED, {
                  setting: "historical_date",
                  value: "single_date",
                });
              }}
              onFocusChange={({ focused }) => {
                updateToDateFocused(focused);
              }}
              numberOfMonths={1}
              daySize={31}
              displayFormat="DD/MM/YYYY"
              isOutsideRange={(day) =>
                isBeforeDay(day, oldestPersonIndexDateMoment)
              }
            />
          </Spacer>
        )}
        {isDateSettingsActive && (
          <Button small onClick={clearDateSettings}>
            Clear
          </Button>
        )}
      </Spacer>
    </>
  );
};

export default HistoricalDataSidebarContent;
