/* eslint-disable no-param-reassign */
import { mapValues, keyBy, reduce, forEach } from "lodash";
import { isTargetAreaGroup } from "./isTargetAreaGroup";

export const buildSourceGroupLookup = ({
  macroAllocations,
  descendantSupplyGroups,
  roleAreaGroups,
  relatedSupplyGroups,
}) => {
  // collect all our source groups
  return {
    ...mapValues(keyBy(macroAllocations, "sourceGroupId"), "sourceGroup"),
    ...keyBy(descendantSupplyGroups, "id"),
    ...keyBy(roleAreaGroups, "id"),
    ...keyBy(relatedSupplyGroups, "id"),
  };
};

export const buildTargetGroupLookup = ({
  macroAllocations,
  descendantTargetGroups,
  relatedBudgetTargetGroups,
  groupTypes,
}) => {
  // collect all our target groups
  const targetGroups = reduce(
    macroAllocations,
    (prev, ma) => {
      const { targetGroup } = ma;

      const { id, parentGroups } = targetGroup;

      forEach(parentGroups, (g) => {
        // Only target isDemand and isRoot parent groups
        if (isTargetAreaGroup(g, groupTypes)) {
          prev[g.id] = g;
        }
      });

      prev[id] = targetGroup;

      return prev;
    },
    {}
  );
  return {
    ...targetGroups,
    ...keyBy(descendantTargetGroups, "id"),
    ...keyBy(relatedBudgetTargetGroups, "id"),
  };
};
