import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { useSearchGroups, useLoadGroup } from "src/hooks/useSearchGroups";
import TeamDropdown from "src/components/TeamDropdown";

const TagAttributeGroupSelect = ({
  id,
  label,
  onSelectGroup,
  value,
  group,
  associatedValue: groupTypes,
  disabled,
  invalid,
  mandatory,
  focus,
}) => {
  const [selectedOption, setSelectedOption] = useState();
  const { loadOptions, isSearching } = useSearchGroups({
    groupTypes,
    includeMembers: false,
    hideHidden: true,
    fetchPolicy: "cache-first",
  });
  const { loadGroup, isLoading } = useLoadGroup();

  useEffect(() => {
    if (group) {
      setSelectedOption({
        label: group.name,
        value,
      });
    } else if (value) {
      loadGroup(value).then((loadedGroup) => {
        if (!loadedGroup) {
          return;
        }
        setSelectedOption({
          label: loadedGroup.name,
          value,
        });
      });
    } else {
      setSelectedOption(null);
    }
  }, [group, loadGroup, value]);

  return (
    <TeamDropdown
      id={id}
      label={label}
      placeholder="Find and select team"
      isSearchable
      isLoading={isSearching || isLoading}
      isDisabled={disabled}
      options={true}
      loadOptions={loadOptions}
      onSelectTeam={onSelectGroup}
      value={selectedOption}
      cacheOptions={false}
      invalid={invalid}
      mandatory={mandatory}
      focus={focus}
      isClearable={!disabled}
    />
  );
};

TagAttributeGroupSelect.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  associatedValue: PropTypes.array,
  value: PropTypes.string,
  group: PropTypes.object,
  onSelectGroup: PropTypes.func,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  mandatory: PropTypes.bool,
  focus: PropTypes.bool,
};
export default TagAttributeGroupSelect;
