import { hashGrouping } from "./util";

const updateModelWithNewTags = (state, action) => {
  const { grouping, updatedTags } = action;

  grouping.tags = updatedTags;

  hashGrouping(grouping, { rehashParents: true });

  return {
    ...state,
  };
};

export default updateModelWithNewTags;
