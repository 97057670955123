import { map } from "lodash";
import { Flex, FlexItem, Icon, P, Small } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { Fragment } from "react";

import { isLastBreadcrumbLink } from "src/components/Badge/GroupBadge.util";
import GroupTypeBadge from "src/components/Badge/GroupTypeBadge";
import { GroupTypesPropType } from "src/custom-prop-types/groupTypes";
import { getGroupNamesArrayFromHierarchy } from "src/util/group";

const FormatOptionLabel = ({ label, hierarchy, type, groupTypes }) => {
  const groups = getGroupNamesArrayFromHierarchy(hierarchy);
  // Remove the last hierarchy name as this will be the group name
  groups.pop();

  const hierarchyLabel = map(
    groups,
    (group, index) => {
      return (
        <Fragment key={`${group}-${index}`}>
          {group}
          {!isLastBreadcrumbLink(groups, group) && (
            <Icon icon={["far", "angle-right"]} size="xs" mx="xs" />
          )}
        </Fragment>
      );
    },
    []
  );

  return (
    <Flex alignItems="center" width="100%" justifyContent="space-between">
      <FlexItem flex="1 1 auto">
        <Small fontSize="0" color="greyDark">
          {hierarchyLabel}
        </Small>
        <P color="black">{label}</P>
      </FlexItem>
      {groupTypes && (
        <FlexItem flex="0 0 auto" ml="s">
          <GroupTypeBadge group={{ type }} groupTypes={groupTypes} />
        </FlexItem>
      )}
    </Flex>
  );
};

FormatOptionLabel.propTypes = {
  label: PropTypes.string,
  hierarchy: PropTypes.string,
  type: PropTypes.string,
  groupTypes: GroupTypesPropType,
};

export default FormatOptionLabel;
