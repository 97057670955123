import * as Sentry from "@sentry/react";
import { HttpClient } from "@sentry/integrations";
import debug from "debug";
import { genericPathname } from "src/util/url";

const disabledSentryLog = debug("orchestrated:sentry-disabled");

const isErrorReportingEnabled =
  process.env.REACT_APP_ENABLE_ERROR_REPORTING === "true";

const makeBeforeSendHandler = () => {
  if (isErrorReportingEnabled) {
    return (event, hint) => {
      const operationName =
        hint?.captureContext?.extra?.operation?.operationName;
      const statusCode = hint?.originalException?.statusCode;
      const ignoredOperation =
        operationName === "getActiveWorkspace" ||
        operationName === "getWorkspaces";

      if (ignoredOperation && statusCode === 400) {
        return null;
      }

      return event;
    };
  }
  return (event, hint) => {
    disabledSentryLog("Event %o, hint %o", event, hint);
    // handler returns null - this stops event going to sentry
    return null;
  };
};

const getTracingConfig = () => {
  if (process.env.REACT_APP_SENTRY_ENABLE_TRACING === "true") {
    return {
      integrations: [
        new Sentry.BrowserProfilingIntegration(),
        new Sentry.BrowserTracing({
          beforeNavigate: (context) => {
            return {
              ...context,
              name: genericPathname(location.pathname),
            };
          },
        }),
      ],
      options: {
        // Please do not change the sample rate from 10% without consulting Ryan first.
        tracesSampleRate: 0.1,
        profilesSampleRate: 0.1,
        tracePropagationTargets: [
          "localhost",
          "api.orchestrated.io",
          "api.dev.orchestrated.io",
          "api.integration.orchestrated.io",
          "api.dev.teamform.co",
          "api.teamform.co",
        ],
      },
    };
  }
  return { options: { tracesSampleRate: 0 }, integrations: [] };
};

export function initErrorReporting() {
  const tracingConfig = getTracingConfig();
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // Valid error scenarios that do not require review
    ignoreErrors: [
      "WORKSPACE_NOT_FOUND",
      "Login required",
      "Readonly mode, can not run mutations",
      "Failed to fetch",
      "Saving zero FTE is not allowed",
      "Please fill in empty attribute label in attributes",
    ],
    normalizeDepth: 6,
    maxValueLength: 500,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: `${process.env.REACT_APP_SENTRY_ENV}_${process.env.REACT_APP_GIT_SHA}`,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    beforeSend: makeBeforeSendHandler(),
    autoSessionTracking: isErrorReportingEnabled,
    sendClientReports: isErrorReportingEnabled,
    integrations: [...tracingConfig.integrations, new HttpClient()],
    ...tracingConfig.options,
  });
}
// TODO : Consider enhancing with these contexts as they are loaded rather than upfront
// - e.g. useWorkspace should set context for sentry etc.
export function configureErrorReporting(
  user,
  tenant,
  workspace,
  uiVersion,
  apiVersion,
  featureFlags
) {
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: user.email,
      username: user.preferredUsername,
      email: user.email,
    });
    scope.setTag("tenant", tenant);
    scope.setExtra("tenant", tenant);
    scope.setExtra("workspace", workspace);
    scope.setExtra("ui_version", uiVersion);
    scope.setExtra("api_version", apiVersion);
    scope.setExtra("feature_flags", featureFlags);
  });
}

export function reportError(error, errorInfo) {
  Sentry.captureException(error, { extra: errorInfo });
}

export function reportMessage(message, context) {
  Sentry.captureMessage(message, context);
}
