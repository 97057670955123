import EventEmitter from "eventemitter3";

const EE = new EventEmitter();

export const EVENTS = {
  // Group related. Used by sidebar groups tree manipulation
  CURRENT_GROUP_UPDATED: "CURRENT_GROUP_UPDATED",
  NEW_GROUP_ADDED: "NEW_GROUP_ADDED",
  GROUP_RENAMED: "GROUP_RENAMED",
  GROUP_REMOVED: "GROUP_REMOVED",

  // Sidebar related
  SIDEBAR_STATUS_CHANGED: "SIDEBAR_STATUS_CHANGED",

  // Tags navigation
  CURRENT_TAG_UPDATED: "CURRENT_TAG_UPDATED",
};

export default EE;
