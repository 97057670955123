import { Small, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import Icons from "src/config/icons";
import ExpandButton from "./ExpandButton";

const Container = styled.div`
  position: relative;
  max-height: ${(props) => `${props.maxHeight}px`};
  overflow: hidden;
  padding-bottom: ${(props) => (props.noCollapse ? "0px" : "20px")};
  transition: max-height 0.2s ease-in-out;

  &::after {
    content: "";
    display: ${(props) =>
      props.isCollapsed && !props.noCollapse ? "block" : "none"};
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100px;
    width: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 1) 80%
    );
    pointer-events: none;
  }
`;

const StyledExpandButton = styled(ExpandButton)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  align-items: center;
`;

const ExpandableContent = ({ children, maxHeight = 150 }) => {
  const [isCollapsed, setCollapsed] = useState(true);
  const [noCollapse, setNoCollapse] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    const el = containerRef.current;
    if (!el) {
      return;
    }

    setNoCollapse(el.scrollHeight <= maxHeight);
  }, [maxHeight, children]);

  const onClick = () => {
    const el = containerRef.current;
    if (!el) {
      return;
    }

    // Adjust section max-height
    if (isCollapsed) {
      el.style.maxHeight = `${el.scrollHeight}px`;
    } else {
      el.style.maxHeight = `${maxHeight}px`;
    }

    setCollapsed(!isCollapsed);
  };

  const title = isCollapsed ? "Show more" : "Show less";

  return (
    <Container
      ref={containerRef}
      isCollapsed={isCollapsed}
      maxHeight={maxHeight}
      noCollapse={noCollapse}
    >
      {children}
      {!noCollapse && (
        <StyledExpandButton
          aria-label={`${title} - visual effect only`}
          onClick={onClick}
        >
          <Small mr="xs">{title}</Small>
          <Icon icon={isCollapsed ? Icons.chevronDown : Icons.chevronUp} />
        </StyledExpandButton>
      )}
    </Container>
  );
};

ExpandableContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  maxHeight: PropTypes.number,
};

export default ExpandableContent;
