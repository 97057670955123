import constate from "constate";

import {
  useTenantId,
  useDateSettings,
} from "src/contexts/global/ApolloSettingsContext";

const useGlobalContext = (values) => values;

const [
  Provider,
  useGlobalContextError,
  useAuth,
  useAllocationProject,
  useOldestPersonIndexDate,
  useVersion,
  useUserPerson,
  useIsContextReady,
  useGlobalConfig,
  useMaintenanceMode,
  useGlobalStateDispatch,
  useImportDate,
] = constate(
  useGlobalContext,
  (value) => value.error,
  (value) => value.auth,
  (value) => value.allocationProject,
  (value) => value.oldestPersonIndexDate,
  (value) => value.version,
  (value) => value.userPerson,
  (value) => value.isContextReady,
  (value) => value.globalConfig,
  (value) => value.maintenanceMode,
  (value) => value.dispatch,
  (value) => value.importDate
);

export {
  Provider,
  useTenantId,
  useGlobalContextError,
  useAuth,
  useAllocationProject,
  useOldestPersonIndexDate,
  useVersion,
  useUserPerson,
  useIsContextReady,
  useDateSettings,
  useGlobalConfig,
  useMaintenanceMode,
  useGlobalStateDispatch,
  useImportDate,
};
