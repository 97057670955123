import { StyledLink, Box, Divider } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import { OptionValuePropType } from "src/custom-prop-types/search";

import TeamListItem from "src/components/Team/TeamListItem";

import Placeholder from "./sub-components/Placeholder";

const CustomStyledLink = styled(StyledLink)`
  &:hover {
    text-decoration: none;
  }
`;

const OptionBadge = ({
  data,
  isLoading,
  theme,
  groupTypes,
  workspace,
  selectedString,
}) => {
  if (isLoading) {
    return (
      <>
        <Box px="s" py="s">
          <Placeholder theme={theme} />
        </Box>
        <Divider light />
      </>
    );
  }

  const { group, link } = data;

  return (
    <CustomStyledLink
      data-testid="cp-option-link"
      to={link}
      display="block"
      width="100%"
    >
      <Box px="s">
        <TeamListItem
          isLastItem={data.customStyles}
          group={group}
          loading={isLoading}
          groupTypes={groupTypes}
          theme={theme}
          showInPage={true}
          workspace={workspace}
          selectedString={selectedString}
        />
      </Box>
    </CustomStyledLink>
  );
};

OptionBadge.propTypes = {
  data: OptionValuePropType,
  isLoading: PropTypes.bool,
  theme: PropTypes.string,
  groupTypes: PropTypes.object,
  workspace: PropTypes.object,
  selectedString: PropTypes.string,
};

export default OptionBadge;
