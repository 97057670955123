export const NOOP = () => {};
export const MAX_INVITE_NUMBERS = 10;

export const TAG_SEARCH_LIMIT = 100;
export const TAG_LOAD_LIMIT = 500;

export const TAGS_UI_COMPONENTS = {
  DIRECTORY: "Directory",
  NAVIGATION: "Navigation",
};
