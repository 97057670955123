import { TAGS_UI_COMPONENTS } from "src/consts/tags";
import useUserRole from "src/app/Restricted/useUserRole";
import { DIRECTORY } from "./consts";

const useTagsVisibleInFilter = () => {
  return useUserRole() === DIRECTORY
    ? TAGS_UI_COMPONENTS.DIRECTORY
    : TAGS_UI_COMPONENTS.TEAMFORM;
};

export default useTagsVisibleInFilter;
