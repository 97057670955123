import PropTypes from "prop-types";
import React from "react";

import useShowInvite from "./hooks/useShowInvite";

import InviteUser from "./InviteUser";

const InviteUserWrapper = ({ workspace, user }) => {
  const shouldShowInvite = useShowInvite({ workspace, user });

  if (!shouldShowInvite) {
    return null;
  }

  return <InviteUser />;
};

InviteUserWrapper.propTypes = {
  workspace: PropTypes.object,
  user: PropTypes.object,
};

export default React.memo(InviteUserWrapper);
