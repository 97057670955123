import { useEffect } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import { useAuth, useVersion } from "src/contexts/global/GlobalContext";
import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { useFeatureFlags } from "src/contexts/global/FeatureFlagsContext";
import { configureErrorReporting } from ".";

const ErrorReportingConfiguration = ({ children }) => {
  const workspace = useWorkspace();
  const auth = useAuth();
  const featureFlags = useFeatureFlags();
  const version = useVersion();

  const { tenantId } = auth;

  const UI_VERSION = process.env.REACT_APP_GIT_SHA.slice(0, 7);
  const UI_BUILD = process.env.REACT_APP_BUILD_NUMBER;
  const uiInfo = `${UI_VERSION} [${UI_BUILD}]`;

  const workspaceId = get(workspace, "id");
  const workspaceName = get(workspace, "name", "ACTIVE");
  const workspaceInfo = workspaceId
    ? `${workspaceName} [${workspaceId}]`
    : workspaceName;

  useEffect(() => {
    configureErrorReporting(
      auth,
      tenantId,
      workspaceInfo,
      uiInfo,
      version,
      featureFlags
    );
  }, [auth, tenantId, workspaceInfo, uiInfo, version, featureFlags]);

  return children;
};

ErrorReportingConfiguration.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ErrorReportingConfiguration;
