import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { GlobalStyles } from "orcs-design-system";
import { ThemeProvider } from "styled-components";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import debug from "debug";

import { createRoot } from "react-dom/client";

import {
  StandardErrorBoundary,
  FailsafeErrorBoundary,
} from "./components/ErrorBoundary";

import { Auth0Provider } from "./contexts/auth0Provider";
import { getAuth0ConnectionFromHostname } from "./services/auth";
import { initErrorReporting } from "./services/errorReporting";
import App from "./app";
import setupFontAwesome, { GlobalCustomSVGStyles } from "./setupFontAwesome";
import setupMomentLocale from "./setupMomentLocale";
import useThemeSwitching from "./hooks/useThemeSwitching";
import useFetchConfig from "./hooks/useFetchConfig";
import {
  GlobalSearchProvider,
  GlobalSearchDefaultScopeProvider,
} from "./components/Search/GlobalSearchContext";

import "./localization";

window.LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL;
debug.enable(process.env.REACT_APP_DEBUG);
// following sentry's advice to do this as soon as possible:
initErrorReporting();

const AppShell = () => {
  const { theme, themeSwitcher } = useThemeSwitching();
  const { config, isGenericDomain, isFetchingConfig } = useFetchConfig();

  useEffect(() => {
    setupFontAwesome();
    setupMomentLocale();
    momentDurationFormatSetup(moment);
  }, []);

  if (isFetchingConfig) {
    return null;
  }

  // config api response now available
  // This returns the Auth0 connection from the Config API endpoint (e.g. "Teamform", "Osprey", etc) based on the hostname
  // We can return null if we don't want to use enforced Single Sign On (SSO)
  const bypassSSO =
    new URLSearchParams(window.location.search).get("bypass-sso") === "1";
  const connection = !bypassSSO
    ? getAuth0ConnectionFromHostname(config?.auth0Connection)
    : null;

  return (
    <ThemeProvider theme={theme}>
      <GlobalSearchDefaultScopeProvider>
        <GlobalSearchProvider>
          <GlobalStyles />
          <GlobalCustomSVGStyles />
          <FailsafeErrorBoundary>
            <Router>
              <Auth0Provider
                audience={process.env.REACT_APP_AUTH0_AUDIENCE}
                client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
                domain={process.env.REACT_APP_AUTH0_DOMAIN}
                isGenericDomain={isGenericDomain}
                redirect_uri={window.location.origin}
                {...connection}
              >
                <StandardErrorBoundary>
                  <App themeSwitcher={themeSwitcher} />
                </StandardErrorBoundary>
              </Auth0Provider>
            </Router>
          </FailsafeErrorBoundary>
        </GlobalSearchProvider>
      </GlobalSearchDefaultScopeProvider>
    </ThemeProvider>
  );
};

createRoot(document.getElementById("root")).render(<AppShell />);
