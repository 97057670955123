export const EVENT_TRACKING = {
  ALLOCATION_SUGGESTED: "Allocation suggested",
  GUIDED_TOUR_STARTED: "Guided tour started",
  GUIDED_TOUR_COMPLETED: "Guided tour completed",
  GUIDED_TOUR_SKIPPED: "Guided tour skipped",
  PERSON_CONTACTED: "Person contacted",
  SEARCH_FILTERED: "Search filtered",
  SEARCH_VIEWED: "Search viewed",
  SEARCH_QUERY: "Search query",
  SETTING_CHANGED: "Setting changed",
  TEAM_CLICKED: "Team clicked",
  TEAM_CREATED: "Team created",
  TEAM_REMOVED: "Team removed",
  TEAM_UPDATED: "Team updated",
  TEAM_MOVED: "Team moved",
  TAG_CLICKED: "Tag clicked",
  USER_INVITED: "User invited",
  USER_LOGOUT: "User logout",
  URL_CLICKED: "URL clicked",
  WORKSPACE_SWITCHED: "Workspace switched",
};
