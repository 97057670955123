/* eslint-disable import/prefer-default-export */
import PropTypes from "prop-types";
import React from "react";
import { components } from "react-select";

import DropdownMenuList from "./DropdownMenuList";

export const getMenuList = (totalTagCount) => {
  const shouldDisplayTotalTagCount =
    totalTagCount?.total > totalTagCount?.currentSize;
  const MenuListComponent = ({ children, ...rest }) => {
    return (
      <>
        <components.MenuList {...rest}>{children}</components.MenuList>
        {shouldDisplayTotalTagCount && (
          <DropdownMenuList totalTagCount={totalTagCount} />
        )}
      </>
    );
  };
  MenuListComponent.propTypes = {
    children: PropTypes.elementType,
  };
  return MenuListComponent;
};
