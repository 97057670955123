import { forEach } from "lodash";

import setLineItemWithForecastCells from "./setLineItemWithForecastCells";

const setGroupingsWithForecastCells = ({
  groupings,
  macroAllocations,
  columns,
  groupedByDemand,
  rootGroup,
}) => {
  forEach(groupings, (grouping) => {
    forEach(grouping.lineItems, (lineItem) => {
      setLineItemWithForecastCells({
        lineItem,
        targetGroupId: groupedByDemand ? grouping.groupId : lineItem.groupId,
        sourceGroupId: groupedByDemand ? lineItem.groupId : grouping.groupId,
        macroAllocations,
        columns,
        rootGroup,
      });
    });

    setGroupingsWithForecastCells({
      groupings: grouping.childGroupings,
      macroAllocations,
      columns,
      groupedByDemand,
      rootGroup,
    });
  });
};

export default setGroupingsWithForecastCells;
