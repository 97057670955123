import { isNil } from "lodash";
import moment from "moment";

import { isContentEmpty } from "src/comments/components/RichTextEditor/serializer/slatePlainTextSerializer";
import { SAVED, SUBMITTED } from "src/allocation/consts";

import newNotification from "../newNotification";
import { DANGER, WARN, INFO } from "../../../../notificationStatus";

export default (macroAllocation, isRequestor, isSkillsMandatory) => {
  const {
    requestStatus,
    requestSavedAt,
    requestSubmittedAt,
    requested,
    requestSubmitted,
    forecastedAt,
    forecast,
    approvalSavedAt,
    approved,
    allocatedMemberFte,
    note, // skills
  } = macroAllocation;

  if (isRequestor) {
    // requestor, requestor cell notifications
    if (
      forecastedAt &&
      !requestSavedAt &&
      !isNil(forecast) &&
      forecast !== allocatedMemberFte
    ) {
      return newNotification({
        status: DANGER,
        message: "Request from forecast to confirm",
        groupMessage: "Requests to confirm",
      });
    }

    if (requestSavedAt && forecastedAt) {
      const forecastedAtMoment = moment(forecastedAt);
      const requestSavedAtMoment = moment(requestSavedAt);

      if (
        forecastedAtMoment.isAfter(requestSavedAtMoment) &&
        !isNil(forecast) &&
        forecast !== requested
      ) {
        return newNotification({
          status: DANGER,
          message: "Request from forecast to confirm",
          groupMessage: "Requests to confirm",
        });
      }
    }

    if (
      isSkillsMandatory &&
      requestSavedAt &&
      requested && // not a user cleared
      requested !== allocatedMemberFte && // not a user entered value same as pre-existing
      isContentEmpty(note)
    ) {
      return newNotification({
        status: DANGER,
        message: "Requirements must be added when making an FTE change",
        groupMessage: "Missing requirements from requests",
      });
    }

    if (requestStatus === SAVED && requestSavedAt && !requestSubmittedAt) {
      const requestSavedAtMoment = moment(requestSavedAt);

      return newNotification({
        status: WARN,
        message: `Request saved ${requestSavedAtMoment.fromNow()} waiting for submission`,
        groupMessage: "Requests to submit",
      });
    }

    if (requestSavedAt && requestSubmittedAt) {
      const requestSavedAtMoment = moment(requestSavedAt);
      const requestSubmittedAtMoment = moment(requestSubmittedAt);

      if (
        requestStatus === SAVED &&
        requestSavedAtMoment.isAfter(requestSubmittedAtMoment)
      ) {
        return newNotification({
          status: WARN,
          message: `Request saved ${requestSavedAtMoment.fromNow()} waiting for submission`,
          groupMessage: "Requests to submit",
        });
      }

      if (requestStatus === SUBMITTED) {
        return newNotification({
          status: INFO,
          message: `Request submitted ${requestSubmittedAtMoment.fromNow()}`,
          groupMessage: "Requests submitted",
        });
      }
    }

    return newNotification({
      status: null,
      message: null,
      groupMessage: null,
      columnMessage: null,
    });
  }

  // approver, requestor cell notifications
  if (requestSubmittedAt) {
    const requestSubmittedAtMoment = moment(requestSubmittedAt);
    if (approvalSavedAt) {
      const approvalSavedAtMoment = moment(approvalSavedAt);
      if (
        requestSubmittedAtMoment.isAfter(approvalSavedAtMoment) &&
        requestSubmitted !== approved
      ) {
        return newNotification({
          status: WARN,
          message: `Request change submitted ${requestSubmittedAtMoment.fromNow()} waiting response`,
          groupMessage: "Request changes submitted",
        });
      }
    } else {
      return newNotification({
        status: WARN,
        message: `Request submitted ${requestSubmittedAtMoment.fromNow()} waiting response`,
        groupMessage: "Request submitted",
      });
    }

    return newNotification({
      status: INFO,
      message: `Request submitted ${requestSubmittedAtMoment.fromNow()}`,
      groupMessage: "Requests submitted",
      columnMessage: null,
    });
  }

  return newNotification({
    status: null,
    message: null,
    groupMessage: null,
    columnMessage: null,
  });
};
