import { forEach, find } from "lodash";

import {
  getIndirectForecastFlg,
  getIndirectForecastTargetFlg,
} from "src/allocation/util/allocationProject";
import { INDIRECT_PLACEHOLDER } from "../../../macroAllocationTypes";
import {
  newLineItem,
  setLineItemWithForecastDefaultCells,
  getDailyRateByFlag,
} from "../../util";

const setGroupingsWithIndirectForecastLineItems = ({
  groupings,
  columns,
  groupTypes = {},
  rootGroup,
  isBudgetEnabled,
} = {}) => {
  const canHaveIndirectForecasts = getIndirectForecastFlg(
    groupTypes,
    rootGroup
  );

  // If root group does not allow placeholder, then do nothing
  if (!canHaveIndirectForecasts) {
    return;
  }

  forEach(groupings, (grouping) => {
    const { group, lineItems } = grouping;

    const isIndirectForecastTarget = getIndirectForecastTargetFlg(
      groupTypes,
      group
    );

    if (isIndirectForecastTarget) {
      const indirectForecastLineItem = find(lineItems, {
        groupId: rootGroup.id,
        type: INDIRECT_PLACEHOLDER,
      });

      // If the grouping is indirect placeholder target but the placer holder line item does not exist
      if (!indirectForecastLineItem) {
        const lineItem = newLineItem({
          group: rootGroup,
          grouping,
          groupId: rootGroup.id,
          columnCount: columns.length,
          canViewInDetails: true,
          canViewInTeamBuilder: true,
          isDemand: true,
          canRename: false,
          canHide: false,
          isSkillsMandatory: false,
          type: INDIRECT_PLACEHOLDER,
          dailyRate: getDailyRateByFlag(isBudgetEnabled, null, grouping),
        });

        grouping.lineItems.push(lineItem);
        setLineItemWithForecastDefaultCells({
          lineItem,
          targetGroupId: rootGroup.id,
          sourceGroupId: group.id,
          columns,
          rootGroup,
        });
      }
    }

    setGroupingsWithIndirectForecastLineItems({
      groupings: grouping.childGroupings,
      columns,
      groupTypes,
      rootGroup,
      isBudgetEnabled,
    });
  });
};

export default setGroupingsWithIndirectForecastLineItems;
