import React from "react";
import { useAuth } from "src/contexts/global/GlobalContext";
import AuthLayout from "src/components/AuthLayout";
import useCan from "./useCan";

const withAccess = (user, allowedRoles, Component, additionalProps) => {
  return function WithAccessComponent(props) {
    const { roles = [] } = useAuth();
    const can = useCan(user, roles, allowedRoles);
    return can ? (
      <Component {...props} {...additionalProps} />
    ) : (
      <AuthLayout>
        <h4>You do not have permissions to view this page</h4>
      </AuthLayout>
    );
  };
};

export default withAccess;
