import { get } from "lodash";

import { displayInitials } from "src/util/personName";
import { isPersonEntityTag } from "src/util/tags";

const getPersonEntityProps = (tag, tagTypeConfig) => {
  const personEntityTag = isPersonEntityTag(
    tag?.tagTypeConfig || tagTypeConfig
  );
  if (!personEntityTag) {
    return false;
  }
  const person = get(tag, "tagAttributesMetaData.person");

  if (person?.avatar) {
    return {
      avatarSrc: person.avatar,
    };
  }

  if (person && displayInitials(person) !== "?") {
    return {
      initials: displayInitials(person),
    };
  }

  return true;
};

export default getPersonEntityProps;
