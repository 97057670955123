import { isEmpty } from "lodash";
import { Box, Spacer, H5, Divider } from "orcs-design-system";
import PropTypes from "prop-types";
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";

import TreeView from "src/shared/TreeView";
import urlPaths, { url, matchPaths } from "src/consts/urlPaths";

import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";
import { buildTagsTree, filterTagTypes } from "./index.logic";
import TagTreeHeader from "./TagTreeHeader";
import useLoadTags from "./useLoadTags";
import useUpdateCurrentTag from "./useUpdateCurrentTagListener";

const customizeTreeHeader = (props) => {
  // eslint-disable-next-line react/prop-types
  return function WrappedTreeHeader({ node }) {
    return <TagTreeHeader {...props} node={node} />;
  };
};

const TagsNavigation = ({ tagTypesConfig, isSelected }) => {
  const [treeData, setTreeData] = useState([]);
  const toggleCountRef = useRef(0);
  const activeTagId = matchPaths()?.params?.id;

  const [targetTagTypes] = useMemo(() => {
    return [filterTagTypes(tagTypesConfig)];
  }, [tagTypesConfig]);

  const { activeTag, tags, loadTags, tagsLoading } = useLoadTags({
    activeTagId,
    isSelected,
  });

  const history = useHistory();

  useUpdateCurrentTag(setTreeData);

  const onNodeToggled = useCallback(
    async (node) => {
      const { id, toggled } = node;
      toggleCountRef.current += 1;

      if (toggled) {
        trackEvent(EVENT_TRACKING.TAG_CLICKED, {
          action: "tag_tree_navigation_expanded",
          totalToggles: toggleCountRef.current,
        });
        await loadTags(id, true);
      } else {
        trackEvent(EVENT_TRACKING.TAG_CLICKED, {
          action: "tag_tree_navigation_collapsed",
          totalToggles: toggleCountRef.current,
        });
      }
    },
    [loadTags]
  );

  useEffect(() => {
    if (isEmpty(targetTagTypes)) {
      return;
    }

    setTreeData((tree) => {
      return buildTagsTree({
        tagTypes: targetTagTypes,
        tags,
        tree,
        activeTag,
        tagsLoading,
      });
    });
  }, [activeTag, tags, tagsLoading, targetTagTypes]);

  const onNodeSelected = useCallback(
    async (node) => {
      const { id, isTagValue, isLoadMore } = node;

      if (isLoadMore) {
        await loadTags(id);
      } else if (isTagValue) {
        trackEvent(EVENT_TRACKING.TAG_CLICKED, {
          action: "tag_tree_navigation_selected",
        });
        history.push(url(urlPaths.TAG_DETAILS, { id }));
      } else if (node.toggled) {
        await onNodeToggled(node);
      }
    },
    [history, loadTags, onNodeToggled]
  );

  return (
    <Box data-testid="tags-navigation">
      <Spacer mb="r">
        <H5 fontWeight="bold" data-testid="heading">
          Tags
        </H5>
        <Divider light />
        {isEmpty(treeData) && <H5>No tags data available</H5>}
        <TreeView
          data={treeData}
          onNodeSelected={onNodeSelected}
          onNodeToggled={onNodeToggled}
          customizeTreeHeader={customizeTreeHeader}
          onToggle={onNodeToggled}
        />
      </Spacer>
    </Box>
  );
};

TagsNavigation.propTypes = {
  tagTypesConfig: PropTypes.object,
  isSelected: PropTypes.bool,
};

export default TagsNavigation;
