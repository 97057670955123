import { get, map, filter, join, startsWith, pullAll } from "lodash";
import { Notification } from "orcs-design-system";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

const NotificationsWrapper = styled(Notification)`
  background-color: transparent;
`;

const NotificationOnFailure = ({ failList }) => {
  const existingUsers = map(
    filter(failList, (res) => startsWith(res.reason, "existing member")),
    "email"
  );
  const invalidUsers = map(
    filter(failList, (res) => startsWith(res.reason, "invalid email")),
    "email"
  );
  const otherFailures = pullAll(map(failList, "email"), [
    ...existingUsers,
    ...invalidUsers,
  ]);

  const existingUsersMessage = `Failed to invite the following ${pluralize(
    "email",
    existingUsers.length
  )} already been invited: ${join(existingUsers, ",")}.`;

  const invalidUsersMessage = `Failed to invite the following ${pluralize(
    "email",
    invalidUsers.length
  )} in invalid format: ${join(invalidUsers, ",")}.`;

  const otherFailureMessage = `Failed to invite the following ${pluralize(
    "email",
    otherFailures.length
  )}. Please refresh the page and try again: ${join(otherFailures, ",")}.`;

  return (
    <>
      {existingUsers.length > 0 && (
        <Notification colour="danger">{existingUsersMessage}</Notification>
      )}

      {invalidUsers.length > 0 && (
        <Notification colour="danger">{invalidUsersMessage}</Notification>
      )}

      {otherFailures.length > 0 && (
        <Notification colour="danger">{otherFailureMessage}</Notification>
      )}
    </>
  );
};

const InviteNotification = ({ data }) => {
  const result = get(data, "result.invited", []);

  const successList = filter(result, "success");
  const successMessage = `Successfully sent invitation to ${
    successList.length
  } ${pluralize("email", successList.length)}.`;

  const failList = filter(result, (res) => !res.success);

  return (
    <NotificationsWrapper centered floating bottom="20px">
      {successList.length > 0 && (
        <Notification colour="success" mb="r">
          {successMessage}
        </Notification>
      )}

      {failList.length > 0 && <NotificationOnFailure failList={failList} />}
    </NotificationsWrapper>
  );
};

NotificationOnFailure.propTypes = {
  failList: PropTypes.array.isRequired,
};

InviteNotification.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InviteNotification;
