import { reduce, isEmpty, isFinite } from "lodash";

const createBudgetId = (grouping, parentGrouping) => {
  if (parentGrouping.isSupply) {
    return `${parentGrouping.groupId}-${grouping.groupId}`;
  }

  return `${grouping.groupId}-${parentGrouping.groupId}`;
};

export const flatBudgets = (budgetMap, group) => {
  const { id, budgets } = group;
  if (isEmpty(budgets)) {
    return;
  }

  reduce(
    budgets,
    (prev, budget) => {
      const { targetGroupId, value } = budget;
      // eslint-disable-next-line no-param-reassign
      prev[`${id}-${targetGroupId}`] = value;

      return prev;
    },
    budgetMap
  );
};

const buildBudgetLookup = (sourceGroupLookup, rootGroup) => {
  const initialMap = {};

  if (rootGroup.isSource && !isEmpty(rootGroup.budgets)) {
    flatBudgets(initialMap, rootGroup);
  }

  const lookupMap = reduce(
    sourceGroupLookup,
    (prev, group) => {
      flatBudgets(prev, group);
      return prev;
    },
    initialMap
  );

  return (grouping, targetField) => {
    let { parent: parentGrouping } = grouping;

    while (parentGrouping) {
      if (parentGrouping[targetField]) {
        const budgetId = createBudgetId(grouping, parentGrouping);
        const budget = lookupMap[budgetId];
        if (isFinite(budget)) {
          // eslint-disable-next-line no-param-reassign
          grouping.budget = budget;
          return;
        }
      }

      parentGrouping = parentGrouping.parent;
    }
  };
};

export default buildBudgetLookup;
