import themeGet from "@styled-system/theme-get";
import styled from "styled-components";

// eslint-disable-next-line import/prefer-default-export
export const CustomMenu = styled.div`
  background-color: ${(props) => themeGet("colors.white")(props)};
  border-radius: ${(props) => themeGet("radii.2")(props)};
  margin: ${(props) => themeGet("space.s")(props)} 0;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  z-index: 1;
  box-sizing: border-box;
  width: calc(100vw - 32px);
  left: 16px;
  position: fixed;
  top: ${(props) => themeGet("appScale.navBarSize")(props)};
  overflow: hidden;
  @media screen and (min-width: 700px) {
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
  }
`;
