import { Button, Icon } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import icons from "src/config/icons";

const TagsFormButton = ({ onClick, icon, caption }) => (
  <Button onClick={onClick} ariaLabel={caption} variant="ghost" small iconLeft>
    <Icon icon={icon} />
    {caption}
  </Button>
);

const TagEditBtn = ({
  // todo: user testing on this, or at least change the icon ?
  showFinishButton = false,
  editMode,
  isTagsEmpty,
  onEditClicked,
  onFinishEditClicked,
  isAssociationTag,
}) => {
  return (
    <>
      {editMode && showFinishButton && (
        <TagsFormButton
          onClick={onFinishEditClicked}
          caption="Finish"
          icon={icons.save}
        />
      )}

      {!editMode && (
        <TagsFormButton
          onClick={onEditClicked}
          caption={`${isTagsEmpty ? "Add" : "Edit"} ${
            isAssociationTag ? "association" : "tags"
          }`}
          icon={icons.pen}
        />
      )}
    </>
  );
};
TagsFormButton.propTypes = {
  icon: PropTypes.array.isRequired,
  caption: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
TagEditBtn.propTypes = {
  editMode: PropTypes.bool,
  showFinishButton: PropTypes.bool,
  isTagsEmpty: PropTypes.bool,
  onEditClicked: PropTypes.func.isRequired,
  onFinishEditClicked: PropTypes.func.isRequired,
  isAssociationTag: PropTypes.bool,
};

export default React.memo(TagEditBtn);
