import { values, forEach } from "lodash";
import { hashGrouping, hashLineItem } from "./util";

const updateModelWithRenamedTeam = (dynamicModel, groupId, name) => {
  const {
    lookups: { groupingLookup, lineItemLookup },
  } = dynamicModel;

  const grouping = groupingLookup[groupId];

  if (grouping) {
    grouping.group.name = name;
    hashGrouping(grouping, {
      rehashChildren: false,
      rehashParents: true,
    });
  }

  const allLineItems = values(lineItemLookup);
  forEach(allLineItems, (lineItem) => {
    if (lineItem.groupId === groupId) {
      lineItem.group.name = name; // eslint-disable-line
      hashLineItem(lineItem, {
        rehashChildren: false,
        rehashParents: true,
      });
    }
  });
  return dynamicModel;
};

export default updateModelWithRenamedTeam;
