import React, { useState } from "react";
import PropTypes from "prop-types";
import { mapValues } from "lodash";

import loadFeatureFlags from "src/services/featureFlags";
import Auth0UserProps from "src/custom-prop-types/auth0user";
import { Provider } from "./FeatureFlagsContext";

const FeatureFlagsContextProvider = (props) => {
  const { children, user } = props;

  const [featureFlags, setFeatureFlags] = useState({});
  const [isFetching, setIsFetching] = useState(false);

  if (!isFetching) {
    setIsFetching(true);
    loadFeatureFlags(user).then((allFeatureFlags) => {
      const enabledFeatureFlags = mapValues(allFeatureFlags, "enabled");
      setFeatureFlags({
        ...enabledFeatureFlags,
        isFeatureFlagsLoaded: true,
      });
    });
  }

  const values = { featureFlags };

  return <Provider {...values}>{children}</Provider>;
};

FeatureFlagsContextProvider.propTypes = {
  user: Auth0UserProps,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default FeatureFlagsContextProvider;
