import React from "react";
import { Spacer, Typography } from "orcs-design-system";
import { Trans } from "react-i18next";
import { t } from "i18next";

import {
  GROUPED_BY_DEMAND,
  GROUPED_BY_SUPPLY,
} from "src/allocation/pages/ForecastPage/context/reducer/viewModes";

/**
 * TODO: Localize the remaining text in this file.
 */

const tourOptions = ({
  demandLabel,
  supplyLabel,
  viewMode,
  isRequestorGroup,
}) => ({
  triggerInSidebar: false,
  autoStart: true,
  showProgress: false,
  showSkipButton: true,
  disableScrolling: true,
  dependsOn: ["allocationPlannerTour"],
  steps: [
    {
      target: ".guided-tour-allocation-forecast-panel",
      placement: "center",
      content: (
        <Spacer my={3}>
          <Typography.H4 weight="bold" textAlign="center">
            The first planner item is open.
          </Typography.H4>
          <Typography.P textAlign="center">
            Let me show you it works.
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-planner-line-item",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            <strong>FTE</strong> demand requests are made to{" "}
            <strong>Suppliers</strong> by the <strong>Demand</strong> groupings
            and teams.
          </Typography.P>
          {viewMode === GROUPED_BY_DEMAND && (
            <Typography.P textAlign="left">
              This line highlights a supplier and the demand request
            </Typography.P>
          )}
          {viewMode === GROUPED_BY_SUPPLY && (
            <Typography.P textAlign="left">
              This line highlights a requestor and its demands.
            </Typography.P>
          )}
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-line-item-role",
      content: (
        <Spacer my={3}>
          {viewMode === GROUPED_BY_DEMAND && (
            <Typography.P textAlign="left">
              This is the <strong>{supplyLabel}</strong> that either:
              <ul>
                <li>currently supplying an FTE allocation</li>
                <li>an FTE demand change is being requested</li>
              </ul>
              for this <strong>{demandLabel}</strong>.
            </Typography.P>
          )}
          {viewMode === GROUPED_BY_SUPPLY && (
            <Typography.P textAlign="left">
              This is the <strong>{demandLabel}</strong> that either:
              <ul>
                <li>currently has an FTE allocation</li>
                <li>is making a FTE allocation change request</li>
              </ul>
              from the <strong>{supplyLabel}</strong>.
            </Typography.P>
          )}
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-planner-current-cell",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            <Trans
              ns="allocationPlannerLineItemTour"
              i18nKey="plannerCurrentCell"
            >
              Current FTE count based <strong>on the start</strong> of the
              {{ allocationProject: t("glossary:allocationProject") }}.
            </Trans>
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-planner-requestor-cell-readonly",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            This is where an <strong>FTE demand request</strong> can be made, or
            the details of existing requests seen.
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-planner-requestor-cell",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            This is where an <strong>FTE demand request</strong> can be made, or
            the details of existing requests seen.
          </Typography.P>
          <Typography.P textAlign="left">
            If there is NO FTE demand change then no action is necessary.
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-cell-skills",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            <strong>Skill</strong> requirements capture of any specific skill
            needs that are known.
          </Typography.P>
          <Typography.P textAlign="left">
            <strong>
              Capture skills simply by typing a “#”, then start typing.
            </strong>{" "}
            A list of known skill options will be presented for choosing
          </Typography.P>
          <Typography.P textAlign="left">
            Again, please only enter <strong>Skill</strong> requirements in this
            box, all other notes or comments are captured in the next mentioned{" "}
            <strong>Notes.</strong>
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-planner-approver-cell-readonly",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            This is where an <strong>FTE supply response</strong> to a demand
            request can be seen, or the details of existing responses.
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-planner-approver-cell",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            This is where an <strong>FTE supply response</strong> to a demand
            request <strong>can be made</strong>, or the details of existing
            responses seen.
          </Typography.P>
          <Typography.P textAlign="left">
            If there is NO FTE demand and supply change then no action is
            necessary.
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-cell-comment",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            General <strong>Notes</strong> provide a place to see all related
            Planner interactions for this demand request.
          </Typography.P>
          {!isRequestorGroup && (
            <Typography.P textAlign="left">
              The <strong>Notes</strong> also provide a good way to{" "}
              <strong>see</strong> any other contextual information about the
              demand request as well as <strong>record</strong> information
              about the response.
            </Typography.P>
          )}
          {isRequestorGroup && (
            <Typography.P textAlign="left">
              The <strong>Notes</strong> also provide a good way to{" "}
              <strong>record</strong> any other contextual information about the
              demand request as well as <strong>see</strong> information about
              the response.
            </Typography.P>
          )}
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-add-role",
      content: (
        <Spacer my={3}>
          <Typography.P textAlign="left">
            <strong>Add Role</strong> allows for a new{" "}
            <strong>{supplyLabel}</strong> to be added against a{" "}
            <strong>{demandLabel}</strong> so that demands against this new{" "}
            <strong>{supplyLabel}</strong> can be made.
          </Typography.P>
          <Typography.P textAlign="left">
            On selection this will provide navigation of the Supply structure.
          </Typography.P>
        </Spacer>
      ),
    },
    {
      target: ".guided-tour-allocation-forecast-panel",
      placement: "center",
      content: (
        <Spacer my={3}>
          <Typography.H4 bold center>
            <strong>Happy planning!</strong>
          </Typography.H4>
          <Typography.P textAlign="left">
            You&apos;ve now learnt all about planning, try clicking around and
            adding some forecasts when your ready :)
          </Typography.P>
          <Typography.Small textAlign="left">
            We also love feedback, please leave any comments or suggestions in
            the help button below.
          </Typography.Small>
        </Spacer>
      ),
    },
  ],
});

export default tourOptions;
