import React, { useEffect } from "react";
import { P, StyledLink, Icon } from "orcs-design-system";
import AuthLayout from "src/components/AuthLayout";
import { useAuth0 } from "src/contexts/auth0Provider";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";

const Logout = () => {
  const { isAuthenticated, logout } = useAuth0();
  useEffect(() => {
    if (isAuthenticated) {
      trackEvent(EVENT_TRACKING.USER_LOGOUT);
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout small={true}>
      <P fontWeight="bold" textAlign="center">
        <Icon icon={["fas", "lock"]} mr="s" />
        You are now logged out
      </P>
      <P textAlign="center" mt="l" mb="s">
        <StyledLink button href="/">
          Log back in
        </StyledLink>
      </P>
    </AuthLayout>
  );
};

export default Logout;
