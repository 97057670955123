import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Box, Spacer, H5, Divider } from "orcs-design-system";

import { updateTreeObject } from "src/shared/TreeView/TreeView.util";

import GroupsNavigation from "./GroupsNavigation";
import LoadingBlock from "./LoadingBlock";

const GroupsNavigationLoader = ({
  root,
  treeData,
  rootGroupsLoading,
  groupTypes,
  hierarchyIds,
  setHierarchyIds,
}) => {
  const history = useHistory();
  const [groupsTree, setGroupsTree] = useState([]);

  const refreshTree = useCallback(() => {
    setGroupsTree((td) => updateTreeObject(td));
  }, []);

  const navigateTo = useCallback(
    (nextPathname, groupPathIds) => {
      setHierarchyIds(groupPathIds);
      history.push(nextPathname);
    },
    [history, setHierarchyIds]
  );

  // Put the treeData into a state, so that we can force a re-render by updating it
  useEffect(() => {
    setGroupsTree(treeData);
  }, [treeData]);

  if (rootGroupsLoading) {
    return <LoadingBlock />;
  }

  return (
    <Box>
      <Spacer mb="r">
        <H5 fontWeight="bold">Navigate teams</H5>
        <Divider light />
        <GroupsNavigation
          root={root}
          treeData={groupsTree}
          hierarchyIds={hierarchyIds}
          navigateTo={navigateTo}
          refreshTree={refreshTree}
          groupTypes={groupTypes}
        />
      </Spacer>
    </Box>
  );
};

GroupsNavigationLoader.propTypes = {
  rootGroupsLoading: PropTypes.bool,
  root: PropTypes.array,
  treeData: PropTypes.object,
  groupTypes: PropTypes.object,
  hierarchyIds: PropTypes.array,
  setHierarchyIds: PropTypes.func,
};

export default GroupsNavigationLoader;
