import PropTypes from "prop-types";

import GroupPermissionsPropType from "./groupPermissions";

// PersonPropType duplicated here to avoid cyclic dependency
const BasePersonPropType = PropTypes.shape({
  aggregateId: PropTypes.string.isRequired,
  address: PropTypes.string,
  city: PropTypes.string,
  avatar: PropTypes.string,
  company: PropTypes.string,
  email: PropTypes.string,
  employeeId: PropTypes.string,
  firstName: PropTypes.string,
  surname: PropTypes.string,
  preferredName: PropTypes.string,
  fte: PropTypes.number,
  gender: PropTypes.string,
  jobTitle: PropTypes.string,
  mobileNumber: PropTypes.string,
  telephoneNumber: PropTypes.string,
  createdAt: PropTypes.string,
  managedByHierarchy: PropTypes.array,
  managing: PropTypes.array,
  division: PropTypes.object,
  __typename: PropTypes.string,
});

const GroupPropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  hierarchy: PropTypes.string,
  hierarchyIds: PropTypes.array,
  type: PropTypes.string,
  members: PropTypes.arrayOf(BasePersonPropType),
  memberCount: PropTypes.number,
  groupCount: PropTypes.number,
  principleSetId: PropTypes.string,
  principles: PropTypes.array, // TODO: want PropTypes.arrayOf(PrinciplePropType)
  childTeams: PropTypes.array, // TODO: want PropTypes.arrayOf(GroupPropType)
  directParentId: PropTypes.string,
  directParent: PropTypes.object, // TODO: want GroupPropType
  parentGroups: PropTypes.array, // TODO: want PropTypes.arrayOf(GroupPropType)[Group]
  parentIds: PropTypes.arrayOf(PropTypes.string),
  descendantGroups: PropTypes.array, // TODO: want PropTypes.arrayOf(GroupPropType)[Group]
  childTeamTotalMemberCount: PropTypes.number,
  permissions: GroupPermissionsPropType,
});

export default GroupPropType;
