import React from "react";
import PropTypes from "prop-types";

import reducer from "./reducer";
import initialState from "./initialState";

export const SidebarContext = React.createContext();

export const SidebarContextProvider = ({
  children,
  initialState: initialStateOverride,
}) => {
  return (
    <SidebarContext.Provider
      value={React.useReducer(reducer, {
        ...initialState(),
        ...initialStateOverride,
      })}
    >
      {children}
    </SidebarContext.Provider>
  );
};

SidebarContextProvider.propTypes = {
  children: PropTypes.node,
  initialState: PropTypes.object,
};
