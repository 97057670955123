/* eslint-disable react/no-array-index-key */
import React from "react";
import PropTypes from "prop-types";
import { initial, isEmpty, last } from "lodash";
import { Flex, Icon, Spacer } from "orcs-design-system";

import styled from "styled-components";
import { getGroupName } from "src/allocation/util/group";
import GroupPropType from "src/custom-prop-types/group";
import GroupTypeBadge from "src/components/GroupTypeBadge";
import TeamLink from "src/components/TeamLink";
import TeamLinkWithIcon from "src/components/TeamLinkWithIcon";
import * as searchUtil from "src/util/search";
import HierarchyTooltip from "./HierarchyTooltip";

const StyledIcon = styled(Icon)`
  svg {
    opacity: 0.5;
    transition: opacity 300ms ease-in-out;
    transition-delay: 250ms;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
`;
// This component handles two variants of group.
// One maps to team in team ES index
// The other is the intersection FTE result set relating to alloc queries
const GroupHierarchyBreadcrumb = ({
  className,
  group,
  titleOnly = false,
  showGroupTypeBadge = false,
  showGroupTypeBadgeShowToolTip = false,
  groupTypeBadgeToolTipPosition = "bottom",
  showBreadcrumb = true,
  showFullHierarchyInBreadcrumb = false,
  extra,
  onLinkClick,
  onTooltipIconMouseOver,
  showTeamLinkWithIcon,
  showTeamName = true,
  selectedString,
  groupTypes,
  bold,
  containerClassName = "guided-tour-allocation-forecast-line-item-role",
  useChildren,
  children,
}) => {
  if (isEmpty(group)) {
    return null;
  }

  const teamName = group.name ? group.name : getGroupName(group.hierarchy);

  if (titleOnly) {
    return teamName;
  }

  const teamId = group.id || group.groupId || last(group.hierarchyIds);
  const parentGroupIds = group.parentIds || initial(group.hierarchyIds); // get all but last if using hierarchyIds

  const breadcrumbGroupIds = showFullHierarchyInBreadcrumb
    ? group.hierarchyIds
    : parentGroupIds;

  const TeamNameLink = showTeamLinkWithIcon ? TeamLinkWithIcon : TeamLink;

  if (useChildren) {
    return (
      <HierarchyTooltip
        className={className}
        selectedString={selectedString}
        group={group}
        showFullHierarchyInBreadcrumb={showFullHierarchyInBreadcrumb}
        bold={bold}
        onTooltipIconMouseOver={onTooltipIconMouseOver}
      >
        {children}
      </HierarchyTooltip>
    );
  }

  return (
    <Flex
      alignItems="center"
      data-testid="group-hierarchy-breadcrumb"
      className={containerClassName}
    >
      <Spacer mr="xs">
        {showBreadcrumb && !!breadcrumbGroupIds.length && (
          <HierarchyTooltip
            className={className}
            selectedString={selectedString}
            group={group}
            showFullHierarchyInBreadcrumb={showFullHierarchyInBreadcrumb}
            bold={bold}
            onTooltipIconMouseOver={onTooltipIconMouseOver}
          >
            <StyledIcon
              icon={["far", "layer-group"]}
              onMouseOver={onTooltipIconMouseOver}
              onFocus={onTooltipIconMouseOver}
            />
          </HierarchyTooltip>
        )}

        {showTeamName && (
          <TeamNameLink
            className={className}
            teamName={searchUtil.highlightSelectedString(
              teamName,
              selectedString
            )}
            team={{ id: teamId }}
            onLinkClick={onLinkClick}
            isHidden={group.isHidden}
            bold={bold}
          />
        )}

        {showGroupTypeBadge && (
          <GroupTypeBadge
            group={group}
            showToolTip={showGroupTypeBadgeShowToolTip}
            toolTipPosition={groupTypeBadgeToolTipPosition}
            groupTypes={groupTypes}
          />
        )}
        {extra}
      </Spacer>
    </Flex>
  );
};

GroupHierarchyBreadcrumb.propTypes = {
  group: GroupPropType.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  bold: PropTypes.bool,
  showBreadcrumb: PropTypes.bool,
  showGroupTypeBadge: PropTypes.bool,
  showGroupTypeBadgeShowToolTip: PropTypes.bool,
  groupTypeBadgeToolTipPosition: PropTypes.string,
  showFullHierarchyInBreadcrumb: PropTypes.bool,
  titleOnly: PropTypes.bool,
  extra: PropTypes.element,
  onLinkClick: PropTypes.func,
  onTooltipIconMouseOver: PropTypes.func,
  showTeamLinkWithIcon: PropTypes.bool,
  showTeamName: PropTypes.bool,
  selectedString: PropTypes.string,
  groupTypes: PropTypes.object,
  useChildren: PropTypes.bool,
  children: PropTypes.element,
};

export default GroupHierarchyBreadcrumb;
