import { get } from "lodash";
import {
  Avatar,
  Flex,
  FlexItem,
  StyledLink,
  Box,
  Divider,
  Badge,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import AvatarPlaceholder from "src/components/Placeholder/AvatarPlaceholder";
import { getTagsPath } from "src/util/paths";
import * as searchUtil from "src/util/search";

const TagListItem = ({ tag, loading, isLastItem, selectedString }) => {
  const tagId = get(tag, "id");

  const groupName = tagId && (
    <StyledLink to={getTagsPath(tagId)} display="block" width="100%">
      {searchUtil.highlightSelectedString(tag.displayValue, selectedString)}
    </StyledLink>
  );

  let itemDivider = null;
  if (!isLastItem) {
    itemDivider = <Divider light />;
  }

  return loading ? (
    <AvatarPlaceholder rows={1} />
  ) : (
    <>
      <Box py="s">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
        >
          <FlexItem flex="1 1 50%">
            <Avatar
              data-testid="cp-explore-team-list-item-panel"
              shape="tag"
              sizing="small"
              title={groupName}
              subtitle={<Badge my="xs">{tag.typeName}</Badge>}
              type="default"
            />
          </FlexItem>
          <Flex
            my="s"
            alignItems="center"
            justifyContent="flex-end"
            flexWrap="wrap"
          >
            {tag?.appliedCount && (
              <FlexItem flex="0 1 auto">
                <Badge>
                  {tag?.appliedCount ? `${tag.appliedCount} tagged` : ""}
                </Badge>
              </FlexItem>
            )}
          </Flex>
        </Flex>
      </Box>
      {itemDivider}
    </>
  );
};

TagListItem.propTypes = {
  tag: PropTypes.object, // number for placeholder
  loading: PropTypes.bool,
  isLastItem: PropTypes.bool,
  selectedString: PropTypes.string,
};

export default TagListItem;
