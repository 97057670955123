import { Flex, Box, Spacer, H1, P } from "orcs-design-system";
import React from "react";

import icons from "src/config/icons";
import EmptyAppNav from "src/components/Nav/EmptyAppNav";
import { ErrorBox, ErrorContainer, ErrorIcon } from "./ErrorPageStyles";
import ErrorFooter from "./ErrorFooter";
import { copywriting } from "./ErrorPage.config";

const SafeErrorMessage = () => {
  return (
    <>
      <EmptyAppNav useAppLinkRoute={false} />
      <ErrorContainer allowSpaceForHeader>
        <ErrorBox>
          <Flex
            flexDirection="row"
            justifyContent="start"
            alignItems="flex-start"
          >
            <ErrorIcon icon={icons.exclamationTriangle} />
            <Box px="r">
              <Spacer my="r">
                <H1 mb="between">{copywriting.safeErrorHeading}</H1>
                <P>{copywriting.safeErrorDetail}</P>
                <ErrorFooter />
              </Spacer>
            </Box>
          </Flex>
        </ErrorBox>
      </ErrorContainer>
    </>
  );
};

export default SafeErrorMessage;
