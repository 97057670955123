import { Flex, Small, Icon, FlexItem, P } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

const PersonFormatOptionLabel = ({ label, person }) => {
  return (
    <Flex justifyContent="space-between" key={label}>
      <Flex alignItems="center">
        <Icon color="grey" icon={["fas", "users"]} size="xs" mr="s" />
        <FlexItem>
          <P color="black">{label}</P>
        </FlexItem>
      </Flex>
      {person?.aggregateId && (
        <Flex>
          <Small>{person.aggregateId}</Small>
        </Flex>
      )}
    </Flex>
  );
};

PersonFormatOptionLabel.propTypes = {
  label: PropTypes.string,
  person: PropTypes.object,
};

export default PersonFormatOptionLabel;
