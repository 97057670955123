import { Flex, FlexItem, Spacer } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import * as placeholder from "src/components/Placeholder/PlaceholderStyles";

const Placeholder = ({ theme }) => (
  <Flex
    alignItems="center"
    justifyContent="flex-start"
    data-testid="cp-option-placeholder-wrapper"
  >
    <placeholder.Circle theme={theme} />
    <FlexItem>
      <Spacer m="xs">
        <placeholder.Line
          data-testid="cp-option-placeholder-title"
          theme={theme}
          width={140}
          height={22}
        />

        <placeholder.Line
          data-testid="cp-option-placeholder-subtitle"
          theme={theme}
          width={100}
          height={16}
        />
      </Spacer>
    </FlexItem>
  </Flex>
);

Placeholder.propTypes = {
  theme: PropTypes.string,
};

export default Placeholder;
