import {
  Small,
  Box,
  RadioButton,
  Spacer,
  H5,
  Divider,
} from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import useThemeSwitching from "src/hooks/useThemeSwitching";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";

const ThemeSwitchToggle = ({ themeSwitcher }) => {
  const { isCompactTheme, onToggleTheme } = useThemeSwitching();
  const onSpacingChange = ({ target }) => {
    const switchToCompact = target.value === "compact";
    onToggleTheme(switchToCompact);
    themeSwitcher(switchToCompact);
    trackEvent(EVENT_TRACKING.SETTING_CHANGED, {
      setting: "theme",
      value: target.value,
    });
  };

  return (
    <Spacer mb="r">
      <H5 fontWeight="bold">View options</H5>
      <Divider light />
      <Box>
        <Small display="block" fontWeight="bold" mb="xs">
          Compact mode
        </Small>
        <Small display="block" color="greyDark">
          Make font size and spacing smaller so more content can fit on the
          screen.
        </Small>
      </Box>
      <RadioButton
        name="spacing"
        label="On"
        onChange={onSpacingChange}
        checked={isCompactTheme}
        value="compact"
      />
      <RadioButton
        name="spacing"
        label="Off"
        onChange={onSpacingChange}
        checked={!isCompactTheme}
        value="regular"
      />
    </Spacer>
  );
};

ThemeSwitchToggle.propTypes = {
  themeSwitcher: PropTypes.func,
};

export default React.memo(ThemeSwitchToggle);
