import { last, find, get } from "lodash";
import {
  findGrouping,
  newGrouping,
  newGroupingOptions,
  setGroupingWithCalculatedSumTotals,
  setGroupingWithDefaultConstraints,
  hashGrouping,
} from "./util";

const updateModalWithNewTeam = (
  forecastModel,
  newTeam,
  teamType,
  showObjectives
) => {
  const parentGroupId = last(newTeam.parentIds);
  const grouping = findGrouping(forecastModel.groupings, parentGroupId);

  if (!grouping) {
    return forecastModel;
  }

  const { columns, lookups } = forecastModel;
  const { allocationProjectLookup } = lookups;
  const activeAllocationProject = find(allocationProjectLookup, "isActive");

  const childGrouping = newGrouping({
    ...newGroupingOptions({ group: newTeam, groupType: teamType }),
    parentGrouping: grouping,
    rootGrouping: grouping.root,
    nestedLevel: grouping.nestedLevel + 1,
    canAddLineItem: true,
    canViewInTeamBuilder: true,
    canViewInDetails: true,
    showObjectives,
    isObjectivesVerified: !get(
      activeAllocationProject,
      "shouldVerifyObjectives"
    ),
    columnCount: columns.length,
  });

  if (childGrouping.canHaveConstraints) {
    setGroupingWithDefaultConstraints(
      childGrouping,
      allocationProjectLookup,
      columns
    );
  }

  grouping.childGroupings.push(childGrouping);
  grouping.childGroupings.sort((a, b) =>
    a.group.name.localeCompare(b.group.name)
  );
  setGroupingWithCalculatedSumTotals(childGrouping, forecastModel.columns);
  hashGrouping(childGrouping, { rehashParents: true });

  // eslint-disable-next-line no-param-reassign
  forecastModel.lookups.groupingLookup[childGrouping.id] = childGrouping;

  return forecastModel;
};

export default updateModalWithNewTeam;
