import React from "react";

const BeforeCreateDatasourcesPage = React.lazy(() =>
  import("../pages/Datasource/BeforeCreateDatasourcesPage")
);
const CreateDatasourcesPage = React.lazy(() =>
  import("../pages/Datasource/CreateDatasourcesPage")
);
const CreateWorkspacePage = React.lazy(() =>
  import("../pages/Workspaces/CreateWorkspacePage")
);
const DatasourcesPage = React.lazy(() =>
  import("../pages/Datasource/DatasourcesPage")
);
const DatasourceDetailPage = React.lazy(() =>
  import("../pages/Datasource/DatasourceDetailPage")
);
const DatasourceSelectPage = React.lazy(() =>
  import("../pages/Datasource/DatasourceSelectPage")
);
const WorkspacesPage = React.lazy(() =>
  import("../pages/Workspaces/WorkspacesPage")
);
const WorkspaceConfigurationPage = React.lazy(() =>
  import("../pages/Workspaces/ConfigurationPage")
);
const InternalDashboard = React.lazy(() =>
  import("src/pages/InternalDashboard")
);
const AdminConfiguration = React.lazy(() =>
  import("../pages/AdminConfiguration")
);

export {
  InternalDashboard,
  BeforeCreateDatasourcesPage,
  CreateDatasourcesPage,
  DatasourcesPage,
  DatasourceDetailPage,
  DatasourceSelectPage,
  CreateWorkspacePage,
  WorkspacesPage,
  WorkspaceConfigurationPage,
  AdminConfiguration,
};
