import React from "react";
import PropTypes from "prop-types";
import { startCase, get } from "lodash";
import { HeaderSimple, StyledLink } from "orcs-design-system";
import Logo from "../Logo";

const EmptyAppNav = ({ logout, user }) => {
  const name = get(user, "name", "");
  const picture = get(user, "picture", "");
  const formattedUserName = startCase(name);

  return (
    <HeaderSimple
      appName={
        <StyledLink block to="/">
          <Logo />
        </StyledLink>
      }
      userName={formattedUserName}
      avatarSource={picture}
      logoutFunction={logout}
    />
  );
};

EmptyAppNav.propTypes = {
  logout: PropTypes.func,
  user: PropTypes.object,
};

export default EmptyAppNav;
