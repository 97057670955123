import { filter, keyBy } from "lodash";

export default ({ allocationProjects }) => {
  return keyBy(
    filter(
      allocationProjects,
      (allocationProject) =>
        allocationProject.forecast || allocationProject.isActive
    ),
    "id"
  );
};
