export const BASE_URL =
  process.env.REACT_APP_CONFIG_DOMAIN || window.location.origin;

export const getFetchHttpOptions = (getTokenSilently = null) => {
  let interceptors;

  if (getTokenSilently) {
    interceptors = {
      // every time we make an http request, this will run 1st before the request is made
      // url, path and route are supplied to the interceptor
      // request options can be modified and must be returned
      request: async ({ options }) => {
        if (!getTokenSilently) return options;

        const token = await getTokenSilently();
        const opts = {
          ...options,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        return opts;
      },
    };
  }

  return {
    cachePolicy: "no-cache",
    // this fails when running via cypress as chromeWebSecurity is set to false (origin header is not passed through).
    // but the failure doesn't seem to matter - so setting retries to 0 in this case to speed things up
    retries:
      process.env.REACT_APP_APP_ENV === "LOCAL" ||
      process.env.REACT_APP_GIT_SHA === "NETLIFY"
        ? 0
        : 3,
    interceptors,
  };
};

export const copywriting = {
  workspaceNotFound: "No workspace found",
  errorQueryingResults: "Sorry, an error has occurred",
  appName: "Team Directory",
};
