import { Box, Divider, StyledLink } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";

import Placeholder from "src/components/OptionBadge/sub-components/Placeholder";
import TagListItem from "src/components/Team/TagListItem";
import { OptionValuePropType } from "src/custom-prop-types/search";
import { trackEvent } from "src/services/segment";
import { EVENT_TRACKING } from "src/consts/eventTracking";

const OptionTagBadge = ({ data, isLoading, selectedString }) => {
  if (isLoading) {
    return (
      <>
        <Box px="s" py="s">
          <Placeholder />
        </Box>
        <Divider light />
      </>
    );
  }

  const { tag, link } = data;

  return (
    <StyledLink
      data-testid="cp-option-link"
      to={link}
      onClick={() => {
        trackEvent(EVENT_TRACKING.TAG_CLICKED, {
          action: "tag_styled_link_selected",
        });
      }}
      display="block"
      width="100%"
    >
      <Box px="s">
        <TagListItem
          isLastItem={data.customStyles}
          tag={tag}
          loading={isLoading}
          selectedString={selectedString}
        />
      </Box>
    </StyledLink>
  );
};

OptionTagBadge.propTypes = {
  data: OptionValuePropType,
  isLoading: PropTypes.bool,
  selectedString: PropTypes.string,
};

export default OptionTagBadge;
