/* eslint-disable import/prefer-default-export */
import PropTypes from "prop-types";

export const Tag = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  displayValue: PropTypes.string,
  isActive: PropTypes.bool,
});
