import { useState, useCallback } from "react";
import constate from "constate";

const useGuideTourContext = (props) => {
  const {
    initialValue = {
      availableInSidebar: {},
    },
  } = props;
  const [sidebarGuidedTour, setSidebarGuidedTour] = useState(initialValue);
  const setAvailableInSidebarGuidedTour = useCallback((tourName, available) => {
    setSidebarGuidedTour((value) => {
      const { availableInSidebar } = value;
      return {
        availableInSidebar: { ...availableInSidebar, [tourName]: available },
      };
    });
  }, []);

  return { sidebarGuidedTour, setAvailableInSidebarGuidedTour };
};

const [
  GuideTourContextProvider,
  useSidebarGuidedTour,
  useSetAvailableInSidebarGuidedTour,
] = constate(
  useGuideTourContext,
  (value) => value.sidebarGuidedTour,
  (value) => value.setAvailableInSidebarGuidedTour
);

export {
  GuideTourContextProvider,
  useSidebarGuidedTour,
  useSetAvailableInSidebarGuidedTour,
};
