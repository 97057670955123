/* eslint-disable no-param-reassign */
import {
  reduce,
  sortBy,
  forEach,
  values,
  keyBy,
  map,
  size,
  isEmpty,
  filter,
  includes,
} from "lodash";

import {
  getFormattedMsg,
  isContentEmpty,
} from "src/components/RichTextContent/RichTextContent.util";
import { TAG_LOAD_LIMIT, TAGS_UI_COMPONENTS } from "src/util/consts";

export const filterTagTypes = (tagTypes) =>
  filter(
    tagTypes,
    (config) =>
      includes(config?.visibleIn, TAGS_UI_COMPONENTS.NAVIGATION) &&
      !config?.isReserved &&
      !config?.isGroupAssociation
  );

export const getChildren = ({ tagType, tags, activeTag, loading }) => {
  if (!tagType) {
    return [];
  }

  if (!tags[tagType]) {
    return [];
  }

  const totalTagCount = tags[tagType]?.totalCount || 0;

  const children = map(tags[tagType]?.tags || [], (tag) => ({
    id: tag.id,
    name: tag.displayValue,
    active: tag?.id === activeTag?.id,
    toggled: false,
    isTagValue: true,
    tagValue: tag,
    children: null,
  }));

  if (loading) {
    return [
      ...children,
      {
        id: `${tagType}-loading`,
        name: "Loading...",
        active: false,
        toggled: false,
        children: null,
        loading: true,
      },
    ];
  }

  if (size(children) < totalTagCount) {
    const remaining = totalTagCount - size(children);

    return [
      ...children,
      {
        id: tagType,
        name: `+${remaining} tags, click to load next ${
          remaining < TAG_LOAD_LIMIT ? remaining : TAG_LOAD_LIMIT
        }`,
        active: false,
        toggled: false,
        isLoadMore: true,
        tagValue: null,
        children: null,
      },
    ];
  }

  return children;
};

export const buildTagsTree = ({
  tagTypes,
  tags,
  tree,
  activeTag,
  tagsLoading = {},
}) => {
  const treeById = keyBy(tree, "id");

  const tagTypesTree = reduce(
    sortBy(tagTypes, "name"),
    (prev, tagType) => {
      const { id, details } = tagType;

      const node = treeById[id];
      const { active = false, toggled = false } = node || {};

      const loading = !!tagsLoading[id];

      const children = getChildren({
        tagType: id,
        tags,
        activeTag,
        loading,
      });

      // eslint-disable-next-line no-param-reassign
      prev[id] = {
        ...tagType,
        active: activeTag && activeTag?.type === id ? true : active,
        toggled: activeTag && activeTag?.type === id ? true : toggled,
        isTagType: true,
        children,
      };

      const parsedDetails = getFormattedMsg(details);

      if (!loading && isEmpty(children)) {
        children.unshift({
          id: `${id}-no-children-message`,
          name: "No tags for this type",
          active: false,
          toggled: false,
          children: null,
        });
      }

      if (loading && isEmpty(children)) {
        children.unshift({
          id: `${id}-loading`,
          name: "Loading...",
          active: false,
          toggled: false,
          children: null,
          loading: true,
        });
      }

      if (details && !isContentEmpty(parsedDetails)) {
        children.unshift({
          id: `${id}-desc`,
          details: parsedDetails,
          active: false,
          toggled: false,
          isTagTypeDesc: true,
          children: null,
        });
      }

      return prev;
    },
    {}
  );

  return values(tagTypesTree);
};

export const resetTreeActiveNode = (tree, tag) => {
  const { id, type } = tag || {};

  forEach(tree, (node) => {
    if (node.id === type) {
      node.toggled = true;

      forEach(node.children, (c) => {
        if (c.id === id) {
          c.active = true;
        } else {
          c.active = false;
        }
      });
    } else if (node.toggled) {
      node.active = false;
      node.toggled = false;

      forEach(node.children, (c) => {
        c.active = false;
      });
    }
  });

  return [...tree];
};
