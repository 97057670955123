import { useCallback, useRef } from "react";

import { useWorkspace } from "src/contexts/global/WorkspaceContext";
import { useAllocationProject } from "src/contexts/global/GlobalContext";
import { EVENT_TRACKING } from "src/consts/eventTracking";
import { trackEvent } from "src/services/segment";
import getTeamPath from "./getTeamPath";

const useOnNodeSelected = ({ navigateTo }) => {
  const workspace = useWorkspace();
  const activeAllocationProject = useAllocationProject();
  const activeNodeHierarchyRef = useRef(null);
  trackEvent(EVENT_TRACKING.TEAM_CLICKED, {
    action: "team_tree_navigation_selected",
  });

  return useCallback(
    (node) => {
      if (!node) {
        return;
      }

      const { group } = node;

      const pathname = getTeamPath({
        group,
        workspace,
        activeAllocationProject,
      });

      activeNodeHierarchyRef.current = group.hierarchyIds;
      navigateTo(pathname, group.hierarchyIds);
    },
    [activeAllocationProject, navigateTo, workspace]
  );
};

export default useOnNodeSelected;
