// TODO: This is a quick hack to integrate the slate processing stuff we do around the place
// into something we can use both in PM and TD. Existing components were too coupled (read only view needs editor?)
// As it is just some tree processing I hope this is ok for now at least. (2022-06-06 !)
//
// only going to bother testing the top level - if it is an array, should be array of objects

import { every, isArray, isNil, isObject, map } from "lodash";
import { P, StyledLink } from "orcs-design-system";
import PropTypes from "prop-types";
import React from "react";
import { Text } from "slate";

// both pm and td have this
import { fixUrlProtocol } from "src/util/url";

import { parseLeaf } from "./RichTextContent.logic";

export { isRichTextContentEmpty } from "./RichTextContent.logic";

const BLOCK_TAGS = {
  p: "paragraph",
  li: "list-item",
  ul: "bulleted-list",
  ol: "numbered-list",
  blockquote: "quote",
  h1: "heading-one",
  h2: "heading-two",
  link: "link",
};

const { ul, li, ol, link } = BLOCK_TAGS;

// if it is object, should either be {} or have a prop called 'children' that is an arraya
const topLevelIsProbablyValid = (input) => {
  if (isArray(input)) {
    return every(input, isObject);
  }
  return input && input.children && isArray(input.children);
};

const literalToParagraphElement = (content) => {
  return {
    type: "paragraph",
    children: [{ text: content }],
  };
};

export const parseRichTextContent = (raw) => {
  if (isNil(raw)) {
    return [];
  }
  if (typeof raw !== "string") {
    throw new Error("parseRichTextContent called with non string param");
  }
  try {
    const parsed = JSON.parse(raw);
    if (topLevelIsProbablyValid(parsed)) {
      return parsed;
    }
  } catch {
    // todo : this is only format errors right?
  }
  return [literalToParagraphElement(raw)];
};

/**
 * Render a Slate node in RTE TextArea
 * @param {*} props - refer to PropTypes
 */
const Element = (props) => {
  const { attributes, children, element, fontSize } = props;

  switch (element.type) {
    case ul:
      return <ul {...attributes}>{children}</ul>;
    case li:
      return <li {...attributes}>{children}</li>;
    case ol:
      return <ol {...attributes}>{children}</ol>;
    case link:
      return <LinkElement {...props} />;
    default:
      return (
        <P mb="s" fontSize={fontSize} {...attributes}>
          {children}
        </P>
      );
  }
};

Element.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  element: PropTypes.object,
  fontSize: PropTypes.string,
};

const LinkElement = ({ attributes, children, element }) => {
  return (
    <StyledLink
      data-testid="cp-element-link"
      bold
      {...attributes}
      href={fixUrlProtocol(element.url)}
      target="_blank"
    >
      {children}
    </StyledLink>
  );
};

LinkElement.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.node,
  element: PropTypes.object,
};

const Content = ({ node, fontSize }) => {
  if (!node) {
    return null;
  }
  if (Text.isText(node)) {
    return parseLeaf(node, fontSize ? { fontSize } : undefined);
  }

  const children = map(node.children, (n, i) => (
    <Content key={i} node={n} fontSize={fontSize} />
  ));

  return (
    <Element element={node} fontSize={fontSize}>
      {children}
    </Element>
  );
};

Content.propTypes = {
  node: PropTypes.object,
  fontSize: PropTypes.string,
};

export const RichTextContentViewer = ({ content, fontSize }) => {
  if (isArray(content)) {
    return (
      <>
        {map(content, (n, i) => (
          <Content key={i} node={n} fontSize={fontSize} />
        ))}
      </>
    );
  }

  return <Content node={content} fontSize={fontSize} />;
};

RichTextContentViewer.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  fontSize: PropTypes.string,
};
