/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import { Box, Small } from "orcs-design-system";

const OptionalLabel = styled(Small)`
  display: block;
`;

const formatOptionLabel = ({ label, optionalLabel }) => {
  return (
    <Box>
      <span>{label}</span>
      {!!optionalLabel && optionalLabel !== label && (
        <OptionalLabel my="0" fontSize={0}>
          <strong>In:</strong> {optionalLabel}
        </OptionalLabel>
      )}
    </Box>
  );
};

export default formatOptionLabel;
