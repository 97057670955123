import React from "react";
import PropTypes from "prop-types";
import { Box, Popover, StatusDot, Icon } from "orcs-design-system";
import { isNil, get } from "lodash";

import { NOTIFICATION_STATUS } from "../../context/ForecastContext";
import getNotificationMessage from "../util/notificationMessage";

const variantMap = {
  [NOTIFICATION_STATUS.INFO]: "success",
  [NOTIFICATION_STATUS.WARN]: "warning",
  [NOTIFICATION_STATUS.DANGER]: "danger",
};
const iconMap = {
  [NOTIFICATION_STATUS.INFO]: (
    <Icon icon={["fas", "check"]} color="white" size="xs" transform="up-1" />
  ),
  [NOTIFICATION_STATUS.WARN]: (
    <Icon
      icon={["fas", "exclamation"]}
      color="white"
      size="xs"
      transform="up-1"
    />
  ),
  [NOTIFICATION_STATUS.DANGER]: (
    <Icon icon={["fas", "bell"]} color="white" size="xs" transform="up-1" />
  ),
};

const NotificationDot = ({
  notification = {},
  direction = "top",
  showTooltip = true,
  isCell = true,
  isGrouping = false,
  isRootGrouping = false,
  isColumn = false,
}) => {
  const { status, isGroupNotificationDisplayedOnRoot } = notification;
  const notificationMessage = getNotificationMessage(
    notification,
    isCell,
    isGrouping,
    isColumn
  );

  if (
    isNil(status) ||
    !notificationMessage ||
    (isRootGrouping && !isGroupNotificationDisplayedOnRoot)
  ) {
    return <div />;
  }

  if (showTooltip)
    return (
      <Box mx="xs" className="guided-tour-allocation-forecast-notification-dot">
        <Popover
          direction={direction}
          text={notificationMessage}
          textAlign="center"
          width="180px"
        >
          <StatusDot icon variant={variantMap[status]}>
            {get(iconMap, status)}
          </StatusDot>
        </Popover>
      </Box>
    );

  return (
    <Box mx="xs" className="guided-tour-allocation-forecast-notification-dot">
      <StatusDot icon variant={variantMap[status]}>
        {get(iconMap, status)}
      </StatusDot>
    </Box>
  );
};

NotificationDot.propTypes = {
  isCell: PropTypes.bool,
  isGrouping: PropTypes.bool,
  isRootGrouping: PropTypes.bool,
  isColumn: PropTypes.bool,
  showTooltip: PropTypes.bool,
  direction: PropTypes.string,
  notification: PropTypes.shape({
    status: PropTypes.number,
    message: PropTypes.string,
    groupMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    columnMessage: PropTypes.string,
  }),
};

export default NotificationDot;
