import React from "react";
import PropTypes from "prop-types";
import { Small, StyledLink } from "orcs-design-system";

const AppSwitchLink = ({ label, description, href }) => {
  return (
    <>
      <StyledLink href={href} target="_blank" mb="xs">
        {label}
      </StyledLink>
      <Small display="block" color="greyDark">
        {description}
      </Small>
    </>
  );
};

AppSwitchLink.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
};

export default AppSwitchLink;
