import PATHS, { PERSON_TAB, TEAM_TAB, url } from "../consts/urlPaths";

export function visualisationPath(type, aggregateId) {
  return url(PATHS.VISUALISATION_WITH_ID, {
    pageType: type,
    aggregateId,
  });
}

export function visualisationPersonPath(person) {
  return visualisationPath("person", person.aggregateId);
}

export function visualisationTeamPath(team) {
  return visualisationPath("team", team.id ? team.id : team.aggregateId);
}

export function personDetailsPath(person, type) {
  return url(PATHS.PERSON_DETAILS, {
    id: person.aggregateId,
    tabType: type || PERSON_TAB.DETAILS,
  });
}

export function teamDetailsPath(team, type) {
  if (!team) {
    return null;
  }
  const teamId = typeof team === "string" ? team : team?.id; // handle group object or just the group id
  return url(PATHS.TEAM_DETAILS, {
    id: teamId,
    tabType: type || TEAM_TAB.DETAILS,
  });
}

export function userHomePath(type) {
  return url(
    PATHS.USER_HOME.replace(":tabType", type || PERSON_TAB.USER_DASHBOARD)
  );
}

export function getTagsPath(id, urlImpl = url) {
  return urlImpl(PATHS.TAG_DETAILS, {
    id,
  });
}

export const getDefaultRoutePath = ({ userId, isRestricted }) => {
  // checks will be removed once we have a better UI to represent User Home page for all types of users
  if (userId && isRestricted) {
    return url(PATHS.DIRECTORY_USER_HOME);
  }

  if (isRestricted) {
    return url(PATHS.EXPLORE);
  }

  return userId ? userHomePath() : url(PATHS.TEAMS);
};
